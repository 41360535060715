/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')
const proto = {};
proto.pbs = require('./standard_item_notify_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.StandardItemNotifyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.StandardItemNotifyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.StandardItemNotify,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_StandardItemNotifyService_Create = new grpc.web.MethodDescriptor(
  '/pbs.StandardItemNotifyService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.StandardItemNotify,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotify} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.StandardItemNotify,
 *   !proto.pbs.Empty>}
 */
const methodInfo_StandardItemNotifyService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotify} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.StandardItemNotify} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.StandardItemNotifyServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Create',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Create,
      callback);
};


/**
 * @param {!proto.pbs.StandardItemNotify} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.StandardItemNotifyServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Create',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.StandardItemNotify,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_StandardItemNotifyService_Update = new grpc.web.MethodDescriptor(
  '/pbs.StandardItemNotifyService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.StandardItemNotify,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotify} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.StandardItemNotify,
 *   !proto.pbs.Empty>}
 */
const methodInfo_StandardItemNotifyService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotify} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.StandardItemNotify} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.StandardItemNotifyServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Update',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Update,
      callback);
};


/**
 * @param {!proto.pbs.StandardItemNotify} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.StandardItemNotifyServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Update',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.StandardItemNotifyId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_StandardItemNotifyService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.StandardItemNotifyService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.StandardItemNotifyId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotifyId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.StandardItemNotifyId,
 *   !proto.pbs.Empty>}
 */
const methodInfo_StandardItemNotifyService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.StandardItemNotifyId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.StandardItemNotifyId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.StandardItemNotifyServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Delete',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.StandardItemNotifyId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.StandardItemNotifyServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/Delete',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.StandardItemNotifyListReq,
 *   !proto.pbs.StandardItemNotifies>}
 */
const methodDescriptor_StandardItemNotifyService_List = new grpc.web.MethodDescriptor(
  '/pbs.StandardItemNotifyService/List',
  grpc.web.MethodType.UNARY,
  proto.pbs.StandardItemNotifyListReq,
  proto.pbs.StandardItemNotifies,
  /**
   * @param {!proto.pbs.StandardItemNotifyListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.StandardItemNotifies.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.StandardItemNotifyListReq,
 *   !proto.pbs.StandardItemNotifies>}
 */
const methodInfo_StandardItemNotifyService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.StandardItemNotifies,
  /**
   * @param {!proto.pbs.StandardItemNotifyListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.StandardItemNotifies.deserializeBinary
);


/**
 * @param {!proto.pbs.StandardItemNotifyListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.StandardItemNotifies)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.StandardItemNotifies>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.StandardItemNotifyServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/List',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_List,
      callback);
};


/**
 * @param {!proto.pbs.StandardItemNotifyListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.StandardItemNotifies>}
 *     A native promise that resolves to the response
 */
proto.pbs.StandardItemNotifyServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/List',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.StandardItemTypeList>}
 */
const methodDescriptor_StandardItemNotifyService_GetStandardItemTypeList = new grpc.web.MethodDescriptor(
  '/pbs.StandardItemNotifyService/GetStandardItemTypeList',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.StandardItemTypeList,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.StandardItemTypeList.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.StandardItemTypeList>}
 */
const methodInfo_StandardItemNotifyService_GetStandardItemTypeList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.StandardItemTypeList,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.StandardItemTypeList.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.StandardItemTypeList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.StandardItemTypeList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.StandardItemNotifyServiceClient.prototype.getStandardItemTypeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/GetStandardItemTypeList',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_GetStandardItemTypeList,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.StandardItemTypeList>}
 *     A native promise that resolves to the response
 */
proto.pbs.StandardItemNotifyServicePromiseClient.prototype.getStandardItemTypeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.StandardItemNotifyService/GetStandardItemTypeList',
      request,
      metadata || {},
      methodDescriptor_StandardItemNotifyService_GetStandardItemTypeList);
};


module.exports = proto.pbs;

