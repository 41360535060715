// source: exam_score.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var cutter_pb = require('./cutter_pb.js');
goog.object.extend(proto, cutter_pb);
var blank_pb = require('./blank_pb.js');
goog.object.extend(proto, blank_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var exams_pb = require('./exams_pb.js');
goog.object.extend(proto, exams_pb);
var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
var operation_log_pb = require('./operation_log_pb.js');
goog.object.extend(proto, operation_log_pb);
var semi_exam_score_pb = require('./semi_exam_score_pb.js');
goog.object.extend(proto, semi_exam_score_pb);
goog.exportSymbol('proto.pbs.AllTypeListResp', null, global);
goog.exportSymbol('proto.pbs.BackupExamDataReq', null, global);
goog.exportSymbol('proto.pbs.BackupExamDataResp', null, global);
goog.exportSymbol('proto.pbs.BlankClamp', null, global);
goog.exportSymbol('proto.pbs.BlankScore', null, global);
goog.exportSymbol('proto.pbs.Clamp', null, global);
goog.exportSymbol('proto.pbs.ClampScore', null, global);
goog.exportSymbol('proto.pbs.CutterScore', null, global);
goog.exportSymbol('proto.pbs.DatailType', null, global);
goog.exportSymbol('proto.pbs.EachItemScore', null, global);
goog.exportSymbol('proto.pbs.ExamIdReq', null, global);
goog.exportSymbol('proto.pbs.ExamScore', null, global);
goog.exportSymbol('proto.pbs.GenerateModelReq', null, global);
goog.exportSymbol('proto.pbs.GenerateScoreReq', null, global);
goog.exportSymbol('proto.pbs.Machine', null, global);
goog.exportSymbol('proto.pbs.MachineCraft', null, global);
goog.exportSymbol('proto.pbs.MachineEffectiveScore', null, global);
goog.exportSymbol('proto.pbs.MachineOperationResp', null, global);
goog.exportSymbol('proto.pbs.MachineParamResp', null, global);
goog.exportSymbol('proto.pbs.MachineProcess', null, global);
goog.exportSymbol('proto.pbs.MachineProcessScore', null, global);
goog.exportSymbol('proto.pbs.MachineScore', null, global);
goog.exportSymbol('proto.pbs.ModelImage', null, global);
goog.exportSymbol('proto.pbs.ModelImageByte', null, global);
goog.exportSymbol('proto.pbs.NCScore', null, global);
goog.exportSymbol('proto.pbs.OperateLog', null, global);
goog.exportSymbol('proto.pbs.OperateLogScore', null, global);
goog.exportSymbol('proto.pbs.OperateProcess', null, global);
goog.exportSymbol('proto.pbs.OperateProcessResp', null, global);
goog.exportSymbol('proto.pbs.OperateProcessScore', null, global);
goog.exportSymbol('proto.pbs.ProfessionalScore', null, global);
goog.exportSymbol('proto.pbs.ProfessionalScoreResp', null, global);
goog.exportSymbol('proto.pbs.ScoreSummaryResp', null, global);
goog.exportSymbol('proto.pbs.UpdateDetail', null, global);
goog.exportSymbol('proto.pbs.UpdateExamScoreFields', null, global);
goog.exportSymbol('proto.pbs.UpdateExamScoreReq', null, global);
goog.exportSymbol('proto.pbs.UpdateExamScoreResp', null, global);
goog.exportSymbol('proto.pbs.Workpiece', null, global);
goog.exportSymbol('proto.pbs.WorkpieceLathe', null, global);
goog.exportSymbol('proto.pbs.WorkpieceMilling', null, global);
goog.exportSymbol('proto.pbs.WorkpieceQuality', null, global);
goog.exportSymbol('proto.pbs.WorkpieceQualityMilling', null, global);
goog.exportSymbol('proto.pbs.WorkpieceQualityScore', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdateDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UpdateDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdateDetail.displayName = 'proto.pbs.UpdateDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdateExamScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.UpdateExamScoreReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.UpdateExamScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdateExamScoreReq.displayName = 'proto.pbs.UpdateExamScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.UpdateExamScoreResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.UpdateExamScoreResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.UpdateExamScoreResp.displayName = 'proto.pbs.UpdateExamScoreResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BackupExamDataReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BackupExamDataReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BackupExamDataReq.displayName = 'proto.pbs.BackupExamDataReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BackupExamDataResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BackupExamDataResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BackupExamDataResp.displayName = 'proto.pbs.BackupExamDataResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.AllTypeListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.AllTypeListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.AllTypeListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.AllTypeListResp.displayName = 'proto.pbs.AllTypeListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GenerateScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.GenerateScoreReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.GenerateScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GenerateScoreReq.displayName = 'proto.pbs.GenerateScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GenerateModelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GenerateModelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GenerateModelReq.displayName = 'proto.pbs.GenerateModelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceLathe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceLathe.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceLathe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceLathe.displayName = 'proto.pbs.WorkpieceLathe';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ModelImageByte = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ModelImageByte, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ModelImageByte.displayName = 'proto.pbs.ModelImageByte';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ModelImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ModelImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ModelImage.displayName = 'proto.pbs.ModelImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Clamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Clamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Clamp.displayName = 'proto.pbs.Clamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineCraft = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineCraft, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineCraft.displayName = 'proto.pbs.MachineCraft';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Workpiece = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Workpiece, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Workpiece.displayName = 'proto.pbs.Workpiece';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceMilling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.WorkpieceMilling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceMilling.displayName = 'proto.pbs.WorkpieceMilling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExamIdReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExamIdReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExamIdReq.displayName = 'proto.pbs.ExamIdReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfessionalScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ProfessionalScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfessionalScore.displayName = 'proto.pbs.ProfessionalScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfessionalScoreResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ProfessionalScoreResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.ProfessionalScoreResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfessionalScoreResp.displayName = 'proto.pbs.ProfessionalScoreResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineOperationResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineOperationResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineOperationResp.displayName = 'proto.pbs.MachineOperationResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreSummaryResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreSummaryResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreSummaryResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreSummaryResp.displayName = 'proto.pbs.ScoreSummaryResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EachItemScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.EachItemScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EachItemScore.displayName = 'proto.pbs.EachItemScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.NCScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.NCScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.NCScore.displayName = 'proto.pbs.NCScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineParamResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineParamResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineParamResp.displayName = 'proto.pbs.MachineParamResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateProcessResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.OperateProcessResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.OperateProcessResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateProcessResp.displayName = 'proto.pbs.OperateProcessResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BlankScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BlankScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BlankScore.displayName = 'proto.pbs.BlankScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ClampScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ClampScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.ClampScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ClampScore.displayName = 'proto.pbs.ClampScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BlankClamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.BlankClamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BlankClamp.displayName = 'proto.pbs.BlankClamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CutterScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CutterScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CutterScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CutterScore.displayName = 'proto.pbs.CutterScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Machine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Machine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Machine.displayName = 'proto.pbs.Machine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MachineProcess.repeatedFields_, null);
};
goog.inherits(proto.pbs.MachineProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineProcess.displayName = 'proto.pbs.MachineProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceQuality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.WorkpieceQuality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceQuality.displayName = 'proto.pbs.WorkpieceQuality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceQualityMilling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.WorkpieceQualityMilling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceQualityMilling.displayName = 'proto.pbs.WorkpieceQualityMilling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineEffectiveScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineEffectiveScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineEffectiveScore.displayName = 'proto.pbs.MachineEffectiveScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateProcess.displayName = 'proto.pbs.OperateProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLog.displayName = 'proto.pbs.OperateLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MachineScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MachineScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineScore.displayName = 'proto.pbs.MachineScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineProcessScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MachineProcessScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MachineProcessScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineProcessScore.displayName = 'proto.pbs.MachineProcessScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceQualityScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceQualityScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceQualityScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceQualityScore.displayName = 'proto.pbs.WorkpieceQualityScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateProcessScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.OperateProcessScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.OperateProcessScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateProcessScore.displayName = 'proto.pbs.OperateProcessScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLogScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.OperateLogScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.OperateLogScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLogScore.displayName = 'proto.pbs.OperateLogScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ExamScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.ExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExamScore.displayName = 'proto.pbs.ExamScore';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdateDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdateDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdateDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updateField: jspb.Message.getFieldWithDefault(msg, 3, 0),
    index: jspb.Message.getFieldWithDefault(msg, 4, 0),
    datailType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdateDetail}
 */
proto.pbs.UpdateDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdateDetail;
  return proto.pbs.UpdateDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdateDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdateDetail}
 */
proto.pbs.UpdateDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.UpdateExamScoreFields} */ (reader.readEnum());
      msg.setUpdateField(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 5:
      var value = /** @type {!proto.pbs.DatailType} */ (reader.readEnum());
      msg.setDatailType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdateDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdateDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdateDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdateField();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDatailType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string score_standard_id = 2;
 * @return {string}
 */
proto.pbs.UpdateDetail.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateDetail} returns this
 */
proto.pbs.UpdateDetail.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UpdateExamScoreFields update_field = 3;
 * @return {!proto.pbs.UpdateExamScoreFields}
 */
proto.pbs.UpdateDetail.prototype.getUpdateField = function() {
  return /** @type {!proto.pbs.UpdateExamScoreFields} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.UpdateExamScoreFields} value
 * @return {!proto.pbs.UpdateDetail} returns this
 */
proto.pbs.UpdateDetail.prototype.setUpdateField = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 index = 4;
 * @return {number}
 */
proto.pbs.UpdateDetail.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateDetail} returns this
 */
proto.pbs.UpdateDetail.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional DatailType datail_type = 5;
 * @return {!proto.pbs.DatailType}
 */
proto.pbs.UpdateDetail.prototype.getDatailType = function() {
  return /** @type {!proto.pbs.DatailType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pbs.DatailType} value
 * @return {!proto.pbs.UpdateDetail} returns this
 */
proto.pbs.UpdateDetail.prototype.setDatailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional double value = 6;
 * @return {number}
 */
proto.pbs.UpdateDetail.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateDetail} returns this
 */
proto.pbs.UpdateDetail.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.UpdateExamScoreReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdateExamScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdateExamScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdateExamScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateExamScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateDetailsList: jspb.Message.toObjectList(msg.getUpdateDetailsList(),
    proto.pbs.UpdateDetail.toObject, includeInstance),
    isSubmit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isSingle: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdateExamScoreReq}
 */
proto.pbs.UpdateExamScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdateExamScoreReq;
  return proto.pbs.UpdateExamScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdateExamScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdateExamScoreReq}
 */
proto.pbs.UpdateExamScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = new proto.pbs.UpdateDetail;
      reader.readMessage(value,proto.pbs.UpdateDetail.deserializeBinaryFromReader);
      msg.addUpdateDetails(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubmit(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdateExamScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdateExamScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdateExamScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateExamScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.UpdateDetail.serializeBinaryToWriter
    );
  }
  f = message.getIsSubmit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsSingle();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.UpdateExamScoreReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
 */
proto.pbs.UpdateExamScoreReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UpdateDetail update_details = 2;
 * @return {!Array<!proto.pbs.UpdateDetail>}
 */
proto.pbs.UpdateExamScoreReq.prototype.getUpdateDetailsList = function() {
  return /** @type{!Array<!proto.pbs.UpdateDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.UpdateDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.UpdateDetail>} value
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
*/
proto.pbs.UpdateExamScoreReq.prototype.setUpdateDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.UpdateDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.UpdateDetail}
 */
proto.pbs.UpdateExamScoreReq.prototype.addUpdateDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.UpdateDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
 */
proto.pbs.UpdateExamScoreReq.prototype.clearUpdateDetailsList = function() {
  return this.setUpdateDetailsList([]);
};


/**
 * optional bool is_submit = 3;
 * @return {boolean}
 */
proto.pbs.UpdateExamScoreReq.prototype.getIsSubmit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
 */
proto.pbs.UpdateExamScoreReq.prototype.setIsSubmit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_single = 4;
 * @return {boolean}
 */
proto.pbs.UpdateExamScoreReq.prototype.getIsSingle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
 */
proto.pbs.UpdateExamScoreReq.prototype.setIsSingle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string score_standard_id = 5;
 * @return {string}
 */
proto.pbs.UpdateExamScoreReq.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateExamScoreReq} returns this
 */
proto.pbs.UpdateExamScoreReq.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.UpdateExamScoreResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.UpdateExamScoreResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.UpdateExamScoreResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateExamScoreResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    newTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    singleScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.UpdateExamScoreResp}
 */
proto.pbs.UpdateExamScoreResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.UpdateExamScoreResp;
  return proto.pbs.UpdateExamScoreResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.UpdateExamScoreResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.UpdateExamScoreResp}
 */
proto.pbs.UpdateExamScoreResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOldTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewTotalScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSingleScore(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.UpdateExamScoreResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.UpdateExamScoreResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.UpdateExamScoreResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.UpdateExamScoreResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getNewTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSingleScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.UpdateExamScoreResp.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateExamScoreResp} returns this
 */
proto.pbs.UpdateExamScoreResp.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double old_total_score = 2;
 * @return {number}
 */
proto.pbs.UpdateExamScoreResp.prototype.getOldTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateExamScoreResp} returns this
 */
proto.pbs.UpdateExamScoreResp.prototype.setOldTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double new_total_score = 3;
 * @return {number}
 */
proto.pbs.UpdateExamScoreResp.prototype.getNewTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateExamScoreResp} returns this
 */
proto.pbs.UpdateExamScoreResp.prototype.setNewTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double single_score = 4;
 * @return {number}
 */
proto.pbs.UpdateExamScoreResp.prototype.getSingleScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.UpdateExamScoreResp} returns this
 */
proto.pbs.UpdateExamScoreResp.prototype.setSingleScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string score_standard_id = 5;
 * @return {string}
 */
proto.pbs.UpdateExamScoreResp.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.UpdateExamScoreResp} returns this
 */
proto.pbs.UpdateExamScoreResp.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BackupExamDataReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BackupExamDataReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BackupExamDataReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BackupExamDataReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examData: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BackupExamDataReq}
 */
proto.pbs.BackupExamDataReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BackupExamDataReq;
  return proto.pbs.BackupExamDataReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BackupExamDataReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BackupExamDataReq}
 */
proto.pbs.BackupExamDataReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BackupExamDataReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BackupExamDataReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BackupExamDataReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BackupExamDataReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.BackupExamDataReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BackupExamDataReq} returns this
 */
proto.pbs.BackupExamDataReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_data = 2;
 * @return {string}
 */
proto.pbs.BackupExamDataReq.prototype.getExamData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BackupExamDataReq} returns this
 */
proto.pbs.BackupExamDataReq.prototype.setExamData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string score_standard_id = 3;
 * @return {string}
 */
proto.pbs.BackupExamDataReq.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BackupExamDataReq} returns this
 */
proto.pbs.BackupExamDataReq.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BackupExamDataResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BackupExamDataResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BackupExamDataResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BackupExamDataResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    examData: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BackupExamDataResp}
 */
proto.pbs.BackupExamDataResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BackupExamDataResp;
  return proto.pbs.BackupExamDataResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BackupExamDataResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BackupExamDataResp}
 */
proto.pbs.BackupExamDataResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BackupExamDataResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BackupExamDataResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BackupExamDataResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BackupExamDataResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exam_data = 1;
 * @return {string}
 */
proto.pbs.BackupExamDataResp.prototype.getExamData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.BackupExamDataResp} returns this
 */
proto.pbs.BackupExamDataResp.prototype.setExamData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.AllTypeListResp.repeatedFields_ = [1,2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.AllTypeListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.AllTypeListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.AllTypeListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AllTypeListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineList: jspb.Message.toObjectList(msg.getMachineList(),
    common_pb.CommonType.toObject, includeInstance),
    workpieceList: jspb.Message.toObjectList(msg.getWorkpieceList(),
    common_pb.CommonType.toObject, includeInstance),
    clampList: jspb.Message.toObjectList(msg.getClampList(),
    common_pb.CommonType.toObject, includeInstance),
    blankMaterialList: jspb.Message.toObjectList(msg.getBlankMaterialList(),
    common_pb.CommonType.toObject, includeInstance),
    questionList: jspb.Message.toObjectList(msg.getQuestionList(),
    common_pb.CommonType.toObject, includeInstance),
    operateProcessList: jspb.Message.toObjectList(msg.getOperateProcessList(),
    common_pb.CommonType.toObject, includeInstance),
    operateLogList: jspb.Message.toObjectList(msg.getOperateLogList(),
    common_pb.CommonType.toObject, includeInstance),
    blankList: jspb.Message.toObjectList(msg.getBlankList(),
    common_pb.CommonType.toObject, includeInstance),
    cutterPointMaterialList: jspb.Message.toObjectList(msg.getCutterPointMaterialList(),
    common_pb.CommonType.toObject, includeInstance),
    cutterTypeList: jspb.Message.toObjectList(msg.getCutterTypeList(),
    common_pb.CommonType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.AllTypeListResp}
 */
proto.pbs.AllTypeListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.AllTypeListResp;
  return proto.pbs.AllTypeListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.AllTypeListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.AllTypeListResp}
 */
proto.pbs.AllTypeListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addMachine(value);
      break;
    case 2:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addWorkpiece(value);
      break;
    case 3:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addClamp(value);
      break;
    case 4:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addBlankMaterial(value);
      break;
    case 5:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addQuestion(value);
      break;
    case 6:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addOperateProcess(value);
      break;
    case 7:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addOperateLog(value);
      break;
    case 8:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addBlank(value);
      break;
    case 9:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addCutterPointMaterial(value);
      break;
    case 10:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addCutterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.AllTypeListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.AllTypeListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.AllTypeListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AllTypeListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getClampList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getBlankMaterialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getBlankList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getCutterPointMaterialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getCutterTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType machine = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getMachineList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setMachineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addMachine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearMachineList = function() {
  return this.setMachineList([]);
};


/**
 * repeated CommonType workpiece = 2;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getWorkpieceList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 2));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setWorkpieceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addWorkpiece = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearWorkpieceList = function() {
  return this.setWorkpieceList([]);
};


/**
 * repeated CommonType clamp = 3;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getClampList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 3));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setClampList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addClamp = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearClampList = function() {
  return this.setClampList([]);
};


/**
 * repeated CommonType blank_material = 4;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getBlankMaterialList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 4));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setBlankMaterialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addBlankMaterial = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearBlankMaterialList = function() {
  return this.setBlankMaterialList([]);
};


/**
 * repeated CommonType question = 5;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getQuestionList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 5));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearQuestionList = function() {
  return this.setQuestionList([]);
};


/**
 * repeated CommonType operate_process = 6;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getOperateProcessList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 6));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setOperateProcessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addOperateProcess = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearOperateProcessList = function() {
  return this.setOperateProcessList([]);
};


/**
 * repeated CommonType operate_log = 7;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getOperateLogList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 7));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setOperateLogList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addOperateLog = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearOperateLogList = function() {
  return this.setOperateLogList([]);
};


/**
 * repeated CommonType blank = 8;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getBlankList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 8));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setBlankList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addBlank = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearBlankList = function() {
  return this.setBlankList([]);
};


/**
 * repeated CommonType cutter_point_material = 9;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getCutterPointMaterialList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 9));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setCutterPointMaterialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addCutterPointMaterial = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearCutterPointMaterialList = function() {
  return this.setCutterPointMaterialList([]);
};


/**
 * repeated CommonType cutter_type = 10;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.AllTypeListResp.prototype.getCutterTypeList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 10));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.AllTypeListResp} returns this
*/
proto.pbs.AllTypeListResp.prototype.setCutterTypeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.AllTypeListResp.prototype.addCutterType = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AllTypeListResp} returns this
 */
proto.pbs.AllTypeListResp.prototype.clearCutterTypeList = function() {
  return this.setCutterTypeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.GenerateScoreReq.repeatedFields_ = [2,10,11,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GenerateScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GenerateScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GenerateScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GenerateScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cutterIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    blankId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ncRunTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    installCutterNum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    changeBlankNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hitCutterNum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ncProgramMap: (f = msg.getNcProgramMap()) ? f.toObject(includeInstance, undefined) : [],
    clampsList: jspb.Message.toObjectList(msg.getClampsList(),
    proto.pbs.Clamp.toObject, includeInstance),
    craftsList: jspb.Message.toObjectList(msg.getCraftsList(),
    proto.pbs.MachineCraft.toObject, includeInstance),
    workpiecesList: jspb.Message.toObjectList(msg.getWorkpiecesList(),
    proto.pbs.Workpiece.toObject, includeInstance),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    proto.pbs.ModelImageByte.toObject, includeInstance),
    workpieceMillingList: jspb.Message.toObjectList(msg.getWorkpieceMillingList(),
    proto.pbs.WorkpieceMilling.toObject, includeInstance),
    ma6GenerateScore: (f = msg.getMa6GenerateScore()) && semi_exam_score_pb.MA6GenerateScore.toObject(includeInstance, f),
    lab18GenerateScore: (f = msg.getLab18GenerateScore()) && semi_exam_score_pb.LAB18GenerateScore.toObject(includeInstance, f),
    spinCoaterGenerateScore: (f = msg.getSpinCoaterGenerateScore()) && semi_exam_score_pb.SpinCoaterGenerateScore.toObject(includeInstance, f),
    cleaningGenerateScore: (f = msg.getCleaningGenerateScore()) && semi_exam_score_pb.CleaningGenerateScore.toObject(includeInstance, f),
    developmentGenerateScore: (f = msg.getDevelopmentGenerateScore()) && semi_exam_score_pb.DevelopmentGenerateScore.toObject(includeInstance, f),
    plasmaGenerateScore: (f = msg.getPlasmaGenerateScore()) && semi_exam_score_pb.PlasmaGenerateScore.toObject(includeInstance, f),
    ionImplantationGenerateScore: (f = msg.getIonImplantationGenerateScore()) && semi_exam_score_pb.IonImplantationGenerateScore.toObject(includeInstance, f),
    electronBeamGenerateScore: (f = msg.getElectronBeamGenerateScore()) && semi_exam_score_pb.ElectronBeamGenerateScore.toObject(includeInstance, f),
    stepperGenerateScore: (f = msg.getStepperGenerateScore()) && semi_exam_score_pb.StepperGenerateScore.toObject(includeInstance, f),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    isSingleStep: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    simiProcessGenerateScore: (f = msg.getSimiProcessGenerateScore()) && semi_exam_score_pb.SimiProcessGenerateScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GenerateScoreReq}
 */
proto.pbs.GenerateScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GenerateScoreReq;
  return proto.pbs.GenerateScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GenerateScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GenerateScoreReq}
 */
proto.pbs.GenerateScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCutterIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlankId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcRunTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCutterNum(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangeBlankNum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitCutterNum(value);
      break;
    case 9:
      var value = msg.getNcProgramMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = new proto.pbs.Clamp;
      reader.readMessage(value,proto.pbs.Clamp.deserializeBinaryFromReader);
      msg.addClamps(value);
      break;
    case 11:
      var value = new proto.pbs.MachineCraft;
      reader.readMessage(value,proto.pbs.MachineCraft.deserializeBinaryFromReader);
      msg.addCrafts(value);
      break;
    case 12:
      var value = new proto.pbs.Workpiece;
      reader.readMessage(value,proto.pbs.Workpiece.deserializeBinaryFromReader);
      msg.addWorkpieces(value);
      break;
    case 13:
      var value = new proto.pbs.ModelImageByte;
      reader.readMessage(value,proto.pbs.ModelImageByte.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    case 14:
      var value = new proto.pbs.WorkpieceMilling;
      reader.readMessage(value,proto.pbs.WorkpieceMilling.deserializeBinaryFromReader);
      msg.addWorkpieceMilling(value);
      break;
    case 15:
      var value = new semi_exam_score_pb.MA6GenerateScore;
      reader.readMessage(value,semi_exam_score_pb.MA6GenerateScore.deserializeBinaryFromReader);
      msg.setMa6GenerateScore(value);
      break;
    case 16:
      var value = new semi_exam_score_pb.LAB18GenerateScore;
      reader.readMessage(value,semi_exam_score_pb.LAB18GenerateScore.deserializeBinaryFromReader);
      msg.setLab18GenerateScore(value);
      break;
    case 17:
      var value = new semi_exam_score_pb.SpinCoaterGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.SpinCoaterGenerateScore.deserializeBinaryFromReader);
      msg.setSpinCoaterGenerateScore(value);
      break;
    case 18:
      var value = new semi_exam_score_pb.CleaningGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.CleaningGenerateScore.deserializeBinaryFromReader);
      msg.setCleaningGenerateScore(value);
      break;
    case 19:
      var value = new semi_exam_score_pb.DevelopmentGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.DevelopmentGenerateScore.deserializeBinaryFromReader);
      msg.setDevelopmentGenerateScore(value);
      break;
    case 20:
      var value = new semi_exam_score_pb.PlasmaGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.PlasmaGenerateScore.deserializeBinaryFromReader);
      msg.setPlasmaGenerateScore(value);
      break;
    case 21:
      var value = new semi_exam_score_pb.IonImplantationGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.IonImplantationGenerateScore.deserializeBinaryFromReader);
      msg.setIonImplantationGenerateScore(value);
      break;
    case 22:
      var value = new semi_exam_score_pb.ElectronBeamGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.ElectronBeamGenerateScore.deserializeBinaryFromReader);
      msg.setElectronBeamGenerateScore(value);
      break;
    case 23:
      var value = new semi_exam_score_pb.StepperGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.StepperGenerateScore.deserializeBinaryFromReader);
      msg.setStepperGenerateScore(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSingleStep(value);
      break;
    case 26:
      var value = new semi_exam_score_pb.SimiProcessGenerateScore;
      reader.readMessage(value,semi_exam_score_pb.SimiProcessGenerateScore.deserializeBinaryFromReader);
      msg.setSimiProcessGenerateScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GenerateScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GenerateScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GenerateScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GenerateScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCutterIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBlankId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNcRunTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getInstallCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getChangeBlankNum();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getHitCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNcProgramMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getClampsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.pbs.Clamp.serializeBinaryToWriter
    );
  }
  f = message.getCraftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.pbs.MachineCraft.serializeBinaryToWriter
    );
  }
  f = message.getWorkpiecesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.pbs.Workpiece.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.pbs.ModelImageByte.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceMillingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.pbs.WorkpieceMilling.serializeBinaryToWriter
    );
  }
  f = message.getMa6GenerateScore();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      semi_exam_score_pb.MA6GenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getLab18GenerateScore();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      semi_exam_score_pb.LAB18GenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getSpinCoaterGenerateScore();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      semi_exam_score_pb.SpinCoaterGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningGenerateScore();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      semi_exam_score_pb.CleaningGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentGenerateScore();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      semi_exam_score_pb.DevelopmentGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaGenerateScore();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      semi_exam_score_pb.PlasmaGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationGenerateScore();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      semi_exam_score_pb.IonImplantationGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamGenerateScore();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      semi_exam_score_pb.ElectronBeamGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperGenerateScore();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      semi_exam_score_pb.StepperGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getIsSingleStep();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getSimiProcessGenerateScore();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      semi_exam_score_pb.SimiProcessGenerateScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.GenerateScoreReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string cutter_ids = 2;
 * @return {!Array<string>}
 */
proto.pbs.GenerateScoreReq.prototype.getCutterIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setCutterIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.addCutterIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearCutterIdsList = function() {
  return this.setCutterIdsList([]);
};


/**
 * optional string blank_id = 3;
 * @return {string}
 */
proto.pbs.GenerateScoreReq.prototype.getBlankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setBlankId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double nc_run_time = 5;
 * @return {number}
 */
proto.pbs.GenerateScoreReq.prototype.getNcRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setNcRunTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 install_cutter_num = 6;
 * @return {number}
 */
proto.pbs.GenerateScoreReq.prototype.getInstallCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setInstallCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 change_blank_num = 7;
 * @return {number}
 */
proto.pbs.GenerateScoreReq.prototype.getChangeBlankNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setChangeBlankNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 hit_cutter_num = 8;
 * @return {number}
 */
proto.pbs.GenerateScoreReq.prototype.getHitCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setHitCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * map<string, string> nc_program = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.GenerateScoreReq.prototype.getNcProgramMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearNcProgramMap = function() {
  this.getNcProgramMap().clear();
  return this;
};


/**
 * repeated Clamp clamps = 10;
 * @return {!Array<!proto.pbs.Clamp>}
 */
proto.pbs.GenerateScoreReq.prototype.getClampsList = function() {
  return /** @type{!Array<!proto.pbs.Clamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Clamp, 10));
};


/**
 * @param {!Array<!proto.pbs.Clamp>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setClampsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.pbs.Clamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Clamp}
 */
proto.pbs.GenerateScoreReq.prototype.addClamps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.pbs.Clamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearClampsList = function() {
  return this.setClampsList([]);
};


/**
 * repeated MachineCraft crafts = 11;
 * @return {!Array<!proto.pbs.MachineCraft>}
 */
proto.pbs.GenerateScoreReq.prototype.getCraftsList = function() {
  return /** @type{!Array<!proto.pbs.MachineCraft>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MachineCraft, 11));
};


/**
 * @param {!Array<!proto.pbs.MachineCraft>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setCraftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.MachineCraft=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineCraft}
 */
proto.pbs.GenerateScoreReq.prototype.addCrafts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.MachineCraft, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearCraftsList = function() {
  return this.setCraftsList([]);
};


/**
 * repeated Workpiece workpieces = 12;
 * @return {!Array<!proto.pbs.Workpiece>}
 */
proto.pbs.GenerateScoreReq.prototype.getWorkpiecesList = function() {
  return /** @type{!Array<!proto.pbs.Workpiece>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Workpiece, 12));
};


/**
 * @param {!Array<!proto.pbs.Workpiece>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setWorkpiecesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pbs.Workpiece=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.GenerateScoreReq.prototype.addWorkpieces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pbs.Workpiece, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearWorkpiecesList = function() {
  return this.setWorkpiecesList([]);
};


/**
 * repeated ModelImageByte model_image = 13;
 * @return {!Array<!proto.pbs.ModelImageByte>}
 */
proto.pbs.GenerateScoreReq.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImageByte>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ModelImageByte, 13));
};


/**
 * @param {!Array<!proto.pbs.ModelImageByte>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.pbs.ModelImageByte=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImageByte}
 */
proto.pbs.GenerateScoreReq.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.pbs.ModelImageByte, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};


/**
 * repeated WorkpieceMilling workpiece_milling = 14;
 * @return {!Array<!proto.pbs.WorkpieceMilling>}
 */
proto.pbs.GenerateScoreReq.prototype.getWorkpieceMillingList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceMilling, 14));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceMilling>} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setWorkpieceMillingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.WorkpieceMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceMilling}
 */
proto.pbs.GenerateScoreReq.prototype.addWorkpieceMilling = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.WorkpieceMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearWorkpieceMillingList = function() {
  return this.setWorkpieceMillingList([]);
};


/**
 * optional MA6GenerateScore ma6_generate_score = 15;
 * @return {?proto.pbs.MA6GenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getMa6GenerateScore = function() {
  return /** @type{?proto.pbs.MA6GenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.MA6GenerateScore, 15));
};


/**
 * @param {?proto.pbs.MA6GenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setMa6GenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearMa6GenerateScore = function() {
  return this.setMa6GenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasMa6GenerateScore = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional LAB18GenerateScore lab18_generate_score = 16;
 * @return {?proto.pbs.LAB18GenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getLab18GenerateScore = function() {
  return /** @type{?proto.pbs.LAB18GenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.LAB18GenerateScore, 16));
};


/**
 * @param {?proto.pbs.LAB18GenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setLab18GenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearLab18GenerateScore = function() {
  return this.setLab18GenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasLab18GenerateScore = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SpinCoaterGenerateScore spin_coater_generate_score = 17;
 * @return {?proto.pbs.SpinCoaterGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getSpinCoaterGenerateScore = function() {
  return /** @type{?proto.pbs.SpinCoaterGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.SpinCoaterGenerateScore, 17));
};


/**
 * @param {?proto.pbs.SpinCoaterGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setSpinCoaterGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearSpinCoaterGenerateScore = function() {
  return this.setSpinCoaterGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasSpinCoaterGenerateScore = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional CleaningGenerateScore cleaning_generate_score = 18;
 * @return {?proto.pbs.CleaningGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getCleaningGenerateScore = function() {
  return /** @type{?proto.pbs.CleaningGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.CleaningGenerateScore, 18));
};


/**
 * @param {?proto.pbs.CleaningGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setCleaningGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearCleaningGenerateScore = function() {
  return this.setCleaningGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasCleaningGenerateScore = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional DevelopmentGenerateScore development_generate_score = 19;
 * @return {?proto.pbs.DevelopmentGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getDevelopmentGenerateScore = function() {
  return /** @type{?proto.pbs.DevelopmentGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.DevelopmentGenerateScore, 19));
};


/**
 * @param {?proto.pbs.DevelopmentGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setDevelopmentGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearDevelopmentGenerateScore = function() {
  return this.setDevelopmentGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasDevelopmentGenerateScore = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PlasmaGenerateScore plasma_generate_score = 20;
 * @return {?proto.pbs.PlasmaGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getPlasmaGenerateScore = function() {
  return /** @type{?proto.pbs.PlasmaGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.PlasmaGenerateScore, 20));
};


/**
 * @param {?proto.pbs.PlasmaGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setPlasmaGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearPlasmaGenerateScore = function() {
  return this.setPlasmaGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasPlasmaGenerateScore = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional IonImplantationGenerateScore ion_implantation_generate_score = 21;
 * @return {?proto.pbs.IonImplantationGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getIonImplantationGenerateScore = function() {
  return /** @type{?proto.pbs.IonImplantationGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.IonImplantationGenerateScore, 21));
};


/**
 * @param {?proto.pbs.IonImplantationGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setIonImplantationGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearIonImplantationGenerateScore = function() {
  return this.setIonImplantationGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasIonImplantationGenerateScore = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional ElectronBeamGenerateScore electron_beam_generate_score = 22;
 * @return {?proto.pbs.ElectronBeamGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getElectronBeamGenerateScore = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.ElectronBeamGenerateScore, 22));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setElectronBeamGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearElectronBeamGenerateScore = function() {
  return this.setElectronBeamGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasElectronBeamGenerateScore = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional StepperGenerateScore stepper_generate_score = 23;
 * @return {?proto.pbs.StepperGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getStepperGenerateScore = function() {
  return /** @type{?proto.pbs.StepperGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.StepperGenerateScore, 23));
};


/**
 * @param {?proto.pbs.StepperGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setStepperGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearStepperGenerateScore = function() {
  return this.setStepperGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasStepperGenerateScore = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string scoreStandard_id = 24;
 * @return {string}
 */
proto.pbs.GenerateScoreReq.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool is_single_step = 25;
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.getIsSingleStep = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.setIsSingleStep = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional SimiProcessGenerateScore simi_process_generate_score = 26;
 * @return {?proto.pbs.SimiProcessGenerateScore}
 */
proto.pbs.GenerateScoreReq.prototype.getSimiProcessGenerateScore = function() {
  return /** @type{?proto.pbs.SimiProcessGenerateScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.SimiProcessGenerateScore, 26));
};


/**
 * @param {?proto.pbs.SimiProcessGenerateScore|undefined} value
 * @return {!proto.pbs.GenerateScoreReq} returns this
*/
proto.pbs.GenerateScoreReq.prototype.setSimiProcessGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.GenerateScoreReq} returns this
 */
proto.pbs.GenerateScoreReq.prototype.clearSimiProcessGenerateScore = function() {
  return this.setSimiProcessGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.GenerateScoreReq.prototype.hasSimiProcessGenerateScore = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GenerateModelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GenerateModelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GenerateModelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GenerateModelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetpath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GenerateModelReq}
 */
proto.pbs.GenerateModelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GenerateModelReq;
  return proto.pbs.GenerateModelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GenerateModelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GenerateModelReq}
 */
proto.pbs.GenerateModelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetpath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GenerateModelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GenerateModelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GenerateModelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GenerateModelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetpath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.GenerateModelReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateModelReq} returns this
 */
proto.pbs.GenerateModelReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetPath = 2;
 * @return {string}
 */
proto.pbs.GenerateModelReq.prototype.getAssetpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateModelReq} returns this
 */
proto.pbs.GenerateModelReq.prototype.setAssetpath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scoreStandard_id = 3;
 * @return {string}
 */
proto.pbs.GenerateModelReq.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GenerateModelReq} returns this
 */
proto.pbs.GenerateModelReq.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceLathe.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceLathe.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceLathe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceLathe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceLathe.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderWorkpiecesList: jspb.Message.toObjectList(msg.getOrderWorkpiecesList(),
    proto.pbs.Workpiece.toObject, includeInstance),
    reverseOrderWorkpiecesList: jspb.Message.toObjectList(msg.getReverseOrderWorkpiecesList(),
    proto.pbs.Workpiece.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceLathe}
 */
proto.pbs.WorkpieceLathe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceLathe;
  return proto.pbs.WorkpieceLathe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceLathe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceLathe}
 */
proto.pbs.WorkpieceLathe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Workpiece;
      reader.readMessage(value,proto.pbs.Workpiece.deserializeBinaryFromReader);
      msg.addOrderWorkpieces(value);
      break;
    case 2:
      var value = new proto.pbs.Workpiece;
      reader.readMessage(value,proto.pbs.Workpiece.deserializeBinaryFromReader);
      msg.addReverseOrderWorkpieces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceLathe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceLathe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceLathe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceLathe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderWorkpiecesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Workpiece.serializeBinaryToWriter
    );
  }
  f = message.getReverseOrderWorkpiecesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.Workpiece.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Workpiece order_workpieces = 1;
 * @return {!Array<!proto.pbs.Workpiece>}
 */
proto.pbs.WorkpieceLathe.prototype.getOrderWorkpiecesList = function() {
  return /** @type{!Array<!proto.pbs.Workpiece>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Workpiece, 1));
};


/**
 * @param {!Array<!proto.pbs.Workpiece>} value
 * @return {!proto.pbs.WorkpieceLathe} returns this
*/
proto.pbs.WorkpieceLathe.prototype.setOrderWorkpiecesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Workpiece=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.WorkpieceLathe.prototype.addOrderWorkpieces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Workpiece, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceLathe} returns this
 */
proto.pbs.WorkpieceLathe.prototype.clearOrderWorkpiecesList = function() {
  return this.setOrderWorkpiecesList([]);
};


/**
 * repeated Workpiece reverse_order_workpieces = 2;
 * @return {!Array<!proto.pbs.Workpiece>}
 */
proto.pbs.WorkpieceLathe.prototype.getReverseOrderWorkpiecesList = function() {
  return /** @type{!Array<!proto.pbs.Workpiece>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Workpiece, 2));
};


/**
 * @param {!Array<!proto.pbs.Workpiece>} value
 * @return {!proto.pbs.WorkpieceLathe} returns this
*/
proto.pbs.WorkpieceLathe.prototype.setReverseOrderWorkpiecesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.Workpiece=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.WorkpieceLathe.prototype.addReverseOrderWorkpieces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.Workpiece, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceLathe} returns this
 */
proto.pbs.WorkpieceLathe.prototype.clearReverseOrderWorkpiecesList = function() {
  return this.setReverseOrderWorkpiecesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ModelImageByte.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ModelImageByte.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ModelImageByte} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelImageByte.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ModelImageByte}
 */
proto.pbs.ModelImageByte.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ModelImageByte;
  return proto.pbs.ModelImageByte.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ModelImageByte} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ModelImageByte}
 */
proto.pbs.ModelImageByte.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ModelImageByte.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ModelImageByte.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ModelImageByte} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelImageByte.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional int32 model_type = 1;
 * @return {number}
 */
proto.pbs.ModelImageByte.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ModelImageByte} returns this
 */
proto.pbs.ModelImageByte.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes file = 2;
 * @return {string}
 */
proto.pbs.ModelImageByte.prototype.getFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes file = 2;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.pbs.ModelImageByte.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.pbs.ModelImageByte.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pbs.ModelImageByte} returns this
 */
proto.pbs.ModelImageByte.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ModelImage.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ModelImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ModelImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    path: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.ModelImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ModelImage;
  return proto.pbs.ModelImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ModelImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.ModelImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ModelImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ModelImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ModelImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ModelImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 model_type = 1;
 * @return {number}
 */
proto.pbs.ModelImage.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ModelImage} returns this
 */
proto.pbs.ModelImage.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.pbs.ModelImage.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ModelImage} returns this
 */
proto.pbs.ModelImage.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Clamp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Clamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Clamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Clamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    clampType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    clampPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clampPositionOther: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Clamp}
 */
proto.pbs.Clamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Clamp;
  return proto.pbs.Clamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Clamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Clamp}
 */
proto.pbs.Clamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClampType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampPositionOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Clamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Clamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Clamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Clamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClampType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getClampPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClampPositionOther();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 clamp_type = 1;
 * @return {number}
 */
proto.pbs.Clamp.prototype.getClampType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Clamp} returns this
 */
proto.pbs.Clamp.prototype.setClampType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double clamp_position = 2;
 * @return {number}
 */
proto.pbs.Clamp.prototype.getClampPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Clamp} returns this
 */
proto.pbs.Clamp.prototype.setClampPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double clamp_position_other = 3;
 * @return {number}
 */
proto.pbs.Clamp.prototype.getClampPositionOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Clamp} returns this
 */
proto.pbs.Clamp.prototype.setClampPositionOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineCraft.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineCraft.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineCraft} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineCraft.toObject = function(includeInstance, msg) {
  var f, obj = {
    craftType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spindleSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    feedSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    backCutterMete: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cutter: (f = msg.getCutter()) && cutter_pb.Cutter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineCraft}
 */
proto.pbs.MachineCraft.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineCraft;
  return proto.pbs.MachineCraft.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineCraft} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineCraft}
 */
proto.pbs.MachineCraft.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCraftType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMete(value);
      break;
    case 5:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineCraft.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineCraft.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineCraft} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineCraft.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCraftType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpindleSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFeedSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBackCutterMete();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 craft_type = 1;
 * @return {number}
 */
proto.pbs.MachineCraft.prototype.getCraftType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineCraft} returns this
 */
proto.pbs.MachineCraft.prototype.setCraftType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double spindle_speed = 2;
 * @return {number}
 */
proto.pbs.MachineCraft.prototype.getSpindleSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineCraft} returns this
 */
proto.pbs.MachineCraft.prototype.setSpindleSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double feed_speed = 3;
 * @return {number}
 */
proto.pbs.MachineCraft.prototype.getFeedSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineCraft} returns this
 */
proto.pbs.MachineCraft.prototype.setFeedSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double back_cutter_mete = 4;
 * @return {number}
 */
proto.pbs.MachineCraft.prototype.getBackCutterMete = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineCraft} returns this
 */
proto.pbs.MachineCraft.prototype.setBackCutterMete = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Cutter cutter = 5;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.MachineCraft.prototype.getCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 5));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.MachineCraft} returns this
*/
proto.pbs.MachineCraft.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineCraft} returns this
 */
proto.pbs.MachineCraft.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineCraft.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Workpiece.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Workpiece.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Workpiece} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Workpiece.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    index: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.Workpiece.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Workpiece;
  return proto.pbs.Workpiece.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Workpiece} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.Workpiece.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Workpiece.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Workpiece.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Workpiece} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Workpiece.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.Workpiece.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Workpiece} returns this
 */
proto.pbs.Workpiece.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double param = 2;
 * @return {number}
 */
proto.pbs.Workpiece.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Workpiece} returns this
 */
proto.pbs.Workpiece.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 index = 3;
 * @return {number}
 */
proto.pbs.Workpiece.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Workpiece} returns this
 */
proto.pbs.Workpiece.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceMilling.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceMilling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceMilling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceMilling.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    index: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceMilling}
 */
proto.pbs.WorkpieceMilling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceMilling;
  return proto.pbs.WorkpieceMilling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceMilling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceMilling}
 */
proto.pbs.WorkpieceMilling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceMilling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceMilling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceMilling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceMilling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceMilling.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceMilling} returns this
 */
proto.pbs.WorkpieceMilling.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double param = 6;
 * @return {number}
 */
proto.pbs.WorkpieceMilling.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceMilling} returns this
 */
proto.pbs.WorkpieceMilling.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int32 index = 7;
 * @return {number}
 */
proto.pbs.WorkpieceMilling.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceMilling} returns this
 */
proto.pbs.WorkpieceMilling.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExamIdReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExamIdReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExamIdReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamIdReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExamIdReq}
 */
proto.pbs.ExamIdReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExamIdReq;
  return proto.pbs.ExamIdReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExamIdReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExamIdReq}
 */
proto.pbs.ExamIdReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExamIdReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExamIdReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExamIdReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamIdReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.ExamIdReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamIdReq} returns this
 */
proto.pbs.ExamIdReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string score_standard_id = 2;
 * @return {string}
 */
proto.pbs.ExamIdReq.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamIdReq} returns this
 */
proto.pbs.ExamIdReq.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfessionalScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfessionalScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfessionalScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfessionalScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionDesc: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionAnswer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examineAnswer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    questionScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    examineScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    questionImage: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfessionalScore}
 */
proto.pbs.ProfessionalScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfessionalScore;
  return proto.pbs.ProfessionalScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfessionalScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfessionalScore}
 */
proto.pbs.ProfessionalScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionDesc(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionAnswer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamineAnswer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuestionScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExamineScore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfessionalScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfessionalScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfessionalScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfessionalScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuestionDesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionAnswer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamineAnswer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuestionScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getExamineScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getQuestionImage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 question_type = 1;
 * @return {number}
 */
proto.pbs.ProfessionalScore.prototype.getQuestionType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string question_desc = 2;
 * @return {string}
 */
proto.pbs.ProfessionalScore.prototype.getQuestionDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setQuestionDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question_answer = 3;
 * @return {string}
 */
proto.pbs.ProfessionalScore.prototype.getQuestionAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setQuestionAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string examine_answer = 4;
 * @return {string}
 */
proto.pbs.ProfessionalScore.prototype.getExamineAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setExamineAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double question_score = 5;
 * @return {number}
 */
proto.pbs.ProfessionalScore.prototype.getQuestionScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setQuestionScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double examine_score = 6;
 * @return {number}
 */
proto.pbs.ProfessionalScore.prototype.getExamineScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setExamineScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string question_image = 7;
 * @return {string}
 */
proto.pbs.ProfessionalScore.prototype.getQuestionImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfessionalScore} returns this
 */
proto.pbs.ProfessionalScore.prototype.setQuestionImage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ProfessionalScoreResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfessionalScoreResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfessionalScoreResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfessionalScoreResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfessionalScoreResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalScoreList: jspb.Message.toObjectList(msg.getProfessionalScoreList(),
    proto.pbs.ProfessionalScore.toObject, includeInstance),
    professionalTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    professionalTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfessionalScoreResp}
 */
proto.pbs.ProfessionalScoreResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfessionalScoreResp;
  return proto.pbs.ProfessionalScoreResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfessionalScoreResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfessionalScoreResp}
 */
proto.pbs.ProfessionalScoreResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ProfessionalScore;
      reader.readMessage(value,proto.pbs.ProfessionalScore.deserializeBinaryFromReader);
      msg.addProfessionalScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfessionalTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfessionalTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfessionalScoreResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfessionalScoreResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfessionalScoreResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfessionalScoreResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ProfessionalScore.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProfessionalTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ProfessionalScore professional_score = 1;
 * @return {!Array<!proto.pbs.ProfessionalScore>}
 */
proto.pbs.ProfessionalScoreResp.prototype.getProfessionalScoreList = function() {
  return /** @type{!Array<!proto.pbs.ProfessionalScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ProfessionalScore, 1));
};


/**
 * @param {!Array<!proto.pbs.ProfessionalScore>} value
 * @return {!proto.pbs.ProfessionalScoreResp} returns this
*/
proto.pbs.ProfessionalScoreResp.prototype.setProfessionalScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ProfessionalScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ProfessionalScore}
 */
proto.pbs.ProfessionalScoreResp.prototype.addProfessionalScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ProfessionalScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ProfessionalScoreResp} returns this
 */
proto.pbs.ProfessionalScoreResp.prototype.clearProfessionalScoreList = function() {
  return this.setProfessionalScoreList([]);
};


/**
 * optional double professional_total_score = 2;
 * @return {number}
 */
proto.pbs.ProfessionalScoreResp.prototype.getProfessionalTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfessionalScoreResp} returns this
 */
proto.pbs.ProfessionalScoreResp.prototype.setProfessionalTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double professional_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.ProfessionalScoreResp.prototype.getProfessionalTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfessionalScoreResp} returns this
 */
proto.pbs.ProfessionalScoreResp.prototype.setProfessionalTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineOperationResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineOperationResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineOperationResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineOperationResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    blankScore: (f = msg.getBlankScore()) && proto.pbs.BlankScore.toObject(includeInstance, f),
    clampScore: (f = msg.getClampScore()) && proto.pbs.ClampScore.toObject(includeInstance, f),
    cutterScore: (f = msg.getCutterScore()) && proto.pbs.CutterScore.toObject(includeInstance, f),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineOperationResp}
 */
proto.pbs.MachineOperationResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineOperationResp;
  return proto.pbs.MachineOperationResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineOperationResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineOperationResp}
 */
proto.pbs.MachineOperationResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.BlankScore;
      reader.readMessage(value,proto.pbs.BlankScore.deserializeBinaryFromReader);
      msg.setBlankScore(value);
      break;
    case 2:
      var value = new proto.pbs.ClampScore;
      reader.readMessage(value,proto.pbs.ClampScore.deserializeBinaryFromReader);
      msg.setClampScore(value);
      break;
    case 3:
      var value = new proto.pbs.CutterScore;
      reader.readMessage(value,proto.pbs.CutterScore.deserializeBinaryFromReader);
      msg.setCutterScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineOperationResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineOperationResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineOperationResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineOperationResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlankScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.BlankScore.serializeBinaryToWriter
    );
  }
  f = message.getClampScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ClampScore.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.CutterScore.serializeBinaryToWriter
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional BlankScore blank_score = 1;
 * @return {?proto.pbs.BlankScore}
 */
proto.pbs.MachineOperationResp.prototype.getBlankScore = function() {
  return /** @type{?proto.pbs.BlankScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.BlankScore, 1));
};


/**
 * @param {?proto.pbs.BlankScore|undefined} value
 * @return {!proto.pbs.MachineOperationResp} returns this
*/
proto.pbs.MachineOperationResp.prototype.setBlankScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineOperationResp} returns this
 */
proto.pbs.MachineOperationResp.prototype.clearBlankScore = function() {
  return this.setBlankScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineOperationResp.prototype.hasBlankScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ClampScore clamp_score = 2;
 * @return {?proto.pbs.ClampScore}
 */
proto.pbs.MachineOperationResp.prototype.getClampScore = function() {
  return /** @type{?proto.pbs.ClampScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ClampScore, 2));
};


/**
 * @param {?proto.pbs.ClampScore|undefined} value
 * @return {!proto.pbs.MachineOperationResp} returns this
*/
proto.pbs.MachineOperationResp.prototype.setClampScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineOperationResp} returns this
 */
proto.pbs.MachineOperationResp.prototype.clearClampScore = function() {
  return this.setClampScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineOperationResp.prototype.hasClampScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CutterScore cutter_score = 3;
 * @return {?proto.pbs.CutterScore}
 */
proto.pbs.MachineOperationResp.prototype.getCutterScore = function() {
  return /** @type{?proto.pbs.CutterScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CutterScore, 3));
};


/**
 * @param {?proto.pbs.CutterScore|undefined} value
 * @return {!proto.pbs.MachineOperationResp} returns this
*/
proto.pbs.MachineOperationResp.prototype.setCutterScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineOperationResp} returns this
 */
proto.pbs.MachineOperationResp.prototype.clearCutterScore = function() {
  return this.setCutterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineOperationResp.prototype.hasCutterScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scoreStandard_id = 4;
 * @return {string}
 */
proto.pbs.MachineOperationResp.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineOperationResp} returns this
 */
proto.pbs.MachineOperationResp.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreSummaryResp.repeatedFields_ = [11,54,56];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreSummaryResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreSummaryResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreSummaryResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreSummaryResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    examinfo: (f = msg.getExaminfo()) && exams_pb.Exam.toObject(includeInstance, f),
    professionalQualityScore: (f = msg.getProfessionalQualityScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    blankScore: (f = msg.getBlankScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    clampScore: (f = msg.getClampScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cutterScore: (f = msg.getCutterScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    operateScore: (f = msg.getOperateScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    machineParamScore: (f = msg.getMachineParamScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    machineProcessScore: (f = msg.getMachineProcessScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    workpieceQualityScore: (f = msg.getWorkpieceQualityScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    machineEffectiveScore: (f = msg.getMachineEffectiveScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    ncprogramScore: (f = msg.getNcprogramScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    proto.pbs.ModelImage.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    waferCarrierScore: (f = msg.getWaferCarrierScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    boardShelfScore: (f = msg.getBoardShelfScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    lithographyBoardScore: (f = msg.getLithographyBoardScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    operationsScore: (f = msg.getOperationsScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    exposureParamsScore: (f = msg.getExposureParamsScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    assetpath: jspb.Message.getFieldWithDefault(msg, 18, ""),
    assetpathListList: (f = jspb.Message.getRepeatedField(msg, 54)) == null ? undefined : f,
    ncProgramPath: jspb.Message.getFieldWithDefault(msg, 55, ""),
    ncProgramPathListList: (f = jspb.Message.getRepeatedField(msg, 56)) == null ? undefined : f,
    targetedMaterialsScore: (f = msg.getTargetedMaterialsScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    programScore: (f = msg.getProgramScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    coaterScore: (f = msg.getCoaterScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    preBakeScore: (f = msg.getPreBakeScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningFluidScore: (f = msg.getCleaningFluidScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningOrderScore: (f = msg.getCleaningOrderScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningTimeSettingScore: (f = msg.getCleaningTimeSettingScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningDeviceScore: (f = msg.getCleaningDeviceScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningTemperatureSettingScore: (f = msg.getCleaningTemperatureSettingScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningProcess: (f = msg.getCleaningProcess()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    cleaningNum: (f = msg.getCleaningNum()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentFluidScore: (f = msg.getDevelopmentFluidScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentOrderScore: (f = msg.getDevelopmentOrderScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentTimeSettingScore: (f = msg.getDevelopmentTimeSettingScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentDeviceScore: (f = msg.getDevelopmentDeviceScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentTemperatureSettingScore: (f = msg.getDevelopmentTemperatureSettingScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentProcess: (f = msg.getDevelopmentProcess()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    developmentNum: (f = msg.getDevelopmentNum()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    plasmaEtchinggasesScore: (f = msg.getPlasmaEtchinggasesScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    plasmaGasParametersScore: (f = msg.getPlasmaGasParametersScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    plasmaEtchParametersScore: (f = msg.getPlasmaEtchParametersScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    plasmaOperationStageScore: (f = msg.getPlasmaOperationStageScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    ionImplantationProcedureScore: (f = msg.getIonImplantationProcedureScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    ionBeamDebugging: (f = msg.getIonBeamDebugging()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    ionImplantationOperationStageScore: (f = msg.getIonImplantationOperationStageScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    electronBeamCrucibleParameters: (f = msg.getElectronBeamCrucibleParameters()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    electronBeamDepoProgram: (f = msg.getElectronBeamDepoProgram()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    electronBeamOperatingParameter: (f = msg.getElectronBeamOperatingParameter()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    electronBeamOperationStageScore: (f = msg.getElectronBeamOperationStageScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    electronBeamProcessProgram: (f = msg.getElectronBeamProcessProgram()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    stepperMask: (f = msg.getStepperMask()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    stepperExposureArea: (f = msg.getStepperExposureArea()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    stepperExposureParams: (f = msg.getStepperExposureParams()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    stepperOperationStageScore: (f = msg.getStepperOperationStageScore()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    semiProcess: (f = msg.getSemiProcess()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    semiSort: (f = msg.getSemiSort()) && proto.pbs.EachItemScore.toObject(includeInstance, f),
    semiProcessStep: (f = msg.getSemiProcessStep()) && proto.pbs.EachItemScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreSummaryResp}
 */
proto.pbs.ScoreSummaryResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreSummaryResp;
  return proto.pbs.ScoreSummaryResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreSummaryResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreSummaryResp}
 */
proto.pbs.ScoreSummaryResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new exams_pb.Exam;
      reader.readMessage(value,exams_pb.Exam.deserializeBinaryFromReader);
      msg.setExaminfo(value);
      break;
    case 2:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setProfessionalQualityScore(value);
      break;
    case 3:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setBlankScore(value);
      break;
    case 4:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setClampScore(value);
      break;
    case 5:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCutterScore(value);
      break;
    case 6:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setOperateScore(value);
      break;
    case 7:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineParamScore(value);
      break;
    case 8:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineProcessScore(value);
      break;
    case 9:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setWorkpieceQualityScore(value);
      break;
    case 10:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineEffectiveScore(value);
      break;
    case 53:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setNcprogramScore(value);
      break;
    case 11:
      var value = new proto.pbs.ModelImage;
      reader.readMessage(value,proto.pbs.ModelImage.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 13:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setWaferCarrierScore(value);
      break;
    case 14:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setBoardShelfScore(value);
      break;
    case 15:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setLithographyBoardScore(value);
      break;
    case 16:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setOperationsScore(value);
      break;
    case 17:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setExposureParamsScore(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetpath(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetpathList(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setNcProgramPath(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.addNcProgramPathList(value);
      break;
    case 19:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setTargetedMaterialsScore(value);
      break;
    case 20:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setProgramScore(value);
      break;
    case 21:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCoaterScore(value);
      break;
    case 22:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setPreBakeScore(value);
      break;
    case 23:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningFluidScore(value);
      break;
    case 24:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningOrderScore(value);
      break;
    case 25:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningTimeSettingScore(value);
      break;
    case 26:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningDeviceScore(value);
      break;
    case 27:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningTemperatureSettingScore(value);
      break;
    case 28:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningProcess(value);
      break;
    case 29:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setCleaningNum(value);
      break;
    case 30:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentFluidScore(value);
      break;
    case 31:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentOrderScore(value);
      break;
    case 32:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentTimeSettingScore(value);
      break;
    case 33:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentDeviceScore(value);
      break;
    case 34:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentTemperatureSettingScore(value);
      break;
    case 35:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentProcess(value);
      break;
    case 36:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setDevelopmentNum(value);
      break;
    case 37:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setPlasmaEtchinggasesScore(value);
      break;
    case 38:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setPlasmaGasParametersScore(value);
      break;
    case 39:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setPlasmaEtchParametersScore(value);
      break;
    case 52:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setPlasmaOperationStageScore(value);
      break;
    case 40:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setIonImplantationProcedureScore(value);
      break;
    case 41:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setIonBeamDebugging(value);
      break;
    case 42:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setIonImplantationOperationStageScore(value);
      break;
    case 43:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setElectronBeamCrucibleParameters(value);
      break;
    case 44:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setElectronBeamDepoProgram(value);
      break;
    case 45:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setElectronBeamOperatingParameter(value);
      break;
    case 46:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setElectronBeamOperationStageScore(value);
      break;
    case 51:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setElectronBeamProcessProgram(value);
      break;
    case 47:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setStepperMask(value);
      break;
    case 48:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setStepperExposureArea(value);
      break;
    case 49:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setStepperExposureParams(value);
      break;
    case 50:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setStepperOperationStageScore(value);
      break;
    case 57:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setSemiProcess(value);
      break;
    case 58:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setSemiSort(value);
      break;
    case 59:
      var value = new proto.pbs.EachItemScore;
      reader.readMessage(value,proto.pbs.EachItemScore.deserializeBinaryFromReader);
      msg.setSemiProcessStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreSummaryResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreSummaryResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreSummaryResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreSummaryResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExaminfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      exams_pb.Exam.serializeBinaryToWriter
    );
  }
  f = message.getProfessionalQualityScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getBlankScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getClampScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getOperateScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineParamScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffectiveScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getNcprogramScore();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.pbs.ModelImage.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getWaferCarrierScore();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getBoardShelfScore();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getLithographyBoardScore();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationsScore();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getExposureParamsScore();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getAssetpath();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAssetpathListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      54,
      f
    );
  }
  f = message.getNcProgramPath();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getNcProgramPathListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      56,
      f
    );
  }
  f = message.getTargetedMaterialsScore();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getProgramScore();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCoaterScore();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getPreBakeScore();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningFluidScore();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningOrderScore();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningTimeSettingScore();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningDeviceScore();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningTemperatureSettingScore();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningProcess();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningNum();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentFluidScore();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentOrderScore();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentTimeSettingScore();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentDeviceScore();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentTemperatureSettingScore();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentProcess();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentNum();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaEtchinggasesScore();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaGasParametersScore();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaEtchParametersScore();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaOperationStageScore();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationProcedureScore();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getIonBeamDebugging();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationOperationStageScore();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamCrucibleParameters();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamDepoProgram();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamOperatingParameter();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamOperationStageScore();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamProcessProgram();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperMask();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperExposureArea();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperExposureParams();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperOperationStageScore();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getSemiProcess();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getSemiSort();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getSemiProcessStep();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      proto.pbs.EachItemScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional Exam examInfo = 1;
 * @return {?proto.pbs.Exam}
 */
proto.pbs.ScoreSummaryResp.prototype.getExaminfo = function() {
  return /** @type{?proto.pbs.Exam} */ (
    jspb.Message.getWrapperField(this, exams_pb.Exam, 1));
};


/**
 * @param {?proto.pbs.Exam|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setExaminfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearExaminfo = function() {
  return this.setExaminfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasExaminfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EachItemScore professional_quality_score = 2;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getProfessionalQualityScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 2));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setProfessionalQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearProfessionalQualityScore = function() {
  return this.setProfessionalQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasProfessionalQualityScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EachItemScore blank_score = 3;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getBlankScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 3));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setBlankScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearBlankScore = function() {
  return this.setBlankScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasBlankScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EachItemScore clamp_score = 4;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getClampScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 4));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setClampScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearClampScore = function() {
  return this.setClampScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasClampScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EachItemScore cutter_score = 5;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCutterScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 5));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCutterScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCutterScore = function() {
  return this.setCutterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCutterScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EachItemScore operate_score = 6;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getOperateScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 6));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setOperateScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearOperateScore = function() {
  return this.setOperateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasOperateScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EachItemScore machine_param_score = 7;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getMachineParamScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 7));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setMachineParamScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearMachineParamScore = function() {
  return this.setMachineParamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasMachineParamScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EachItemScore machine_process_score = 8;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getMachineProcessScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 8));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearMachineProcessScore = function() {
  return this.setMachineProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasMachineProcessScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EachItemScore workpiece_quality_score = 9;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getWorkpieceQualityScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 9));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearWorkpieceQualityScore = function() {
  return this.setWorkpieceQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasWorkpieceQualityScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EachItemScore machine_effective_score = 10;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getMachineEffectiveScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 10));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearMachineEffectiveScore = function() {
  return this.setMachineEffectiveScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasMachineEffectiveScore = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional EachItemScore ncProgram_score = 53;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getNcprogramScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 53));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setNcprogramScore = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearNcprogramScore = function() {
  return this.setNcprogramScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasNcprogramScore = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * repeated ModelImage model_image = 11;
 * @return {!Array<!proto.pbs.ModelImage>}
 */
proto.pbs.ScoreSummaryResp.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ModelImage, 11));
};


/**
 * @param {!Array<!proto.pbs.ModelImage>} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.ModelImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.ScoreSummaryResp.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.ModelImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};


/**
 * optional double total_score = 12;
 * @return {number}
 */
proto.pbs.ScoreSummaryResp.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional EachItemScore wafer_carrier_score = 13;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getWaferCarrierScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 13));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setWaferCarrierScore = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearWaferCarrierScore = function() {
  return this.setWaferCarrierScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasWaferCarrierScore = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional EachItemScore board_shelf_score = 14;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getBoardShelfScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 14));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setBoardShelfScore = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearBoardShelfScore = function() {
  return this.setBoardShelfScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasBoardShelfScore = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional EachItemScore lithography_board_score = 15;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getLithographyBoardScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 15));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setLithographyBoardScore = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearLithographyBoardScore = function() {
  return this.setLithographyBoardScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasLithographyBoardScore = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional EachItemScore operations_score = 16;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getOperationsScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 16));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setOperationsScore = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearOperationsScore = function() {
  return this.setOperationsScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasOperationsScore = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional EachItemScore exposure_params_score = 17;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getExposureParamsScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 17));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setExposureParamsScore = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearExposureParamsScore = function() {
  return this.setExposureParamsScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasExposureParamsScore = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string assetPath = 18;
 * @return {string}
 */
proto.pbs.ScoreSummaryResp.prototype.getAssetpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.setAssetpath = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated string assetPath_list = 54;
 * @return {!Array<string>}
 */
proto.pbs.ScoreSummaryResp.prototype.getAssetpathListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 54));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.setAssetpathListList = function(value) {
  return jspb.Message.setField(this, 54, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.addAssetpathList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 54, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearAssetpathListList = function() {
  return this.setAssetpathListList([]);
};


/**
 * optional string nc_program_path = 55;
 * @return {string}
 */
proto.pbs.ScoreSummaryResp.prototype.getNcProgramPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.setNcProgramPath = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * repeated string nc_program_path_list = 56;
 * @return {!Array<string>}
 */
proto.pbs.ScoreSummaryResp.prototype.getNcProgramPathListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 56));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.setNcProgramPathListList = function(value) {
  return jspb.Message.setField(this, 56, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.addNcProgramPathList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 56, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearNcProgramPathListList = function() {
  return this.setNcProgramPathListList([]);
};


/**
 * optional EachItemScore targeted_materials_score = 19;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getTargetedMaterialsScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 19));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setTargetedMaterialsScore = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearTargetedMaterialsScore = function() {
  return this.setTargetedMaterialsScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasTargetedMaterialsScore = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional EachItemScore program_score = 20;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getProgramScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 20));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setProgramScore = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearProgramScore = function() {
  return this.setProgramScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasProgramScore = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional EachItemScore coater_score = 21;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCoaterScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 21));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCoaterScore = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCoaterScore = function() {
  return this.setCoaterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCoaterScore = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional EachItemScore pre_bake_score = 22;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getPreBakeScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 22));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setPreBakeScore = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearPreBakeScore = function() {
  return this.setPreBakeScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasPreBakeScore = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional EachItemScore cleaning_fluid_score = 23;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningFluidScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 23));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningFluidScore = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningFluidScore = function() {
  return this.setCleaningFluidScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningFluidScore = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional EachItemScore cleaning_order_score = 24;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningOrderScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 24));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningOrderScore = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningOrderScore = function() {
  return this.setCleaningOrderScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningOrderScore = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional EachItemScore cleaning_time_setting_score = 25;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningTimeSettingScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 25));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningTimeSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningTimeSettingScore = function() {
  return this.setCleaningTimeSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningTimeSettingScore = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional EachItemScore cleaning_device_score = 26;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningDeviceScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 26));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningDeviceScore = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningDeviceScore = function() {
  return this.setCleaningDeviceScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningDeviceScore = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional EachItemScore cleaning_temperature_setting_score = 27;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningTemperatureSettingScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 27));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningTemperatureSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningTemperatureSettingScore = function() {
  return this.setCleaningTemperatureSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningTemperatureSettingScore = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional EachItemScore cleaning_process = 28;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningProcess = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 28));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningProcess = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningProcess = function() {
  return this.setCleaningProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningProcess = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional EachItemScore cleaning_num = 29;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getCleaningNum = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 29));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setCleaningNum = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearCleaningNum = function() {
  return this.setCleaningNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasCleaningNum = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional EachItemScore development_fluid_score = 30;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentFluidScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 30));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentFluidScore = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentFluidScore = function() {
  return this.setDevelopmentFluidScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentFluidScore = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional EachItemScore development_order_score = 31;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentOrderScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 31));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentOrderScore = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentOrderScore = function() {
  return this.setDevelopmentOrderScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentOrderScore = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional EachItemScore development_time_setting_score = 32;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentTimeSettingScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 32));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentTimeSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentTimeSettingScore = function() {
  return this.setDevelopmentTimeSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentTimeSettingScore = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional EachItemScore development_device_score = 33;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentDeviceScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 33));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentDeviceScore = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentDeviceScore = function() {
  return this.setDevelopmentDeviceScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentDeviceScore = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional EachItemScore development_temperature_setting_score = 34;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentTemperatureSettingScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 34));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentTemperatureSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentTemperatureSettingScore = function() {
  return this.setDevelopmentTemperatureSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentTemperatureSettingScore = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional EachItemScore development_process = 35;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentProcess = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 35));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentProcess = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentProcess = function() {
  return this.setDevelopmentProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentProcess = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional EachItemScore development_num = 36;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getDevelopmentNum = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 36));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setDevelopmentNum = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearDevelopmentNum = function() {
  return this.setDevelopmentNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasDevelopmentNum = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional EachItemScore plasma_etchinggases_score = 37;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getPlasmaEtchinggasesScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 37));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setPlasmaEtchinggasesScore = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearPlasmaEtchinggasesScore = function() {
  return this.setPlasmaEtchinggasesScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasPlasmaEtchinggasesScore = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional EachItemScore plasma_gas_parameters_score = 38;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getPlasmaGasParametersScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 38));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setPlasmaGasParametersScore = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearPlasmaGasParametersScore = function() {
  return this.setPlasmaGasParametersScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasPlasmaGasParametersScore = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional EachItemScore plasma_etch_parameters_score = 39;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getPlasmaEtchParametersScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 39));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setPlasmaEtchParametersScore = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearPlasmaEtchParametersScore = function() {
  return this.setPlasmaEtchParametersScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasPlasmaEtchParametersScore = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional EachItemScore plasma_operation_stage_score = 52;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getPlasmaOperationStageScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 52));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setPlasmaOperationStageScore = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearPlasmaOperationStageScore = function() {
  return this.setPlasmaOperationStageScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasPlasmaOperationStageScore = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional EachItemScore ion_implantation_procedure_score = 40;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getIonImplantationProcedureScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 40));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setIonImplantationProcedureScore = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearIonImplantationProcedureScore = function() {
  return this.setIonImplantationProcedureScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasIonImplantationProcedureScore = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional EachItemScore ion_beam_debugging = 41;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getIonBeamDebugging = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 41));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setIonBeamDebugging = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearIonBeamDebugging = function() {
  return this.setIonBeamDebugging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasIonBeamDebugging = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional EachItemScore ion_implantation_operation_stage_score = 42;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getIonImplantationOperationStageScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 42));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setIonImplantationOperationStageScore = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearIonImplantationOperationStageScore = function() {
  return this.setIonImplantationOperationStageScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasIonImplantationOperationStageScore = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional EachItemScore electron_beam_crucible_parameters = 43;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getElectronBeamCrucibleParameters = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 43));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setElectronBeamCrucibleParameters = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearElectronBeamCrucibleParameters = function() {
  return this.setElectronBeamCrucibleParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasElectronBeamCrucibleParameters = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional EachItemScore electron_beam_depo_program = 44;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getElectronBeamDepoProgram = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 44));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setElectronBeamDepoProgram = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearElectronBeamDepoProgram = function() {
  return this.setElectronBeamDepoProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasElectronBeamDepoProgram = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional EachItemScore electron_beam_operating_parameter = 45;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getElectronBeamOperatingParameter = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 45));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setElectronBeamOperatingParameter = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearElectronBeamOperatingParameter = function() {
  return this.setElectronBeamOperatingParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasElectronBeamOperatingParameter = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional EachItemScore electron_beam_operation_stage_score = 46;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getElectronBeamOperationStageScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 46));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setElectronBeamOperationStageScore = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearElectronBeamOperationStageScore = function() {
  return this.setElectronBeamOperationStageScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasElectronBeamOperationStageScore = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional EachItemScore electron_beam_process_program = 51;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getElectronBeamProcessProgram = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 51));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setElectronBeamProcessProgram = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearElectronBeamProcessProgram = function() {
  return this.setElectronBeamProcessProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasElectronBeamProcessProgram = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional EachItemScore stepper_mask = 47;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getStepperMask = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 47));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setStepperMask = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearStepperMask = function() {
  return this.setStepperMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasStepperMask = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional EachItemScore stepper_exposure_area = 48;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getStepperExposureArea = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 48));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setStepperExposureArea = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearStepperExposureArea = function() {
  return this.setStepperExposureArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasStepperExposureArea = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional EachItemScore stepper_exposure_params = 49;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getStepperExposureParams = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 49));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setStepperExposureParams = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearStepperExposureParams = function() {
  return this.setStepperExposureParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasStepperExposureParams = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional EachItemScore stepper_operation_stage_score = 50;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getStepperOperationStageScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 50));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setStepperOperationStageScore = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearStepperOperationStageScore = function() {
  return this.setStepperOperationStageScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasStepperOperationStageScore = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional EachItemScore semi_process = 57;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getSemiProcess = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 57));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setSemiProcess = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearSemiProcess = function() {
  return this.setSemiProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasSemiProcess = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional EachItemScore semi_sort = 58;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getSemiSort = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 58));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setSemiSort = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearSemiSort = function() {
  return this.setSemiSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasSemiSort = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional EachItemScore semi_process_step = 59;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.ScoreSummaryResp.prototype.getSemiProcessStep = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.EachItemScore, 59));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.ScoreSummaryResp} returns this
*/
proto.pbs.ScoreSummaryResp.prototype.setSemiProcessStep = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreSummaryResp} returns this
 */
proto.pbs.ScoreSummaryResp.prototype.clearSemiProcessStep = function() {
  return this.setSemiProcessStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreSummaryResp.prototype.hasSemiProcessStep = function() {
  return jspb.Message.getField(this, 59) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EachItemScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EachItemScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EachItemScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EachItemScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EachItemScore}
 */
proto.pbs.EachItemScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EachItemScore;
  return proto.pbs.EachItemScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EachItemScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EachItemScore}
 */
proto.pbs.EachItemScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EachItemScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EachItemScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EachItemScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EachItemScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double total_score = 1;
 * @return {number}
 */
proto.pbs.EachItemScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.EachItemScore} returns this
 */
proto.pbs.EachItemScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total_score_refer = 2;
 * @return {number}
 */
proto.pbs.EachItemScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.EachItemScore} returns this
 */
proto.pbs.EachItemScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.NCScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.NCScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.NCScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.NCScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ncScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    ncScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.NCScore}
 */
proto.pbs.NCScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.NCScore;
  return proto.pbs.NCScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.NCScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.NCScore}
 */
proto.pbs.NCScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.NCScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.NCScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.NCScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.NCScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNcScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double nc_score = 1;
 * @return {number}
 */
proto.pbs.NCScore.prototype.getNcScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.NCScore} returns this
 */
proto.pbs.NCScore.prototype.setNcScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double nc_score_refer = 2;
 * @return {number}
 */
proto.pbs.NCScore.prototype.getNcScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.NCScore} returns this
 */
proto.pbs.NCScore.prototype.setNcScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineParamResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineParamResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineParamResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParamResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineScore: (f = msg.getMachineScore()) && proto.pbs.MachineScore.toObject(includeInstance, f),
    machineProcessScore: (f = msg.getMachineProcessScore()) && proto.pbs.MachineProcessScore.toObject(includeInstance, f),
    workpieceQualityScore: (f = msg.getWorkpieceQualityScore()) && proto.pbs.WorkpieceQualityScore.toObject(includeInstance, f),
    machineEffectiveScore: (f = msg.getMachineEffectiveScore()) && proto.pbs.MachineEffectiveScore.toObject(includeInstance, f),
    ncProgramMap: (f = msg.getNcProgramMap()) ? f.toObject(includeInstance, undefined) : [],
    ncScore: (f = msg.getNcScore()) && proto.pbs.NCScore.toObject(includeInstance, f),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineParamResp}
 */
proto.pbs.MachineParamResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineParamResp;
  return proto.pbs.MachineParamResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineParamResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineParamResp}
 */
proto.pbs.MachineParamResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MachineScore;
      reader.readMessage(value,proto.pbs.MachineScore.deserializeBinaryFromReader);
      msg.setMachineScore(value);
      break;
    case 2:
      var value = new proto.pbs.MachineProcessScore;
      reader.readMessage(value,proto.pbs.MachineProcessScore.deserializeBinaryFromReader);
      msg.setMachineProcessScore(value);
      break;
    case 3:
      var value = new proto.pbs.WorkpieceQualityScore;
      reader.readMessage(value,proto.pbs.WorkpieceQualityScore.deserializeBinaryFromReader);
      msg.setWorkpieceQualityScore(value);
      break;
    case 4:
      var value = new proto.pbs.MachineEffectiveScore;
      reader.readMessage(value,proto.pbs.MachineEffectiveScore.deserializeBinaryFromReader);
      msg.setMachineEffectiveScore(value);
      break;
    case 5:
      var value = msg.getNcProgramMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = new proto.pbs.NCScore;
      reader.readMessage(value,proto.pbs.NCScore.deserializeBinaryFromReader);
      msg.setNcScore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineParamResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineParamResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineParamResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParamResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MachineScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.MachineProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.WorkpieceQualityScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffectiveScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.MachineEffectiveScore.serializeBinaryToWriter
    );
  }
  f = message.getNcProgramMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getNcScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.NCScore.serializeBinaryToWriter
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional MachineScore machine_score = 1;
 * @return {?proto.pbs.MachineScore}
 */
proto.pbs.MachineParamResp.prototype.getMachineScore = function() {
  return /** @type{?proto.pbs.MachineScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineScore, 1));
};


/**
 * @param {?proto.pbs.MachineScore|undefined} value
 * @return {!proto.pbs.MachineParamResp} returns this
*/
proto.pbs.MachineParamResp.prototype.setMachineScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearMachineScore = function() {
  return this.setMachineScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParamResp.prototype.hasMachineScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MachineProcessScore machine_process_score = 2;
 * @return {?proto.pbs.MachineProcessScore}
 */
proto.pbs.MachineParamResp.prototype.getMachineProcessScore = function() {
  return /** @type{?proto.pbs.MachineProcessScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineProcessScore, 2));
};


/**
 * @param {?proto.pbs.MachineProcessScore|undefined} value
 * @return {!proto.pbs.MachineParamResp} returns this
*/
proto.pbs.MachineParamResp.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearMachineProcessScore = function() {
  return this.setMachineProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParamResp.prototype.hasMachineProcessScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WorkpieceQualityScore workpiece_quality_score = 3;
 * @return {?proto.pbs.WorkpieceQualityScore}
 */
proto.pbs.MachineParamResp.prototype.getWorkpieceQualityScore = function() {
  return /** @type{?proto.pbs.WorkpieceQualityScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.WorkpieceQualityScore, 3));
};


/**
 * @param {?proto.pbs.WorkpieceQualityScore|undefined} value
 * @return {!proto.pbs.MachineParamResp} returns this
*/
proto.pbs.MachineParamResp.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearWorkpieceQualityScore = function() {
  return this.setWorkpieceQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParamResp.prototype.hasWorkpieceQualityScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MachineEffectiveScore machine_effective_score = 4;
 * @return {?proto.pbs.MachineEffectiveScore}
 */
proto.pbs.MachineParamResp.prototype.getMachineEffectiveScore = function() {
  return /** @type{?proto.pbs.MachineEffectiveScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineEffectiveScore, 4));
};


/**
 * @param {?proto.pbs.MachineEffectiveScore|undefined} value
 * @return {!proto.pbs.MachineParamResp} returns this
*/
proto.pbs.MachineParamResp.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearMachineEffectiveScore = function() {
  return this.setMachineEffectiveScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParamResp.prototype.hasMachineEffectiveScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, string> nc_program = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.MachineParamResp.prototype.getNcProgramMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearNcProgramMap = function() {
  this.getNcProgramMap().clear();
  return this;
};


/**
 * optional NCScore nc_score = 6;
 * @return {?proto.pbs.NCScore}
 */
proto.pbs.MachineParamResp.prototype.getNcScore = function() {
  return /** @type{?proto.pbs.NCScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.NCScore, 6));
};


/**
 * @param {?proto.pbs.NCScore|undefined} value
 * @return {!proto.pbs.MachineParamResp} returns this
*/
proto.pbs.MachineParamResp.prototype.setNcScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.clearNcScore = function() {
  return this.setNcScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParamResp.prototype.hasNcScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string scoreStandard_id = 7;
 * @return {string}
 */
proto.pbs.MachineParamResp.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineParamResp} returns this
 */
proto.pbs.MachineParamResp.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.OperateProcessResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateProcessResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateProcessResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateProcessResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateProcessScore: (f = msg.getOperateProcessScore()) && proto.pbs.OperateProcessScore.toObject(includeInstance, f),
    operationLogListList: jspb.Message.toObjectList(msg.getOperationLogListList(),
    operation_log_pb.OperationLog.toObject, includeInstance),
    operateLogScore: (f = msg.getOperateLogScore()) && proto.pbs.OperateLogScore.toObject(includeInstance, f),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateProcessResp}
 */
proto.pbs.OperateProcessResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateProcessResp;
  return proto.pbs.OperateProcessResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateProcessResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateProcessResp}
 */
proto.pbs.OperateProcessResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.OperateProcessScore;
      reader.readMessage(value,proto.pbs.OperateProcessScore.deserializeBinaryFromReader);
      msg.setOperateProcessScore(value);
      break;
    case 2:
      var value = new operation_log_pb.OperationLog;
      reader.readMessage(value,operation_log_pb.OperationLog.deserializeBinaryFromReader);
      msg.addOperationLogList(value);
      break;
    case 3:
      var value = new proto.pbs.OperateLogScore;
      reader.readMessage(value,proto.pbs.OperateLogScore.deserializeBinaryFromReader);
      msg.setOperateLogScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateProcessResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateProcessResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateProcessResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateProcessScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.OperateProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationLogListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      operation_log_pb.OperationLog.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.OperateLogScore.serializeBinaryToWriter
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional OperateProcessScore operate_process_score = 1;
 * @return {?proto.pbs.OperateProcessScore}
 */
proto.pbs.OperateProcessResp.prototype.getOperateProcessScore = function() {
  return /** @type{?proto.pbs.OperateProcessScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateProcessScore, 1));
};


/**
 * @param {?proto.pbs.OperateProcessScore|undefined} value
 * @return {!proto.pbs.OperateProcessResp} returns this
*/
proto.pbs.OperateProcessResp.prototype.setOperateProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperateProcessResp} returns this
 */
proto.pbs.OperateProcessResp.prototype.clearOperateProcessScore = function() {
  return this.setOperateProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperateProcessResp.prototype.hasOperateProcessScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OperationLog operation_log_list = 2;
 * @return {!Array<!proto.pbs.OperationLog>}
 */
proto.pbs.OperateProcessResp.prototype.getOperationLogListList = function() {
  return /** @type{!Array<!proto.pbs.OperationLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, operation_log_pb.OperationLog, 2));
};


/**
 * @param {!Array<!proto.pbs.OperationLog>} value
 * @return {!proto.pbs.OperateProcessResp} returns this
*/
proto.pbs.OperateProcessResp.prototype.setOperationLogListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.OperationLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperationLog}
 */
proto.pbs.OperateProcessResp.prototype.addOperationLogList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.OperationLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.OperateProcessResp} returns this
 */
proto.pbs.OperateProcessResp.prototype.clearOperationLogListList = function() {
  return this.setOperationLogListList([]);
};


/**
 * optional OperateLogScore operate_log_score = 3;
 * @return {?proto.pbs.OperateLogScore}
 */
proto.pbs.OperateProcessResp.prototype.getOperateLogScore = function() {
  return /** @type{?proto.pbs.OperateLogScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateLogScore, 3));
};


/**
 * @param {?proto.pbs.OperateLogScore|undefined} value
 * @return {!proto.pbs.OperateProcessResp} returns this
*/
proto.pbs.OperateProcessResp.prototype.setOperateLogScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperateProcessResp} returns this
 */
proto.pbs.OperateProcessResp.prototype.clearOperateLogScore = function() {
  return this.setOperateLogScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperateProcessResp.prototype.hasOperateLogScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scoreStandard_id = 4;
 * @return {string}
 */
proto.pbs.OperateProcessResp.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperateProcessResp} returns this
 */
proto.pbs.OperateProcessResp.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BlankScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BlankScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BlankScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    blank: (f = msg.getBlank()) && blank_pb.Blank.toObject(includeInstance, f),
    blankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    blankScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BlankScore}
 */
proto.pbs.BlankScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BlankScore;
  return proto.pbs.BlankScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BlankScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BlankScore}
 */
proto.pbs.BlankScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.setBlank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBlankScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBlankScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BlankScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BlankScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BlankScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlank();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBlankScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional Blank blank = 1;
 * @return {?proto.pbs.Blank}
 */
proto.pbs.BlankScore.prototype.getBlank = function() {
  return /** @type{?proto.pbs.Blank} */ (
    jspb.Message.getWrapperField(this, blank_pb.Blank, 1));
};


/**
 * @param {?proto.pbs.Blank|undefined} value
 * @return {!proto.pbs.BlankScore} returns this
*/
proto.pbs.BlankScore.prototype.setBlank = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankScore} returns this
 */
proto.pbs.BlankScore.prototype.clearBlank = function() {
  return this.setBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankScore.prototype.hasBlank = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double blank_score = 2;
 * @return {number}
 */
proto.pbs.BlankScore.prototype.getBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankScore} returns this
 */
proto.pbs.BlankScore.prototype.setBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double blank_score_refer = 3;
 * @return {number}
 */
proto.pbs.BlankScore.prototype.getBlankScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankScore} returns this
 */
proto.pbs.BlankScore.prototype.setBlankScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ClampScore.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ClampScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ClampScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ClampScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    clampTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    clampTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clampsList: jspb.Message.toObjectList(msg.getClampsList(),
    proto.pbs.BlankClamp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ClampScore}
 */
proto.pbs.ClampScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ClampScore;
  return proto.pbs.ClampScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ClampScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ClampScore}
 */
proto.pbs.ClampScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampTotalScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampTotalScoreRefer(value);
      break;
    case 3:
      var value = new proto.pbs.BlankClamp;
      reader.readMessage(value,proto.pbs.BlankClamp.deserializeBinaryFromReader);
      msg.addClamps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ClampScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ClampScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ClampScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClampTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getClampTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClampsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.BlankClamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional double clamp_total_score = 1;
 * @return {number}
 */
proto.pbs.ClampScore.prototype.getClampTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampScore} returns this
 */
proto.pbs.ClampScore.prototype.setClampTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double clamp_total_score_refer = 2;
 * @return {number}
 */
proto.pbs.ClampScore.prototype.getClampTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampScore} returns this
 */
proto.pbs.ClampScore.prototype.setClampTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated BlankClamp clamps = 3;
 * @return {!Array<!proto.pbs.BlankClamp>}
 */
proto.pbs.ClampScore.prototype.getClampsList = function() {
  return /** @type{!Array<!proto.pbs.BlankClamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.BlankClamp, 3));
};


/**
 * @param {!Array<!proto.pbs.BlankClamp>} value
 * @return {!proto.pbs.ClampScore} returns this
*/
proto.pbs.ClampScore.prototype.setClampsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.BlankClamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.BlankClamp}
 */
proto.pbs.ClampScore.prototype.addClamps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.BlankClamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ClampScore} returns this
 */
proto.pbs.ClampScore.prototype.clearClampsList = function() {
  return this.setClampsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BlankClamp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BlankClamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BlankClamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankClamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    clampType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    clampPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clampPositionAnother: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    clampScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    clampRefer: (f = msg.getClampRefer()) && score_standard_pb.ClampParam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BlankClamp}
 */
proto.pbs.BlankClamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BlankClamp;
  return proto.pbs.BlankClamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BlankClamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BlankClamp}
 */
proto.pbs.BlankClamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClampType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampPositionAnother(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampScore(value);
      break;
    case 5:
      var value = new score_standard_pb.ClampParam;
      reader.readMessage(value,score_standard_pb.ClampParam.deserializeBinaryFromReader);
      msg.setClampRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BlankClamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BlankClamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BlankClamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankClamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClampType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getClampPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClampPositionAnother();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClampScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getClampRefer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      score_standard_pb.ClampParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 clamp_type = 1;
 * @return {number}
 */
proto.pbs.BlankClamp.prototype.getClampType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankClamp} returns this
 */
proto.pbs.BlankClamp.prototype.setClampType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double clamp_position = 2;
 * @return {number}
 */
proto.pbs.BlankClamp.prototype.getClampPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankClamp} returns this
 */
proto.pbs.BlankClamp.prototype.setClampPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double clamp_position_another = 3;
 * @return {number}
 */
proto.pbs.BlankClamp.prototype.getClampPositionAnother = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankClamp} returns this
 */
proto.pbs.BlankClamp.prototype.setClampPositionAnother = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double clamp_score = 4;
 * @return {number}
 */
proto.pbs.BlankClamp.prototype.getClampScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankClamp} returns this
 */
proto.pbs.BlankClamp.prototype.setClampScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional ClampParam clamp_refer = 5;
 * @return {?proto.pbs.ClampParam}
 */
proto.pbs.BlankClamp.prototype.getClampRefer = function() {
  return /** @type{?proto.pbs.ClampParam} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.ClampParam, 5));
};


/**
 * @param {?proto.pbs.ClampParam|undefined} value
 * @return {!proto.pbs.BlankClamp} returns this
*/
proto.pbs.BlankClamp.prototype.setClampRefer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankClamp} returns this
 */
proto.pbs.BlankClamp.prototype.clearClampRefer = function() {
  return this.setClampRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankClamp.prototype.hasClampRefer = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CutterScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CutterScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CutterScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CutterScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    cuttersList: jspb.Message.toObjectList(msg.getCuttersList(),
    cutter_pb.Cutter.toObject, includeInstance),
    cutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    cutterScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CutterScore}
 */
proto.pbs.CutterScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CutterScore;
  return proto.pbs.CutterScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CutterScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CutterScore}
 */
proto.pbs.CutterScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addCutters(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CutterScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CutterScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CutterScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCuttersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCutterScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated Cutter cutters = 1;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterScore.prototype.getCuttersList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 1));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterScore} returns this
*/
proto.pbs.CutterScore.prototype.setCuttersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterScore.prototype.addCutters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterScore} returns this
 */
proto.pbs.CutterScore.prototype.clearCuttersList = function() {
  return this.setCuttersList([]);
};


/**
 * optional double cutter_score = 2;
 * @return {number}
 */
proto.pbs.CutterScore.prototype.getCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterScore} returns this
 */
proto.pbs.CutterScore.prototype.setCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double cutter_score_refer = 3;
 * @return {number}
 */
proto.pbs.CutterScore.prototype.getCutterScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterScore} returns this
 */
proto.pbs.CutterScore.prototype.setCutterScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Machine.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Machine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Machine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Machine.toObject = function(includeInstance, msg) {
  var f, obj = {
    craftType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spindleSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    feedSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    backCutterMete: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cutter: (f = msg.getCutter()) && cutter_pb.Cutter.toObject(includeInstance, f),
    spindleSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    feedSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    backCutterMeteScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    cutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    machineRefer: (f = msg.getMachineRefer()) && score_standard_pb.MachineParam.toObject(includeInstance, f),
    machineParamId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Machine}
 */
proto.pbs.Machine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Machine;
  return proto.pbs.Machine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Machine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Machine}
 */
proto.pbs.Machine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCraftType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMete(value);
      break;
    case 5:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScore(value);
      break;
    case 10:
      var value = new score_standard_pb.MachineParam;
      reader.readMessage(value,score_standard_pb.MachineParam.deserializeBinaryFromReader);
      msg.setMachineRefer(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineParamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Machine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Machine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Machine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Machine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCraftType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpindleSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFeedSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBackCutterMete();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getSpindleSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getFeedSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getBackCutterMeteScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getMachineRefer();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      score_standard_pb.MachineParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineParamId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 craft_type = 1;
 * @return {number}
 */
proto.pbs.Machine.prototype.getCraftType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setCraftType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double spindle_speed = 2;
 * @return {number}
 */
proto.pbs.Machine.prototype.getSpindleSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setSpindleSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double feed_speed = 3;
 * @return {number}
 */
proto.pbs.Machine.prototype.getFeedSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setFeedSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double back_cutter_mete = 4;
 * @return {number}
 */
proto.pbs.Machine.prototype.getBackCutterMete = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setBackCutterMete = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Cutter cutter = 5;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.Machine.prototype.getCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 5));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.Machine} returns this
*/
proto.pbs.Machine.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Machine.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double spindle_speed_score = 6;
 * @return {number}
 */
proto.pbs.Machine.prototype.getSpindleSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setSpindleSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double feed_speed_score = 7;
 * @return {number}
 */
proto.pbs.Machine.prototype.getFeedSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setFeedSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double back_cutter_mete_score = 8;
 * @return {number}
 */
proto.pbs.Machine.prototype.getBackCutterMeteScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setBackCutterMeteScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double cutter_score = 9;
 * @return {number}
 */
proto.pbs.Machine.prototype.getCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional MachineParam machine_refer = 10;
 * @return {?proto.pbs.MachineParam}
 */
proto.pbs.Machine.prototype.getMachineRefer = function() {
  return /** @type{?proto.pbs.MachineParam} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.MachineParam, 10));
};


/**
 * @param {?proto.pbs.MachineParam|undefined} value
 * @return {!proto.pbs.Machine} returns this
*/
proto.pbs.Machine.prototype.setMachineRefer = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.clearMachineRefer = function() {
  return this.setMachineRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Machine.prototype.hasMachineRefer = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string machine_param_id = 11;
 * @return {string}
 */
proto.pbs.Machine.prototype.getMachineParamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Machine} returns this
 */
proto.pbs.Machine.prototype.setMachineParamId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MachineProcess.repeatedFields_ = [2,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dependProcessList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    processScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    isDepend: jspb.Message.getFieldWithDefault(msg, 4, 0),
    machineProcessRefer: (f = msg.getMachineProcessRefer()) && score_standard_pb.MachineProcessParam.toObject(includeInstance, f),
    machineParamId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dependParamIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineProcess}
 */
proto.pbs.MachineProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineProcess;
  return proto.pbs.MachineProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineProcess}
 */
proto.pbs.MachineProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcess(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDependProcess(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsDepend(value);
      break;
    case 5:
      var value = new score_standard_pb.MachineProcessParam;
      reader.readMessage(value,score_standard_pb.MachineProcessParam.deserializeBinaryFromReader);
      msg.setMachineProcessRefer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineParamId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependParamIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDependProcessList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getProcessScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIsDepend();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMachineProcessRefer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      score_standard_pb.MachineProcessParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineParamId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDependParamIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int32 process = 1;
 * @return {number}
 */
proto.pbs.MachineProcess.prototype.getProcess = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setProcess = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 depend_process = 2;
 * @return {!Array<number>}
 */
proto.pbs.MachineProcess.prototype.getDependProcessList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setDependProcessList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.addDependProcess = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.clearDependProcessList = function() {
  return this.setDependProcessList([]);
};


/**
 * optional double process_score = 3;
 * @return {number}
 */
proto.pbs.MachineProcess.prototype.getProcessScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setProcessScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 is_depend = 4;
 * @return {number}
 */
proto.pbs.MachineProcess.prototype.getIsDepend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setIsDepend = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional MachineProcessParam machine_process_refer = 5;
 * @return {?proto.pbs.MachineProcessParam}
 */
proto.pbs.MachineProcess.prototype.getMachineProcessRefer = function() {
  return /** @type{?proto.pbs.MachineProcessParam} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.MachineProcessParam, 5));
};


/**
 * @param {?proto.pbs.MachineProcessParam|undefined} value
 * @return {!proto.pbs.MachineProcess} returns this
*/
proto.pbs.MachineProcess.prototype.setMachineProcessRefer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.clearMachineProcessRefer = function() {
  return this.setMachineProcessRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineProcess.prototype.hasMachineProcessRefer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string machine_param_id = 6;
 * @return {string}
 */
proto.pbs.MachineProcess.prototype.getMachineParamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setMachineParamId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string depend_param_ids = 7;
 * @return {!Array<string>}
 */
proto.pbs.MachineProcess.prototype.getDependParamIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.setDependParamIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.addDependParamIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcess} returns this
 */
proto.pbs.MachineProcess.prototype.clearDependParamIdsList = function() {
  return this.setDependParamIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceQuality.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceQuality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceQuality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQuality.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    workpieceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    workpieceRefer: (f = msg.getWorkpieceRefer()) && score_standard_pb.WorkpieceParam.toObject(includeInstance, f),
    startPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    startPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    referParam: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    paramName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceQuality}
 */
proto.pbs.WorkpieceQuality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceQuality;
  return proto.pbs.WorkpieceQuality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceQuality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceQuality}
 */
proto.pbs.WorkpieceQuality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceScore(value);
      break;
    case 4:
      var value = new score_standard_pb.WorkpieceParam;
      reader.readMessage(value,score_standard_pb.WorkpieceParam.deserializeBinaryFromReader);
      msg.setWorkpieceRefer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointZ(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReferParam(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceQuality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceQuality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceQuality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQuality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getWorkpieceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getWorkpieceRefer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      score_standard_pb.WorkpieceParam.serializeBinaryToWriter
    );
  }
  f = message.getStartPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStartPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getReferParam();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getParamName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double param = 2;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double workpiece_score = 3;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getWorkpieceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setWorkpieceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional WorkpieceParam workpiece_refer = 4;
 * @return {?proto.pbs.WorkpieceParam}
 */
proto.pbs.WorkpieceQuality.prototype.getWorkpieceRefer = function() {
  return /** @type{?proto.pbs.WorkpieceParam} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.WorkpieceParam, 4));
};


/**
 * @param {?proto.pbs.WorkpieceParam|undefined} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
*/
proto.pbs.WorkpieceQuality.prototype.setWorkpieceRefer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.clearWorkpieceRefer = function() {
  return this.setWorkpieceRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.WorkpieceQuality.prototype.hasWorkpieceRefer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double start_point_x = 5;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getStartPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setStartPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double start_point_z = 6;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getStartPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setStartPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double refer_param = 7;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getReferParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setReferParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string param_name = 8;
 * @return {string}
 */
proto.pbs.WorkpieceQuality.prototype.getParamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setParamName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 workpiece_index = 9;
 * @return {number}
 */
proto.pbs.WorkpieceQuality.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQuality} returns this
 */
proto.pbs.WorkpieceQuality.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceQualityMilling.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceQualityMilling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceQualityMilling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQualityMilling.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workpieceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    workpieceMillingRefer: (f = msg.getWorkpieceMillingRefer()) && score_standard_pb.WorkpieceParamMilling.toObject(includeInstance, f),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 9, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    paramName: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceQualityMilling}
 */
proto.pbs.WorkpieceQualityMilling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceQualityMilling;
  return proto.pbs.WorkpieceQualityMilling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceQualityMilling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceQualityMilling}
 */
proto.pbs.WorkpieceQualityMilling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceScore(value);
      break;
    case 8:
      var value = new score_standard_pb.WorkpieceParamMilling;
      reader.readMessage(value,score_standard_pb.WorkpieceParamMilling.deserializeBinaryFromReader);
      msg.setWorkpieceMillingRefer(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceQualityMilling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceQualityMilling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceQualityMilling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQualityMilling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWorkpieceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getWorkpieceMillingRefer();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      score_standard_pb.WorkpieceParamMilling.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getParamName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double workpiece_score = 7;
 * @return {number}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getWorkpieceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.setWorkpieceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional WorkpieceParamMilling workpiece_milling_refer = 8;
 * @return {?proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getWorkpieceMillingRefer = function() {
  return /** @type{?proto.pbs.WorkpieceParamMilling} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.WorkpieceParamMilling, 8));
};


/**
 * @param {?proto.pbs.WorkpieceParamMilling|undefined} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
*/
proto.pbs.WorkpieceQualityMilling.prototype.setWorkpieceMillingRefer = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.clearWorkpieceMillingRefer = function() {
  return this.setWorkpieceMillingRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.WorkpieceQualityMilling.prototype.hasWorkpieceMillingRefer = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 workpiece_index = 9;
 * @return {number}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double param = 10;
 * @return {number}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string param_name = 11;
 * @return {string}
 */
proto.pbs.WorkpieceQualityMilling.prototype.getParamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceQualityMilling} returns this
 */
proto.pbs.WorkpieceQualityMilling.prototype.setParamName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineEffectiveScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineEffectiveScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineEffectiveScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ncRunTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    installCutterNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    changeBlankNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hitCutterNum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    workpieceQualityProp: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    machineScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    machineEffectiveScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineEffectiveScore}
 */
proto.pbs.MachineEffectiveScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineEffectiveScore;
  return proto.pbs.MachineEffectiveScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineEffectiveScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineEffectiveScore}
 */
proto.pbs.MachineEffectiveScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNcRunTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCutterNum(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangeBlankNum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitCutterNum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityProp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineEffectiveScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineEffectiveScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineEffectiveScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineEffectiveScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcRunTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInstallCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChangeBlankNum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getHitCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getWorkpieceQualityProp();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMachineScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMachineEffectiveScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int64 nc_run_time = 1;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getNcRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setNcRunTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 install_cutter_num = 2;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getInstallCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setInstallCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 change_blank_num = 3;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getChangeBlankNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setChangeBlankNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 hit_cutter_num = 4;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getHitCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setHitCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double workpiece_quality_prop = 5;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getWorkpieceQualityProp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setWorkpieceQualityProp = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double machine_score = 6;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getMachineScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setMachineScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double machine_effective_score_refer = 7;
 * @return {number}
 */
proto.pbs.MachineEffectiveScore.prototype.getMachineEffectiveScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveScore} returns this
 */
proto.pbs.MachineEffectiveScore.prototype.setMachineEffectiveScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operateScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isDone: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operateProcessRefer: (f = msg.getOperateProcessRefer()) && score_standard_pb.OperateProcessParam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateProcess}
 */
proto.pbs.OperateProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateProcess;
  return proto.pbs.OperateProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateProcess}
 */
proto.pbs.OperateProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOperateScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsDone(value);
      break;
    case 4:
      var value = new score_standard_pb.OperateProcessParam;
      reader.readMessage(value,score_standard_pb.OperateProcessParam.deserializeBinaryFromReader);
      msg.setOperateProcessRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperateScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getIsDone();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOperateProcessRefer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      score_standard_pb.OperateProcessParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateProcess.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcess} returns this
 */
proto.pbs.OperateProcess.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double operate_score = 2;
 * @return {number}
 */
proto.pbs.OperateProcess.prototype.getOperateScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcess} returns this
 */
proto.pbs.OperateProcess.prototype.setOperateScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 is_done = 3;
 * @return {number}
 */
proto.pbs.OperateProcess.prototype.getIsDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcess} returns this
 */
proto.pbs.OperateProcess.prototype.setIsDone = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional OperateProcessParam operate_process_refer = 4;
 * @return {?proto.pbs.OperateProcessParam}
 */
proto.pbs.OperateProcess.prototype.getOperateProcessRefer = function() {
  return /** @type{?proto.pbs.OperateProcessParam} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.OperateProcessParam, 4));
};


/**
 * @param {?proto.pbs.OperateProcessParam|undefined} value
 * @return {!proto.pbs.OperateProcess} returns this
*/
proto.pbs.OperateProcess.prototype.setOperateProcessRefer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperateProcess} returns this
 */
proto.pbs.OperateProcess.prototype.clearOperateProcessRefer = function() {
  return this.setOperateProcessRefer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperateProcess.prototype.hasOperateProcessRefer = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLog.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deductNums: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLog}
 */
proto.pbs.OperateLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLog;
  return proto.pbs.OperateLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLog}
 */
proto.pbs.OperateLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductNums(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeductScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeductNums();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateLog.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLog} returns this
 */
proto.pbs.OperateLog.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 deduct_nums = 2;
 * @return {number}
 */
proto.pbs.OperateLog.prototype.getDeductNums = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLog} returns this
 */
proto.pbs.OperateLog.prototype.setDeductNums = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double deduct_score = 3;
 * @return {number}
 */
proto.pbs.OperateLog.prototype.getDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLog} returns this
 */
proto.pbs.OperateLog.prototype.setDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MachineScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineListList: jspb.Message.toObjectList(msg.getMachineListList(),
    proto.pbs.Machine.toObject, includeInstance),
    machineTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    machineTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineScore}
 */
proto.pbs.MachineScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineScore;
  return proto.pbs.MachineScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineScore}
 */
proto.pbs.MachineScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Machine;
      reader.readMessage(value,proto.pbs.Machine.deserializeBinaryFromReader);
      msg.addMachineList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Machine.serializeBinaryToWriter
    );
  }
  f = message.getMachineTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMachineTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated Machine machine_list = 1;
 * @return {!Array<!proto.pbs.Machine>}
 */
proto.pbs.MachineScore.prototype.getMachineListList = function() {
  return /** @type{!Array<!proto.pbs.Machine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Machine, 1));
};


/**
 * @param {!Array<!proto.pbs.Machine>} value
 * @return {!proto.pbs.MachineScore} returns this
*/
proto.pbs.MachineScore.prototype.setMachineListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Machine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Machine}
 */
proto.pbs.MachineScore.prototype.addMachineList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Machine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineScore} returns this
 */
proto.pbs.MachineScore.prototype.clearMachineListList = function() {
  return this.setMachineListList([]);
};


/**
 * optional double machine_total_score = 2;
 * @return {number}
 */
proto.pbs.MachineScore.prototype.getMachineTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineScore} returns this
 */
proto.pbs.MachineScore.prototype.setMachineTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double machine_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MachineScore.prototype.getMachineTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineScore} returns this
 */
proto.pbs.MachineScore.prototype.setMachineTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MachineProcessScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineProcessScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineProcessScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineProcessScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    machineProcessListList: jspb.Message.toObjectList(msg.getMachineProcessListList(),
    proto.pbs.MachineProcess.toObject, includeInstance),
    machineProcessTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    machineProcessTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineProcessScore}
 */
proto.pbs.MachineProcessScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineProcessScore;
  return proto.pbs.MachineProcessScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineProcessScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineProcessScore}
 */
proto.pbs.MachineProcessScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MachineProcess;
      reader.readMessage(value,proto.pbs.MachineProcess.deserializeBinaryFromReader);
      msg.addMachineProcessList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineProcessTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineProcessTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineProcessScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineProcessScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineProcessScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMachineProcessListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.MachineProcess.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMachineProcessTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated MachineProcess machine_process_list = 1;
 * @return {!Array<!proto.pbs.MachineProcess>}
 */
proto.pbs.MachineProcessScore.prototype.getMachineProcessListList = function() {
  return /** @type{!Array<!proto.pbs.MachineProcess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MachineProcess, 1));
};


/**
 * @param {!Array<!proto.pbs.MachineProcess>} value
 * @return {!proto.pbs.MachineProcessScore} returns this
*/
proto.pbs.MachineProcessScore.prototype.setMachineProcessListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.MachineProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcess}
 */
proto.pbs.MachineProcessScore.prototype.addMachineProcessList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.MachineProcess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcessScore} returns this
 */
proto.pbs.MachineProcessScore.prototype.clearMachineProcessListList = function() {
  return this.setMachineProcessListList([]);
};


/**
 * optional double machine_process_total_score = 2;
 * @return {number}
 */
proto.pbs.MachineProcessScore.prototype.getMachineProcessTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcessScore} returns this
 */
proto.pbs.MachineProcessScore.prototype.setMachineProcessTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double machine_process_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MachineProcessScore.prototype.getMachineProcessTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcessScore} returns this
 */
proto.pbs.MachineProcessScore.prototype.setMachineProcessTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceQualityScore.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceQualityScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceQualityScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceQualityScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQualityScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    workpieceQualityListList: jspb.Message.toObjectList(msg.getWorkpieceQualityListList(),
    proto.pbs.WorkpieceQuality.toObject, includeInstance),
    workpieceQualityTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    workpieceQualityTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    workpieceQualityMillingList: jspb.Message.toObjectList(msg.getWorkpieceQualityMillingList(),
    proto.pbs.WorkpieceQualityMilling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceQualityScore}
 */
proto.pbs.WorkpieceQualityScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceQualityScore;
  return proto.pbs.WorkpieceQualityScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceQualityScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceQualityScore}
 */
proto.pbs.WorkpieceQualityScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.WorkpieceQuality;
      reader.readMessage(value,proto.pbs.WorkpieceQuality.deserializeBinaryFromReader);
      msg.addWorkpieceQualityList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityTotalScoreRefer(value);
      break;
    case 4:
      var value = new proto.pbs.WorkpieceQualityMilling;
      reader.readMessage(value,proto.pbs.WorkpieceQualityMilling.deserializeBinaryFromReader);
      msg.addWorkpieceQualityMilling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceQualityScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceQualityScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceQualityScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceQualityScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkpieceQualityListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.WorkpieceQuality.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getWorkpieceQualityTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getWorkpieceQualityMillingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.WorkpieceQualityMilling.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WorkpieceQuality workpiece_quality_list = 1;
 * @return {!Array<!proto.pbs.WorkpieceQuality>}
 */
proto.pbs.WorkpieceQualityScore.prototype.getWorkpieceQualityListList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceQuality>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceQuality, 1));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceQuality>} value
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
*/
proto.pbs.WorkpieceQualityScore.prototype.setWorkpieceQualityListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.WorkpieceQuality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceQuality}
 */
proto.pbs.WorkpieceQualityScore.prototype.addWorkpieceQualityList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.WorkpieceQuality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
 */
proto.pbs.WorkpieceQualityScore.prototype.clearWorkpieceQualityListList = function() {
  return this.setWorkpieceQualityListList([]);
};


/**
 * optional double workpiece_quality_total_score = 2;
 * @return {number}
 */
proto.pbs.WorkpieceQualityScore.prototype.getWorkpieceQualityTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
 */
proto.pbs.WorkpieceQualityScore.prototype.setWorkpieceQualityTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double workpiece_quality_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.WorkpieceQualityScore.prototype.getWorkpieceQualityTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
 */
proto.pbs.WorkpieceQualityScore.prototype.setWorkpieceQualityTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated WorkpieceQualityMilling workpiece_quality_milling = 4;
 * @return {!Array<!proto.pbs.WorkpieceQualityMilling>}
 */
proto.pbs.WorkpieceQualityScore.prototype.getWorkpieceQualityMillingList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceQualityMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceQualityMilling, 4));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceQualityMilling>} value
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
*/
proto.pbs.WorkpieceQualityScore.prototype.setWorkpieceQualityMillingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.WorkpieceQualityMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceQualityMilling}
 */
proto.pbs.WorkpieceQualityScore.prototype.addWorkpieceQualityMilling = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.WorkpieceQualityMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceQualityScore} returns this
 */
proto.pbs.WorkpieceQualityScore.prototype.clearWorkpieceQualityMillingList = function() {
  return this.setWorkpieceQualityMillingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.OperateProcessScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateProcessScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateProcessScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateProcessScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateProcessListList: jspb.Message.toObjectList(msg.getOperateProcessListList(),
    proto.pbs.OperateProcess.toObject, includeInstance),
    operateProcessTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    operateProcessTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateProcessScore}
 */
proto.pbs.OperateProcessScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateProcessScore;
  return proto.pbs.OperateProcessScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateProcessScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateProcessScore}
 */
proto.pbs.OperateProcessScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.OperateProcess;
      reader.readMessage(value,proto.pbs.OperateProcess.deserializeBinaryFromReader);
      msg.addOperateProcessList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOperateProcessTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOperateProcessTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateProcessScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateProcessScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateProcessScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateProcessListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.OperateProcess.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOperateProcessTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated OperateProcess operate_process_list = 1;
 * @return {!Array<!proto.pbs.OperateProcess>}
 */
proto.pbs.OperateProcessScore.prototype.getOperateProcessListList = function() {
  return /** @type{!Array<!proto.pbs.OperateProcess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateProcess, 1));
};


/**
 * @param {!Array<!proto.pbs.OperateProcess>} value
 * @return {!proto.pbs.OperateProcessScore} returns this
*/
proto.pbs.OperateProcessScore.prototype.setOperateProcessListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.OperateProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateProcess}
 */
proto.pbs.OperateProcessScore.prototype.addOperateProcessList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.OperateProcess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.OperateProcessScore} returns this
 */
proto.pbs.OperateProcessScore.prototype.clearOperateProcessListList = function() {
  return this.setOperateProcessListList([]);
};


/**
 * optional double operate_process_total_score = 2;
 * @return {number}
 */
proto.pbs.OperateProcessScore.prototype.getOperateProcessTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessScore} returns this
 */
proto.pbs.OperateProcessScore.prototype.setOperateProcessTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double operate_process_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.OperateProcessScore.prototype.getOperateProcessTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessScore} returns this
 */
proto.pbs.OperateProcessScore.prototype.setOperateProcessTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.OperateLogScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLogScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLogScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLogScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateLogListList: jspb.Message.toObjectList(msg.getOperateLogListList(),
    proto.pbs.OperateLog.toObject, includeInstance),
    operateLogTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    operateLogTotalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLogScore}
 */
proto.pbs.OperateLogScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLogScore;
  return proto.pbs.OperateLogScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLogScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLogScore}
 */
proto.pbs.OperateLogScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.OperateLog;
      reader.readMessage(value,proto.pbs.OperateLog.deserializeBinaryFromReader);
      msg.addOperateLogList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOperateLogTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOperateLogTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLogScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLogScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLogScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateLogListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.OperateLog.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOperateLogTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated OperateLog operate_log_list = 1;
 * @return {!Array<!proto.pbs.OperateLog>}
 */
proto.pbs.OperateLogScore.prototype.getOperateLogListList = function() {
  return /** @type{!Array<!proto.pbs.OperateLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateLog, 1));
};


/**
 * @param {!Array<!proto.pbs.OperateLog>} value
 * @return {!proto.pbs.OperateLogScore} returns this
*/
proto.pbs.OperateLogScore.prototype.setOperateLogListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.OperateLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateLog}
 */
proto.pbs.OperateLogScore.prototype.addOperateLogList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.OperateLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.OperateLogScore} returns this
 */
proto.pbs.OperateLogScore.prototype.clearOperateLogListList = function() {
  return this.setOperateLogListList([]);
};


/**
 * optional double operate_log_total_score = 2;
 * @return {number}
 */
proto.pbs.OperateLogScore.prototype.getOperateLogTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogScore} returns this
 */
proto.pbs.OperateLogScore.prototype.setOperateLogTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double operate_log_total_score_refer = 3;
 * @return {number}
 */
proto.pbs.OperateLogScore.prototype.getOperateLogTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogScore} returns this
 */
proto.pbs.OperateLogScore.prototype.setOperateLogTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ExamScore.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blankScore: (f = msg.getBlankScore()) && proto.pbs.BlankScore.toObject(includeInstance, f),
    clampScore: (f = msg.getClampScore()) && proto.pbs.ClampScore.toObject(includeInstance, f),
    cutterScore: (f = msg.getCutterScore()) && proto.pbs.CutterScore.toObject(includeInstance, f),
    machineScore: (f = msg.getMachineScore()) && proto.pbs.MachineScore.toObject(includeInstance, f),
    machineProcessScore: (f = msg.getMachineProcessScore()) && proto.pbs.MachineProcessScore.toObject(includeInstance, f),
    workpieceQualityScore: (f = msg.getWorkpieceQualityScore()) && proto.pbs.WorkpieceQualityScore.toObject(includeInstance, f),
    machineEffectiveScore: (f = msg.getMachineEffectiveScore()) && proto.pbs.MachineEffectiveScore.toObject(includeInstance, f),
    operateProcessScore: (f = msg.getOperateProcessScore()) && proto.pbs.OperateProcessScore.toObject(includeInstance, f),
    ncProgramMap: (f = msg.getNcProgramMap()) ? f.toObject(includeInstance, undefined) : [],
    ncScore: (f = msg.getNcScore()) && proto.pbs.NCScore.toObject(includeInstance, f),
    operateLogScore: (f = msg.getOperateLogScore()) && proto.pbs.OperateLogScore.toObject(includeInstance, f),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    proto.pbs.ModelImage.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 27, 0),
    ma6StandardExamScore: (f = msg.getMa6StandardExamScore()) && semi_exam_score_pb.MA6StandardExamScore.toObject(includeInstance, f),
    standardType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    assetpath: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ncProgramPath: jspb.Message.getFieldWithDefault(msg, 30, ""),
    lab18StandardExamScore: (f = msg.getLab18StandardExamScore()) && semi_exam_score_pb.LAB18StandardExamScore.toObject(includeInstance, f),
    spinCoaterStandardExamScore: (f = msg.getSpinCoaterStandardExamScore()) && semi_exam_score_pb.SpinCoaterStandardExamScore.toObject(includeInstance, f),
    cleaningStandardExamScore: (f = msg.getCleaningStandardExamScore()) && semi_exam_score_pb.CleaningStandardExamScore.toObject(includeInstance, f),
    developmentStandardExamScore: (f = msg.getDevelopmentStandardExamScore()) && semi_exam_score_pb.DevelopmentStandardExamScore.toObject(includeInstance, f),
    plasmaStandardExamScore: (f = msg.getPlasmaStandardExamScore()) && semi_exam_score_pb.PlasmaStandardExamScore.toObject(includeInstance, f),
    ionImplantationStandardExamScore: (f = msg.getIonImplantationStandardExamScore()) && semi_exam_score_pb.IonImplantationStandardExamScore.toObject(includeInstance, f),
    electronBeamStandardExamScore: (f = msg.getElectronBeamStandardExamScore()) && semi_exam_score_pb.ElectronBeamStandardExamScore.toObject(includeInstance, f),
    stepperStandardExamScore: (f = msg.getStepperStandardExamScore()) && semi_exam_score_pb.StepperStandardExamScore.toObject(includeInstance, f),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    simiProcessStandardExamScore: (f = msg.getSimiProcessStandardExamScore()) && semi_exam_score_pb.SimiProcessStandardExamScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExamScore}
 */
proto.pbs.ExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExamScore;
  return proto.pbs.ExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExamScore}
 */
proto.pbs.ExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto.pbs.BlankScore;
      reader.readMessage(value,proto.pbs.BlankScore.deserializeBinaryFromReader);
      msg.setBlankScore(value);
      break;
    case 5:
      var value = new proto.pbs.ClampScore;
      reader.readMessage(value,proto.pbs.ClampScore.deserializeBinaryFromReader);
      msg.setClampScore(value);
      break;
    case 6:
      var value = new proto.pbs.CutterScore;
      reader.readMessage(value,proto.pbs.CutterScore.deserializeBinaryFromReader);
      msg.setCutterScore(value);
      break;
    case 7:
      var value = new proto.pbs.MachineScore;
      reader.readMessage(value,proto.pbs.MachineScore.deserializeBinaryFromReader);
      msg.setMachineScore(value);
      break;
    case 8:
      var value = new proto.pbs.MachineProcessScore;
      reader.readMessage(value,proto.pbs.MachineProcessScore.deserializeBinaryFromReader);
      msg.setMachineProcessScore(value);
      break;
    case 9:
      var value = new proto.pbs.WorkpieceQualityScore;
      reader.readMessage(value,proto.pbs.WorkpieceQualityScore.deserializeBinaryFromReader);
      msg.setWorkpieceQualityScore(value);
      break;
    case 10:
      var value = new proto.pbs.MachineEffectiveScore;
      reader.readMessage(value,proto.pbs.MachineEffectiveScore.deserializeBinaryFromReader);
      msg.setMachineEffectiveScore(value);
      break;
    case 11:
      var value = new proto.pbs.OperateProcessScore;
      reader.readMessage(value,proto.pbs.OperateProcessScore.deserializeBinaryFromReader);
      msg.setOperateProcessScore(value);
      break;
    case 12:
      var value = msg.getNcProgramMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 28:
      var value = new proto.pbs.NCScore;
      reader.readMessage(value,proto.pbs.NCScore.deserializeBinaryFromReader);
      msg.setNcScore(value);
      break;
    case 13:
      var value = new proto.pbs.OperateLogScore;
      reader.readMessage(value,proto.pbs.OperateLogScore.deserializeBinaryFromReader);
      msg.setOperateLogScore(value);
      break;
    case 14:
      var value = new proto.pbs.ModelImage;
      reader.readMessage(value,proto.pbs.ModelImage.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 16:
      var value = new semi_exam_score_pb.MA6StandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.MA6StandardExamScore.deserializeBinaryFromReader);
      msg.setMa6StandardExamScore(value);
      break;
    case 17:
      var value = /** @type {!proto.pbs.ScoreStandardType} */ (reader.readEnum());
      msg.setStandardType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetpath(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setNcProgramPath(value);
      break;
    case 19:
      var value = new semi_exam_score_pb.LAB18StandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.LAB18StandardExamScore.deserializeBinaryFromReader);
      msg.setLab18StandardExamScore(value);
      break;
    case 20:
      var value = new semi_exam_score_pb.SpinCoaterStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.SpinCoaterStandardExamScore.deserializeBinaryFromReader);
      msg.setSpinCoaterStandardExamScore(value);
      break;
    case 21:
      var value = new semi_exam_score_pb.CleaningStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.CleaningStandardExamScore.deserializeBinaryFromReader);
      msg.setCleaningStandardExamScore(value);
      break;
    case 22:
      var value = new semi_exam_score_pb.DevelopmentStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.DevelopmentStandardExamScore.deserializeBinaryFromReader);
      msg.setDevelopmentStandardExamScore(value);
      break;
    case 23:
      var value = new semi_exam_score_pb.PlasmaStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.PlasmaStandardExamScore.deserializeBinaryFromReader);
      msg.setPlasmaStandardExamScore(value);
      break;
    case 24:
      var value = new semi_exam_score_pb.IonImplantationStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.IonImplantationStandardExamScore.deserializeBinaryFromReader);
      msg.setIonImplantationStandardExamScore(value);
      break;
    case 25:
      var value = new semi_exam_score_pb.ElectronBeamStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.ElectronBeamStandardExamScore.deserializeBinaryFromReader);
      msg.setElectronBeamStandardExamScore(value);
      break;
    case 26:
      var value = new semi_exam_score_pb.StepperStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.StepperStandardExamScore.deserializeBinaryFromReader);
      msg.setStepperStandardExamScore(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    case 32:
      var value = new semi_exam_score_pb.SimiProcessStandardExamScore;
      reader.readMessage(value,semi_exam_score_pb.SimiProcessStandardExamScore.deserializeBinaryFromReader);
      msg.setSimiProcessStandardExamScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlankScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.BlankScore.serializeBinaryToWriter
    );
  }
  f = message.getClampScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.ClampScore.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.CutterScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.MachineScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.MachineProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.WorkpieceQualityScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffectiveScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.MachineEffectiveScore.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessScore();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.pbs.OperateProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getNcProgramMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getNcScore();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.pbs.NCScore.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogScore();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.OperateLogScore.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.pbs.ModelImage.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getMa6StandardExamScore();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      semi_exam_score_pb.MA6StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getStandardType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getAssetpath();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNcProgramPath();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getLab18StandardExamScore();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      semi_exam_score_pb.LAB18StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getSpinCoaterStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      semi_exam_score_pb.SpinCoaterStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      semi_exam_score_pb.CleaningStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      semi_exam_score_pb.DevelopmentStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      semi_exam_score_pb.PlasmaStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      semi_exam_score_pb.IonImplantationStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      semi_exam_score_pb.ElectronBeamStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      semi_exam_score_pb.StepperStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSimiProcessStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      semi_exam_score_pb.SimiProcessStandardExamScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_id = 2;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BlankScore blank_score = 4;
 * @return {?proto.pbs.BlankScore}
 */
proto.pbs.ExamScore.prototype.getBlankScore = function() {
  return /** @type{?proto.pbs.BlankScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.BlankScore, 4));
};


/**
 * @param {?proto.pbs.BlankScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setBlankScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearBlankScore = function() {
  return this.setBlankScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasBlankScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClampScore clamp_score = 5;
 * @return {?proto.pbs.ClampScore}
 */
proto.pbs.ExamScore.prototype.getClampScore = function() {
  return /** @type{?proto.pbs.ClampScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ClampScore, 5));
};


/**
 * @param {?proto.pbs.ClampScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setClampScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearClampScore = function() {
  return this.setClampScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasClampScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CutterScore cutter_score = 6;
 * @return {?proto.pbs.CutterScore}
 */
proto.pbs.ExamScore.prototype.getCutterScore = function() {
  return /** @type{?proto.pbs.CutterScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CutterScore, 6));
};


/**
 * @param {?proto.pbs.CutterScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setCutterScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearCutterScore = function() {
  return this.setCutterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasCutterScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MachineScore machine_score = 7;
 * @return {?proto.pbs.MachineScore}
 */
proto.pbs.ExamScore.prototype.getMachineScore = function() {
  return /** @type{?proto.pbs.MachineScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineScore, 7));
};


/**
 * @param {?proto.pbs.MachineScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setMachineScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearMachineScore = function() {
  return this.setMachineScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasMachineScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MachineProcessScore machine_process_score = 8;
 * @return {?proto.pbs.MachineProcessScore}
 */
proto.pbs.ExamScore.prototype.getMachineProcessScore = function() {
  return /** @type{?proto.pbs.MachineProcessScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineProcessScore, 8));
};


/**
 * @param {?proto.pbs.MachineProcessScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearMachineProcessScore = function() {
  return this.setMachineProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasMachineProcessScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WorkpieceQualityScore workpiece_quality_score = 9;
 * @return {?proto.pbs.WorkpieceQualityScore}
 */
proto.pbs.ExamScore.prototype.getWorkpieceQualityScore = function() {
  return /** @type{?proto.pbs.WorkpieceQualityScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.WorkpieceQualityScore, 9));
};


/**
 * @param {?proto.pbs.WorkpieceQualityScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearWorkpieceQualityScore = function() {
  return this.setWorkpieceQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasWorkpieceQualityScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MachineEffectiveScore machine_effective_score = 10;
 * @return {?proto.pbs.MachineEffectiveScore}
 */
proto.pbs.ExamScore.prototype.getMachineEffectiveScore = function() {
  return /** @type{?proto.pbs.MachineEffectiveScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineEffectiveScore, 10));
};


/**
 * @param {?proto.pbs.MachineEffectiveScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearMachineEffectiveScore = function() {
  return this.setMachineEffectiveScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasMachineEffectiveScore = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OperateProcessScore operate_process_score = 11;
 * @return {?proto.pbs.OperateProcessScore}
 */
proto.pbs.ExamScore.prototype.getOperateProcessScore = function() {
  return /** @type{?proto.pbs.OperateProcessScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateProcessScore, 11));
};


/**
 * @param {?proto.pbs.OperateProcessScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setOperateProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearOperateProcessScore = function() {
  return this.setOperateProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasOperateProcessScore = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * map<string, string> nc_program = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.ExamScore.prototype.getNcProgramMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearNcProgramMap = function() {
  this.getNcProgramMap().clear();
  return this;
};


/**
 * optional NCScore nc_score = 28;
 * @return {?proto.pbs.NCScore}
 */
proto.pbs.ExamScore.prototype.getNcScore = function() {
  return /** @type{?proto.pbs.NCScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.NCScore, 28));
};


/**
 * @param {?proto.pbs.NCScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setNcScore = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearNcScore = function() {
  return this.setNcScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasNcScore = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional OperateLogScore operate_log_score = 13;
 * @return {?proto.pbs.OperateLogScore}
 */
proto.pbs.ExamScore.prototype.getOperateLogScore = function() {
  return /** @type{?proto.pbs.OperateLogScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateLogScore, 13));
};


/**
 * @param {?proto.pbs.OperateLogScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setOperateLogScore = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearOperateLogScore = function() {
  return this.setOperateLogScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasOperateLogScore = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated ModelImage model_image = 14;
 * @return {!Array<!proto.pbs.ModelImage>}
 */
proto.pbs.ExamScore.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ModelImage, 14));
};


/**
 * @param {!Array<!proto.pbs.ModelImage>} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.ModelImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.ExamScore.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.ModelImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};


/**
 * optional int64 created_at = 15;
 * @return {number}
 */
proto.pbs.ExamScore.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 deleted_at = 27;
 * @return {number}
 */
proto.pbs.ExamScore.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional MA6StandardExamScore ma6_standard_exam_score = 16;
 * @return {?proto.pbs.MA6StandardExamScore}
 */
proto.pbs.ExamScore.prototype.getMa6StandardExamScore = function() {
  return /** @type{?proto.pbs.MA6StandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.MA6StandardExamScore, 16));
};


/**
 * @param {?proto.pbs.MA6StandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setMa6StandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearMa6StandardExamScore = function() {
  return this.setMa6StandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasMa6StandardExamScore = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ScoreStandardType standard_type = 17;
 * @return {!proto.pbs.ScoreStandardType}
 */
proto.pbs.ExamScore.prototype.getStandardType = function() {
  return /** @type {!proto.pbs.ScoreStandardType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.pbs.ScoreStandardType} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setStandardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string assetPath = 18;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getAssetpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setAssetpath = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string nc_program_path = 30;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getNcProgramPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setNcProgramPath = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional LAB18StandardExamScore lab18_standard_exam_score = 19;
 * @return {?proto.pbs.LAB18StandardExamScore}
 */
proto.pbs.ExamScore.prototype.getLab18StandardExamScore = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.LAB18StandardExamScore, 19));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setLab18StandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearLab18StandardExamScore = function() {
  return this.setLab18StandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasLab18StandardExamScore = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional SpinCoaterStandardExamScore spin_coater_standard_exam_score = 20;
 * @return {?proto.pbs.SpinCoaterStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getSpinCoaterStandardExamScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.SpinCoaterStandardExamScore, 20));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setSpinCoaterStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearSpinCoaterStandardExamScore = function() {
  return this.setSpinCoaterStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasSpinCoaterStandardExamScore = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional CleaningStandardExamScore cleaning_standard_exam_score = 21;
 * @return {?proto.pbs.CleaningStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getCleaningStandardExamScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.CleaningStandardExamScore, 21));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setCleaningStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearCleaningStandardExamScore = function() {
  return this.setCleaningStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasCleaningStandardExamScore = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional DevelopmentStandardExamScore development_standard_exam_score = 22;
 * @return {?proto.pbs.DevelopmentStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getDevelopmentStandardExamScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.DevelopmentStandardExamScore, 22));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setDevelopmentStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearDevelopmentStandardExamScore = function() {
  return this.setDevelopmentStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasDevelopmentStandardExamScore = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PlasmaStandardExamScore plasma_standard_exam_score = 23;
 * @return {?proto.pbs.PlasmaStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getPlasmaStandardExamScore = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.PlasmaStandardExamScore, 23));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setPlasmaStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearPlasmaStandardExamScore = function() {
  return this.setPlasmaStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasPlasmaStandardExamScore = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional IonImplantationStandardExamScore ion_implantation_standard_exam_score = 24;
 * @return {?proto.pbs.IonImplantationStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getIonImplantationStandardExamScore = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.IonImplantationStandardExamScore, 24));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setIonImplantationStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearIonImplantationStandardExamScore = function() {
  return this.setIonImplantationStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasIonImplantationStandardExamScore = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional ElectronBeamStandardExamScore electron_beam_standard_exam_score = 25;
 * @return {?proto.pbs.ElectronBeamStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getElectronBeamStandardExamScore = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.ElectronBeamStandardExamScore, 25));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setElectronBeamStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearElectronBeamStandardExamScore = function() {
  return this.setElectronBeamStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasElectronBeamStandardExamScore = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional StepperStandardExamScore stepper_standard_exam_score = 26;
 * @return {?proto.pbs.StepperStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getStepperStandardExamScore = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.StepperStandardExamScore, 26));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setStepperStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearStepperStandardExamScore = function() {
  return this.setStepperStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasStepperStandardExamScore = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string score_standard_id = 31;
 * @return {string}
 */
proto.pbs.ExamScore.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional SimiProcessStandardExamScore simi_process_standard_exam_score = 32;
 * @return {?proto.pbs.SimiProcessStandardExamScore}
 */
proto.pbs.ExamScore.prototype.getSimiProcessStandardExamScore = function() {
  return /** @type{?proto.pbs.SimiProcessStandardExamScore} */ (
    jspb.Message.getWrapperField(this, semi_exam_score_pb.SimiProcessStandardExamScore, 32));
};


/**
 * @param {?proto.pbs.SimiProcessStandardExamScore|undefined} value
 * @return {!proto.pbs.ExamScore} returns this
*/
proto.pbs.ExamScore.prototype.setSimiProcessStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExamScore} returns this
 */
proto.pbs.ExamScore.prototype.clearSimiProcessStandardExamScore = function() {
  return this.setSimiProcessStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExamScore.prototype.hasSimiProcessStandardExamScore = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * @enum {number}
 */
proto.pbs.UpdateExamScoreFields = {
  EXAMSCOREFIELD_: 0,
  EXAMSCOREFIELD_MACHINIE: 1,
  EXAMSCOREFIELD_WORKPIECEQUALITY: 2,
  EXAMSCOREFIELD_NCSCORE: 3
};

/**
 * @enum {number}
 */
proto.pbs.DatailType = {
  DATAILTYPE_: 0,
  DATAILTYPE_SPINDLESPEED: 1,
  DATAILTYPE_FEEDSPEED: 2
};

goog.object.extend(exports, proto.pbs);
