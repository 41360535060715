import { Component, OnInit } from '@angular/core';
import { NzModalService, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.styl']
})
export class ReportHeaderComponent implements OnInit {

  public header_logo:any = 'logo';
  public header_title:any = 'title';
  public title_text:any = '职业技能考核系统';
  public username:any = 'Megan';

  private user:any;

  constructor(
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private examsService: ExamsService,
    private userService: UserService) { }

  exit(){

    const modal: NzModalRef = this.modalService.create({
      nzTitle: '退出',
      nzMaskClosable: false,
      nzContent: '<div class="modal-container">\
                    <div class="icon"><img src="/assets/img/tip.png" alt=""></div>\
                    <p>确定要退出系统吗？</p>\
                  </div>',
      nzFooter: [
        {
          label: '确定',
          NzButtonShape: 'default',
          onClick: () => {
            // this.router.navigate(['/login']);
            common.returnLogin(this.router, this.examsService, this.message);
            modal.destroy();
          }
        },
        {
          label: '取消',
          type: 'primary',
          onClick: () =>  modal.destroy()
        }
      ]
    });
  }

  returnEntrance() {
    this.router.navigate(["/entrance"]);
  }

  ngOnInit() {
    this.user = this.userService.getUser();
	  let majorName = localStorage.getItem("majorName")
	  if (majorName != null) {
		  this.title_text = majorName + "考试平台";
	  }
  }

}

