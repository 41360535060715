import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { EventService } from 'src/app/event.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { webSocket } from 'rxjs/webSocket';
import { ExamsService } from 'src/app/services/exams.service';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.styl']
})
export class RightComponent implements OnInit {

  @ViewChild('markDiv', {static: true}) markDiv:ElementRef;

  private ws: WebSocket;
  public startDo:boolean = false; //是否开始加工
  private examId:any;
  private viewQuestions:any; //查看时的左侧菜单
  // 判断题 A 和 B 映射为 "正确" 和 "错误"
  public answerMapping: { [key: string]: string } = {
    'A': '正确',
    'B': '错误'
  };
  constructor(private event: EventService,
    private el:ElementRef,
    private renderer2: Renderer2,
    private examsService: ExamsService,
    private message: NzMessageService,
    private modalService: NzModalService, 
    private router: Router,
    private activatedRoute: ActivatedRoute) { 
      activatedRoute.queryParams.subscribe(queryParams => {
        this.type = queryParams.type;
        this.examId = queryParams.examId;
      });
    }
	
  public style;
  public type; //页面类型viewResult试卷查看
  private data:any; //题目
  private allQuestions:any = []; //所有题目
  private totalNum:any;  //题目总数
  private len:any;  //题目总数
  public typeId: any;  //题目类型
  public standardType: -1; //考核标准类型
  public item:any = {
    questionanswerList: [],
    questionanswerdesc: "",
    questionexamanswerList: [], //考生答案
    questionname: "",
    questionoptionList: [],
    questionoptionArr: [],
    questionmedia: "",
    questionmediaAn: []
  };  //单个题目
  public subscription: Subscription;
  public err:any;
  public isFlag:any = false; //是否标记
  public isPreEnd:boolean = true;
  public isNextEnd:boolean = false;
  public questionNo: any; //左侧题号
  public number: any;  //右侧题号
  private qtName = [   //填空题目
    {
      id: "",
      value: ""
    }
  ];

  private qtAns = []; //标注题答案

  private arrIndex: any = 0; //当前数组的下标

  public operationBtn = "开始加工"; //正在加工...

  checkedItem: string;

  checked = false;
  
  public uploadHost = environment.domainName;

  private isExit:boolean = false; //是否退出页面
  
  public connectWs():void {
		if (this.ws != null) 
		{
			//this.ws.close();
		}
		this.ws = new WebSocket("ws://127.0.0.1:5099/exam");
		this.ws.onopen = function (event) {}
		this.ws.onmessage = (evt)=>{
			this.onMessage(evt);
		}
		this.ws.onerror = (evt)=>{this.OnError(evt);}
		this.ws.onclose = (evt)=>{this.OnClose(evt);}
	}
	
	OnClose(evt) {
		if (this.isExit)return;
		console.log("重试中")
		setTimeout(()=>{this.connectWs();},3000);//3秒后重试
	}
	
	OnError(event) {
		//setTimeout(()=>{this.ws = new WebSocket("ws://127.0.0.1:5099/exam");},3000);//3秒后重试
	}
	
	onMessage(event) {
		//socket 获取后端传递到前端的信息
		//that.ws.send('sonmething');
		let that = this;
		if (event.data == 'ok') {//应用程序已经启动
		  that.startDo = true;
		  that.operationBtn = "正在加工...";
		} else if (event.data == 'exit') { //应用程序已经退出
		  //根据当前状态显示按钮
		  let exam = JSON.parse(localStorage.getItem("examVenue"));
		  that.examsService.getResult(exam.examid,(response) => {
		      if (response.examstatus == 3 || response.examstatus == 5) {
		        that.startDo = true;
		        that.operationBtn = "加工完成";
		        const modal: NzModalRef = that.modalService.create({
		          nzTitle: '完成提交',
		          nzMaskClosable: false,
		          nzClosable: false,
		          nzContent: '<div class="modal-container">\
		                        <div class="icon"><img src="/assets/img/ok.png" alt=""></div>\
		                        <p>考试已提交！</p>\
		                      </div>',
		          nzFooter: [
		            {
						label: '确认',
						NzButtonShape: 'primary',
						onClick: () => {
							that.router.navigate(["/entrance"]);
							modal.destroy();
		              }
		            }
		          ]
		        });
		      } else {
		        that.startDo = false;
		        that.operationBtn = "开始加工";
		      }
		    },(fail) => {
		      that.message.create("error",decodeURI(fail));
		    });
		} else {
			// alert(event.data);
      console.log(event.data);
		}
		
	}

  // 标记
  onClickFlag(et:any){
    if (this.item.isFlag) {
      this.item.isFlag = false;
      this.isFlag = false;
    } else {
      this.item.isFlag = true;
      this.isFlag = true;
    }
    
    this.event.publish(this.item,this.err);
  }

  // 上一题
  pre(et:any) {
    this.isNextEnd = false;
    this.arrIndex = this.arrIndex - 1;
    if (this.arrIndex == 1) {
      this.isPreEnd = true;
    } else {
      this.isPreEnd = false;
    }
    this.item = this.allQuestions[this.arrIndex - 1];
    if (this.allQuestions[this.arrIndex - 1].qtIndex < 10) {
      this.questionNo = '0' + this.allQuestions[this.arrIndex - 1].qtIndex;
    } else {
      this.questionNo = this.allQuestions[this.arrIndex - 1].qtIndex;
    }
    this.number = this.allQuestions[this.arrIndex - 1].qtIndex + "/" + this.totalNum;
    this.isFlag = this.allQuestions[this.arrIndex - 1].isFlag;

    for (let index = 0; index < this.viewQuestions.length; index++) {
      this.viewQuestions[index].isCurrent = false;
      if (this.item.questiontype == this.viewQuestions[index].typeId) {
        this.viewQuestions[index].isCurrent = true;
      }
    }
    this.event.publish(this.item,this.err);
  }

  // 下一题
  next(et:any) {
    this.isPreEnd = false;
    this.arrIndex = this.arrIndex + 1;
    if (this.arrIndex == this.totalNum) {
      this.isNextEnd = true;
    } else {
      this.isNextEnd = false;
    }
    this.item = this.allQuestions[this.arrIndex - 1];
    if (this.allQuestions[this.arrIndex - 1].qtIndex < 10) {
      this.questionNo = '0' + this.allQuestions[this.arrIndex - 1].qtIndex;
    } else {
      this.questionNo = this.allQuestions[this.arrIndex - 1].qtIndex;
    }
    this.number = this.allQuestions[this.arrIndex - 1].qtIndex + "/" + this.totalNum;
    this.isFlag = this.allQuestions[this.arrIndex - 1].isFlag;

    for (let index = 0; index < this.viewQuestions.length; index++) {
      this.viewQuestions[index].isCurrent = false;
      if (this.item.questiontype == this.viewQuestions[index].typeId) {
        this.viewQuestions[index].isCurrent = true;
      }
    }
    this.event.publish(this.item,this.err);
    console.log("下一题",this.item);
  }


  // 单选题
  onClick(value: any) {
    let examAn = [];
    if (!this.item.isActive){
      this.item.isActive = true;
    }
    examAn.push(value);

    this.item.questionexamanswerList = examAn;
    this.event.publish(this.item,this.err);
    console.log("right单选答案",this.item.questionexamanswerList);
  }

  // 多选题
  onChange(value: object[]){  
    this.item.questionoptionList.forEach((option,i) => {
      option.isChecked = false;
    });
    for (let item of this.item.questionoptionList) {
      for (let i = 0; i < value.length; i++) {
        if (item.id == value[i]) {
          item.isChecked = true;
        }
      }
    }

    this.item.questionexamanswerList = value;
    if (!this.item.isActive && this.item.questionexamanswerList.length != 0){
      this.item.isActive = true;
    } else if (this.item.isActive && this.item.questionexamanswerList.length == 0){
      this.item.isActive = false;
    }
    this.event.publish(this.item,this.err);
    console.log("right多选答案",this.item.questionexamanswerList);
  }

  //填空题
  onWrite(item:any) {
    let examAn = [];
    for (let i = 0; i < this.item.questionoptionArr.length - 1; i++) {
      examAn.push(this.item.questionoptionArr[i].value);
    }
    this.item.questionexamanswerList = examAn;
    if (!this.item.isActive && examAn.length != 0){
      this.item.isActive = true;
    } else if (this.item.isActive && examAn.length == 0){
      this.item.isActive = false;
    }
    this.event.publish(this.item,this.err);
    console.log("right填空答案",this.item.questionexamanswerList);
  }

  //标注题
  onMark(ev:any) {
    let examAn = this.item.questionexamanswerList ? this.item.questionexamanswerList : [];
    
    const markBox = this.renderer2.createElement('div');
    this.renderer2.addClass(markBox, 'mark');
    this.renderer2.setStyle(markBox,'top',ev.offsetY +'px');
    this.renderer2.setStyle(markBox,'left',ev.offsetX +'px');
    this.renderer2.appendChild(this.el.nativeElement.querySelector(".markBox"),markBox);

    let mark = ev.offsetX + "," + ev.offsetY;
    examAn.push(mark);

    this.item.questionexamanswerList = examAn;
    if (!this.item.isActive && examAn.length != 0){
      this.item.isActive = true;
    } else if (this.item.isActive && examAn.length == 0){
      this.item.isActive = false;
    }
    this.event.publish(this.item,this.err);
    console.log("right标注答案",this.item.questionexamanswerList);
  }

  //标注题重置
  reset() {
    this.el.nativeElement.querySelectorAll('.mark').forEach(element =>{
      this.renderer2.removeChild(this.el.nativeElement.querySelector(".markBox"),element);
    })
    this.item.questionexamanswerList = [];
    this.item.isActive = false;
    this.event.publish(this.item,this.err);
    console.log("right标注答案",this.item.questionexamanswerList);
  }

  //简答题
  onShortAn(ev:any) {
    let examAn = [];
    examAn.push(ev.target.value);
    this.item.questionexamanswerList = examAn;

    if (!this.item.isActive && examAn.length != 0){
      this.item.isActive = true;
    } else if (this.item.isActive && examAn.length == 0){
      this.item.isActive = false;
    }
    this.event.publish(this.item,this.err);
    console.log("right简答答案",this.item.questionexamanswerList);
  }

  // 判断题
  onTFClick(value: any) {
    let examAn = [];
    if (!this.item.isActive){
      this.item.isActive = true;
    }
    examAn.push(value);

    this.item.questionexamanswerList = examAn;
    this.event.publish(this.item,this.err);
    console.log("right判断题答案",this.item.questionexamanswerList);
  }
  // 时政题
  onCPClick(value: any) {
    let examAn = [];
    if (!this.item.isActive){
      this.item.isActive = true;
    }
    examAn.push(value);

    this.item.questionexamanswerList = examAn;
    this.event.publish(this.item,this.err);
    console.log("right时政题答案",this.item.questionexamanswerList);
  }
  // 职业素养题
  onPROClick(value: any) {
    let examAn = [];
    if (!this.item.isActive){
      this.item.isActive = true;
    }
    examAn.push(value);

    this.item.questionexamanswerList = examAn;
    this.event.publish(this.item,this.err);
    console.log("right职业素养题答案",this.item.questionexamanswerList);
  }
  //实操题
  onStartDo () {
	let exam = JSON.parse(localStorage.getItem("examVenue"));
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let devType = 'Lathe';//启动仿真加工类型
	let stype = exam.examvenue.venuepaper.paperscorestandard.standardType;
	
	if (stype == 0) {//车床
		devType = 'Lathe';
	} else if (stype == 1) {//铣床
		devType = 'Milling';
	} else if (stype == 2) {//ma6
		devType = 'ma6';
	} else if (stype == 3) {// LAB18
    devType = 'LAB18';
  } else if (stype == 4) {//SpinCoater
		devType = 'SpinCoater';
	} else if (stype == 5) {//Cleaning
		devType = 'Cleaning';
	} else if (stype == 6) {// Development
    devType = 'Development';
  } else if (stype == 7) {//Plasma
		devType = 'Plasma';
	} else if (stype == 8) {//IonImplantation
		devType = 'IonImplantation';
	} else if (stype == 9) {// ElectronBeam
    devType = 'ElectronBeam';
  } else if (stype == 10) {// Stepper
    devType = 'Stepper';
  }
	let msg = {
		id:0,
		args:[devType,userInfo.usertoken,exam.examid]
	}
	if (this.ws != null && this.ws.readyState === 1) {
		this.ws.send(JSON.stringify(msg))
	}
  }
	
  ngOnDestroy() {
	  this.isExit = true;
  }

  
  ngOnInit() {
	this.isExit = false;
	this.connectWs();
    this.subscription = this.event.subscribe().subscribe(value => {
      let questData = localStorage.getItem("questionData");
      let examVenue = localStorage.getItem("examVenue");
      let exam:any;
	  if (examVenue) {
	  		  exam = JSON.parse(examVenue);
          if (exam.examvenue.venuepaper.paperscorestandard) {
            this.standardType = exam.examvenue.venuepaper.paperscorestandard.standardType
          } else {
            this.standardType = -1
          }
	  }
      let questions:any;
      console.log("standardType=", this.standardType)
      if(value.length > 0){
        this.data = value; //某一个题型对应的所有题目
        this.item = value[0]; //右侧显示的题目
        this.totalNum = value[0].totalNum; //题目数量
      } else {
        this.item = value;
      }

      if (questData) {
        questions = JSON.parse(questData);
        for (let i = 0; i < questions.length; i++) {
          for (let index = 0; index < questions[i].questions.length; index++) {
            if (questions[i].questions[index].questionid == this.item.questionid) {
              questions[i].questions[index] = this.item;
            }
          }
        }
        
        localStorage.setItem("questionData",JSON.stringify(questions));
      }

      if (examVenue && this.item.questiontype != 5) {
        let ans = this.item.questionexamanswerList || [];
        exam = JSON.parse(examVenue);
        if (this.item.questiontype != 5) {
          exam.examvenue.venuepaper.paperquestionList[this.item.index].questionexamanswerList = ans;
          exam.examanswersList[this.item.index] = JSON.stringify(ans);
  
          localStorage.setItem("examVenue",JSON.stringify(exam));
        } else {
          if (exam.examstatus == 3 || exam.examstatus == 5) {
            this.startDo = true;
            this.operationBtn = "加工完成";
          } else {
            this.startDo = false;
            this.operationBtn = "开始加工";
          }
        }
      }
      console.log("right:this.item",this.item);
      this.isFlag = this.item.isFlag;
      if (this.item.qtIndex < 10) {
        this.questionNo = '0' + this.item.qtIndex;
      } else {
        this.questionNo = this.item.qtIndex;
      }
      this.number = this.item.qtIndex + "/" + this.totalNum;

      this.typeId = this.item.questiontype;

      this.arrIndex = this.item.qtIndex;
      if (this.arrIndex == this.totalNum) {
        this.isNextEnd = true;
      } else {
        this.isNextEnd = false;
      }
      if (this.arrIndex == 1) {
        this.isPreEnd = true;
      } else {
        this.isPreEnd = false;
      }
    });

    this.event.getMenuData().subscribe(value => {
      this.viewQuestions = value;
      value.forEach(el => {
        el.questions.forEach(item => {
          this.allQuestions.push(item);
        })
      });
    })
  }

}
