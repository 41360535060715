import { Component, TemplateRef, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.styl']
})
export class ConfirmComponent implements OnInit {
  modalRef: BsModalRef;
  public modal_title: string;
  public modal_content: string;
  private isCancel:boolean = true;
  constructor(public bsModalRef: BsModalRef) { }

  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template, {ignoreBackdropClick: true});
  // }

  // openModalWithClass(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(
  //     template,
  //     Object.assign({}, { class: 'gray' })
  //   );
  // }

  confirm(): void {
    this.isCancel = false;
    this.bsModalRef.hide();
  }
 
  decline(): void {
    this.bsModalRef.hide();
  }

  ngOnInit() {
  }

}
