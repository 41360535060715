import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private subject = new Subject <any> ();
  private venueSubject = new Subject <any> ();
  private menuSubject = new Subject <any> ();
  private examStatusSubject = new Subject <any> ();
  private menuSelectSubject = new Subject <any> ();

  private itemSubject = new Subject <any> ();

  constructor() { }

  // sendMessage(message: string){
  //   this.subject.next({id: message});
  // }

  // getMessage(): Observable<any>{
  //   return this.subject.asObservable();
  // }

  // getMenu(){
  //   this.subject.next({menu: this.menu});
  // }

  public sendDetailData(data: any) {
    this.itemSubject.next(data);
  }

  public getDetailData(): Observable<any>{
    return this.itemSubject.asObservable();
  }

  sendMenuData(data: any) {
    this.menuSubject.next(data);
  }

  getMenuData(): Observable<any>{
    return this.menuSubject.asObservable();
  }

  sendVenueData(data: any) {
    this.venueSubject.next(data);
  }

  getVenueData(): Observable<any>{
    return this.venueSubject.asObservable();
  }

  sendExamStatusData(data: any) {
    this.examStatusSubject.next(data);
  }

  getExamStatusData(): Observable<any>{
    return this.examStatusSubject.asObservable();
  }

  sendMenuSelectData(data: any) {
    this.menuSelectSubject.next(data);
  }

  getMenuSelectData(): Observable<any>{
    return this.menuSelectSubject.asObservable();
  }


  public publish(value: any,err: any){
    if (value !== undefined) {
      this.subject.next(value);
    }
    if (err !== undefined) {
      this.subject.error(err);
    }
    // this.subject.complete();
  }

  public subscribe(): Observable<any>{
    return this.subject.asObservable();
  }

  // public publish(value: any,err: any){
  //   if (value !== undefined) {
  //     // 将新的事件放入next队列中
  //     this.subject.next(value);
  //   }
  //   if (err !== undefined) {
  //     // 将错误放入error队列
  //     this.subject.error(err);
  //   }
  //   // 表示当前事件结束
  //   this.subject.complete();
  // }
  // public subscribe(handler: {
  //   next: (value) => void,
  //   error: (err) => void,
  //   complete: () => void
  //  }) {
  //   this.subject.asObservable().subscribe(handler);
  // }
}
