import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPipe'
})
export class NumberPipePipe implements PipeTransform {

  transform(value: number, type: string): number {
    if (type == "ceil"){
      return Math.ceil(value) ;
    } else if (type == "floor"){
      return Math.floor(value) ;
    } else{
      return Math.round(value) ;
    }
  }

}
