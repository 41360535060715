import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeTransPipe'
})
export class TypeTransPipePipe implements PipeTransform {

  private typeList:any = [];
  
  transform(value: any,type: any,typeLists: any): any {
        let name = "";
        this.typeList = typeLists[type];
        let keepGoing = true;
        if (typeof(value) == "object") {
          let nList = [];
          for (let i = 0; i < value.length; i++) {
            keepGoing = true;
            this.typeList.forEach((item, index) => {
              if (keepGoing) {
                  if (item.value == value[i]) {
                    nList.push(item.name);
                    keepGoing = false;
                  }
                }
            });
          }
          name = nList.join(",");
        } else {
          this.typeList.forEach((item, index) => {
            if (keepGoing) {
              if (item.value == value) {
                name = item.name;
                keepGoing = false;
              }
            }
          });
        }
        return name;
  }

}
