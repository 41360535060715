import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from 'src/environments/environment';

declare var $:any;

@Component({
  selector: 'app-detail2',
  templateUrl: './detail2.component.html',
  styleUrls: ['./detail2.component.styl']
})
export class Detail2Component implements OnInit {

  public detailData:any = {
    courseCreator: {
      userfname:"",
      userid:"",
      useravatar:""
    },
    courseFile: "",
    courseName: "",
    courseDifficulty: 0,
    courseIntro: "",
    courseCoverImg: "",
    courseDesc: "",
    courseType: 0
  };

  public courseId:string;

  public host2:string = environment.domainName;
  public host:string = "https://exam.vsteach.com/pdf"

  public isContinue:boolean = false;

  constructor(private event: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private courseService: CourseService) { 
      activatedRoute.queryParams.subscribe(queryParams => {
        this.courseId = queryParams.courseId;
      })
  }

  onClick(userId:string) {
    this.router.navigate(['/class/teacher-main'],{queryParams: { userid: userId }});
  }

  onContinue() {
    $(".article").css({
      'overflow': 'auto',
      'height': '100%'
    });
    this.isContinue = false;
  }

  afterRender(e) {
    let h = $("pdf-viewer").height();
    if ( h > 1081 ) {
      this.isContinue = true;
    } else {
      this.isContinue = false;
    }
  }

  ngOnInit() {
    this.courseService.getDetail(this.courseId,
      (response) => {
        this.detailData = response;
        // this.detailData.courseFile='http://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf';
        // console.log(response.dataList);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

}
