import { Injectable } from '@angular/core';
import { Service } from './service';
import { FacultyServiceClient } from '../pbs/faculty_grpc_web_pb';
import { Faculties } from '../pbs/faculty_pb';
import { Empty } from '../pbs/common_pb';
import { isNull } from 'util';

@Injectable({
  providedIn: 'root'
})
export class FacultyService extends Service {

  facultyService: FacultyServiceClient;

  constructor() { 
    super()
    this.facultyService = new FacultyServiceClient(this.url, null, null);
  }

  /**
   * @description 获取院系列表
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof facultyService
   */
  getFacultyList(callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.facultyService.myFacultyList(new Empty(),
      this.metadata, (err:Error, response: Faculties) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

}
