// source: course.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var course_category_pb = require('./course_category_pb.js');
goog.object.extend(proto, course_category_pb);
goog.exportSymbol('proto.pbs.CollectReq', null, global);
goog.exportSymbol('proto.pbs.CollectResp', null, global);
goog.exportSymbol('proto.pbs.Course', null, global);
goog.exportSymbol('proto.pbs.CourseId', null, global);
goog.exportSymbol('proto.pbs.CourseListReq', null, global);
goog.exportSymbol('proto.pbs.Courses', null, global);
goog.exportSymbol('proto.pbs.MyCollectReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CourseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CourseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CourseId.displayName = 'proto.pbs.CourseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MyCollectReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MyCollectReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MyCollectReq.displayName = 'proto.pbs.MyCollectReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CollectReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CollectReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CollectReq.displayName = 'proto.pbs.CollectReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CollectResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CollectResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CollectResp.displayName = 'proto.pbs.CollectResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CourseListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CourseListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CourseListReq.displayName = 'proto.pbs.CourseListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Courses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Courses.repeatedFields_, null);
};
goog.inherits(proto.pbs.Courses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Courses.displayName = 'proto.pbs.Courses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Course = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Course, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Course.displayName = 'proto.pbs.Course';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CourseId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CourseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CourseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CourseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CourseId}
 */
proto.pbs.CourseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CourseId;
  return proto.pbs.CourseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CourseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CourseId}
 */
proto.pbs.CourseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CourseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CourseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CourseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CourseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.CourseId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CourseId} returns this
 */
proto.pbs.CourseId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MyCollectReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MyCollectReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MyCollectReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MyCollectReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MyCollectReq}
 */
proto.pbs.MyCollectReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MyCollectReq;
  return proto.pbs.MyCollectReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MyCollectReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MyCollectReq}
 */
proto.pbs.MyCollectReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MyCollectReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MyCollectReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MyCollectReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MyCollectReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.pbs.MyCollectReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MyCollectReq} returns this
 */
proto.pbs.MyCollectReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.pbs.MyCollectReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MyCollectReq} returns this
 */
proto.pbs.MyCollectReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CollectReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CollectReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CollectReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CollectReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CollectReq}
 */
proto.pbs.CollectReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CollectReq;
  return proto.pbs.CollectReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CollectReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CollectReq}
 */
proto.pbs.CollectReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CollectReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CollectReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CollectReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CollectReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.CollectReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CollectReq} returns this
 */
proto.pbs.CollectReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 type = 2;
 * @return {number}
 */
proto.pbs.CollectReq.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CollectReq} returns this
 */
proto.pbs.CollectReq.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CollectResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CollectResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CollectResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CollectResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectNum: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CollectResp}
 */
proto.pbs.CollectResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CollectResp;
  return proto.pbs.CollectResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CollectResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CollectResp}
 */
proto.pbs.CollectResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CollectResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CollectResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CollectResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CollectResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollectNum();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 collect_num = 1;
 * @return {number}
 */
proto.pbs.CollectResp.prototype.getCollectNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CollectResp} returns this
 */
proto.pbs.CollectResp.prototype.setCollectNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CourseListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CourseListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CourseListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CourseListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    courseCategoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    courseDifficulty: jspb.Message.getFieldWithDefault(msg, 2, 0),
    creatorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CourseListReq}
 */
proto.pbs.CourseListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CourseListReq;
  return proto.pbs.CourseListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CourseListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CourseListReq}
 */
proto.pbs.CourseListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseCategoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCourseDifficulty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CourseListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CourseListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CourseListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CourseListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCourseCategoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCourseDifficulty();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string course_category_id = 1;
 * @return {string}
 */
proto.pbs.CourseListReq.prototype.getCourseCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setCourseCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 course_difficulty = 2;
 * @return {number}
 */
proto.pbs.CourseListReq.prototype.getCourseDifficulty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setCourseDifficulty = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string creator_id = 3;
 * @return {string}
 */
proto.pbs.CourseListReq.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keyword = 4;
 * @return {string}
 */
proto.pbs.CourseListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 page = 5;
 * @return {number}
 */
proto.pbs.CourseListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 page_size = 6;
 * @return {number}
 */
proto.pbs.CourseListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CourseListReq} returns this
 */
proto.pbs.CourseListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Courses.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Courses.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Courses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Courses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Courses.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Course.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Courses}
 */
proto.pbs.Courses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Courses;
  return proto.pbs.Courses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Courses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Courses}
 */
proto.pbs.Courses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Course;
      reader.readMessage(value,proto.pbs.Course.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Courses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Courses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Courses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Courses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Course.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Course data = 1;
 * @return {!Array<!proto.pbs.Course>}
 */
proto.pbs.Courses.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Course>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Course, 1));
};


/**
 * @param {!Array<!proto.pbs.Course>} value
 * @return {!proto.pbs.Courses} returns this
*/
proto.pbs.Courses.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Course=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Course}
 */
proto.pbs.Courses.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Course, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Courses} returns this
 */
proto.pbs.Courses.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Courses.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Courses} returns this
 */
proto.pbs.Courses.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Course.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Course.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Course} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Course.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    courseName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    courseCategory: (f = msg.getCourseCategory()) && course_category_pb.CourseCategory.toObject(includeInstance, f),
    courseType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    courseDifficulty: jspb.Message.getFieldWithDefault(msg, 5, 0),
    courseDesc: jspb.Message.getFieldWithDefault(msg, 6, ""),
    courseFile: jspb.Message.getFieldWithDefault(msg, 7, ""),
    courseCoverImg: jspb.Message.getFieldWithDefault(msg, 8, ""),
    courseIntro: jspb.Message.getFieldWithDefault(msg, 9, ""),
    courseCreator: (f = msg.getCourseCreator()) && user_pb.User.toObject(includeInstance, f),
    courseOriginFile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    officeFileTransform: jspb.Message.getFieldWithDefault(msg, 12, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    coursemodifier: jspb.Message.getFieldWithDefault(msg, 19, ""),
    collectNums: jspb.Message.getFieldWithDefault(msg, 16, 0),
    readNums: jspb.Message.getFieldWithDefault(msg, 17, 0),
    hasCollect: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Course}
 */
proto.pbs.Course.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Course;
  return proto.pbs.Course.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Course} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Course}
 */
proto.pbs.Course.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseName(value);
      break;
    case 3:
      var value = new course_category_pb.CourseCategory;
      reader.readMessage(value,course_category_pb.CourseCategory.deserializeBinaryFromReader);
      msg.setCourseCategory(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCourseType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCourseDifficulty(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseDesc(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseFile(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseCoverImg(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseIntro(value);
      break;
    case 10:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setCourseCreator(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCourseOriginFile(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfficeFileTransform(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoursemodifier(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectNums(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReadNums(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCollect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Course.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Course.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Course} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Course.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCourseName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCourseCategory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      course_category_pb.CourseCategory.serializeBinaryToWriter
    );
  }
  f = message.getCourseType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCourseDifficulty();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCourseDesc();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCourseFile();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCourseCoverImg();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCourseIntro();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCourseCreator();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCourseOriginFile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOfficeFileTransform();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getCoursemodifier();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCollectNums();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getReadNums();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getHasCollect();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.Course.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string course_name = 2;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CourseCategory course_category = 3;
 * @return {?proto.pbs.CourseCategory}
 */
proto.pbs.Course.prototype.getCourseCategory = function() {
  return /** @type{?proto.pbs.CourseCategory} */ (
    jspb.Message.getWrapperField(this, course_category_pb.CourseCategory, 3));
};


/**
 * @param {?proto.pbs.CourseCategory|undefined} value
 * @return {!proto.pbs.Course} returns this
*/
proto.pbs.Course.prototype.setCourseCategory = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.clearCourseCategory = function() {
  return this.setCourseCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Course.prototype.hasCourseCategory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 course_type = 4;
 * @return {number}
 */
proto.pbs.Course.prototype.getCourseType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 course_difficulty = 5;
 * @return {number}
 */
proto.pbs.Course.prototype.getCourseDifficulty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseDifficulty = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string course_desc = 6;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string course_file = 7;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseFile = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string course_cover_img = 8;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseCoverImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseCoverImg = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string course_intro = 9;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseIntro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseIntro = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional User course_creator = 10;
 * @return {?proto.pbs.User}
 */
proto.pbs.Course.prototype.getCourseCreator = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 10));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Course} returns this
*/
proto.pbs.Course.prototype.setCourseCreator = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.clearCourseCreator = function() {
  return this.setCourseCreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Course.prototype.hasCourseCreator = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string course_origin_file = 11;
 * @return {string}
 */
proto.pbs.Course.prototype.getCourseOriginFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCourseOriginFile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 office_file_transform = 12;
 * @return {number}
 */
proto.pbs.Course.prototype.getOfficeFileTransform = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setOfficeFileTransform = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 created_at = 13;
 * @return {number}
 */
proto.pbs.Course.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 updated_at = 14;
 * @return {number}
 */
proto.pbs.Course.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 deleted_at = 15;
 * @return {number}
 */
proto.pbs.Course.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string courseModifier = 19;
 * @return {string}
 */
proto.pbs.Course.prototype.getCoursemodifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCoursemodifier = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 collect_nums = 16;
 * @return {number}
 */
proto.pbs.Course.prototype.getCollectNums = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setCollectNums = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 read_nums = 17;
 * @return {number}
 */
proto.pbs.Course.prototype.getReadNums = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setReadNums = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool has_collect = 18;
 * @return {boolean}
 */
proto.pbs.Course.prototype.getHasCollect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.Course} returns this
 */
proto.pbs.Course.prototype.setHasCollect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


goog.object.extend(exports, proto.pbs);
