import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { VenueService } from 'src/app/services/venue.service';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['../registration.component.styl','./step2.component.styl']
})
export class Step2Component implements OnInit {

  public dataSet = [
  ];
  public dateRange: Date[] = [];
  private startValue: Date | null = null;
  private endValue: Date | null = null;
  private endOpen = false;
  private venueId:string;

  constructor(private router: Router,
    private venueService: VenueService,
    private examsService: ExamsService,
    private message: NzMessageService,
	private activatedRoute: ActivatedRoute) {
		activatedRoute.queryParams.subscribe(queryParams => {
		  this.venueId = queryParams.venueId;})
	}

  onDateChange(date: Date[]) {
    if (date.length != 0) {
      date[0] = new Date(common.dateFtt("yyyy-MM-dd",date[0]) + " 00:00:00");
      date[1] = new Date(common.dateFtt("yyyy-MM-dd",date[1]) + " 23:59:59");
    }
    this.startValue = date[0];
    this.endValue = date[1];
  }

  onSearch(){
    let startDate = this.startValue.valueOf() / 1000;
    let endDate = this.endValue.valueOf() / 1000;
    this.venueService.searchList(parseInt(startDate.toString()),parseInt(endDate.toString()),
      (response) => {
      this.dataSet = response.dataList;
    },
    (fail) => {
      this.message.create("error", decodeURI(fail));
    });
  }
  onRegistration(value:any){
    // console.log(ev)
	localStorage.setItem("majorName", value.venuemajor.majorname);
    this.router.navigate(['/registration/step3'],{ queryParams: { venueId: value.venueid } });
  }

  ngOnInit() {
    let end = new Date(new Date().toLocaleDateString());
    this.startValue = new Date(new Date().toLocaleDateString());
    end.setMonth(this.startValue.getMonth() +1);
    this.endValue = new Date(common.dateFtt("yyyy-MM-dd",end) + " 23:59:59");//new Date(end);
    let startDate = this.startValue.valueOf() / 1000;
    let endDate = this.endValue.valueOf() / 1000;
    this.dateRange = [this.startValue,this.endValue];
    this.venueService.searchList(parseInt(startDate.toString()),parseInt(endDate.toString()),
      (response) => {
      console.log(response);
      this.dataSet = response.dataList;
	  this.dataSet.map((item)=>{
		if (item.majorsList.length > 0) {
		  let majorsName = ''
		  for (let j=0;j<item.majorsList.length;j++) {
		    if (j == item.majorsList.length - 1) {
		      majorsName += item.majorsList[j].majorname
		    } else {
		      majorsName += item.majorsList[j].majorname + "、"
		    }
		  }
		  item.venuemajor.majorname = majorsName
		}
	  })
    },
    (fail) => {
      this.message.create("error", decodeURI(fail));
      if (decodeURI(fail) == "token无效") {
        common.returnLogin(this.router, this.examsService, this.message);
      }
    });
  }
  
  toRedirectApplyStep(step) {
  	  let url = ""
  	  if (step == 1) {
  		  url = '/registration/step1'
  	  } else if (step == 2) {
  		  url = '/registration/step2'
  	  } else if (step == 3) {
  		  url = '/registration/step3'
  	  } else if (step == 4) {
  		  url = '/registration/step4'
  	  }
	  this.router.navigate([url],{ queryParams: { venueId: this.venueId } });
  	  // if (this.venueId != "") {
  	  // 	this.router.navigate([url],{ queryParams: { venueId: this.venueid } });
  	  // } else {
  	  // 	this.router.navigate([url]);
  	  // }
  }
}
