import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { NzModalService, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {

  public type;
  public user:any;
  public header_logo:any = 'logo';
  public header_title:any = 'title';
  public title_text:any = '职业技能考核系统';
  public username:any = 'Megan';

  @Input() startTime;
  @Input() endTime;

  constructor(private modalService: NzModalService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private examsService: ExamsService,
    private router: Router) { 
      activatedRoute.queryParams.subscribe(queryParams => {
        this.type = queryParams.type;
      })
    }


  ngOnInit() {
    this.user = this.userService.getUser();
    const isFromExternalLogin = localStorage.getItem('isFromExternalLogin');
    if (isFromExternalLogin === 'true') {
      this.title_text = ""
    }
    if (this.user.extuserid !== "") {
      this.title_text = ""
      this.header_logo = ""
    }
	let majorName = localStorage.getItem("majorName")
	if (majorName != null) {
		this.title_text = majorName + "考试平台";
	}
  }

  exit(){
    const isFromExternalLogin = localStorage.getItem('isFromExternalLogin');
    const modal: NzModalRef = this.modalService.create({
      nzTitle: '退出',
      nzMaskClosable: false,
      nzContent: '<div class="modal-container">\
                    <div class="icon"><img src="/assets/img/tip.png" alt=""></div>\
                    <p>退出以后，可能会影响您的成绩</p>\
                  </div>',
      nzFooter: [
        {
          label: '确定',
          NzButtonShape: 'default',
          onClick: () => {
            // this.router.navigate(['/login']);
            if (this.user.extuserid !== "" || isFromExternalLogin === 'true'){
              this.router.navigate(['/externalLogin']);
              modal.destroy();
            } else {
              common.returnLogin(this.router, this.examsService, this.message);
            modal.destroy();
            }
            
          }
        },
        {
          label: '取消',
          type: 'primary',
          onClick: () =>  modal.destroy()
        }
      ]
    });
  }

  returnEntrance() {
    this.router.navigate(["/entrance"]);
  }

}
