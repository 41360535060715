// source: semi_exam_score.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
goog.exportSymbol('proto.pbs.CleaningGenerateScore', null, global);
goog.exportSymbol('proto.pbs.CleaningGenerateScore.CleaningDetail', null, global);
goog.exportSymbol('proto.pbs.CleaningGenerateScore.CleaningProcess', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreField', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.CleaningDeviceScore', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.CleaningFluidScore', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.CleaningNum', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.CleaningProcess', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.OrderScore', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.TemperatureSettingScore', null, global);
goog.exportSymbol('proto.pbs.CleaningStandardExamScore.TimeSettingScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentGenerateScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentGenerateScore.DevelopmentDetail', null, global);
goog.exportSymbol('proto.pbs.DevelopmentGenerateScore.DevelopmentProcess', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreField', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.DevelopmentNum', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.OrderScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore', null, global);
goog.exportSymbol('proto.pbs.DevelopmentStandardExamScore.TimeSettingScore', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.CrucibleParameters', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.CrucibleSetting', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.DepoProgram', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.EBElement', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.OperatingParameter', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.ProcessProgram', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamGenerateScore.ProgramDetail', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreField', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamStandardExamScore.scoreInfoList', null, global);
goog.exportSymbol('proto.pbs.IonImplantationGenerateScore', null, global);
goog.exportSymbol('proto.pbs.IonImplantationGenerateScore.IonBeamDebugging', null, global);
goog.exportSymbol('proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure', null, global);
goog.exportSymbol('proto.pbs.IonImplantationScoreField', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure', null, global);
goog.exportSymbol('proto.pbs.IonImplantationStandardExamScore.scoreInfoList', null, global);
goog.exportSymbol('proto.pbs.LAB18GenerateScore', null, global);
goog.exportSymbol('proto.pbs.LAB18GenerateScore.ProgramParameters', null, global);
goog.exportSymbol('proto.pbs.LAB18ScoreField', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18OperationScore', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18ProgramScore', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore', null, global);
goog.exportSymbol('proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo', null, global);
goog.exportSymbol('proto.pbs.MA6BoardShelfScore', null, global);
goog.exportSymbol('proto.pbs.MA6ExposureScore', null, global);
goog.exportSymbol('proto.pbs.MA6GenerateScore', null, global);
goog.exportSymbol('proto.pbs.MA6LithographyBoardScore', null, global);
goog.exportSymbol('proto.pbs.MA6OperationScore', null, global);
goog.exportSymbol('proto.pbs.MA6OperationScore.operationScore', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreField', null, global);
goog.exportSymbol('proto.pbs.MA6StandardExamScore', null, global);
goog.exportSymbol('proto.pbs.MA6WaferCarrierScore', null, global);
goog.exportSymbol('proto.pbs.PlasmaGenerateScore', null, global);
goog.exportSymbol('proto.pbs.PlasmaGenerateScore.EtchParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaGenerateScore.Etchinggases', null, global);
goog.exportSymbol('proto.pbs.PlasmaGenerateScore.GasParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaScoreField', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore.EtchParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore.Etchinggases', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore.GasParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage', null, global);
goog.exportSymbol('proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore', null, global);
goog.exportSymbol('proto.pbs.RegenerateScoreReq', null, global);
goog.exportSymbol('proto.pbs.ScoreInfo', null, global);
goog.exportSymbol('proto.pbs.SemiExamIdReq', null, global);
goog.exportSymbol('proto.pbs.SemiExamScoreInfo', null, global);
goog.exportSymbol('proto.pbs.SemiExamScoreInfo.SemiExamScoreCase', null, global);
goog.exportSymbol('proto.pbs.SimiProcessGenerateScore', null, global);
goog.exportSymbol('proto.pbs.SimiProcessGenerateScore.SimiProcessStep', null, global);
goog.exportSymbol('proto.pbs.SimiProcessGenerateScore.SimiScoreReq', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandardExamScore.SimiExamScore', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandardExamScore.SimiProcessStep', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandardExamScore.scoreInfoList', null, global);
goog.exportSymbol('proto.pbs.SimiQuestionModule', null, global);
goog.exportSymbol('proto.pbs.SimiQuestionModule.ScoreInfo', null, global);
goog.exportSymbol('proto.pbs.SimiQuestionRet', null, global);
goog.exportSymbol('proto.pbs.SingleScoreReq', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterGenerateScore', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterGenerateScore.CoaterDetail', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterGenerateScore.PreBake', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterScoreField', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterStandardExamScore.CoaterScore', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterStandardExamScore.PreBakeScore', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore', null, global);
goog.exportSymbol('proto.pbs.StepperGenerateScore', null, global);
goog.exportSymbol('proto.pbs.StepperGenerateScore.ExposureArea', null, global);
goog.exportSymbol('proto.pbs.StepperGenerateScore.ExposureParams', null, global);
goog.exportSymbol('proto.pbs.StepperGenerateScore.Mask', null, global);
goog.exportSymbol('proto.pbs.StepperScoreField', null, global);
goog.exportSymbol('proto.pbs.StepperStandardExamScore', null, global);
goog.exportSymbol('proto.pbs.StepperStandardExamScore.ExposureParams', null, global);
goog.exportSymbol('proto.pbs.StepperStandardExamScore.StepperOperationStage', null, global);
goog.exportSymbol('proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore', null, global);
goog.exportSymbol('proto.pbs.StepperStandardExamScore.scoreInfoList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.RegenerateScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.RegenerateScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.RegenerateScoreReq.displayName = 'proto.pbs.RegenerateScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SemiExamIdReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SemiExamIdReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SemiExamIdReq.displayName = 'proto.pbs.SemiExamIdReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SemiExamScoreInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pbs.SemiExamScoreInfo.oneofGroups_);
};
goog.inherits(proto.pbs.SemiExamScoreInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SemiExamScoreInfo.displayName = 'proto.pbs.SemiExamScoreInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SingleScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SingleScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SingleScoreReq.displayName = 'proto.pbs.SingleScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ScoreInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreInfo.displayName = 'proto.pbs.ScoreInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiQuestionRet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiQuestionRet.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiQuestionRet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiQuestionRet.displayName = 'proto.pbs.SimiQuestionRet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiQuestionModule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiQuestionModule.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiQuestionModule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiQuestionModule.displayName = 'proto.pbs.SimiQuestionModule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiQuestionModule.ScoreInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiQuestionModule.ScoreInfo.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiQuestionModule.ScoreInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiQuestionModule.ScoreInfo.displayName = 'proto.pbs.SimiQuestionModule.ScoreInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6WaferCarrierScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6WaferCarrierScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6WaferCarrierScore.displayName = 'proto.pbs.MA6WaferCarrierScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6BoardShelfScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6BoardShelfScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6BoardShelfScore.displayName = 'proto.pbs.MA6BoardShelfScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6LithographyBoardScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6LithographyBoardScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6LithographyBoardScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6LithographyBoardScore.displayName = 'proto.pbs.MA6LithographyBoardScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ExposureScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6ExposureScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6ExposureScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ExposureScore.displayName = 'proto.pbs.MA6ExposureScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6OperationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6OperationScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6OperationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6OperationScore.displayName = 'proto.pbs.MA6OperationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6OperationScore.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6OperationScore.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6OperationScore.operationScore.displayName = 'proto.pbs.MA6OperationScore.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6StandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6StandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6StandardExamScore.displayName = 'proto.pbs.MA6StandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6GenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6GenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6GenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6GenerateScore.displayName = 'proto.pbs.MA6GenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.displayName = 'proto.pbs.LAB18StandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18ProgramScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18ProgramScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18StandardExamScore.LAB18OperationScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18OperationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18OperationScore.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18OperationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.displayName = 'proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18GenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18GenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18GenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18GenerateScore.displayName = 'proto.pbs.LAB18GenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18GenerateScore.ProgramParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18GenerateScore.ProgramParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18GenerateScore.ProgramParameters.displayName = 'proto.pbs.LAB18GenerateScore.ProgramParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterStandardExamScore.displayName = 'proto.pbs.SpinCoaterStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterStandardExamScore.CoaterScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterStandardExamScore.CoaterScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterStandardExamScore.CoaterScore.displayName = 'proto.pbs.SpinCoaterStandardExamScore.CoaterScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterStandardExamScore.PreBakeScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.displayName = 'proto.pbs.SpinCoaterStandardExamScore.PreBakeScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.displayName = 'proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.displayName = 'proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterGenerateScore.displayName = 'proto.pbs.SpinCoaterGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterGenerateScore.CoaterDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterGenerateScore.CoaterDetail.displayName = 'proto.pbs.SpinCoaterGenerateScore.CoaterDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterGenerateScore.PreBake = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterGenerateScore.PreBake, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterGenerateScore.PreBake.displayName = 'proto.pbs.SpinCoaterGenerateScore.PreBake';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.displayName = 'proto.pbs.CleaningStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.CleaningFluidScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.CleaningFluidScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.CleaningFluidScore.displayName = 'proto.pbs.CleaningStandardExamScore.CleaningFluidScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.OrderScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.OrderScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.OrderScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.OrderScore.displayName = 'proto.pbs.CleaningStandardExamScore.OrderScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.TimeSettingScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.TimeSettingScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.TimeSettingScore.displayName = 'proto.pbs.CleaningStandardExamScore.TimeSettingScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.CleaningDeviceScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.displayName = 'proto.pbs.CleaningStandardExamScore.CleaningDeviceScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.TemperatureSettingScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.displayName = 'proto.pbs.CleaningStandardExamScore.TemperatureSettingScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.CleaningProcess.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.CleaningProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.CleaningProcess.displayName = 'proto.pbs.CleaningStandardExamScore.CleaningProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningStandardExamScore.CleaningNum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningStandardExamScore.CleaningNum.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningStandardExamScore.CleaningNum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningStandardExamScore.CleaningNum.displayName = 'proto.pbs.CleaningStandardExamScore.CleaningNum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningGenerateScore.displayName = 'proto.pbs.CleaningGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningGenerateScore.CleaningDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningGenerateScore.CleaningDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningGenerateScore.CleaningDetail.displayName = 'proto.pbs.CleaningGenerateScore.CleaningDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningGenerateScore.CleaningProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningGenerateScore.CleaningProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningGenerateScore.CleaningProcess.displayName = 'proto.pbs.CleaningGenerateScore.CleaningProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.displayName = 'proto.pbs.DevelopmentStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.displayName = 'proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.OrderScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.OrderScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.OrderScore.displayName = 'proto.pbs.DevelopmentStandardExamScore.OrderScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.TimeSettingScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.displayName = 'proto.pbs.DevelopmentStandardExamScore.TimeSettingScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.displayName = 'proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.displayName = 'proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.displayName = 'proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentStandardExamScore.DevelopmentNum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.displayName = 'proto.pbs.DevelopmentStandardExamScore.DevelopmentNum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentGenerateScore.displayName = 'proto.pbs.DevelopmentGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentGenerateScore.DevelopmentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.displayName = 'proto.pbs.DevelopmentGenerateScore.DevelopmentDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentGenerateScore.DevelopmentProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.displayName = 'proto.pbs.DevelopmentGenerateScore.DevelopmentProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.displayName = 'proto.pbs.PlasmaStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaStandardExamScore.Etchinggases.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore.Etchinggases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.Etchinggases.displayName = 'proto.pbs.PlasmaStandardExamScore.Etchinggases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore.GasParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaStandardExamScore.GasParameters.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore.GasParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.GasParameters.displayName = 'proto.pbs.PlasmaStandardExamScore.GasParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaStandardExamScore.EtchParameters.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore.EtchParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.EtchParameters.displayName = 'proto.pbs.PlasmaStandardExamScore.EtchParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.displayName = 'proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.displayName = 'proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaGenerateScore.displayName = 'proto.pbs.PlasmaGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaGenerateScore.Etchinggases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaGenerateScore.Etchinggases.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaGenerateScore.Etchinggases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaGenerateScore.Etchinggases.displayName = 'proto.pbs.PlasmaGenerateScore.Etchinggases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaGenerateScore.GasParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaGenerateScore.GasParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaGenerateScore.GasParameters.displayName = 'proto.pbs.PlasmaGenerateScore.GasParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaGenerateScore.EtchParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaGenerateScore.EtchParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaGenerateScore.EtchParameters.displayName = 'proto.pbs.PlasmaGenerateScore.EtchParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.displayName = 'proto.pbs.IonImplantationStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationStandardExamScore.scoreInfoList.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore.scoreInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.scoreInfoList.displayName = 'proto.pbs.IonImplantationStandardExamScore.scoreInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.displayName = 'proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.displayName = 'proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.displayName = 'proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.displayName = 'proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationGenerateScore.displayName = 'proto.pbs.IonImplantationGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.displayName = 'proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationGenerateScore.IonBeamDebugging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.displayName = 'proto.pbs.IonImplantationGenerateScore.IonBeamDebugging';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamStandardExamScore.displayName = 'proto.pbs.ElectronBeamStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamStandardExamScore.scoreInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.displayName = 'proto.pbs.ElectronBeamStandardExamScore.scoreInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.displayName = 'proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.displayName = 'proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.displayName = 'proto.pbs.ElectronBeamGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.EBElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.EBElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.EBElement.displayName = 'proto.pbs.ElectronBeamGenerateScore.EBElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.CrucibleParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.displayName = 'proto.pbs.ElectronBeamGenerateScore.CrucibleParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.CrucibleSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.displayName = 'proto.pbs.ElectronBeamGenerateScore.CrucibleSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.DepoProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.DepoProgram.displayName = 'proto.pbs.ElectronBeamGenerateScore.DepoProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.ProgramDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.ProgramDetail.displayName = 'proto.pbs.ElectronBeamGenerateScore.ProgramDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.ProcessProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.ProcessProgram.displayName = 'proto.pbs.ElectronBeamGenerateScore.ProcessProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamGenerateScore.OperatingParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamGenerateScore.OperatingParameter.displayName = 'proto.pbs.ElectronBeamGenerateScore.OperatingParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperStandardExamScore.displayName = 'proto.pbs.StepperStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperStandardExamScore.scoreInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.StepperStandardExamScore.scoreInfoList.repeatedFields_, null);
};
goog.inherits(proto.pbs.StepperStandardExamScore.scoreInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperStandardExamScore.scoreInfoList.displayName = 'proto.pbs.StepperStandardExamScore.scoreInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperStandardExamScore.ExposureParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperStandardExamScore.ExposureParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperStandardExamScore.ExposureParams.displayName = 'proto.pbs.StepperStandardExamScore.ExposureParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.StepperStandardExamScore.StepperOperationStage.repeatedFields_, null);
};
goog.inherits(proto.pbs.StepperStandardExamScore.StepperOperationStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperStandardExamScore.StepperOperationStage.displayName = 'proto.pbs.StepperStandardExamScore.StepperOperationStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.displayName = 'proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.StepperGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.StepperGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperGenerateScore.displayName = 'proto.pbs.StepperGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperGenerateScore.Mask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperGenerateScore.Mask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperGenerateScore.Mask.displayName = 'proto.pbs.StepperGenerateScore.Mask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperGenerateScore.ExposureArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperGenerateScore.ExposureArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperGenerateScore.ExposureArea.displayName = 'proto.pbs.StepperGenerateScore.ExposureArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperGenerateScore.ExposureParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperGenerateScore.ExposureParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperGenerateScore.ExposureParams.displayName = 'proto.pbs.StepperGenerateScore.ExposureParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandardExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiProcessStandardExamScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiProcessStandardExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandardExamScore.displayName = 'proto.pbs.SimiProcessStandardExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiProcessStandardExamScore.scoreInfoList.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiProcessStandardExamScore.scoreInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandardExamScore.scoreInfoList.displayName = 'proto.pbs.SimiProcessStandardExamScore.scoreInfoList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SimiProcessStandardExamScore.SimiExamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandardExamScore.SimiExamScore.displayName = 'proto.pbs.SimiProcessStandardExamScore.SimiExamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiProcessStandardExamScore.SimiProcessStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.displayName = 'proto.pbs.SimiProcessStandardExamScore.SimiProcessStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessGenerateScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiProcessGenerateScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiProcessGenerateScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessGenerateScore.displayName = 'proto.pbs.SimiProcessGenerateScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SimiProcessGenerateScore.SimiScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessGenerateScore.SimiScoreReq.displayName = 'proto.pbs.SimiProcessGenerateScore.SimiScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SimiProcessGenerateScore.SimiProcessStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessGenerateScore.SimiProcessStep.displayName = 'proto.pbs.SimiProcessGenerateScore.SimiProcessStep';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.RegenerateScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.RegenerateScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.RegenerateScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegenerateScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.RegenerateScoreReq}
 */
proto.pbs.RegenerateScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.RegenerateScoreReq;
  return proto.pbs.RegenerateScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.RegenerateScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.RegenerateScoreReq}
 */
proto.pbs.RegenerateScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.RegenerateScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.RegenerateScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.RegenerateScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegenerateScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.RegenerateScoreReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegenerateScoreReq} returns this
 */
proto.pbs.RegenerateScoreReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SemiExamIdReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SemiExamIdReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SemiExamIdReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SemiExamIdReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoreStandardType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SemiExamIdReq}
 */
proto.pbs.SemiExamIdReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SemiExamIdReq;
  return proto.pbs.SemiExamIdReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SemiExamIdReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SemiExamIdReq}
 */
proto.pbs.SemiExamIdReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.ScoreStandardType} */ (reader.readEnum());
      msg.setScoreStandardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SemiExamIdReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SemiExamIdReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SemiExamIdReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SemiExamIdReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoreStandardType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.SemiExamIdReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SemiExamIdReq} returns this
 */
proto.pbs.SemiExamIdReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ScoreStandardType score_standard_type = 2;
 * @return {!proto.pbs.ScoreStandardType}
 */
proto.pbs.SemiExamIdReq.prototype.getScoreStandardType = function() {
  return /** @type {!proto.pbs.ScoreStandardType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.ScoreStandardType} value
 * @return {!proto.pbs.SemiExamIdReq} returns this
 */
proto.pbs.SemiExamIdReq.prototype.setScoreStandardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pbs.SemiExamScoreInfo.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.pbs.SemiExamScoreInfo.SemiExamScoreCase = {
  SEMI_EXAM_SCORE_NOT_SET: 0,
  MA6_SCORE: 1,
  LAB18_SCORE: 2,
  SPINCOATER_SCORE: 3,
  CLEANING_SCORE: 4,
  DEVELOPMENT_SCORE: 5,
  PLASMA_SCORE: 6,
  ION_IMPLANTATION_SCORE: 7,
  ELECTRON_BEAM_SCORE: 8,
  STEPPER_STANDARD_EXAM_SCORE: 9,
  SIMI_PROCESS_STANDARD_EXAM_SCORE: 10
};

/**
 * @return {proto.pbs.SemiExamScoreInfo.SemiExamScoreCase}
 */
proto.pbs.SemiExamScoreInfo.prototype.getSemiExamScoreCase = function() {
  return /** @type {proto.pbs.SemiExamScoreInfo.SemiExamScoreCase} */(jspb.Message.computeOneofCase(this, proto.pbs.SemiExamScoreInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SemiExamScoreInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SemiExamScoreInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SemiExamScoreInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SemiExamScoreInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ma6Score: (f = msg.getMa6Score()) && proto.pbs.MA6StandardExamScore.toObject(includeInstance, f),
    lab18Score: (f = msg.getLab18Score()) && proto.pbs.LAB18StandardExamScore.toObject(includeInstance, f),
    spincoaterScore: (f = msg.getSpincoaterScore()) && proto.pbs.SpinCoaterStandardExamScore.toObject(includeInstance, f),
    cleaningScore: (f = msg.getCleaningScore()) && proto.pbs.CleaningStandardExamScore.toObject(includeInstance, f),
    developmentScore: (f = msg.getDevelopmentScore()) && proto.pbs.DevelopmentStandardExamScore.toObject(includeInstance, f),
    plasmaScore: (f = msg.getPlasmaScore()) && proto.pbs.PlasmaStandardExamScore.toObject(includeInstance, f),
    ionImplantationScore: (f = msg.getIonImplantationScore()) && proto.pbs.IonImplantationStandardExamScore.toObject(includeInstance, f),
    electronBeamScore: (f = msg.getElectronBeamScore()) && proto.pbs.ElectronBeamStandardExamScore.toObject(includeInstance, f),
    stepperStandardExamScore: (f = msg.getStepperStandardExamScore()) && proto.pbs.StepperStandardExamScore.toObject(includeInstance, f),
    simiProcessStandardExamScore: (f = msg.getSimiProcessStandardExamScore()) && proto.pbs.SimiProcessStandardExamScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SemiExamScoreInfo}
 */
proto.pbs.SemiExamScoreInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SemiExamScoreInfo;
  return proto.pbs.SemiExamScoreInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SemiExamScoreInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SemiExamScoreInfo}
 */
proto.pbs.SemiExamScoreInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MA6StandardExamScore;
      reader.readMessage(value,proto.pbs.MA6StandardExamScore.deserializeBinaryFromReader);
      msg.setMa6Score(value);
      break;
    case 2:
      var value = new proto.pbs.LAB18StandardExamScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.deserializeBinaryFromReader);
      msg.setLab18Score(value);
      break;
    case 3:
      var value = new proto.pbs.SpinCoaterStandardExamScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.deserializeBinaryFromReader);
      msg.setSpincoaterScore(value);
      break;
    case 4:
      var value = new proto.pbs.CleaningStandardExamScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.deserializeBinaryFromReader);
      msg.setCleaningScore(value);
      break;
    case 5:
      var value = new proto.pbs.DevelopmentStandardExamScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.deserializeBinaryFromReader);
      msg.setDevelopmentScore(value);
      break;
    case 6:
      var value = new proto.pbs.PlasmaStandardExamScore;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.deserializeBinaryFromReader);
      msg.setPlasmaScore(value);
      break;
    case 7:
      var value = new proto.pbs.IonImplantationStandardExamScore;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.deserializeBinaryFromReader);
      msg.setIonImplantationScore(value);
      break;
    case 8:
      var value = new proto.pbs.ElectronBeamStandardExamScore;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.deserializeBinaryFromReader);
      msg.setElectronBeamScore(value);
      break;
    case 9:
      var value = new proto.pbs.StepperStandardExamScore;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.deserializeBinaryFromReader);
      msg.setStepperStandardExamScore(value);
      break;
    case 10:
      var value = new proto.pbs.SimiProcessStandardExamScore;
      reader.readMessage(value,proto.pbs.SimiProcessStandardExamScore.deserializeBinaryFromReader);
      msg.setSimiProcessStandardExamScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SemiExamScoreInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SemiExamScoreInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SemiExamScoreInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SemiExamScoreInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMa6Score();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MA6StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getLab18Score();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.LAB18StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getSpincoaterScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.SpinCoaterStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.CleaningStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.DevelopmentStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.PlasmaStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.IonImplantationStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.ElectronBeamStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.StepperStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getSimiProcessStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.SimiProcessStandardExamScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional MA6StandardExamScore ma6_score = 1;
 * @return {?proto.pbs.MA6StandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getMa6Score = function() {
  return /** @type{?proto.pbs.MA6StandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6StandardExamScore, 1));
};


/**
 * @param {?proto.pbs.MA6StandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setMa6Score = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearMa6Score = function() {
  return this.setMa6Score(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasMa6Score = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LAB18StandardExamScore lab18_score = 2;
 * @return {?proto.pbs.LAB18StandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getLab18Score = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore, 2));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setLab18Score = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearLab18Score = function() {
  return this.setLab18Score(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasLab18Score = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpinCoaterStandardExamScore spincoater_score = 3;
 * @return {?proto.pbs.SpinCoaterStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getSpincoaterScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterStandardExamScore, 3));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setSpincoaterScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearSpincoaterScore = function() {
  return this.setSpincoaterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasSpincoaterScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CleaningStandardExamScore cleaning_score = 4;
 * @return {?proto.pbs.CleaningStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getCleaningScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore, 4));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setCleaningScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearCleaningScore = function() {
  return this.setCleaningScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasCleaningScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DevelopmentStandardExamScore development_score = 5;
 * @return {?proto.pbs.DevelopmentStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getDevelopmentScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore, 5));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setDevelopmentScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearDevelopmentScore = function() {
  return this.setDevelopmentScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasDevelopmentScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PlasmaStandardExamScore plasma_score = 6;
 * @return {?proto.pbs.PlasmaStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getPlasmaScore = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore, 6));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setPlasmaScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearPlasmaScore = function() {
  return this.setPlasmaScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasPlasmaScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IonImplantationStandardExamScore ion_implantation_score = 7;
 * @return {?proto.pbs.IonImplantationStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getIonImplantationScore = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore, 7));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setIonImplantationScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearIonImplantationScore = function() {
  return this.setIonImplantationScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasIonImplantationScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ElectronBeamStandardExamScore electron_beam_score = 8;
 * @return {?proto.pbs.ElectronBeamStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getElectronBeamScore = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore, 8));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setElectronBeamScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearElectronBeamScore = function() {
  return this.setElectronBeamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasElectronBeamScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional StepperStandardExamScore stepper_standard_exam_score = 9;
 * @return {?proto.pbs.StepperStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getStepperStandardExamScore = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore, 9));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setStepperStandardExamScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearStepperStandardExamScore = function() {
  return this.setStepperStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasStepperStandardExamScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SimiProcessStandardExamScore simi_process_standard_exam_score = 10;
 * @return {?proto.pbs.SimiProcessStandardExamScore}
 */
proto.pbs.SemiExamScoreInfo.prototype.getSimiProcessStandardExamScore = function() {
  return /** @type{?proto.pbs.SimiProcessStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiProcessStandardExamScore, 10));
};


/**
 * @param {?proto.pbs.SimiProcessStandardExamScore|undefined} value
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
*/
proto.pbs.SemiExamScoreInfo.prototype.setSimiProcessStandardExamScore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.pbs.SemiExamScoreInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SemiExamScoreInfo} returns this
 */
proto.pbs.SemiExamScoreInfo.prototype.clearSimiProcessStandardExamScore = function() {
  return this.setSimiProcessStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SemiExamScoreInfo.prototype.hasSimiProcessStandardExamScore = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SingleScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SingleScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SingleScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SingleScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ma6GenerateScore: (f = msg.getMa6GenerateScore()) && proto.pbs.MA6GenerateScore.toObject(includeInstance, f),
    ma6ScoreFiled: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lab18GenerateScore: (f = msg.getLab18GenerateScore()) && proto.pbs.LAB18GenerateScore.toObject(includeInstance, f),
    lab18ScoreFiled: jspb.Message.getFieldWithDefault(msg, 5, 0),
    spinCoaterGenerateScore: (f = msg.getSpinCoaterGenerateScore()) && proto.pbs.SpinCoaterGenerateScore.toObject(includeInstance, f),
    spinCoaterScoreFiled: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cleaningGenerateScore: (f = msg.getCleaningGenerateScore()) && proto.pbs.CleaningGenerateScore.toObject(includeInstance, f),
    cleaningScoreFiled: jspb.Message.getFieldWithDefault(msg, 9, 0),
    developmentGenerateScore: (f = msg.getDevelopmentGenerateScore()) && proto.pbs.DevelopmentGenerateScore.toObject(includeInstance, f),
    developmentScoreFiled: jspb.Message.getFieldWithDefault(msg, 11, 0),
    plasmaGenerateScore: (f = msg.getPlasmaGenerateScore()) && proto.pbs.PlasmaGenerateScore.toObject(includeInstance, f),
    plasmaScoreFiled: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ionImplantationGenerateScore: (f = msg.getIonImplantationGenerateScore()) && proto.pbs.IonImplantationGenerateScore.toObject(includeInstance, f),
    ionImplantationScoreFiled: jspb.Message.getFieldWithDefault(msg, 15, 0),
    electronBeamGenerateScore: (f = msg.getElectronBeamGenerateScore()) && proto.pbs.ElectronBeamGenerateScore.toObject(includeInstance, f),
    electronBeamScoreField: jspb.Message.getFieldWithDefault(msg, 17, 0),
    stepperScoreField: jspb.Message.getFieldWithDefault(msg, 18, 0),
    stepperGenerateScore: (f = msg.getStepperGenerateScore()) && proto.pbs.StepperGenerateScore.toObject(includeInstance, f),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SingleScoreReq}
 */
proto.pbs.SingleScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SingleScoreReq;
  return proto.pbs.SingleScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SingleScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SingleScoreReq}
 */
proto.pbs.SingleScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = new proto.pbs.MA6GenerateScore;
      reader.readMessage(value,proto.pbs.MA6GenerateScore.deserializeBinaryFromReader);
      msg.setMa6GenerateScore(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.MA6ScoreField} */ (reader.readEnum());
      msg.setMa6ScoreFiled(value);
      break;
    case 4:
      var value = new proto.pbs.LAB18GenerateScore;
      reader.readMessage(value,proto.pbs.LAB18GenerateScore.deserializeBinaryFromReader);
      msg.setLab18GenerateScore(value);
      break;
    case 5:
      var value = /** @type {!proto.pbs.LAB18ScoreField} */ (reader.readEnum());
      msg.setLab18ScoreFiled(value);
      break;
    case 6:
      var value = new proto.pbs.SpinCoaterGenerateScore;
      reader.readMessage(value,proto.pbs.SpinCoaterGenerateScore.deserializeBinaryFromReader);
      msg.setSpinCoaterGenerateScore(value);
      break;
    case 7:
      var value = /** @type {!proto.pbs.SpinCoaterScoreField} */ (reader.readEnum());
      msg.setSpinCoaterScoreFiled(value);
      break;
    case 8:
      var value = new proto.pbs.CleaningGenerateScore;
      reader.readMessage(value,proto.pbs.CleaningGenerateScore.deserializeBinaryFromReader);
      msg.setCleaningGenerateScore(value);
      break;
    case 9:
      var value = /** @type {!proto.pbs.CleaningScoreField} */ (reader.readEnum());
      msg.setCleaningScoreFiled(value);
      break;
    case 10:
      var value = new proto.pbs.DevelopmentGenerateScore;
      reader.readMessage(value,proto.pbs.DevelopmentGenerateScore.deserializeBinaryFromReader);
      msg.setDevelopmentGenerateScore(value);
      break;
    case 11:
      var value = /** @type {!proto.pbs.DevelopmentScoreField} */ (reader.readEnum());
      msg.setDevelopmentScoreFiled(value);
      break;
    case 12:
      var value = new proto.pbs.PlasmaGenerateScore;
      reader.readMessage(value,proto.pbs.PlasmaGenerateScore.deserializeBinaryFromReader);
      msg.setPlasmaGenerateScore(value);
      break;
    case 13:
      var value = /** @type {!proto.pbs.PlasmaScoreField} */ (reader.readEnum());
      msg.setPlasmaScoreFiled(value);
      break;
    case 14:
      var value = new proto.pbs.IonImplantationGenerateScore;
      reader.readMessage(value,proto.pbs.IonImplantationGenerateScore.deserializeBinaryFromReader);
      msg.setIonImplantationGenerateScore(value);
      break;
    case 15:
      var value = /** @type {!proto.pbs.IonImplantationScoreField} */ (reader.readEnum());
      msg.setIonImplantationScoreFiled(value);
      break;
    case 16:
      var value = new proto.pbs.ElectronBeamGenerateScore;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.deserializeBinaryFromReader);
      msg.setElectronBeamGenerateScore(value);
      break;
    case 17:
      var value = /** @type {!proto.pbs.ElectronBeamScoreField} */ (reader.readEnum());
      msg.setElectronBeamScoreField(value);
      break;
    case 18:
      var value = /** @type {!proto.pbs.StepperScoreField} */ (reader.readEnum());
      msg.setStepperScoreField(value);
      break;
    case 19:
      var value = new proto.pbs.StepperGenerateScore;
      reader.readMessage(value,proto.pbs.StepperGenerateScore.deserializeBinaryFromReader);
      msg.setStepperGenerateScore(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SingleScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SingleScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SingleScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SingleScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMa6GenerateScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.MA6GenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getMa6ScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLab18GenerateScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.LAB18GenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getLab18ScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSpinCoaterGenerateScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.SpinCoaterGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getSpinCoaterScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCleaningGenerateScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.CleaningGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDevelopmentGenerateScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.DevelopmentGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPlasmaGenerateScore();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.pbs.PlasmaGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getIonImplantationGenerateScore();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.pbs.IonImplantationGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationScoreFiled();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getElectronBeamGenerateScore();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.pbs.ElectronBeamGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamScoreField();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getStepperScoreField();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getStepperGenerateScore();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.pbs.StepperGenerateScore.serializeBinaryToWriter
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.SingleScoreReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MA6GenerateScore ma6_generate_score = 2;
 * @return {?proto.pbs.MA6GenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getMa6GenerateScore = function() {
  return /** @type{?proto.pbs.MA6GenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6GenerateScore, 2));
};


/**
 * @param {?proto.pbs.MA6GenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setMa6GenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearMa6GenerateScore = function() {
  return this.setMa6GenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasMa6GenerateScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MA6ScoreField ma6_score_filed = 3;
 * @return {!proto.pbs.MA6ScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getMa6ScoreFiled = function() {
  return /** @type {!proto.pbs.MA6ScoreField} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.MA6ScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setMa6ScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional LAB18GenerateScore lab18_generate_score = 4;
 * @return {?proto.pbs.LAB18GenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getLab18GenerateScore = function() {
  return /** @type{?proto.pbs.LAB18GenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18GenerateScore, 4));
};


/**
 * @param {?proto.pbs.LAB18GenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setLab18GenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearLab18GenerateScore = function() {
  return this.setLab18GenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasLab18GenerateScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LAB18ScoreField lab18_score_filed = 5;
 * @return {!proto.pbs.LAB18ScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getLab18ScoreFiled = function() {
  return /** @type {!proto.pbs.LAB18ScoreField} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pbs.LAB18ScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setLab18ScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SpinCoaterGenerateScore spin_coater_generate_score = 6;
 * @return {?proto.pbs.SpinCoaterGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getSpinCoaterGenerateScore = function() {
  return /** @type{?proto.pbs.SpinCoaterGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterGenerateScore, 6));
};


/**
 * @param {?proto.pbs.SpinCoaterGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setSpinCoaterGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearSpinCoaterGenerateScore = function() {
  return this.setSpinCoaterGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasSpinCoaterGenerateScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SpinCoaterScoreField spin_coater_score_filed = 7;
 * @return {!proto.pbs.SpinCoaterScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getSpinCoaterScoreFiled = function() {
  return /** @type {!proto.pbs.SpinCoaterScoreField} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.pbs.SpinCoaterScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setSpinCoaterScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional CleaningGenerateScore cleaning_generate_score = 8;
 * @return {?proto.pbs.CleaningGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getCleaningGenerateScore = function() {
  return /** @type{?proto.pbs.CleaningGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningGenerateScore, 8));
};


/**
 * @param {?proto.pbs.CleaningGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setCleaningGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearCleaningGenerateScore = function() {
  return this.setCleaningGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasCleaningGenerateScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CleaningScoreField cleaning_score_filed = 9;
 * @return {!proto.pbs.CleaningScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getCleaningScoreFiled = function() {
  return /** @type {!proto.pbs.CleaningScoreField} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.pbs.CleaningScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setCleaningScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional DevelopmentGenerateScore development_generate_score = 10;
 * @return {?proto.pbs.DevelopmentGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getDevelopmentGenerateScore = function() {
  return /** @type{?proto.pbs.DevelopmentGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentGenerateScore, 10));
};


/**
 * @param {?proto.pbs.DevelopmentGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setDevelopmentGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearDevelopmentGenerateScore = function() {
  return this.setDevelopmentGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasDevelopmentGenerateScore = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DevelopmentScoreField development_score_filed = 11;
 * @return {!proto.pbs.DevelopmentScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getDevelopmentScoreFiled = function() {
  return /** @type {!proto.pbs.DevelopmentScoreField} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.pbs.DevelopmentScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setDevelopmentScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional PlasmaGenerateScore plasma_generate_score = 12;
 * @return {?proto.pbs.PlasmaGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getPlasmaGenerateScore = function() {
  return /** @type{?proto.pbs.PlasmaGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaGenerateScore, 12));
};


/**
 * @param {?proto.pbs.PlasmaGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setPlasmaGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearPlasmaGenerateScore = function() {
  return this.setPlasmaGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasPlasmaGenerateScore = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PlasmaScoreField plasma_score_filed = 13;
 * @return {!proto.pbs.PlasmaScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getPlasmaScoreFiled = function() {
  return /** @type {!proto.pbs.PlasmaScoreField} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.pbs.PlasmaScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setPlasmaScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional IonImplantationGenerateScore ion_implantation_generate_score = 14;
 * @return {?proto.pbs.IonImplantationGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getIonImplantationGenerateScore = function() {
  return /** @type{?proto.pbs.IonImplantationGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationGenerateScore, 14));
};


/**
 * @param {?proto.pbs.IonImplantationGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setIonImplantationGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearIonImplantationGenerateScore = function() {
  return this.setIonImplantationGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasIonImplantationGenerateScore = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional IonImplantationScoreField ion_implantation_score_filed = 15;
 * @return {!proto.pbs.IonImplantationScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getIonImplantationScoreFiled = function() {
  return /** @type {!proto.pbs.IonImplantationScoreField} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.pbs.IonImplantationScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setIonImplantationScoreFiled = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional ElectronBeamGenerateScore electron_beam_generate_score = 16;
 * @return {?proto.pbs.ElectronBeamGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getElectronBeamGenerateScore = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamGenerateScore, 16));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setElectronBeamGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearElectronBeamGenerateScore = function() {
  return this.setElectronBeamGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasElectronBeamGenerateScore = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ElectronBeamScoreField electron_beam_score_field = 17;
 * @return {!proto.pbs.ElectronBeamScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getElectronBeamScoreField = function() {
  return /** @type {!proto.pbs.ElectronBeamScoreField} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.pbs.ElectronBeamScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setElectronBeamScoreField = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional StepperScoreField stepper_score_field = 18;
 * @return {!proto.pbs.StepperScoreField}
 */
proto.pbs.SingleScoreReq.prototype.getStepperScoreField = function() {
  return /** @type {!proto.pbs.StepperScoreField} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.pbs.StepperScoreField} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setStepperScoreField = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional StepperGenerateScore stepper_generate_score = 19;
 * @return {?proto.pbs.StepperGenerateScore}
 */
proto.pbs.SingleScoreReq.prototype.getStepperGenerateScore = function() {
  return /** @type{?proto.pbs.StepperGenerateScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperGenerateScore, 19));
};


/**
 * @param {?proto.pbs.StepperGenerateScore|undefined} value
 * @return {!proto.pbs.SingleScoreReq} returns this
*/
proto.pbs.SingleScoreReq.prototype.setStepperGenerateScore = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.clearStepperGenerateScore = function() {
  return this.setStepperGenerateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SingleScoreReq.prototype.hasStepperGenerateScore = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string score_standard_id = 20;
 * @return {string}
 */
proto.pbs.SingleScoreReq.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SingleScoreReq} returns this
 */
proto.pbs.SingleScoreReq.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    datatypename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    standarddataname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    examineedataname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fluid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gasParameters: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.ScoreInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreInfo;
  return proto.pbs.ScoreInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.ScoreInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatypename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandarddataname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamineedataname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatatypename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStandarddataname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExamineedataname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFluid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGasParameters();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string dataTypeName = 1;
 * @return {string}
 */
proto.pbs.ScoreInfo.prototype.getDatatypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setDatatypename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string standardDataName = 2;
 * @return {string}
 */
proto.pbs.ScoreInfo.prototype.getStandarddataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setStandarddataname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string examineeDataName = 3;
 * @return {string}
 */
proto.pbs.ScoreInfo.prototype.getExamineedataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setExamineedataname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.ScoreInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string fluid = 5;
 * @return {string}
 */
proto.pbs.ScoreInfo.prototype.getFluid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setFluid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gas_parameters = 6;
 * @return {string}
 */
proto.pbs.ScoreInfo.prototype.getGasParameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreInfo} returns this
 */
proto.pbs.ScoreInfo.prototype.setGasParameters = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiQuestionRet.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiQuestionRet.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiQuestionRet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiQuestionRet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionRet.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    examineeanswerList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.SimiQuestionRet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiQuestionRet;
  return proto.pbs.SimiQuestionRet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiQuestionRet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.SimiQuestionRet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExamineeanswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiQuestionRet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiQuestionRet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiQuestionRet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionRet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExamineeanswerList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 question_no = 1;
 * @return {number}
 */
proto.pbs.SimiQuestionRet.prototype.getQuestionNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestionRet} returns this
 */
proto.pbs.SimiQuestionRet.prototype.setQuestionNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string examineeAnswer = 2;
 * @return {!Array<string>}
 */
proto.pbs.SimiQuestionRet.prototype.getExamineeanswerList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiQuestionRet} returns this
 */
proto.pbs.SimiQuestionRet.prototype.setExamineeanswerList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet} returns this
 */
proto.pbs.SimiQuestionRet.prototype.addExamineeanswer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiQuestionRet} returns this
 */
proto.pbs.SimiQuestionRet.prototype.clearExamineeanswerList = function() {
  return this.setExamineeanswerList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiQuestionModule.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiQuestionModule.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiQuestionModule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiQuestionModule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionModule.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoListList: jspb.Message.toObjectList(msg.getScoreInfoListList(),
    proto.pbs.SimiQuestionModule.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiQuestionModule}
 */
proto.pbs.SimiQuestionModule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiQuestionModule;
  return proto.pbs.SimiQuestionModule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiQuestionModule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiQuestionModule}
 */
proto.pbs.SimiQuestionModule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SimiQuestionModule.ScoreInfo;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfoList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiQuestionModule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiQuestionModule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiQuestionModule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionModule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.SimiQuestionModule.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiQuestionModule.ScoreInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiQuestionModule.ScoreInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiQuestionModule.ScoreInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionModule.ScoreInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    datatypename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    standarddataname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    examineedataname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    questionpiclistList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiQuestionModule.ScoreInfo;
  return proto.pbs.SimiQuestionModule.ScoreInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiQuestionModule.ScoreInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatypename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandarddataname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamineedataname(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionpiclist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiQuestionModule.ScoreInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiQuestionModule.ScoreInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestionModule.ScoreInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDatatypename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStandarddataname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExamineedataname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getQuestionpiclistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional int32 question_no = 1;
 * @return {number}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getQuestionNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setQuestionNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string dataTypeName = 2;
 * @return {string}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getDatatypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setDatatypename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string standardDataName = 3;
 * @return {string}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getStandarddataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setStandarddataname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string examineeDataName = 4;
 * @return {string}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getExamineedataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setExamineedataname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated string questionPicList = 6;
 * @return {!Array<string>}
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.getQuestionpiclistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.setQuestionpiclistList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.addQuestionpiclist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo} returns this
 */
proto.pbs.SimiQuestionModule.ScoreInfo.prototype.clearQuestionpiclistList = function() {
  return this.setQuestionpiclistList([]);
};


/**
 * repeated ScoreInfo score_info_list = 1;
 * @return {!Array<!proto.pbs.SimiQuestionModule.ScoreInfo>}
 */
proto.pbs.SimiQuestionModule.prototype.getScoreInfoListList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionModule.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionModule.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionModule.ScoreInfo>} value
 * @return {!proto.pbs.SimiQuestionModule} returns this
*/
proto.pbs.SimiQuestionModule.prototype.setScoreInfoListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.SimiQuestionModule.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionModule.ScoreInfo}
 */
proto.pbs.SimiQuestionModule.prototype.addScoreInfoList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.SimiQuestionModule.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiQuestionModule} returns this
 */
proto.pbs.SimiQuestionModule.prototype.clearScoreInfoListList = function() {
  return this.setScoreInfoListList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.SimiQuestionModule.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestionModule} returns this
 */
proto.pbs.SimiQuestionModule.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.SimiQuestionModule.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestionModule} returns this
 */
proto.pbs.SimiQuestionModule.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6WaferCarrierScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6WaferCarrierScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6WaferCarrierScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6WaferCarrierScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfo: (f = msg.getScoreInfo()) && proto.pbs.ScoreInfo.toObject(includeInstance, f),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6WaferCarrierScore}
 */
proto.pbs.MA6WaferCarrierScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6WaferCarrierScore;
  return proto.pbs.MA6WaferCarrierScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6WaferCarrierScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6WaferCarrierScore}
 */
proto.pbs.MA6WaferCarrierScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.setScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6WaferCarrierScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6WaferCarrierScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6WaferCarrierScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6WaferCarrierScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional ScoreInfo score_info = 1;
 * @return {?proto.pbs.ScoreInfo}
 */
proto.pbs.MA6WaferCarrierScore.prototype.getScoreInfo = function() {
  return /** @type{?proto.pbs.ScoreInfo} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {?proto.pbs.ScoreInfo|undefined} value
 * @return {!proto.pbs.MA6WaferCarrierScore} returns this
*/
proto.pbs.MA6WaferCarrierScore.prototype.setScoreInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6WaferCarrierScore} returns this
 */
proto.pbs.MA6WaferCarrierScore.prototype.clearScoreInfo = function() {
  return this.setScoreInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6WaferCarrierScore.prototype.hasScoreInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.MA6WaferCarrierScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6WaferCarrierScore} returns this
 */
proto.pbs.MA6WaferCarrierScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MA6WaferCarrierScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6WaferCarrierScore} returns this
 */
proto.pbs.MA6WaferCarrierScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6BoardShelfScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6BoardShelfScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6BoardShelfScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6BoardShelfScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfo: (f = msg.getScoreInfo()) && proto.pbs.ScoreInfo.toObject(includeInstance, f),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6BoardShelfScore}
 */
proto.pbs.MA6BoardShelfScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6BoardShelfScore;
  return proto.pbs.MA6BoardShelfScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6BoardShelfScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6BoardShelfScore}
 */
proto.pbs.MA6BoardShelfScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.setScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6BoardShelfScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6BoardShelfScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6BoardShelfScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6BoardShelfScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional ScoreInfo score_info = 1;
 * @return {?proto.pbs.ScoreInfo}
 */
proto.pbs.MA6BoardShelfScore.prototype.getScoreInfo = function() {
  return /** @type{?proto.pbs.ScoreInfo} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {?proto.pbs.ScoreInfo|undefined} value
 * @return {!proto.pbs.MA6BoardShelfScore} returns this
*/
proto.pbs.MA6BoardShelfScore.prototype.setScoreInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6BoardShelfScore} returns this
 */
proto.pbs.MA6BoardShelfScore.prototype.clearScoreInfo = function() {
  return this.setScoreInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6BoardShelfScore.prototype.hasScoreInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.MA6BoardShelfScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6BoardShelfScore} returns this
 */
proto.pbs.MA6BoardShelfScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MA6BoardShelfScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6BoardShelfScore} returns this
 */
proto.pbs.MA6BoardShelfScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6LithographyBoardScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6LithographyBoardScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6LithographyBoardScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6LithographyBoardScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6LithographyBoardScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6LithographyBoardScore}
 */
proto.pbs.MA6LithographyBoardScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6LithographyBoardScore;
  return proto.pbs.MA6LithographyBoardScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6LithographyBoardScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6LithographyBoardScore}
 */
proto.pbs.MA6LithographyBoardScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6LithographyBoardScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6LithographyBoardScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6LithographyBoardScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6LithographyBoardScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.MA6LithographyBoardScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.MA6LithographyBoardScore} returns this
*/
proto.pbs.MA6LithographyBoardScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.MA6LithographyBoardScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6LithographyBoardScore} returns this
 */
proto.pbs.MA6LithographyBoardScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.MA6LithographyBoardScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6LithographyBoardScore} returns this
 */
proto.pbs.MA6LithographyBoardScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MA6LithographyBoardScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6LithographyBoardScore} returns this
 */
proto.pbs.MA6LithographyBoardScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6ExposureScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ExposureScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ExposureScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ExposureScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ExposureScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ExposureScore}
 */
proto.pbs.MA6ExposureScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ExposureScore;
  return proto.pbs.MA6ExposureScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ExposureScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ExposureScore}
 */
proto.pbs.MA6ExposureScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ExposureScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ExposureScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ExposureScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ExposureScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.MA6ExposureScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.MA6ExposureScore} returns this
*/
proto.pbs.MA6ExposureScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.MA6ExposureScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ExposureScore} returns this
 */
proto.pbs.MA6ExposureScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.MA6ExposureScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ExposureScore} returns this
 */
proto.pbs.MA6ExposureScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MA6ExposureScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ExposureScore} returns this
 */
proto.pbs.MA6ExposureScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6OperationScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6OperationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6OperationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6OperationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationScoreList: jspb.Message.toObjectList(msg.getOperationScoreList(),
    proto.pbs.MA6OperationScore.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6OperationScore}
 */
proto.pbs.MA6OperationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6OperationScore;
  return proto.pbs.MA6OperationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6OperationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6OperationScore}
 */
proto.pbs.MA6OperationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MA6OperationScore.operationScore;
      reader.readMessage(value,proto.pbs.MA6OperationScore.operationScore.deserializeBinaryFromReader);
      msg.addOperationScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6OperationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6OperationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6OperationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.MA6OperationScore.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6OperationScore.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6OperationScore.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationScore.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6OperationScore.operationScore}
 */
proto.pbs.MA6OperationScore.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6OperationScore.operationScore;
  return proto.pbs.MA6OperationScore.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6OperationScore.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6OperationScore.operationScore}
 */
proto.pbs.MA6OperationScore.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6OperationScore.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6OperationScore.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationScore.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6OperationScore.operationScore} returns this
 */
proto.pbs.MA6OperationScore.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationScore.operationScore} returns this
 */
proto.pbs.MA6OperationScore.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationScore.operationScore} returns this
 */
proto.pbs.MA6OperationScore.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationScore.operationScore} returns this
 */
proto.pbs.MA6OperationScore.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.MA6OperationScore.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6OperationScore.operationScore} returns this
 */
proto.pbs.MA6OperationScore.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_score = 1;
 * @return {!Array<!proto.pbs.MA6OperationScore.operationScore>}
 */
proto.pbs.MA6OperationScore.prototype.getOperationScoreList = function() {
  return /** @type{!Array<!proto.pbs.MA6OperationScore.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6OperationScore.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.MA6OperationScore.operationScore>} value
 * @return {!proto.pbs.MA6OperationScore} returns this
*/
proto.pbs.MA6OperationScore.prototype.setOperationScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.MA6OperationScore.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6OperationScore.operationScore}
 */
proto.pbs.MA6OperationScore.prototype.addOperationScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.MA6OperationScore.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6OperationScore} returns this
 */
proto.pbs.MA6OperationScore.prototype.clearOperationScoreList = function() {
  return this.setOperationScoreList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.MA6OperationScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6OperationScore} returns this
 */
proto.pbs.MA6OperationScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.MA6OperationScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6OperationScore} returns this
 */
proto.pbs.MA6OperationScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6StandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6StandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6StandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6StandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    waferCarrierScore: (f = msg.getWaferCarrierScore()) && proto.pbs.MA6WaferCarrierScore.toObject(includeInstance, f),
    boardShelfScore: (f = msg.getBoardShelfScore()) && proto.pbs.MA6BoardShelfScore.toObject(includeInstance, f),
    lithographyBoardScore: (f = msg.getLithographyBoardScore()) && proto.pbs.MA6LithographyBoardScore.toObject(includeInstance, f),
    exposureScore: (f = msg.getExposureScore()) && proto.pbs.MA6ExposureScore.toObject(includeInstance, f),
    operationScore: (f = msg.getOperationScore()) && proto.pbs.MA6OperationScore.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6StandardExamScore}
 */
proto.pbs.MA6StandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6StandardExamScore;
  return proto.pbs.MA6StandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6StandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6StandardExamScore}
 */
proto.pbs.MA6StandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MA6WaferCarrierScore;
      reader.readMessage(value,proto.pbs.MA6WaferCarrierScore.deserializeBinaryFromReader);
      msg.setWaferCarrierScore(value);
      break;
    case 2:
      var value = new proto.pbs.MA6BoardShelfScore;
      reader.readMessage(value,proto.pbs.MA6BoardShelfScore.deserializeBinaryFromReader);
      msg.setBoardShelfScore(value);
      break;
    case 3:
      var value = new proto.pbs.MA6LithographyBoardScore;
      reader.readMessage(value,proto.pbs.MA6LithographyBoardScore.deserializeBinaryFromReader);
      msg.setLithographyBoardScore(value);
      break;
    case 4:
      var value = new proto.pbs.MA6ExposureScore;
      reader.readMessage(value,proto.pbs.MA6ExposureScore.deserializeBinaryFromReader);
      msg.setExposureScore(value);
      break;
    case 5:
      var value = new proto.pbs.MA6OperationScore;
      reader.readMessage(value,proto.pbs.MA6OperationScore.deserializeBinaryFromReader);
      msg.setOperationScore(value);
      break;
    case 6:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6StandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6StandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6StandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6StandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaferCarrierScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MA6WaferCarrierScore.serializeBinaryToWriter
    );
  }
  f = message.getBoardShelfScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.MA6BoardShelfScore.serializeBinaryToWriter
    );
  }
  f = message.getLithographyBoardScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.MA6LithographyBoardScore.serializeBinaryToWriter
    );
  }
  f = message.getExposureScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.MA6ExposureScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.MA6OperationScore.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};


/**
 * optional MA6WaferCarrierScore wafer_carrier_score = 1;
 * @return {?proto.pbs.MA6WaferCarrierScore}
 */
proto.pbs.MA6StandardExamScore.prototype.getWaferCarrierScore = function() {
  return /** @type{?proto.pbs.MA6WaferCarrierScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6WaferCarrierScore, 1));
};


/**
 * @param {?proto.pbs.MA6WaferCarrierScore|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setWaferCarrierScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearWaferCarrierScore = function() {
  return this.setWaferCarrierScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasWaferCarrierScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MA6BoardShelfScore board_shelf_score = 2;
 * @return {?proto.pbs.MA6BoardShelfScore}
 */
proto.pbs.MA6StandardExamScore.prototype.getBoardShelfScore = function() {
  return /** @type{?proto.pbs.MA6BoardShelfScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6BoardShelfScore, 2));
};


/**
 * @param {?proto.pbs.MA6BoardShelfScore|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setBoardShelfScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearBoardShelfScore = function() {
  return this.setBoardShelfScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasBoardShelfScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MA6LithographyBoardScore lithography_board_score = 3;
 * @return {?proto.pbs.MA6LithographyBoardScore}
 */
proto.pbs.MA6StandardExamScore.prototype.getLithographyBoardScore = function() {
  return /** @type{?proto.pbs.MA6LithographyBoardScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6LithographyBoardScore, 3));
};


/**
 * @param {?proto.pbs.MA6LithographyBoardScore|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setLithographyBoardScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearLithographyBoardScore = function() {
  return this.setLithographyBoardScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasLithographyBoardScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MA6ExposureScore exposure_score = 4;
 * @return {?proto.pbs.MA6ExposureScore}
 */
proto.pbs.MA6StandardExamScore.prototype.getExposureScore = function() {
  return /** @type{?proto.pbs.MA6ExposureScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6ExposureScore, 4));
};


/**
 * @param {?proto.pbs.MA6ExposureScore|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setExposureScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearExposureScore = function() {
  return this.setExposureScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasExposureScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MA6OperationScore operation_score = 5;
 * @return {?proto.pbs.MA6OperationScore}
 */
proto.pbs.MA6StandardExamScore.prototype.getOperationScore = function() {
  return /** @type{?proto.pbs.MA6OperationScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6OperationScore, 5));
};


/**
 * @param {?proto.pbs.MA6OperationScore|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setOperationScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearOperationScore = function() {
  return this.setOperationScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasOperationScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 6;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.MA6StandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 6));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.MA6StandardExamScore} returns this
*/
proto.pbs.MA6StandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6StandardExamScore} returns this
 */
proto.pbs.MA6StandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6StandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6GenerateScore.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6GenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6GenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6GenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6GenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ma6WaferCarrierSizeSel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ma6BoardShelfSizeSel: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ma6LithographyBoardSizeSel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ma6LithographyBoardOpTypeSel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ma6WaferAlignPositionX: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    ma6WaferAlignPositionY: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    ma6WaferAlignAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    ma6ExposureModeSel: jspb.Message.getFieldWithDefault(msg, 8, 0),
    exposureDurationSet: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6GenerateScore}
 */
proto.pbs.MA6GenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6GenerateScore;
  return proto.pbs.MA6GenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6GenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6GenerateScore}
 */
proto.pbs.MA6GenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.MA6WaferCarrierSize} */ (reader.readEnum());
      msg.setMa6WaferCarrierSizeSel(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.MA6BoardShelfSize} */ (reader.readEnum());
      msg.setMa6BoardShelfSizeSel(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.MA6LithographyBoardSize} */ (reader.readEnum());
      msg.setMa6LithographyBoardSizeSel(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.MA6LithographyBoardOpType} */ (reader.readEnum());
      msg.setMa6LithographyBoardOpTypeSel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignPositionX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignPositionY(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignAngle(value);
      break;
    case 8:
      var value = /** @type {!proto.pbs.MA6ExposureMode} */ (reader.readEnum());
      msg.setMa6ExposureModeSel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExposureDurationSet(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 11:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6GenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6GenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6GenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6GenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMa6WaferCarrierSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMa6BoardShelfSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMa6LithographyBoardSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMa6LithographyBoardOpTypeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMa6WaferAlignPositionX();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMa6WaferAlignPositionY();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMa6WaferAlignAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMa6ExposureModeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getExposureDurationSet();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};


/**
 * optional MA6WaferCarrierSize ma6_wafer_carrier_size_sel = 1;
 * @return {!proto.pbs.MA6WaferCarrierSize}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6WaferCarrierSizeSel = function() {
  return /** @type {!proto.pbs.MA6WaferCarrierSize} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.MA6WaferCarrierSize} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6WaferCarrierSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MA6BoardShelfSize ma6_board_shelf_size_sel = 2;
 * @return {!proto.pbs.MA6BoardShelfSize}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6BoardShelfSizeSel = function() {
  return /** @type {!proto.pbs.MA6BoardShelfSize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.MA6BoardShelfSize} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6BoardShelfSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MA6LithographyBoardSize ma6_lithography_board_size_sel = 3;
 * @return {!proto.pbs.MA6LithographyBoardSize}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6LithographyBoardSizeSel = function() {
  return /** @type {!proto.pbs.MA6LithographyBoardSize} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.MA6LithographyBoardSize} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6LithographyBoardSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional MA6LithographyBoardOpType ma6_lithography_board_op_type_sel = 4;
 * @return {!proto.pbs.MA6LithographyBoardOpType}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6LithographyBoardOpTypeSel = function() {
  return /** @type {!proto.pbs.MA6LithographyBoardOpType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.MA6LithographyBoardOpType} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6LithographyBoardOpTypeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional float ma6_wafer_align_position_x = 5;
 * @return {number}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6WaferAlignPositionX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6WaferAlignPositionX = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float ma6_wafer_align_position_y = 6;
 * @return {number}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6WaferAlignPositionY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6WaferAlignPositionY = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float ma6_wafer_align_angle = 7;
 * @return {number}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6WaferAlignAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6WaferAlignAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional MA6ExposureMode ma6_exposure_mode_sel = 8;
 * @return {!proto.pbs.MA6ExposureMode}
 */
proto.pbs.MA6GenerateScore.prototype.getMa6ExposureModeSel = function() {
  return /** @type {!proto.pbs.MA6ExposureMode} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.pbs.MA6ExposureMode} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setMa6ExposureModeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional float exposure_duration_set = 9;
 * @return {number}
 */
proto.pbs.MA6GenerateScore.prototype.getExposureDurationSet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setExposureDurationSet = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional bool enable_single_score = 10;
 * @return {boolean}
 */
proto.pbs.MA6GenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated SimiQuestionRet simi_question = 11;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.MA6GenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 11));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.MA6GenerateScore} returns this
*/
proto.pbs.MA6GenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.MA6GenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6GenerateScore} returns this
 */
proto.pbs.MA6GenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetedMaterialsScore: (f = msg.getTargetedMaterialsScore()) && proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.toObject(includeInstance, f),
    programScore: (f = msg.getProgramScore()) && proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.toObject(includeInstance, f),
    operationScore: (f = msg.getOperationScore()) && proto.pbs.LAB18StandardExamScore.LAB18OperationScore.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore}
 */
proto.pbs.LAB18StandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore;
  return proto.pbs.LAB18StandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore}
 */
proto.pbs.LAB18StandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.deserializeBinaryFromReader);
      msg.setTargetedMaterialsScore(value);
      break;
    case 2:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18ProgramScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.deserializeBinaryFromReader);
      msg.setProgramScore(value);
      break;
    case 3:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18OperationScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18OperationScore.deserializeBinaryFromReader);
      msg.setOperationScore(value);
      break;
    case 4:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetedMaterialsScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.serializeBinaryToWriter
    );
  }
  f = message.getProgramScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18OperationScore.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfo: (f = msg.getScoreInfo()) && proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.toObject(includeInstance, f),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore;
  return proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.deserializeBinaryFromReader);
      msg.setScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    datatypename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    standarddataname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    examineedataname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo;
  return proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatypename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandarddataname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamineedataname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatatypename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStandarddataname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExamineedataname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string dataTypeName = 1;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.getDatatypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.setDatatypename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string standardDataName = 2;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.getStandarddataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.setStandarddataname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string examineeDataName = 3;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.getExamineedataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.setExamineedataname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional ScoreInfo score_info = 1;
 * @return {?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.getScoreInfo = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo, 1));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.ScoreInfo|undefined} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} returns this
*/
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.setScoreInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.clearScoreInfo = function() {
  return this.setScoreInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.hasScoreInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18ProgramScore;
  return proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    datatypename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    standarddataname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    examineedataname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo;
  return proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatypename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandarddataname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamineedataname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatatypename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStandarddataname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExamineedataname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string dataTypeName = 1;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.getDatatypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.setDatatypename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string standardDataName = 2;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.getStandarddataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.setStandarddataname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string examineeDataName = 3;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.getExamineedataname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.setExamineedataname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo>}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo>} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} returns this
*/
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18ProgramScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18OperationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationListList: jspb.Message.toObjectList(msg.getOperationListList(),
    proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18OperationScore;
  return proto.pbs.LAB18StandardExamScore.LAB18OperationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.deserializeBinaryFromReader);
      msg.addOperationList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18OperationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore;
  return proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_list = 1;
 * @return {!Array<!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore>}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.getOperationListList = function() {
  return /** @type{!Array<!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore>} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} returns this
*/
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.setOperationListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.addOperationList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.LAB18StandardExamScore.LAB18OperationScore.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.clearOperationListList = function() {
  return this.setOperationListList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18StandardExamScore.LAB18OperationScore} returns this
 */
proto.pbs.LAB18StandardExamScore.LAB18OperationScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional LAB18TargetedMaterialsScore targeted_materials_score = 1;
 * @return {?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore}
 */
proto.pbs.LAB18StandardExamScore.prototype.getTargetedMaterialsScore = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore, 1));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore.LAB18TargetedMaterialsScore|undefined} value
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
*/
proto.pbs.LAB18StandardExamScore.prototype.setTargetedMaterialsScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
 */
proto.pbs.LAB18StandardExamScore.prototype.clearTargetedMaterialsScore = function() {
  return this.setTargetedMaterialsScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18StandardExamScore.prototype.hasTargetedMaterialsScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LAB18ProgramScore program_score = 2;
 * @return {?proto.pbs.LAB18StandardExamScore.LAB18ProgramScore}
 */
proto.pbs.LAB18StandardExamScore.prototype.getProgramScore = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore.LAB18ProgramScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18ProgramScore, 2));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore.LAB18ProgramScore|undefined} value
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
*/
proto.pbs.LAB18StandardExamScore.prototype.setProgramScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
 */
proto.pbs.LAB18StandardExamScore.prototype.clearProgramScore = function() {
  return this.setProgramScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18StandardExamScore.prototype.hasProgramScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LAB18OperationScore operation_score = 3;
 * @return {?proto.pbs.LAB18StandardExamScore.LAB18OperationScore}
 */
proto.pbs.LAB18StandardExamScore.prototype.getOperationScore = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore.LAB18OperationScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore.LAB18OperationScore, 3));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore.LAB18OperationScore|undefined} value
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
*/
proto.pbs.LAB18StandardExamScore.prototype.setOperationScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
 */
proto.pbs.LAB18StandardExamScore.prototype.clearOperationScore = function() {
  return this.setOperationScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18StandardExamScore.prototype.hasOperationScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 4;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.LAB18StandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 4));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
*/
proto.pbs.LAB18StandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18StandardExamScore} returns this
 */
proto.pbs.LAB18StandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18StandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18GenerateScore.repeatedFields_ = [1,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18GenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18GenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18GenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18GenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctMaterialsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    programType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    programParametersList: jspb.Message.toObjectList(msg.getProgramParametersList(),
    proto.pbs.LAB18GenerateScore.ProgramParameters.toObject, includeInstance),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18GenerateScore}
 */
proto.pbs.LAB18GenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18GenerateScore;
  return proto.pbs.LAB18GenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18GenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18GenerateScore}
 */
proto.pbs.LAB18GenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectMaterials(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.ProgramType} */ (reader.readEnum());
      msg.setProgramType(value);
      break;
    case 3:
      var value = new proto.pbs.LAB18GenerateScore.ProgramParameters;
      reader.readMessage(value,proto.pbs.LAB18GenerateScore.ProgramParameters.deserializeBinaryFromReader);
      msg.addProgramParameters(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18GenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18GenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18GenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18GenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getProgramType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgramParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.LAB18GenerateScore.ProgramParameters.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18GenerateScore.ProgramParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18GenerateScore.ProgramParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    equipmentTestValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18GenerateScore.ProgramParameters}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18GenerateScore.ProgramParameters;
  return proto.pbs.LAB18GenerateScore.ProgramParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18GenerateScore.ProgramParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18GenerateScore.ProgramParameters}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEquipmentTestValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18GenerateScore.ProgramParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18GenerateScore.ProgramParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEquipmentTestValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18GenerateScore.ProgramParameters} returns this
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float equipment_test_value = 2;
 * @return {number}
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.getEquipmentTestValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18GenerateScore.ProgramParameters} returns this
 */
proto.pbs.LAB18GenerateScore.ProgramParameters.prototype.setEquipmentTestValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated string correct_materials = 1;
 * @return {!Array<string>}
 */
proto.pbs.LAB18GenerateScore.prototype.getCorrectMaterialsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.setCorrectMaterialsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.addCorrectMaterials = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.clearCorrectMaterialsList = function() {
  return this.setCorrectMaterialsList([]);
};


/**
 * optional ProgramType program_type = 2;
 * @return {!proto.pbs.ProgramType}
 */
proto.pbs.LAB18GenerateScore.prototype.getProgramType = function() {
  return /** @type {!proto.pbs.ProgramType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.ProgramType} value
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.setProgramType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated ProgramParameters program_parameters = 3;
 * @return {!Array<!proto.pbs.LAB18GenerateScore.ProgramParameters>}
 */
proto.pbs.LAB18GenerateScore.prototype.getProgramParametersList = function() {
  return /** @type{!Array<!proto.pbs.LAB18GenerateScore.ProgramParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18GenerateScore.ProgramParameters, 3));
};


/**
 * @param {!Array<!proto.pbs.LAB18GenerateScore.ProgramParameters>} value
 * @return {!proto.pbs.LAB18GenerateScore} returns this
*/
proto.pbs.LAB18GenerateScore.prototype.setProgramParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.LAB18GenerateScore.ProgramParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18GenerateScore.ProgramParameters}
 */
proto.pbs.LAB18GenerateScore.prototype.addProgramParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.LAB18GenerateScore.ProgramParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.clearProgramParametersList = function() {
  return this.setProgramParametersList([]);
};


/**
 * optional bool enable_single_score = 4;
 * @return {boolean}
 */
proto.pbs.LAB18GenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SimiQuestionRet simi_question = 5;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.LAB18GenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 5));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.LAB18GenerateScore} returns this
*/
proto.pbs.LAB18GenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.LAB18GenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18GenerateScore} returns this
 */
proto.pbs.LAB18GenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    coaterScore: (f = msg.getCoaterScore()) && proto.pbs.SpinCoaterStandardExamScore.CoaterScore.toObject(includeInstance, f),
    preBakeScore: (f = msg.getPreBakeScore()) && proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.toObject(includeInstance, f),
    operationScore: (f = msg.getOperationScore()) && proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterStandardExamScore}
 */
proto.pbs.SpinCoaterStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterStandardExamScore;
  return proto.pbs.SpinCoaterStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterStandardExamScore}
 */
proto.pbs.SpinCoaterStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SpinCoaterStandardExamScore.CoaterScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.CoaterScore.deserializeBinaryFromReader);
      msg.setCoaterScore(value);
      break;
    case 2:
      var value = new proto.pbs.SpinCoaterStandardExamScore.PreBakeScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.deserializeBinaryFromReader);
      msg.setPreBakeScore(value);
      break;
    case 3:
      var value = new proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.deserializeBinaryFromReader);
      msg.setOperationScore(value);
      break;
    case 4:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoaterScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.SpinCoaterStandardExamScore.CoaterScore.serializeBinaryToWriter
    );
  }
  f = message.getPreBakeScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterStandardExamScore.CoaterScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterStandardExamScore.CoaterScore;
  return proto.pbs.SpinCoaterStandardExamScore.CoaterScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterStandardExamScore.CoaterScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.CoaterScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.CoaterScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterStandardExamScore.PreBakeScore;
  return proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.PreBakeScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationListList: jspb.Message.toObjectList(msg.getOperationListList(),
    proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore;
  return proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.deserializeBinaryFromReader);
      msg.addOperationList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore;
  return proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated OperationScore operation_list = 1;
 * @return {!Array<!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore>}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.getOperationListList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore>} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.setOperationListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.addOperationList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.OperationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.clearOperationListList = function() {
  return this.setOperationListList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional CoaterScore coater_score = 1;
 * @return {?proto.pbs.SpinCoaterStandardExamScore.CoaterScore}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.getCoaterScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore.CoaterScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterStandardExamScore.CoaterScore, 1));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore.CoaterScore|undefined} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.prototype.setCoaterScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.clearCoaterScore = function() {
  return this.setCoaterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.hasCoaterScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PreBakeScore pre_bake_score = 2;
 * @return {?proto.pbs.SpinCoaterStandardExamScore.PreBakeScore}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.getPreBakeScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore.PreBakeScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterStandardExamScore.PreBakeScore, 2));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore.PreBakeScore|undefined} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.prototype.setPreBakeScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.clearPreBakeScore = function() {
  return this.setPreBakeScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.hasPreBakeScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpinCoaterOperationScore operation_score = 3;
 * @return {?proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.getOperationScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore, 3));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore.SpinCoaterOperationScore|undefined} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.prototype.setOperationScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.clearOperationScore = function() {
  return this.setOperationScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.hasOperationScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 4;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 4));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
*/
proto.pbs.SpinCoaterStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterStandardExamScore} returns this
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterGenerateScore.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    coaterDetailList: jspb.Message.toObjectList(msg.getCoaterDetailList(),
    proto.pbs.SpinCoaterGenerateScore.CoaterDetail.toObject, includeInstance),
    preBake: (f = msg.getPreBake()) && proto.pbs.SpinCoaterGenerateScore.PreBake.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterGenerateScore}
 */
proto.pbs.SpinCoaterGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterGenerateScore;
  return proto.pbs.SpinCoaterGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterGenerateScore}
 */
proto.pbs.SpinCoaterGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SpinCoaterGenerateScore.CoaterDetail;
      reader.readMessage(value,proto.pbs.SpinCoaterGenerateScore.CoaterDetail.deserializeBinaryFromReader);
      msg.addCoaterDetail(value);
      break;
    case 2:
      var value = new proto.pbs.SpinCoaterGenerateScore.PreBake;
      reader.readMessage(value,proto.pbs.SpinCoaterGenerateScore.PreBake.deserializeBinaryFromReader);
      msg.setPreBake(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 4:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoaterDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.SpinCoaterGenerateScore.CoaterDetail.serializeBinaryToWriter
    );
  }
  f = message.getPreBake();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.SpinCoaterGenerateScore.PreBake.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterGenerateScore.CoaterDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterGenerateScore.CoaterDetail;
  return proto.pbs.SpinCoaterGenerateScore.CoaterDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterGenerateScore.CoaterDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double speed = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float time = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterGenerateScore.CoaterDetail.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterGenerateScore.PreBake.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterGenerateScore.PreBake} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.toObject = function(includeInstance, msg) {
  var f, obj = {
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterGenerateScore.PreBake}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterGenerateScore.PreBake;
  return proto.pbs.SpinCoaterGenerateScore.PreBake.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterGenerateScore.PreBake} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterGenerateScore.PreBake}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterGenerateScore.PreBake.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterGenerateScore.PreBake} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float temperature = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterGenerateScore.PreBake} returns this
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float time = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterGenerateScore.PreBake} returns this
 */
proto.pbs.SpinCoaterGenerateScore.PreBake.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated CoaterDetail coater_detail = 1;
 * @return {!Array<!proto.pbs.SpinCoaterGenerateScore.CoaterDetail>}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.getCoaterDetailList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterGenerateScore.CoaterDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterGenerateScore.CoaterDetail, 1));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterGenerateScore.CoaterDetail>} value
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
*/
proto.pbs.SpinCoaterGenerateScore.prototype.setCoaterDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterGenerateScore.CoaterDetail}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.addCoaterDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.SpinCoaterGenerateScore.CoaterDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
 */
proto.pbs.SpinCoaterGenerateScore.prototype.clearCoaterDetailList = function() {
  return this.setCoaterDetailList([]);
};


/**
 * optional PreBake pre_bake = 2;
 * @return {?proto.pbs.SpinCoaterGenerateScore.PreBake}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.getPreBake = function() {
  return /** @type{?proto.pbs.SpinCoaterGenerateScore.PreBake} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterGenerateScore.PreBake, 2));
};


/**
 * @param {?proto.pbs.SpinCoaterGenerateScore.PreBake|undefined} value
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
*/
proto.pbs.SpinCoaterGenerateScore.prototype.setPreBake = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
 */
proto.pbs.SpinCoaterGenerateScore.prototype.clearPreBake = function() {
  return this.setPreBake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.hasPreBake = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool enable_single_score = 3;
 * @return {boolean}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
 */
proto.pbs.SpinCoaterGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated SimiQuestionRet simi_question = 4;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 4));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
*/
proto.pbs.SpinCoaterGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.SpinCoaterGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterGenerateScore} returns this
 */
proto.pbs.SpinCoaterGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    cleaningFluidScore: (f = msg.getCleaningFluidScore()) && proto.pbs.CleaningStandardExamScore.CleaningFluidScore.toObject(includeInstance, f),
    orderScore: (f = msg.getOrderScore()) && proto.pbs.CleaningStandardExamScore.OrderScore.toObject(includeInstance, f),
    timeSettingScore: (f = msg.getTimeSettingScore()) && proto.pbs.CleaningStandardExamScore.TimeSettingScore.toObject(includeInstance, f),
    cleaningDeviceScore: (f = msg.getCleaningDeviceScore()) && proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.toObject(includeInstance, f),
    temperatureSettingScore: (f = msg.getTemperatureSettingScore()) && proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.toObject(includeInstance, f),
    cleaningProcess: (f = msg.getCleaningProcess()) && proto.pbs.CleaningStandardExamScore.CleaningProcess.toObject(includeInstance, f),
    cleaningNum: (f = msg.getCleaningNum()) && proto.pbs.CleaningStandardExamScore.CleaningNum.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore}
 */
proto.pbs.CleaningStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore;
  return proto.pbs.CleaningStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore}
 */
proto.pbs.CleaningStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.CleaningStandardExamScore.CleaningFluidScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.CleaningFluidScore.deserializeBinaryFromReader);
      msg.setCleaningFluidScore(value);
      break;
    case 2:
      var value = new proto.pbs.CleaningStandardExamScore.OrderScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.OrderScore.deserializeBinaryFromReader);
      msg.setOrderScore(value);
      break;
    case 3:
      var value = new proto.pbs.CleaningStandardExamScore.TimeSettingScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.TimeSettingScore.deserializeBinaryFromReader);
      msg.setTimeSettingScore(value);
      break;
    case 4:
      var value = new proto.pbs.CleaningStandardExamScore.CleaningDeviceScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.deserializeBinaryFromReader);
      msg.setCleaningDeviceScore(value);
      break;
    case 5:
      var value = new proto.pbs.CleaningStandardExamScore.TemperatureSettingScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader);
      msg.setTemperatureSettingScore(value);
      break;
    case 6:
      var value = new proto.pbs.CleaningStandardExamScore.CleaningProcess;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.CleaningProcess.deserializeBinaryFromReader);
      msg.setCleaningProcess(value);
      break;
    case 7:
      var value = new proto.pbs.CleaningStandardExamScore.CleaningNum;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.CleaningNum.deserializeBinaryFromReader);
      msg.setCleaningNum(value);
      break;
    case 8:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCleaningFluidScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.CleaningStandardExamScore.CleaningFluidScore.serializeBinaryToWriter
    );
  }
  f = message.getOrderScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.CleaningStandardExamScore.OrderScore.serializeBinaryToWriter
    );
  }
  f = message.getTimeSettingScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.CleaningStandardExamScore.TimeSettingScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningDeviceScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureSettingScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningProcess();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.CleaningStandardExamScore.CleaningProcess.serializeBinaryToWriter
    );
  }
  f = message.getCleaningNum();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.CleaningStandardExamScore.CleaningNum.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.CleaningFluidScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.CleaningFluidScore;
  return proto.pbs.CleaningStandardExamScore.CleaningFluidScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.CleaningFluidScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} returns this
*/
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningFluidScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningFluidScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.OrderScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.OrderScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.OrderScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.OrderScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.OrderScore;
  return proto.pbs.CleaningStandardExamScore.OrderScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.OrderScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.OrderScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.OrderScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.OrderScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore} returns this
*/
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore} returns this
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore} returns this
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.OrderScore} returns this
 */
proto.pbs.CleaningStandardExamScore.OrderScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.TimeSettingScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.TimeSettingScore;
  return proto.pbs.CleaningStandardExamScore.TimeSettingScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.TimeSettingScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} returns this
*/
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TimeSettingScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.CleaningDeviceScore;
  return proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} returns this
*/
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningDeviceScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.TemperatureSettingScore;
  return proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} returns this
*/
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.CleaningStandardExamScore.TemperatureSettingScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.CleaningProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.CleaningProcess;
  return proto.pbs.CleaningStandardExamScore.CleaningProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.CleaningProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess} returns this
*/
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningProcess} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningProcess.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningStandardExamScore.CleaningNum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningNum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningStandardExamScore.CleaningNum;
  return proto.pbs.CleaningStandardExamScore.CleaningNum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningNum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningStandardExamScore.CleaningNum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningStandardExamScore.CleaningNum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum} returns this
*/
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningStandardExamScore.CleaningNum} returns this
 */
proto.pbs.CleaningStandardExamScore.CleaningNum.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional CleaningFluidScore cleaning_fluid_score = 1;
 * @return {?proto.pbs.CleaningStandardExamScore.CleaningFluidScore}
 */
proto.pbs.CleaningStandardExamScore.prototype.getCleaningFluidScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.CleaningFluidScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.CleaningFluidScore, 1));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.CleaningFluidScore|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setCleaningFluidScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearCleaningFluidScore = function() {
  return this.setCleaningFluidScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasCleaningFluidScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OrderScore order_score = 2;
 * @return {?proto.pbs.CleaningStandardExamScore.OrderScore}
 */
proto.pbs.CleaningStandardExamScore.prototype.getOrderScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.OrderScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.OrderScore, 2));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.OrderScore|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setOrderScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearOrderScore = function() {
  return this.setOrderScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasOrderScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeSettingScore time_setting_score = 3;
 * @return {?proto.pbs.CleaningStandardExamScore.TimeSettingScore}
 */
proto.pbs.CleaningStandardExamScore.prototype.getTimeSettingScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.TimeSettingScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.TimeSettingScore, 3));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.TimeSettingScore|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setTimeSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearTimeSettingScore = function() {
  return this.setTimeSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasTimeSettingScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CleaningDeviceScore cleaning_device_score = 4;
 * @return {?proto.pbs.CleaningStandardExamScore.CleaningDeviceScore}
 */
proto.pbs.CleaningStandardExamScore.prototype.getCleaningDeviceScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.CleaningDeviceScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.CleaningDeviceScore, 4));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.CleaningDeviceScore|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setCleaningDeviceScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearCleaningDeviceScore = function() {
  return this.setCleaningDeviceScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasCleaningDeviceScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TemperatureSettingScore temperature_setting_score = 5;
 * @return {?proto.pbs.CleaningStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.CleaningStandardExamScore.prototype.getTemperatureSettingScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.TemperatureSettingScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.TemperatureSettingScore, 5));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.TemperatureSettingScore|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setTemperatureSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearTemperatureSettingScore = function() {
  return this.setTemperatureSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasTemperatureSettingScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CleaningProcess cleaning_process = 6;
 * @return {?proto.pbs.CleaningStandardExamScore.CleaningProcess}
 */
proto.pbs.CleaningStandardExamScore.prototype.getCleaningProcess = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.CleaningProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.CleaningProcess, 6));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.CleaningProcess|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setCleaningProcess = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearCleaningProcess = function() {
  return this.setCleaningProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasCleaningProcess = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CleaningNum cleaning_num = 7;
 * @return {?proto.pbs.CleaningStandardExamScore.CleaningNum}
 */
proto.pbs.CleaningStandardExamScore.prototype.getCleaningNum = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore.CleaningNum} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore.CleaningNum, 7));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore.CleaningNum|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setCleaningNum = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearCleaningNum = function() {
  return this.setCleaningNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasCleaningNum = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 8;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.CleaningStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 8));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
*/
proto.pbs.CleaningStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningStandardExamScore} returns this
 */
proto.pbs.CleaningStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningGenerateScore.repeatedFields_ = [1,2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    cleaningDetailList: jspb.Message.toObjectList(msg.getCleaningDetailList(),
    proto.pbs.CleaningGenerateScore.CleaningDetail.toObject, includeInstance),
    cleaningProcess: (f = msg.getCleaningProcess()) && proto.pbs.CleaningGenerateScore.CleaningProcess.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningGenerateScore}
 */
proto.pbs.CleaningGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningGenerateScore;
  return proto.pbs.CleaningGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningGenerateScore}
 */
proto.pbs.CleaningGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 2:
      var value = new proto.pbs.CleaningGenerateScore.CleaningDetail;
      reader.readMessage(value,proto.pbs.CleaningGenerateScore.CleaningDetail.deserializeBinaryFromReader);
      msg.addCleaningDetail(value);
      break;
    case 3:
      var value = new proto.pbs.CleaningGenerateScore.CleaningProcess;
      reader.readMessage(value,proto.pbs.CleaningGenerateScore.CleaningProcess.deserializeBinaryFromReader);
      msg.setCleaningProcess(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCleaningDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.CleaningGenerateScore.CleaningDetail.serializeBinaryToWriter
    );
  }
  f = message.getCleaningProcess();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.CleaningGenerateScore.CleaningProcess.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningGenerateScore.CleaningDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningGenerateScore.CleaningDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    fluidOption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cleaningDevice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cleaningTimes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningGenerateScore.CleaningDetail;
  return proto.pbs.CleaningGenerateScore.CleaningDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningGenerateScore.CleaningDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setCleaningDevice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviceScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCleaningTimes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningGenerateScore.CleaningDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningGenerateScore.CleaningDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCleaningDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCleaningTimes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional double order_score = 1;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getOrderScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setOrderScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string fluid_option = 2;
 * @return {string}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CleaningDeviceType cleaning_device = 3;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getCleaningDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setCleaningDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double device_score = 4;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getDeviceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setDeviceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 cleaning_times = 5;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getCleaningTimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setCleaningTimes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float time = 6;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float temperature = 7;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningDetail.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningGenerateScore.CleaningProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningGenerateScore.CleaningProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    cleanStep: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningGenerateScore.CleaningProcess}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningGenerateScore.CleaningProcess;
  return proto.pbs.CleaningGenerateScore.CleaningProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningGenerateScore.CleaningProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningGenerateScore.CleaningProcess}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.CleaningStep} */ (reader.readEnum());
      msg.setCleanStep(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningGenerateScore.CleaningProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningGenerateScore.CleaningProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCleanStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CleaningStep clean_step = 1;
 * @return {!proto.pbs.CleaningStep}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.getCleanStep = function() {
  return /** @type {!proto.pbs.CleaningStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.CleaningStep} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningProcess} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.setCleanStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningGenerateScore.CleaningProcess} returns this
 */
proto.pbs.CleaningGenerateScore.CleaningProcess.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated string correct_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.CleaningGenerateScore.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * repeated CleaningDetail cleaning_detail = 2;
 * @return {!Array<!proto.pbs.CleaningGenerateScore.CleaningDetail>}
 */
proto.pbs.CleaningGenerateScore.prototype.getCleaningDetailList = function() {
  return /** @type{!Array<!proto.pbs.CleaningGenerateScore.CleaningDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.CleaningGenerateScore.CleaningDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.CleaningGenerateScore.CleaningDetail>} value
 * @return {!proto.pbs.CleaningGenerateScore} returns this
*/
proto.pbs.CleaningGenerateScore.prototype.setCleaningDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.CleaningGenerateScore.CleaningDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningGenerateScore.CleaningDetail}
 */
proto.pbs.CleaningGenerateScore.prototype.addCleaningDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.CleaningGenerateScore.CleaningDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.clearCleaningDetailList = function() {
  return this.setCleaningDetailList([]);
};


/**
 * optional CleaningProcess cleaning_process = 3;
 * @return {?proto.pbs.CleaningGenerateScore.CleaningProcess}
 */
proto.pbs.CleaningGenerateScore.prototype.getCleaningProcess = function() {
  return /** @type{?proto.pbs.CleaningGenerateScore.CleaningProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningGenerateScore.CleaningProcess, 3));
};


/**
 * @param {?proto.pbs.CleaningGenerateScore.CleaningProcess|undefined} value
 * @return {!proto.pbs.CleaningGenerateScore} returns this
*/
proto.pbs.CleaningGenerateScore.prototype.setCleaningProcess = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.clearCleaningProcess = function() {
  return this.setCleaningProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningGenerateScore.prototype.hasCleaningProcess = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enable_single_score = 4;
 * @return {boolean}
 */
proto.pbs.CleaningGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SimiQuestionRet simi_question = 5;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.CleaningGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 5));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.CleaningGenerateScore} returns this
*/
proto.pbs.CleaningGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.CleaningGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningGenerateScore} returns this
 */
proto.pbs.CleaningGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    developmentFluidScore: (f = msg.getDevelopmentFluidScore()) && proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.toObject(includeInstance, f),
    orderScore: (f = msg.getOrderScore()) && proto.pbs.DevelopmentStandardExamScore.OrderScore.toObject(includeInstance, f),
    timeSettingScore: (f = msg.getTimeSettingScore()) && proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.toObject(includeInstance, f),
    developmentDeviceScore: (f = msg.getDevelopmentDeviceScore()) && proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.toObject(includeInstance, f),
    temperatureSettingScore: (f = msg.getTemperatureSettingScore()) && proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.toObject(includeInstance, f),
    developmentProcess: (f = msg.getDevelopmentProcess()) && proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.toObject(includeInstance, f),
    developmentNum: (f = msg.getDevelopmentNum()) && proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore}
 */
proto.pbs.DevelopmentStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore;
  return proto.pbs.DevelopmentStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore}
 */
proto.pbs.DevelopmentStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.deserializeBinaryFromReader);
      msg.setDevelopmentFluidScore(value);
      break;
    case 2:
      var value = new proto.pbs.DevelopmentStandardExamScore.OrderScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.OrderScore.deserializeBinaryFromReader);
      msg.setOrderScore(value);
      break;
    case 3:
      var value = new proto.pbs.DevelopmentStandardExamScore.TimeSettingScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.deserializeBinaryFromReader);
      msg.setTimeSettingScore(value);
      break;
    case 4:
      var value = new proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.deserializeBinaryFromReader);
      msg.setDevelopmentDeviceScore(value);
      break;
    case 5:
      var value = new proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader);
      msg.setTemperatureSettingScore(value);
      break;
    case 6:
      var value = new proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.deserializeBinaryFromReader);
      msg.setDevelopmentProcess(value);
      break;
    case 7:
      var value = new proto.pbs.DevelopmentStandardExamScore.DevelopmentNum;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.deserializeBinaryFromReader);
      msg.setDevelopmentNum(value);
      break;
    case 8:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevelopmentFluidScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.serializeBinaryToWriter
    );
  }
  f = message.getOrderScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.DevelopmentStandardExamScore.OrderScore.serializeBinaryToWriter
    );
  }
  f = message.getTimeSettingScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentDeviceScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureSettingScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentProcess();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentNum();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore;
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.OrderScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.OrderScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.OrderScore;
  return proto.pbs.DevelopmentStandardExamScore.OrderScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.OrderScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.OrderScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.OrderScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.OrderScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.OrderScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.TimeSettingScore;
  return proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TimeSettingScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore;
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore;
  return proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess;
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} returns this
*/
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentStandardExamScore.DevelopmentNum;
  return proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} returns this
*/
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} returns this
 */
proto.pbs.DevelopmentStandardExamScore.DevelopmentNum.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional DevelopmentFluidScore development_fluid_score = 1;
 * @return {?proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getDevelopmentFluidScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore, 1));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.DevelopmentFluidScore|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setDevelopmentFluidScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearDevelopmentFluidScore = function() {
  return this.setDevelopmentFluidScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasDevelopmentFluidScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OrderScore order_score = 2;
 * @return {?proto.pbs.DevelopmentStandardExamScore.OrderScore}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getOrderScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.OrderScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.OrderScore, 2));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.OrderScore|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setOrderScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearOrderScore = function() {
  return this.setOrderScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasOrderScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeSettingScore time_setting_score = 3;
 * @return {?proto.pbs.DevelopmentStandardExamScore.TimeSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getTimeSettingScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.TimeSettingScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.TimeSettingScore, 3));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.TimeSettingScore|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setTimeSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearTimeSettingScore = function() {
  return this.setTimeSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasTimeSettingScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DevelopmentDeviceScore development_device_score = 4;
 * @return {?proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getDevelopmentDeviceScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore, 4));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.DevelopmentDeviceScore|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setDevelopmentDeviceScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearDevelopmentDeviceScore = function() {
  return this.setDevelopmentDeviceScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasDevelopmentDeviceScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TemperatureSettingScore temperature_setting_score = 5;
 * @return {?proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getTemperatureSettingScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore, 5));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.TemperatureSettingScore|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setTemperatureSettingScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearTemperatureSettingScore = function() {
  return this.setTemperatureSettingScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasTemperatureSettingScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DevelopmentProcess development_process = 6;
 * @return {?proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getDevelopmentProcess = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess, 6));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.DevelopmentProcess|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setDevelopmentProcess = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearDevelopmentProcess = function() {
  return this.setDevelopmentProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasDevelopmentProcess = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DevelopmentNum development_num = 7;
 * @return {?proto.pbs.DevelopmentStandardExamScore.DevelopmentNum}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getDevelopmentNum = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore.DevelopmentNum} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore.DevelopmentNum, 7));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore.DevelopmentNum|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setDevelopmentNum = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearDevelopmentNum = function() {
  return this.setDevelopmentNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasDevelopmentNum = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 8;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 8));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
*/
proto.pbs.DevelopmentStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentStandardExamScore} returns this
 */
proto.pbs.DevelopmentStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentGenerateScore.repeatedFields_ = [1,2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    developmentDetailList: jspb.Message.toObjectList(msg.getDevelopmentDetailList(),
    proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.toObject, includeInstance),
    developmentProcess: (f = msg.getDevelopmentProcess()) && proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentGenerateScore}
 */
proto.pbs.DevelopmentGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentGenerateScore;
  return proto.pbs.DevelopmentGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentGenerateScore}
 */
proto.pbs.DevelopmentGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 2:
      var value = new proto.pbs.DevelopmentGenerateScore.DevelopmentDetail;
      reader.readMessage(value,proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.deserializeBinaryFromReader);
      msg.addDevelopmentDetail(value);
      break;
    case 3:
      var value = new proto.pbs.DevelopmentGenerateScore.DevelopmentProcess;
      reader.readMessage(value,proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.deserializeBinaryFromReader);
      msg.setDevelopmentProcess(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDevelopmentDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentProcess();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    fluidOption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    developmentDevice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    developmentTimes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentGenerateScore.DevelopmentDetail;
  return proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setDevelopmentDevice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviceScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDevelopmentTimes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevelopmentDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDevelopmentTimes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional double order_score = 1;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getOrderScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setOrderScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string fluid_option = 2;
 * @return {string}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CleaningDeviceType development_device = 3;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getDevelopmentDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setDevelopmentDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double device_score = 4;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getDeviceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setDeviceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 development_times = 5;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getDevelopmentTimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setDevelopmentTimes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float time = 6;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float temperature = 7;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentDetail.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    cleanStep: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentGenerateScore.DevelopmentProcess;
  return proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.CleaningStep} */ (reader.readEnum());
      msg.setCleanStep(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCleanStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CleaningStep clean_step = 1;
 * @return {!proto.pbs.CleaningStep}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.getCleanStep = function() {
  return /** @type {!proto.pbs.CleaningStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.CleaningStep} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.setCleanStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentGenerateScore.DevelopmentProcess.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated string correct_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.DevelopmentGenerateScore.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * repeated DevelopmentDetail development_detail = 2;
 * @return {!Array<!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail>}
 */
proto.pbs.DevelopmentGenerateScore.prototype.getDevelopmentDetailList = function() {
  return /** @type{!Array<!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.DevelopmentGenerateScore.DevelopmentDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail>} value
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
*/
proto.pbs.DevelopmentGenerateScore.prototype.setDevelopmentDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentGenerateScore.DevelopmentDetail}
 */
proto.pbs.DevelopmentGenerateScore.prototype.addDevelopmentDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.DevelopmentGenerateScore.DevelopmentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.clearDevelopmentDetailList = function() {
  return this.setDevelopmentDetailList([]);
};


/**
 * optional DevelopmentProcess development_process = 3;
 * @return {?proto.pbs.DevelopmentGenerateScore.DevelopmentProcess}
 */
proto.pbs.DevelopmentGenerateScore.prototype.getDevelopmentProcess = function() {
  return /** @type{?proto.pbs.DevelopmentGenerateScore.DevelopmentProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentGenerateScore.DevelopmentProcess, 3));
};


/**
 * @param {?proto.pbs.DevelopmentGenerateScore.DevelopmentProcess|undefined} value
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
*/
proto.pbs.DevelopmentGenerateScore.prototype.setDevelopmentProcess = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.clearDevelopmentProcess = function() {
  return this.setDevelopmentProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentGenerateScore.prototype.hasDevelopmentProcess = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enable_single_score = 4;
 * @return {boolean}
 */
proto.pbs.DevelopmentGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SimiQuestionRet simi_question = 5;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.DevelopmentGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 5));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
*/
proto.pbs.DevelopmentGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.DevelopmentGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentGenerateScore} returns this
 */
proto.pbs.DevelopmentGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    etchinggases: (f = msg.getEtchinggases()) && proto.pbs.PlasmaStandardExamScore.Etchinggases.toObject(includeInstance, f),
    gasParameters: (f = msg.getGasParameters()) && proto.pbs.PlasmaStandardExamScore.GasParameters.toObject(includeInstance, f),
    etchParameters: (f = msg.getEtchParameters()) && proto.pbs.PlasmaStandardExamScore.EtchParameters.toObject(includeInstance, f),
    operations: (f = msg.getOperations()) && proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore}
 */
proto.pbs.PlasmaStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore;
  return proto.pbs.PlasmaStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore}
 */
proto.pbs.PlasmaStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PlasmaStandardExamScore.Etchinggases;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.Etchinggases.deserializeBinaryFromReader);
      msg.setEtchinggases(value);
      break;
    case 2:
      var value = new proto.pbs.PlasmaStandardExamScore.GasParameters;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.GasParameters.deserializeBinaryFromReader);
      msg.setGasParameters(value);
      break;
    case 3:
      var value = new proto.pbs.PlasmaStandardExamScore.EtchParameters;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.EtchParameters.deserializeBinaryFromReader);
      msg.setEtchParameters(value);
      break;
    case 4:
      var value = new proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.deserializeBinaryFromReader);
      msg.setOperations(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEtchinggases();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.PlasmaStandardExamScore.Etchinggases.serializeBinaryToWriter
    );
  }
  f = message.getGasParameters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.PlasmaStandardExamScore.GasParameters.serializeBinaryToWriter
    );
  }
  f = message.getEtchParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.PlasmaStandardExamScore.EtchParameters.serializeBinaryToWriter
    );
  }
  f = message.getOperations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.Etchinggases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore.Etchinggases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore.Etchinggases;
  return proto.pbs.PlasmaStandardExamScore.Etchinggases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore.Etchinggases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.Etchinggases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore.Etchinggases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases} returns this
*/
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases} returns this
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases} returns this
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.Etchinggases} returns this
 */
proto.pbs.PlasmaStandardExamScore.Etchinggases.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.GasParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore.GasParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore.GasParameters;
  return proto.pbs.PlasmaStandardExamScore.GasParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore.GasParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.GasParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore.GasParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters} returns this
*/
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.GasParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.GasParameters.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.EtchParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore.EtchParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore.EtchParameters;
  return proto.pbs.PlasmaStandardExamScore.EtchParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore.EtchParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.EtchParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore.EtchParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters} returns this
*/
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.EtchParameters} returns this
 */
proto.pbs.PlasmaStandardExamScore.EtchParameters.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationScoreList: jspb.Message.toObjectList(msg.getOperationScoreList(),
    proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage;
  return proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.deserializeBinaryFromReader);
      msg.addOperationScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore;
  return proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_score = 1;
 * @return {!Array<!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore>}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.getOperationScoreList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore>} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} returns this
*/
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.setOperationScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.addOperationScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.clearOperationScoreList = function() {
  return this.setOperationScoreList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} returns this
 */
proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Etchinggases etchinggases = 1;
 * @return {?proto.pbs.PlasmaStandardExamScore.Etchinggases}
 */
proto.pbs.PlasmaStandardExamScore.prototype.getEtchinggases = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore.Etchinggases} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore.Etchinggases, 1));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore.Etchinggases|undefined} value
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
*/
proto.pbs.PlasmaStandardExamScore.prototype.setEtchinggases = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.prototype.clearEtchinggases = function() {
  return this.setEtchinggases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaStandardExamScore.prototype.hasEtchinggases = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GasParameters gas_parameters = 2;
 * @return {?proto.pbs.PlasmaStandardExamScore.GasParameters}
 */
proto.pbs.PlasmaStandardExamScore.prototype.getGasParameters = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore.GasParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore.GasParameters, 2));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore.GasParameters|undefined} value
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
*/
proto.pbs.PlasmaStandardExamScore.prototype.setGasParameters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.prototype.clearGasParameters = function() {
  return this.setGasParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaStandardExamScore.prototype.hasGasParameters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EtchParameters etch_parameters = 3;
 * @return {?proto.pbs.PlasmaStandardExamScore.EtchParameters}
 */
proto.pbs.PlasmaStandardExamScore.prototype.getEtchParameters = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore.EtchParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore.EtchParameters, 3));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore.EtchParameters|undefined} value
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
*/
proto.pbs.PlasmaStandardExamScore.prototype.setEtchParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.prototype.clearEtchParameters = function() {
  return this.setEtchParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaStandardExamScore.prototype.hasEtchParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PlasmaOperationStage operations = 4;
 * @return {?proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage}
 */
proto.pbs.PlasmaStandardExamScore.prototype.getOperations = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage, 4));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore.PlasmaOperationStage|undefined} value
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
*/
proto.pbs.PlasmaStandardExamScore.prototype.setOperations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.prototype.clearOperations = function() {
  return this.setOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaStandardExamScore.prototype.hasOperations = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 5;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.PlasmaStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 5));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
*/
proto.pbs.PlasmaStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaStandardExamScore} returns this
 */
proto.pbs.PlasmaStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaGenerateScore.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    etchinggases: (f = msg.getEtchinggases()) && proto.pbs.PlasmaGenerateScore.Etchinggases.toObject(includeInstance, f),
    gasParametersList: jspb.Message.toObjectList(msg.getGasParametersList(),
    proto.pbs.PlasmaGenerateScore.GasParameters.toObject, includeInstance),
    etchParameters: (f = msg.getEtchParameters()) && proto.pbs.PlasmaGenerateScore.EtchParameters.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaGenerateScore}
 */
proto.pbs.PlasmaGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaGenerateScore;
  return proto.pbs.PlasmaGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaGenerateScore}
 */
proto.pbs.PlasmaGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PlasmaGenerateScore.Etchinggases;
      reader.readMessage(value,proto.pbs.PlasmaGenerateScore.Etchinggases.deserializeBinaryFromReader);
      msg.setEtchinggases(value);
      break;
    case 2:
      var value = new proto.pbs.PlasmaGenerateScore.GasParameters;
      reader.readMessage(value,proto.pbs.PlasmaGenerateScore.GasParameters.deserializeBinaryFromReader);
      msg.addGasParameters(value);
      break;
    case 3:
      var value = new proto.pbs.PlasmaGenerateScore.EtchParameters;
      reader.readMessage(value,proto.pbs.PlasmaGenerateScore.EtchParameters.deserializeBinaryFromReader);
      msg.setEtchParameters(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEtchinggases();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.PlasmaGenerateScore.Etchinggases.serializeBinaryToWriter
    );
  }
  f = message.getGasParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.PlasmaGenerateScore.GasParameters.serializeBinaryToWriter
    );
  }
  f = message.getEtchParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.PlasmaGenerateScore.EtchParameters.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaGenerateScore.Etchinggases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaGenerateScore.Etchinggases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaGenerateScore.Etchinggases}
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaGenerateScore.Etchinggases;
  return proto.pbs.PlasmaGenerateScore.Etchinggases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaGenerateScore.Etchinggases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaGenerateScore.Etchinggases}
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaGenerateScore.Etchinggases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaGenerateScore.Etchinggases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.PlasmaGenerateScore.Etchinggases} returns this
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaGenerateScore.Etchinggases} returns this
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaGenerateScore.Etchinggases} returns this
 */
proto.pbs.PlasmaGenerateScore.Etchinggases.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaGenerateScore.GasParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaGenerateScore.GasParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.GasParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    pressureParameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    flowParameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    gasOption: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaGenerateScore.GasParameters;
  return proto.pbs.PlasmaGenerateScore.GasParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaGenerateScore.GasParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPressureParameter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFlowParameter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaGenerateScore.GasParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaGenerateScore.GasParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.GasParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPressureParameter();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFlowParameter();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getGasOption();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional float pressure_parameter = 1;
 * @return {number}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.getPressureParameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.setPressureParameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float flow_parameter = 2;
 * @return {number}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.getFlowParameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.setFlowParameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string gas_option = 3;
 * @return {string}
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.getGasOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.GasParameters.prototype.setGasOption = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaGenerateScore.EtchParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaGenerateScore.EtchParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeParameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    icpParameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rfParameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaGenerateScore.EtchParameters}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaGenerateScore.EtchParameters;
  return proto.pbs.PlasmaGenerateScore.EtchParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaGenerateScore.EtchParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaGenerateScore.EtchParameters}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeParameter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIcpParameter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRfParameter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaGenerateScore.EtchParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaGenerateScore.EtchParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeParameter();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getIcpParameter();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRfParameter();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float time_parameter = 1;
 * @return {number}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.getTimeParameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaGenerateScore.EtchParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.setTimeParameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float ICP_parameter = 2;
 * @return {number}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.getIcpParameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaGenerateScore.EtchParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.setIcpParameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float RF_parameter = 3;
 * @return {number}
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.getRfParameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaGenerateScore.EtchParameters} returns this
 */
proto.pbs.PlasmaGenerateScore.EtchParameters.prototype.setRfParameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Etchinggases etchinggases = 1;
 * @return {?proto.pbs.PlasmaGenerateScore.Etchinggases}
 */
proto.pbs.PlasmaGenerateScore.prototype.getEtchinggases = function() {
  return /** @type{?proto.pbs.PlasmaGenerateScore.Etchinggases} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaGenerateScore.Etchinggases, 1));
};


/**
 * @param {?proto.pbs.PlasmaGenerateScore.Etchinggases|undefined} value
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
*/
proto.pbs.PlasmaGenerateScore.prototype.setEtchinggases = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
 */
proto.pbs.PlasmaGenerateScore.prototype.clearEtchinggases = function() {
  return this.setEtchinggases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaGenerateScore.prototype.hasEtchinggases = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GasParameters gas_parameters = 2;
 * @return {!Array<!proto.pbs.PlasmaGenerateScore.GasParameters>}
 */
proto.pbs.PlasmaGenerateScore.prototype.getGasParametersList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaGenerateScore.GasParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaGenerateScore.GasParameters, 2));
};


/**
 * @param {!Array<!proto.pbs.PlasmaGenerateScore.GasParameters>} value
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
*/
proto.pbs.PlasmaGenerateScore.prototype.setGasParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.PlasmaGenerateScore.GasParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaGenerateScore.GasParameters}
 */
proto.pbs.PlasmaGenerateScore.prototype.addGasParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.PlasmaGenerateScore.GasParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
 */
proto.pbs.PlasmaGenerateScore.prototype.clearGasParametersList = function() {
  return this.setGasParametersList([]);
};


/**
 * optional EtchParameters etch_parameters = 3;
 * @return {?proto.pbs.PlasmaGenerateScore.EtchParameters}
 */
proto.pbs.PlasmaGenerateScore.prototype.getEtchParameters = function() {
  return /** @type{?proto.pbs.PlasmaGenerateScore.EtchParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaGenerateScore.EtchParameters, 3));
};


/**
 * @param {?proto.pbs.PlasmaGenerateScore.EtchParameters|undefined} value
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
*/
proto.pbs.PlasmaGenerateScore.prototype.setEtchParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
 */
proto.pbs.PlasmaGenerateScore.prototype.clearEtchParameters = function() {
  return this.setEtchParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaGenerateScore.prototype.hasEtchParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enable_single_score = 4;
 * @return {boolean}
 */
proto.pbs.PlasmaGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
 */
proto.pbs.PlasmaGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SimiQuestionRet simi_question = 5;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.PlasmaGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 5));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
*/
proto.pbs.PlasmaGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.PlasmaGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaGenerateScore} returns this
 */
proto.pbs.PlasmaGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ionImplantationProcedure: (f = msg.getIonImplantationProcedure()) && proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.toObject(includeInstance, f),
    ionBeamDebugging: (f = msg.getIonBeamDebugging()) && proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.toObject(includeInstance, f),
    operations: (f = msg.getOperations()) && proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.toObject(includeInstance, f),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore}
 */
proto.pbs.IonImplantationStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore;
  return proto.pbs.IonImplantationStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore}
 */
proto.pbs.IonImplantationStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.deserializeBinaryFromReader);
      msg.setIonImplantationProcedure(value);
      break;
    case 2:
      var value = new proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.deserializeBinaryFromReader);
      msg.setIonBeamDebugging(value);
      break;
    case 3:
      var value = new proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.deserializeBinaryFromReader);
      msg.setOperations(value);
      break;
    case 4:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIonImplantationProcedure();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.serializeBinaryToWriter
    );
  }
  f = message.getIonBeamDebugging();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.serializeBinaryToWriter
    );
  }
  f = message.getOperations();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
  return proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} returns this
*/
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.IonImplantationStandardExamScore.scoreInfoList.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.toObject = function(includeInstance, msg) {
  var f, obj = {
    element: (f = msg.getElement()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    energy: (f = msg.getEnergy()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    dosage: (f = msg.getDosage()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    gasPressure: (f = msg.getGasPressure()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    gasFlow: (f = msg.getGasFlow()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    beam: (f = msg.getBeam()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    alphaAxisAngle: (f = msg.getAlphaAxisAngle()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    betaAxisAngle: (f = msg.getBetaAxisAngle()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure;
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setElement(value);
      break;
    case 2:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setEnergy(value);
      break;
    case 4:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setDosage(value);
      break;
    case 5:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setGasPressure(value);
      break;
    case 6:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setGasFlow(value);
      break;
    case 7:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setBeam(value);
      break;
    case 8:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setAlphaAxisAngle(value);
      break;
    case 9:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setBetaAxisAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getEnergy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getDosage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getGasPressure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getGasFlow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getBeam();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getAlphaAxisAngle();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getBetaAxisAngle();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoreInfoList element = 1;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getElement = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setElement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearElement = function() {
  return this.setElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasElement = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional scoreInfoList time = 2;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getTime = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 2));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional scoreInfoList energy = 3;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getEnergy = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 3));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setEnergy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearEnergy = function() {
  return this.setEnergy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasEnergy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional scoreInfoList dosage = 4;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getDosage = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 4));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setDosage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearDosage = function() {
  return this.setDosage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasDosage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional scoreInfoList gas_pressure = 5;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getGasPressure = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 5));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setGasPressure = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearGasPressure = function() {
  return this.setGasPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasGasPressure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional scoreInfoList gas_flow = 6;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getGasFlow = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 6));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setGasFlow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearGasFlow = function() {
  return this.setGasFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasGasFlow = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional scoreInfoList beam = 7;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getBeam = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 7));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setBeam = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearBeam = function() {
  return this.setBeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasBeam = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional scoreInfoList alpha_axis_angle = 8;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getAlphaAxisAngle = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 8));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setAlphaAxisAngle = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearAlphaAxisAngle = function() {
  return this.setAlphaAxisAngle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasAlphaAxisAngle = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional scoreInfoList beta_axis_angle = 9;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.getBetaAxisAngle = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 9));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.setBetaAxisAngle = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.clearBetaAxisAngle = function() {
  return this.setBetaAxisAngle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure.prototype.hasBetaAxisAngle = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.toObject = function(includeInstance, msg) {
  var f, obj = {
    dischargeSpace: (f = msg.getDischargeSpace()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    magneticAnalyzer: (f = msg.getMagneticAnalyzer()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    accelerationVoltage: (f = msg.getAccelerationVoltage()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    scanVoltage: (f = msg.getScanVoltage()) && proto.pbs.IonImplantationStandardExamScore.scoreInfoList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging;
  return proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setDischargeSpace(value);
      break;
    case 2:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setMagneticAnalyzer(value);
      break;
    case 3:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setAccelerationVoltage(value);
      break;
    case 4:
      var value = new proto.pbs.IonImplantationStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setScanVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDischargeSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getMagneticAnalyzer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationVoltage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getScanVoltage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.IonImplantationStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoreInfoList discharge_space = 1;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.getDischargeSpace = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.setDischargeSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.clearDischargeSpace = function() {
  return this.setDischargeSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.hasDischargeSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional scoreInfoList magnetic_analyzer = 2;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.getMagneticAnalyzer = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 2));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.setMagneticAnalyzer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.clearMagneticAnalyzer = function() {
  return this.setMagneticAnalyzer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.hasMagneticAnalyzer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional scoreInfoList acceleration_voltage = 3;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.getAccelerationVoltage = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 3));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.setAccelerationVoltage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.clearAccelerationVoltage = function() {
  return this.setAccelerationVoltage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.hasAccelerationVoltage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional scoreInfoList scan_voltage = 4;
 * @return {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.getScanVoltage = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.scoreInfoList, 4));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.setScanVoltage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.clearScanVoltage = function() {
  return this.setScanVoltage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging.prototype.hasScanVoltage = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationScoreList: jspb.Message.toObjectList(msg.getOperationScoreList(),
    proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage;
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.deserializeBinaryFromReader);
      msg.addOperationScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore;
  return proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_score = 1;
 * @return {!Array<!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore>}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.getOperationScoreList = function() {
  return /** @type{!Array<!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore>} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} returns this
*/
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.setOperationScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.addOperationScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.clearOperationScoreList = function() {
  return this.setOperationScoreList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} returns this
 */
proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional IonImplantationProcedure ion_implantation_procedure = 1;
 * @return {?proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.getIonImplantationProcedure = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure, 1));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.IonImplantationProcedure|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
*/
proto.pbs.IonImplantationStandardExamScore.prototype.setIonImplantationProcedure = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.prototype.clearIonImplantationProcedure = function() {
  return this.setIonImplantationProcedure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.hasIonImplantationProcedure = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IonBeamDebugging ion_beam_debugging = 2;
 * @return {?proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.getIonBeamDebugging = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging, 2));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.IonBeamDebugging|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
*/
proto.pbs.IonImplantationStandardExamScore.prototype.setIonBeamDebugging = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.prototype.clearIonBeamDebugging = function() {
  return this.setIonBeamDebugging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.hasIonBeamDebugging = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IonImplantationOperationStage operations = 3;
 * @return {?proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.getOperations = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage, 3));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore.IonImplantationOperationStage|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
*/
proto.pbs.IonImplantationStandardExamScore.prototype.setOperations = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.prototype.clearOperations = function() {
  return this.setOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.hasOperations = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SimiQuestionModule simi_question_module = 4;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 4));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
*/
proto.pbs.IonImplantationStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationStandardExamScore} returns this
 */
proto.pbs.IonImplantationStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationGenerateScore.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ionImplantationProcedure: (f = msg.getIonImplantationProcedure()) && proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.toObject(includeInstance, f),
    ionBeamDebugging: (f = msg.getIonBeamDebugging()) && proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationGenerateScore}
 */
proto.pbs.IonImplantationGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationGenerateScore;
  return proto.pbs.IonImplantationGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationGenerateScore}
 */
proto.pbs.IonImplantationGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure;
      reader.readMessage(value,proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.deserializeBinaryFromReader);
      msg.setIonImplantationProcedure(value);
      break;
    case 2:
      var value = new proto.pbs.IonImplantationGenerateScore.IonBeamDebugging;
      reader.readMessage(value,proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.deserializeBinaryFromReader);
      msg.setIonBeamDebugging(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 4:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIonImplantationProcedure();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.serializeBinaryToWriter
    );
  }
  f = message.getIonBeamDebugging();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    energy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    dosage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    gasPressure: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    gasFlow: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    beam: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    alphaAxisAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    betaAxisAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure;
  return proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addElement(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEnergy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDosage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasPressure(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasFlow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBeam(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAlphaAxisAngle(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBetaAxisAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDosage();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getGasPressure();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getGasFlow();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getBeam();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAlphaAxisAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getBetaAxisAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * repeated string element = 1;
 * @return {!Array<string>}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getElementList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setElementList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.addElement = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.clearElementList = function() {
  return this.setElementList([]);
};


/**
 * optional float time = 2;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float energy = 3;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float dosage = 4;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getDosage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setDosage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float gas_pressure = 5;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getGasPressure = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setGasPressure = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float gas_flow = 6;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getGasFlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setGasFlow = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float beam = 7;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getBeam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setBeam = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float alpha_axis_angle = 8;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getAlphaAxisAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setAlphaAxisAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float beta_axis_angle = 9;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.getBetaAxisAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure.prototype.setBetaAxisAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.toObject = function(includeInstance, msg) {
  var f, obj = {
    cathodePower: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    electricArcPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    extractionVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    amu: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    accelerationVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    scanVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationGenerateScore.IonBeamDebugging;
  return proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCathodePower(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setElectricArcPower(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExtractionVoltage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmu(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccelerationVoltage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScanVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCathodePower();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getElectricArcPower();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getExtractionVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAmu();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAccelerationVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getScanVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional float cathode_power = 1;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getCathodePower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setCathodePower = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float electric_arc_power = 2;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getElectricArcPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setElectricArcPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float extraction_voltage = 3;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getExtractionVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setExtractionVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float amu = 4;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getAmu = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setAmu = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float acceleration_voltage = 5;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getAccelerationVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setAccelerationVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float scan_voltage = 6;
 * @return {number}
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.getScanVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationGenerateScore.IonBeamDebugging.prototype.setScanVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional IonImplantationProcedure ion_implantation_procedure = 1;
 * @return {?proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure}
 */
proto.pbs.IonImplantationGenerateScore.prototype.getIonImplantationProcedure = function() {
  return /** @type{?proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure, 1));
};


/**
 * @param {?proto.pbs.IonImplantationGenerateScore.IonImplantationProcedure|undefined} value
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
*/
proto.pbs.IonImplantationGenerateScore.prototype.setIonImplantationProcedure = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
 */
proto.pbs.IonImplantationGenerateScore.prototype.clearIonImplantationProcedure = function() {
  return this.setIonImplantationProcedure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationGenerateScore.prototype.hasIonImplantationProcedure = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IonBeamDebugging ion_beam_debugging = 2;
 * @return {?proto.pbs.IonImplantationGenerateScore.IonBeamDebugging}
 */
proto.pbs.IonImplantationGenerateScore.prototype.getIonBeamDebugging = function() {
  return /** @type{?proto.pbs.IonImplantationGenerateScore.IonBeamDebugging} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationGenerateScore.IonBeamDebugging, 2));
};


/**
 * @param {?proto.pbs.IonImplantationGenerateScore.IonBeamDebugging|undefined} value
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
*/
proto.pbs.IonImplantationGenerateScore.prototype.setIonBeamDebugging = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
 */
proto.pbs.IonImplantationGenerateScore.prototype.clearIonBeamDebugging = function() {
  return this.setIonBeamDebugging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationGenerateScore.prototype.hasIonBeamDebugging = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool enable_single_score = 3;
 * @return {boolean}
 */
proto.pbs.IonImplantationGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
 */
proto.pbs.IonImplantationGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated SimiQuestionRet simi_question = 4;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.IonImplantationGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 4));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
*/
proto.pbs.IonImplantationGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.IonImplantationGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationGenerateScore} returns this
 */
proto.pbs.IonImplantationGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    crucibleParameters: (f = msg.getCrucibleParameters()) && proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    depoProgram: (f = msg.getDepoProgram()) && proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    processProgram: (f = msg.getProcessProgram()) && proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    operatingParameter: (f = msg.getOperatingParameter()) && proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    operations: (f = msg.getOperations()) && proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamStandardExamScore}
 */
proto.pbs.ElectronBeamStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamStandardExamScore;
  return proto.pbs.ElectronBeamStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamStandardExamScore}
 */
proto.pbs.ElectronBeamStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ElectronBeamStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setCrucibleParameters(value);
      break;
    case 2:
      var value = new proto.pbs.ElectronBeamStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setDepoProgram(value);
      break;
    case 3:
      var value = new proto.pbs.ElectronBeamStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setProcessProgram(value);
      break;
    case 4:
      var value = new proto.pbs.ElectronBeamStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setOperatingParameter(value);
      break;
    case 5:
      var value = new proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.deserializeBinaryFromReader);
      msg.setOperations(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrucibleParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getDepoProgram();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getProcessProgram();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getOperatingParameter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getOperations();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamStandardExamScore.scoreInfoList;
  return proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.scoreInfoList.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationScoreList: jspb.Message.toObjectList(msg.getOperationScoreList(),
    proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage;
  return proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.deserializeBinaryFromReader);
      msg.addOperationScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore;
  return proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_score = 1;
 * @return {!Array<!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore>}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.getOperationScoreList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore>} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.setOperationScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.addOperationScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.clearOperationScoreList = function() {
  return this.setOperationScoreList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional scoreInfoList crucible_parameters = 1;
 * @return {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getCrucibleParameters = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setCrucibleParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearCrucibleParameters = function() {
  return this.setCrucibleParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasCrucibleParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional scoreInfoList depo_program = 2;
 * @return {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getDepoProgram = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.scoreInfoList, 2));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setDepoProgram = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearDepoProgram = function() {
  return this.setDepoProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasDepoProgram = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional scoreInfoList process_program = 3;
 * @return {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getProcessProgram = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.scoreInfoList, 3));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setProcessProgram = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearProcessProgram = function() {
  return this.setProcessProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasProcessProgram = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional scoreInfoList operating_parameter = 4;
 * @return {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getOperatingParameter = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.scoreInfoList, 4));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setOperatingParameter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearOperatingParameter = function() {
  return this.setOperatingParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasOperatingParameter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EleBeamOperationStage operations = 5;
 * @return {?proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getOperations = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage, 5));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore.EleBeamOperationStage|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setOperations = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearOperations = function() {
  return this.setOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasOperations = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool enable_single_score = 6;
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional SimiQuestionModule simi_question_module = 7;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 7));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
*/
proto.pbs.ElectronBeamStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamStandardExamScore} returns this
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamGenerateScore.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    crucibleParameters: (f = msg.getCrucibleParameters()) && proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.toObject(includeInstance, f),
    depoProgramList: jspb.Message.toObjectList(msg.getDepoProgramList(),
    proto.pbs.ElectronBeamGenerateScore.DepoProgram.toObject, includeInstance),
    operatingParameter: (f = msg.getOperatingParameter()) && proto.pbs.ElectronBeamGenerateScore.OperatingParameter.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore}
 */
proto.pbs.ElectronBeamGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore;
  return proto.pbs.ElectronBeamGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore}
 */
proto.pbs.ElectronBeamGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ElectronBeamGenerateScore.CrucibleParameters;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.deserializeBinaryFromReader);
      msg.setCrucibleParameters(value);
      break;
    case 2:
      var value = new proto.pbs.ElectronBeamGenerateScore.DepoProgram;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.DepoProgram.deserializeBinaryFromReader);
      msg.addDepoProgram(value);
      break;
    case 3:
      var value = new proto.pbs.ElectronBeamGenerateScore.OperatingParameter;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.OperatingParameter.deserializeBinaryFromReader);
      msg.setOperatingParameter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 5:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrucibleParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.serializeBinaryToWriter
    );
  }
  f = message.getDepoProgramList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.ElectronBeamGenerateScore.DepoProgram.serializeBinaryToWriter
    );
  }
  f = message.getOperatingParameter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ElectronBeamGenerateScore.OperatingParameter.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.EBElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.EBElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.EBElement}
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.EBElement;
  return proto.pbs.ElectronBeamGenerateScore.EBElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.EBElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.EBElement}
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.EBElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.EBElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.EBElement} returns this
 */
proto.pbs.ElectronBeamGenerateScore.EBElement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    crucibleNumList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.CrucibleParameters;
  return proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCrucibleNum(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrucibleNumList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * repeated int32 crucible_num = 2;
 * @return {!Array<number>}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.getCrucibleNumList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.setCrucibleNumList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.addCrucibleNum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleParameters.prototype.clearCrucibleNumList = function() {
  return this.setCrucibleNumList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    crucibleNo: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.CrucibleSetting;
  return proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCrucibleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrucibleNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 crucible_no = 1;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.prototype.getCrucibleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.CrucibleSetting} returns this
 */
proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.prototype.setCrucibleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.DepoProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    material: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preEvaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    evaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    thickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    revisedValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    crucibleSetting: (f = msg.getCrucibleSetting()) && proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.DepoProgram;
  return proto.pbs.ElectronBeamGenerateScore.DepoProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPreEvaporationRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEvaporationRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThickness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRevisedValue(value);
      break;
    case 6:
      var value = new proto.pbs.ElectronBeamGenerateScore.CrucibleSetting;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.deserializeBinaryFromReader);
      msg.setCrucibleSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.DepoProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getRevisedValue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCrucibleSetting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.ElectronBeamGenerateScore.CrucibleSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string material = 1;
 * @return {string}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float pre_evaporation_rate = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getPreEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setPreEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float evaporation_rate = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float thickness = 4;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float revised_value = 5;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getRevisedValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setRevisedValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional CrucibleSetting crucible_setting = 6;
 * @return {?proto.pbs.ElectronBeamGenerateScore.CrucibleSetting}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.getCrucibleSetting = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore.CrucibleSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamGenerateScore.CrucibleSetting, 6));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore.CrucibleSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
*/
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.setCrucibleSetting = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.clearCrucibleSetting = function() {
  return this.setCrucibleSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamGenerateScore.DepoProgram.prototype.hasCrucibleSetting = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.ProgramDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    material: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preEvaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    evaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    thickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    revisedValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    crucibleNo: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.ProgramDetail;
  return proto.pbs.ElectronBeamGenerateScore.ProgramDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPreEvaporationRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEvaporationRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThickness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRevisedValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCrucibleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.ProgramDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getRevisedValue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCrucibleNo();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string material = 1;
 * @return {string}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float pre_evaporation_rate = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getPreEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setPreEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float evaporation_rate = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float thickness = 4;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float revised_value = 5;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getRevisedValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setRevisedValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 crucible_no = 6;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.getCrucibleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProgramDetail} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProgramDetail.prototype.setCrucibleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.ProcessProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    processNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programDetail: (f = msg.getProgramDetail()) && proto.pbs.ElectronBeamGenerateScore.ProgramDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.ProcessProgram;
  return proto.pbs.ElectronBeamGenerateScore.ProcessProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcessNo(value);
      break;
    case 2:
      var value = new proto.pbs.ElectronBeamGenerateScore.ProgramDetail;
      reader.readMessage(value,proto.pbs.ElectronBeamGenerateScore.ProgramDetail.deserializeBinaryFromReader);
      msg.setProgramDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.ProcessProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProgramDetail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ElectronBeamGenerateScore.ProgramDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 process_no = 1;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.getProcessNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.setProcessNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ProgramDetail program_detail = 2;
 * @return {?proto.pbs.ElectronBeamGenerateScore.ProgramDetail}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.getProgramDetail = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore.ProgramDetail} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamGenerateScore.ProgramDetail, 2));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore.ProgramDetail|undefined} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} returns this
*/
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.setProgramDetail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamGenerateScore.ProcessProgram} returns this
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.clearProgramDetail = function() {
  return this.setProgramDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamGenerateScore.ProcessProgram.prototype.hasProgramDetail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamGenerateScore.OperatingParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ebeamSwitch: jspb.Message.getFieldWithDefault(msg, 1, 0),
    switchScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    holderSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamGenerateScore.OperatingParameter;
  return proto.pbs.ElectronBeamGenerateScore.OperatingParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.EbeamSwitchStatusType} */ (reader.readEnum());
      msg.setEbeamSwitch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwitchScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHolderSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamGenerateScore.OperatingParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEbeamSwitch();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSwitchScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getHolderSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional EbeamSwitchStatusType ebeam_switch = 1;
 * @return {!proto.pbs.EbeamSwitchStatusType}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.getEbeamSwitch = function() {
  return /** @type {!proto.pbs.EbeamSwitchStatusType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.EbeamSwitchStatusType} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.setEbeamSwitch = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double switch_score = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.getSwitchScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.setSwitchScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float holder_speed = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.getHolderSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamGenerateScore.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamGenerateScore.OperatingParameter.prototype.setHolderSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional CrucibleParameters crucible_parameters = 1;
 * @return {?proto.pbs.ElectronBeamGenerateScore.CrucibleParameters}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.getCrucibleParameters = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore.CrucibleParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamGenerateScore.CrucibleParameters, 1));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore.CrucibleParameters|undefined} value
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
*/
proto.pbs.ElectronBeamGenerateScore.prototype.setCrucibleParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
 */
proto.pbs.ElectronBeamGenerateScore.prototype.clearCrucibleParameters = function() {
  return this.setCrucibleParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.hasCrucibleParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DepoProgram depo_program = 2;
 * @return {!Array<!proto.pbs.ElectronBeamGenerateScore.DepoProgram>}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.getDepoProgramList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamGenerateScore.DepoProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamGenerateScore.DepoProgram, 2));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamGenerateScore.DepoProgram>} value
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
*/
proto.pbs.ElectronBeamGenerateScore.prototype.setDepoProgramList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.ElectronBeamGenerateScore.DepoProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamGenerateScore.DepoProgram}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.addDepoProgram = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.ElectronBeamGenerateScore.DepoProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
 */
proto.pbs.ElectronBeamGenerateScore.prototype.clearDepoProgramList = function() {
  return this.setDepoProgramList([]);
};


/**
 * optional OperatingParameter operating_parameter = 3;
 * @return {?proto.pbs.ElectronBeamGenerateScore.OperatingParameter}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.getOperatingParameter = function() {
  return /** @type{?proto.pbs.ElectronBeamGenerateScore.OperatingParameter} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamGenerateScore.OperatingParameter, 3));
};


/**
 * @param {?proto.pbs.ElectronBeamGenerateScore.OperatingParameter|undefined} value
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
*/
proto.pbs.ElectronBeamGenerateScore.prototype.setOperatingParameter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
 */
proto.pbs.ElectronBeamGenerateScore.prototype.clearOperatingParameter = function() {
  return this.setOperatingParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.hasOperatingParameter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enable_single_score = 4;
 * @return {boolean}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
 */
proto.pbs.ElectronBeamGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SimiQuestionRet simi_question = 5;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 5));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
*/
proto.pbs.ElectronBeamGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.ElectronBeamGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamGenerateScore} returns this
 */
proto.pbs.ElectronBeamGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    mask: (f = msg.getMask()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    exposureAreaA: (f = msg.getExposureAreaA()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    exposureAreaB: (f = msg.getExposureAreaB()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    exposureParams: (f = msg.getExposureParams()) && proto.pbs.StepperStandardExamScore.ExposureParams.toObject(includeInstance, f),
    operations: (f = msg.getOperations()) && proto.pbs.StepperStandardExamScore.StepperOperationStage.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    simiQuestionModule: (f = msg.getSimiQuestionModule()) && proto.pbs.SimiQuestionModule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperStandardExamScore}
 */
proto.pbs.StepperStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperStandardExamScore;
  return proto.pbs.StepperStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperStandardExamScore}
 */
proto.pbs.StepperStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 2:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setExposureAreaA(value);
      break;
    case 3:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setExposureAreaB(value);
      break;
    case 4:
      var value = new proto.pbs.StepperStandardExamScore.ExposureParams;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.ExposureParams.deserializeBinaryFromReader);
      msg.setExposureParams(value);
      break;
    case 5:
      var value = new proto.pbs.StepperStandardExamScore.StepperOperationStage;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.StepperOperationStage.deserializeBinaryFromReader);
      msg.setOperations(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestionModule;
      reader.readMessage(value,proto.pbs.SimiQuestionModule.deserializeBinaryFromReader);
      msg.setSimiQuestionModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getExposureAreaA();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getExposureAreaB();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getExposureParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.StepperStandardExamScore.ExposureParams.serializeBinaryToWriter
    );
  }
  f = message.getOperations();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.StepperStandardExamScore.StepperOperationStage.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSimiQuestionModule();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.SimiQuestionModule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperStandardExamScore.scoreInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperStandardExamScore.scoreInfoList;
  return proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperStandardExamScore.scoreInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperStandardExamScore.scoreInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList} returns this
*/
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.StepperStandardExamScore.scoreInfoList.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperStandardExamScore.ExposureParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperStandardExamScore.ExposureParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.ExposureParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    focalPlane: (f = msg.getFocalPlane()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    stepping: (f = msg.getStepping()) && proto.pbs.StepperStandardExamScore.scoreInfoList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperStandardExamScore.ExposureParams;
  return proto.pbs.StepperStandardExamScore.ExposureParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperStandardExamScore.ExposureParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setFocalPlane(value);
      break;
    case 3:
      var value = new proto.pbs.StepperStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setStepping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperStandardExamScore.ExposureParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperStandardExamScore.ExposureParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.ExposureParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getFocalPlane();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getStepping();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.StepperStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoreInfoList time = 1;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.getTime = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
*/
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional scoreInfoList focal_plane = 2;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.getFocalPlane = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 2));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
*/
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.setFocalPlane = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.clearFocalPlane = function() {
  return this.setFocalPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.hasFocalPlane = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional scoreInfoList stepping = 3;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.getStepping = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 3));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
*/
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.setStepping = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore.ExposureParams} returns this
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.clearStepping = function() {
  return this.setStepping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.ExposureParams.prototype.hasStepping = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperStandardExamScore.StepperOperationStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationScoreList: jspb.Message.toObjectList(msg.getOperationScoreList(),
    proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperStandardExamScore.StepperOperationStage;
  return proto.pbs.StepperStandardExamScore.StepperOperationStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.deserializeBinaryFromReader);
      msg.addOperationScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperStandardExamScore.StepperOperationStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 4, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore;
  return proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation_stage = 2;
 * @return {string}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pre_order_act_dep = 3;
 * @return {string}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post_order_act_dep = 4;
 * @return {string}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated operationScore operation_score = 1;
 * @return {!Array<!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore>}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.getOperationScoreList = function() {
  return /** @type{!Array<!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore, 1));
};


/**
 * @param {!Array<!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore>} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage} returns this
*/
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.setOperationScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.addOperationScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.StepperStandardExamScore.StepperOperationStage.operationScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.clearOperationScoreList = function() {
  return this.setOperationScoreList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperStandardExamScore.StepperOperationStage} returns this
 */
proto.pbs.StepperStandardExamScore.StepperOperationStage.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional scoreInfoList mask = 1;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.prototype.getMask = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasMask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional scoreInfoList exposure_area_A = 2;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.prototype.getExposureAreaA = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 2));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setExposureAreaA = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearExposureAreaA = function() {
  return this.setExposureAreaA(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasExposureAreaA = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional scoreInfoList exposure_area_B = 3;
 * @return {?proto.pbs.StepperStandardExamScore.scoreInfoList}
 */
proto.pbs.StepperStandardExamScore.prototype.getExposureAreaB = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.scoreInfoList, 3));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setExposureAreaB = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearExposureAreaB = function() {
  return this.setExposureAreaB(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasExposureAreaB = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExposureParams exposure_params = 4;
 * @return {?proto.pbs.StepperStandardExamScore.ExposureParams}
 */
proto.pbs.StepperStandardExamScore.prototype.getExposureParams = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.ExposureParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.ExposureParams, 4));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.ExposureParams|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setExposureParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearExposureParams = function() {
  return this.setExposureParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasExposureParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional StepperOperationStage operations = 5;
 * @return {?proto.pbs.StepperStandardExamScore.StepperOperationStage}
 */
proto.pbs.StepperStandardExamScore.prototype.getOperations = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore.StepperOperationStage} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore.StepperOperationStage, 5));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore.StepperOperationStage|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setOperations = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearOperations = function() {
  return this.setOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasOperations = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool enable_single_score = 6;
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional SimiQuestionModule simi_question_module = 7;
 * @return {?proto.pbs.SimiQuestionModule}
 */
proto.pbs.StepperStandardExamScore.prototype.getSimiQuestionModule = function() {
  return /** @type{?proto.pbs.SimiQuestionModule} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiQuestionModule, 7));
};


/**
 * @param {?proto.pbs.SimiQuestionModule|undefined} value
 * @return {!proto.pbs.StepperStandardExamScore} returns this
*/
proto.pbs.StepperStandardExamScore.prototype.setSimiQuestionModule = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperStandardExamScore} returns this
 */
proto.pbs.StepperStandardExamScore.prototype.clearSimiQuestionModule = function() {
  return this.setSimiQuestionModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperStandardExamScore.prototype.hasSimiQuestionModule = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.StepperGenerateScore.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    maskInstall: (f = msg.getMaskInstall()) && proto.pbs.StepperGenerateScore.Mask.toObject(includeInstance, f),
    maskParams: (f = msg.getMaskParams()) && proto.pbs.StepperGenerateScore.Mask.toObject(includeInstance, f),
    exposureArea: (f = msg.getExposureArea()) && proto.pbs.StepperGenerateScore.ExposureArea.toObject(includeInstance, f),
    exposureParams: (f = msg.getExposureParams()) && proto.pbs.StepperGenerateScore.ExposureParams.toObject(includeInstance, f),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    simiQuestionList: jspb.Message.toObjectList(msg.getSimiQuestionList(),
    proto.pbs.SimiQuestionRet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperGenerateScore}
 */
proto.pbs.StepperGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperGenerateScore;
  return proto.pbs.StepperGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperGenerateScore}
 */
proto.pbs.StepperGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.StepperGenerateScore.Mask;
      reader.readMessage(value,proto.pbs.StepperGenerateScore.Mask.deserializeBinaryFromReader);
      msg.setMaskInstall(value);
      break;
    case 2:
      var value = new proto.pbs.StepperGenerateScore.Mask;
      reader.readMessage(value,proto.pbs.StepperGenerateScore.Mask.deserializeBinaryFromReader);
      msg.setMaskParams(value);
      break;
    case 3:
      var value = new proto.pbs.StepperGenerateScore.ExposureArea;
      reader.readMessage(value,proto.pbs.StepperGenerateScore.ExposureArea.deserializeBinaryFromReader);
      msg.setExposureArea(value);
      break;
    case 4:
      var value = new proto.pbs.StepperGenerateScore.ExposureParams;
      reader.readMessage(value,proto.pbs.StepperGenerateScore.ExposureParams.deserializeBinaryFromReader);
      msg.setExposureParams(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 6:
      var value = new proto.pbs.SimiQuestionRet;
      reader.readMessage(value,proto.pbs.SimiQuestionRet.deserializeBinaryFromReader);
      msg.addSimiQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaskInstall();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.StepperGenerateScore.Mask.serializeBinaryToWriter
    );
  }
  f = message.getMaskParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.StepperGenerateScore.Mask.serializeBinaryToWriter
    );
  }
  f = message.getExposureArea();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.StepperGenerateScore.ExposureArea.serializeBinaryToWriter
    );
  }
  f = message.getExposureParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.StepperGenerateScore.ExposureParams.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSimiQuestionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.SimiQuestionRet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperGenerateScore.Mask.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperGenerateScore.Mask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperGenerateScore.Mask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.Mask.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperGenerateScore.Mask}
 */
proto.pbs.StepperGenerateScore.Mask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperGenerateScore.Mask;
  return proto.pbs.StepperGenerateScore.Mask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperGenerateScore.Mask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperGenerateScore.Mask}
 */
proto.pbs.StepperGenerateScore.Mask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperGenerateScore.Mask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperGenerateScore.Mask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperGenerateScore.Mask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.Mask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.Mask.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.Mask} returns this
 */
proto.pbs.StepperGenerateScore.Mask.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperGenerateScore.ExposureArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperGenerateScore.ExposureArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.ExposureArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaAx: jspb.Message.getFieldWithDefault(msg, 1, 0),
    areaAy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    areaBx: jspb.Message.getFieldWithDefault(msg, 3, 0),
    areaBy: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea}
 */
proto.pbs.StepperGenerateScore.ExposureArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperGenerateScore.ExposureArea;
  return proto.pbs.StepperGenerateScore.ExposureArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperGenerateScore.ExposureArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea}
 */
proto.pbs.StepperGenerateScore.ExposureArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaAx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaAy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaBx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperGenerateScore.ExposureArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperGenerateScore.ExposureArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.ExposureArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaAx();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAreaAy();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAreaBx();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAreaBy();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 area_AX = 1;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.getAreaAx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea} returns this
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.setAreaAx = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 area_AY = 2;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.getAreaAy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea} returns this
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.setAreaAy = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 area_BX = 3;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.getAreaBx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea} returns this
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.setAreaBx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 area_BY = 4;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.getAreaBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureArea} returns this
 */
proto.pbs.StepperGenerateScore.ExposureArea.prototype.setAreaBy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperGenerateScore.ExposureParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperGenerateScore.ExposureParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.ExposureParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    focalPlane: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stepping: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperGenerateScore.ExposureParams}
 */
proto.pbs.StepperGenerateScore.ExposureParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperGenerateScore.ExposureParams;
  return proto.pbs.StepperGenerateScore.ExposureParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperGenerateScore.ExposureParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperGenerateScore.ExposureParams}
 */
proto.pbs.StepperGenerateScore.ExposureParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocalPlane(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStepping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperGenerateScore.ExposureParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperGenerateScore.ExposureParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperGenerateScore.ExposureParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFocalPlane();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getStepping();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float time = 1;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureParams} returns this
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float focal_plane = 2;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.getFocalPlane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureParams} returns this
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.setFocalPlane = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float stepping = 3;
 * @return {number}
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.getStepping = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperGenerateScore.ExposureParams} returns this
 */
proto.pbs.StepperGenerateScore.ExposureParams.prototype.setStepping = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Mask mask_install = 1;
 * @return {?proto.pbs.StepperGenerateScore.Mask}
 */
proto.pbs.StepperGenerateScore.prototype.getMaskInstall = function() {
  return /** @type{?proto.pbs.StepperGenerateScore.Mask} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperGenerateScore.Mask, 1));
};


/**
 * @param {?proto.pbs.StepperGenerateScore.Mask|undefined} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
*/
proto.pbs.StepperGenerateScore.prototype.setMaskInstall = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.clearMaskInstall = function() {
  return this.setMaskInstall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperGenerateScore.prototype.hasMaskInstall = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Mask mask_params = 2;
 * @return {?proto.pbs.StepperGenerateScore.Mask}
 */
proto.pbs.StepperGenerateScore.prototype.getMaskParams = function() {
  return /** @type{?proto.pbs.StepperGenerateScore.Mask} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperGenerateScore.Mask, 2));
};


/**
 * @param {?proto.pbs.StepperGenerateScore.Mask|undefined} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
*/
proto.pbs.StepperGenerateScore.prototype.setMaskParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.clearMaskParams = function() {
  return this.setMaskParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperGenerateScore.prototype.hasMaskParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExposureArea exposure_area = 3;
 * @return {?proto.pbs.StepperGenerateScore.ExposureArea}
 */
proto.pbs.StepperGenerateScore.prototype.getExposureArea = function() {
  return /** @type{?proto.pbs.StepperGenerateScore.ExposureArea} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperGenerateScore.ExposureArea, 3));
};


/**
 * @param {?proto.pbs.StepperGenerateScore.ExposureArea|undefined} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
*/
proto.pbs.StepperGenerateScore.prototype.setExposureArea = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.clearExposureArea = function() {
  return this.setExposureArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperGenerateScore.prototype.hasExposureArea = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExposureParams exposure_params = 4;
 * @return {?proto.pbs.StepperGenerateScore.ExposureParams}
 */
proto.pbs.StepperGenerateScore.prototype.getExposureParams = function() {
  return /** @type{?proto.pbs.StepperGenerateScore.ExposureParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperGenerateScore.ExposureParams, 4));
};


/**
 * @param {?proto.pbs.StepperGenerateScore.ExposureParams|undefined} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
*/
proto.pbs.StepperGenerateScore.prototype.setExposureParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.clearExposureParams = function() {
  return this.setExposureParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperGenerateScore.prototype.hasExposureParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool enable_single_score = 5;
 * @return {boolean}
 */
proto.pbs.StepperGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated SimiQuestionRet simi_question = 6;
 * @return {!Array<!proto.pbs.SimiQuestionRet>}
 */
proto.pbs.StepperGenerateScore.prototype.getSimiQuestionList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestionRet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestionRet, 6));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestionRet>} value
 * @return {!proto.pbs.StepperGenerateScore} returns this
*/
proto.pbs.StepperGenerateScore.prototype.setSimiQuestionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.SimiQuestionRet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestionRet}
 */
proto.pbs.StepperGenerateScore.prototype.addSimiQuestion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.SimiQuestionRet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperGenerateScore} returns this
 */
proto.pbs.StepperGenerateScore.prototype.clearSimiQuestionList = function() {
  return this.setSimiQuestionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiProcessStandardExamScore.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandardExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandardExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    processsort: (f = msg.getProcesssort()) && proto.pbs.SimiProcessStandardExamScore.scoreInfoList.toObject(includeInstance, f),
    simiProcessStepList: jspb.Message.toObjectList(msg.getSimiProcessStepList(),
    proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandardExamScore;
  return proto.pbs.SimiProcessStandardExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandardExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SimiProcessStandardExamScore.scoreInfoList;
      reader.readMessage(value,proto.pbs.SimiProcessStandardExamScore.scoreInfoList.deserializeBinaryFromReader);
      msg.setProcesssort(value);
      break;
    case 2:
      var value = new proto.pbs.SimiProcessStandardExamScore.SimiProcessStep;
      reader.readMessage(value,proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.deserializeBinaryFromReader);
      msg.addSimiProcessStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandardExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandardExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcesssort();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.SimiProcessStandardExamScore.scoreInfoList.serializeBinaryToWriter
    );
  }
  f = message.getSimiProcessStepList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandardExamScore.scoreInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoreInfoList: jspb.Message.toObjectList(msg.getScoreInfoList(),
    proto.pbs.ScoreInfo.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    icDevice: (f = msg.getIcDevice()) && ic_device_pb.ICDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandardExamScore.scoreInfoList;
  return proto.pbs.SimiProcessStandardExamScore.scoreInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreInfo;
      reader.readMessage(value,proto.pbs.ScoreInfo.deserializeBinaryFromReader);
      msg.addScoreInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    case 4:
      var value = new ic_device_pb.ICDevice;
      reader.readMessage(value,ic_device_pb.ICDevice.deserializeBinaryFromReader);
      msg.setIcDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandardExamScore.scoreInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoreInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIcDevice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ic_device_pb.ICDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScoreInfo score_info = 1;
 * @return {!Array<!proto.pbs.ScoreInfo>}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.getScoreInfoList = function() {
  return /** @type{!Array<!proto.pbs.ScoreInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreInfo, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreInfo>} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
*/
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.setScoreInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreInfo}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.addScoreInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.clearScoreInfoList = function() {
  return this.setScoreInfoList([]);
};


/**
 * optional double total_score = 2;
 * @return {number}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_score_refer = 3;
 * @return {number}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional ICDevice ic_device = 4;
 * @return {?proto.pbs.ICDevice}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.getIcDevice = function() {
  return /** @type{?proto.pbs.ICDevice} */ (
    jspb.Message.getWrapperField(this, ic_device_pb.ICDevice, 4));
};


/**
 * @param {?proto.pbs.ICDevice|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
*/
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.setIcDevice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.scoreInfoList} returns this
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.clearIcDevice = function() {
  return this.setIcDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.scoreInfoList.prototype.hasIcDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandardExamScore.SimiExamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ma6StandardExamScore: (f = msg.getMa6StandardExamScore()) && proto.pbs.MA6StandardExamScore.toObject(includeInstance, f),
    lab18StandardExamScore: (f = msg.getLab18StandardExamScore()) && proto.pbs.LAB18StandardExamScore.toObject(includeInstance, f),
    spinCoaterStandardExamScore: (f = msg.getSpinCoaterStandardExamScore()) && proto.pbs.SpinCoaterStandardExamScore.toObject(includeInstance, f),
    cleaningStandardExamScore: (f = msg.getCleaningStandardExamScore()) && proto.pbs.CleaningStandardExamScore.toObject(includeInstance, f),
    developmentStandardExamScore: (f = msg.getDevelopmentStandardExamScore()) && proto.pbs.DevelopmentStandardExamScore.toObject(includeInstance, f),
    plasmaStandardExamScore: (f = msg.getPlasmaStandardExamScore()) && proto.pbs.PlasmaStandardExamScore.toObject(includeInstance, f),
    ionImplantationStandardExamScore: (f = msg.getIonImplantationStandardExamScore()) && proto.pbs.IonImplantationStandardExamScore.toObject(includeInstance, f),
    electronBeamStandardExamScore: (f = msg.getElectronBeamStandardExamScore()) && proto.pbs.ElectronBeamStandardExamScore.toObject(includeInstance, f),
    stepperStandardExamScore: (f = msg.getStepperStandardExamScore()) && proto.pbs.StepperStandardExamScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandardExamScore.SimiExamScore;
  return proto.pbs.SimiProcessStandardExamScore.SimiExamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MA6StandardExamScore;
      reader.readMessage(value,proto.pbs.MA6StandardExamScore.deserializeBinaryFromReader);
      msg.setMa6StandardExamScore(value);
      break;
    case 2:
      var value = new proto.pbs.LAB18StandardExamScore;
      reader.readMessage(value,proto.pbs.LAB18StandardExamScore.deserializeBinaryFromReader);
      msg.setLab18StandardExamScore(value);
      break;
    case 3:
      var value = new proto.pbs.SpinCoaterStandardExamScore;
      reader.readMessage(value,proto.pbs.SpinCoaterStandardExamScore.deserializeBinaryFromReader);
      msg.setSpinCoaterStandardExamScore(value);
      break;
    case 4:
      var value = new proto.pbs.CleaningStandardExamScore;
      reader.readMessage(value,proto.pbs.CleaningStandardExamScore.deserializeBinaryFromReader);
      msg.setCleaningStandardExamScore(value);
      break;
    case 5:
      var value = new proto.pbs.DevelopmentStandardExamScore;
      reader.readMessage(value,proto.pbs.DevelopmentStandardExamScore.deserializeBinaryFromReader);
      msg.setDevelopmentStandardExamScore(value);
      break;
    case 6:
      var value = new proto.pbs.PlasmaStandardExamScore;
      reader.readMessage(value,proto.pbs.PlasmaStandardExamScore.deserializeBinaryFromReader);
      msg.setPlasmaStandardExamScore(value);
      break;
    case 7:
      var value = new proto.pbs.IonImplantationStandardExamScore;
      reader.readMessage(value,proto.pbs.IonImplantationStandardExamScore.deserializeBinaryFromReader);
      msg.setIonImplantationStandardExamScore(value);
      break;
    case 8:
      var value = new proto.pbs.ElectronBeamStandardExamScore;
      reader.readMessage(value,proto.pbs.ElectronBeamStandardExamScore.deserializeBinaryFromReader);
      msg.setElectronBeamStandardExamScore(value);
      break;
    case 9:
      var value = new proto.pbs.StepperStandardExamScore;
      reader.readMessage(value,proto.pbs.StepperStandardExamScore.deserializeBinaryFromReader);
      msg.setStepperStandardExamScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandardExamScore.SimiExamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMa6StandardExamScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MA6StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getLab18StandardExamScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.LAB18StandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getSpinCoaterStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.SpinCoaterStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getCleaningStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.CleaningStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.DevelopmentStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.PlasmaStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.IonImplantationStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.ElectronBeamStandardExamScore.serializeBinaryToWriter
    );
  }
  f = message.getStepperStandardExamScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.StepperStandardExamScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional MA6StandardExamScore ma6_standard_exam_score = 1;
 * @return {?proto.pbs.MA6StandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getMa6StandardExamScore = function() {
  return /** @type{?proto.pbs.MA6StandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6StandardExamScore, 1));
};


/**
 * @param {?proto.pbs.MA6StandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setMa6StandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearMa6StandardExamScore = function() {
  return this.setMa6StandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasMa6StandardExamScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LAB18StandardExamScore lab18_standard_exam_score = 2;
 * @return {?proto.pbs.LAB18StandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getLab18StandardExamScore = function() {
  return /** @type{?proto.pbs.LAB18StandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18StandardExamScore, 2));
};


/**
 * @param {?proto.pbs.LAB18StandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setLab18StandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearLab18StandardExamScore = function() {
  return this.setLab18StandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasLab18StandardExamScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SpinCoaterStandardExamScore spin_coater_standard_exam_score = 3;
 * @return {?proto.pbs.SpinCoaterStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getSpinCoaterStandardExamScore = function() {
  return /** @type{?proto.pbs.SpinCoaterStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterStandardExamScore, 3));
};


/**
 * @param {?proto.pbs.SpinCoaterStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setSpinCoaterStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearSpinCoaterStandardExamScore = function() {
  return this.setSpinCoaterStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasSpinCoaterStandardExamScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CleaningStandardExamScore cleaning_standard_exam_score = 4;
 * @return {?proto.pbs.CleaningStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getCleaningStandardExamScore = function() {
  return /** @type{?proto.pbs.CleaningStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningStandardExamScore, 4));
};


/**
 * @param {?proto.pbs.CleaningStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setCleaningStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearCleaningStandardExamScore = function() {
  return this.setCleaningStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasCleaningStandardExamScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DevelopmentStandardExamScore development_standard_exam_score = 5;
 * @return {?proto.pbs.DevelopmentStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getDevelopmentStandardExamScore = function() {
  return /** @type{?proto.pbs.DevelopmentStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentStandardExamScore, 5));
};


/**
 * @param {?proto.pbs.DevelopmentStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setDevelopmentStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearDevelopmentStandardExamScore = function() {
  return this.setDevelopmentStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasDevelopmentStandardExamScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PlasmaStandardExamScore plasma_standard_exam_score = 6;
 * @return {?proto.pbs.PlasmaStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getPlasmaStandardExamScore = function() {
  return /** @type{?proto.pbs.PlasmaStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaStandardExamScore, 6));
};


/**
 * @param {?proto.pbs.PlasmaStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setPlasmaStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearPlasmaStandardExamScore = function() {
  return this.setPlasmaStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasPlasmaStandardExamScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IonImplantationStandardExamScore ion_implantation_standard_exam_score = 7;
 * @return {?proto.pbs.IonImplantationStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getIonImplantationStandardExamScore = function() {
  return /** @type{?proto.pbs.IonImplantationStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationStandardExamScore, 7));
};


/**
 * @param {?proto.pbs.IonImplantationStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setIonImplantationStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearIonImplantationStandardExamScore = function() {
  return this.setIonImplantationStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasIonImplantationStandardExamScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ElectronBeamStandardExamScore electron_beam_standard_exam_score = 8;
 * @return {?proto.pbs.ElectronBeamStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getElectronBeamStandardExamScore = function() {
  return /** @type{?proto.pbs.ElectronBeamStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamStandardExamScore, 8));
};


/**
 * @param {?proto.pbs.ElectronBeamStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setElectronBeamStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearElectronBeamStandardExamScore = function() {
  return this.setElectronBeamStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasElectronBeamStandardExamScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional StepperStandardExamScore stepper_standard_exam_score = 9;
 * @return {?proto.pbs.StepperStandardExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.getStepperStandardExamScore = function() {
  return /** @type{?proto.pbs.StepperStandardExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperStandardExamScore, 9));
};


/**
 * @param {?proto.pbs.StepperStandardExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.setStepperStandardExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.clearStepperStandardExamScore = function() {
  return this.setStepperStandardExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiExamScore.prototype.hasStepperStandardExamScore = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icDevice: (f = msg.getIcDevice()) && ic_device_pb.ICDevice.toObject(includeInstance, f),
    examScoreId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    questionpiclistList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    simiExamScore: (f = msg.getSimiExamScore()) && proto.pbs.SimiProcessStandardExamScore.SimiExamScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandardExamScore.SimiProcessStep;
  return proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = new ic_device_pb.ICDevice;
      reader.readMessage(value,ic_device_pb.ICDevice.deserializeBinaryFromReader);
      msg.setIcDevice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamScoreId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionpiclist(value);
      break;
    case 7:
      var value = new proto.pbs.SimiProcessStandardExamScore.SimiExamScore;
      reader.readMessage(value,proto.pbs.SimiProcessStandardExamScore.SimiExamScore.deserializeBinaryFromReader);
      msg.setSimiExamScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIcDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ic_device_pb.ICDevice.serializeBinaryToWriter
    );
  }
  f = message.getExamScoreId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getQuestionpiclistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSimiExamScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.SimiProcessStandardExamScore.SimiExamScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ICDevice ic_device = 2;
 * @return {?proto.pbs.ICDevice}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getIcDevice = function() {
  return /** @type{?proto.pbs.ICDevice} */ (
    jspb.Message.getWrapperField(this, ic_device_pb.ICDevice, 2));
};


/**
 * @param {?proto.pbs.ICDevice|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setIcDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.clearIcDevice = function() {
  return this.setIcDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.hasIcDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string exam_score_id = 3;
 * @return {string}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getExamScoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setExamScoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double total_score = 4;
 * @return {number}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_score_refer = 5;
 * @return {number}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated string questionPicList = 6;
 * @return {!Array<string>}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getQuestionpiclistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setQuestionpiclistList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.addQuestionpiclist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.clearQuestionpiclistList = function() {
  return this.setQuestionpiclistList([]);
};


/**
 * optional SimiExamScore simi_exam_score = 7;
 * @return {?proto.pbs.SimiProcessStandardExamScore.SimiExamScore}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.getSimiExamScore = function() {
  return /** @type{?proto.pbs.SimiProcessStandardExamScore.SimiExamScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiProcessStandardExamScore.SimiExamScore, 7));
};


/**
 * @param {?proto.pbs.SimiProcessStandardExamScore.SimiExamScore|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
*/
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.setSimiExamScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.clearSimiExamScore = function() {
  return this.setSimiExamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.SimiProcessStep.prototype.hasSimiExamScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional scoreInfoList processSort = 1;
 * @return {?proto.pbs.SimiProcessStandardExamScore.scoreInfoList}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.getProcesssort = function() {
  return /** @type{?proto.pbs.SimiProcessStandardExamScore.scoreInfoList} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiProcessStandardExamScore.scoreInfoList, 1));
};


/**
 * @param {?proto.pbs.SimiProcessStandardExamScore.scoreInfoList|undefined} value
 * @return {!proto.pbs.SimiProcessStandardExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.prototype.setProcesssort = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandardExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.prototype.clearProcesssort = function() {
  return this.setProcesssort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.hasProcesssort = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimiProcessStep simi_process_step = 2;
 * @return {!Array<!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep>}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.getSimiProcessStepList = function() {
  return /** @type{!Array<!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiProcessStandardExamScore.SimiProcessStep, 2));
};


/**
 * @param {!Array<!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep>} value
 * @return {!proto.pbs.SimiProcessStandardExamScore} returns this
*/
proto.pbs.SimiProcessStandardExamScore.prototype.setSimiProcessStepList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessStandardExamScore.SimiProcessStep}
 */
proto.pbs.SimiProcessStandardExamScore.prototype.addSimiProcessStep = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.SimiProcessStandardExamScore.SimiProcessStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandardExamScore} returns this
 */
proto.pbs.SimiProcessStandardExamScore.prototype.clearSimiProcessStepList = function() {
  return this.setSimiProcessStepList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiProcessGenerateScore.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessGenerateScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessGenerateScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessGenerateScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    simiProcessStepListList: jspb.Message.toObjectList(msg.getSimiProcessStepListList(),
    proto.pbs.SimiProcessGenerateScore.SimiProcessStep.toObject, includeInstance),
    enableSingleScore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isCorrectSerial: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    serialNoListList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessGenerateScore}
 */
proto.pbs.SimiProcessGenerateScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessGenerateScore;
  return proto.pbs.SimiProcessGenerateScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessGenerateScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessGenerateScore}
 */
proto.pbs.SimiProcessGenerateScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SimiProcessGenerateScore.SimiProcessStep;
      reader.readMessage(value,proto.pbs.SimiProcessGenerateScore.SimiProcessStep.deserializeBinaryFromReader);
      msg.addSimiProcessStepList(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableSingleScore(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCorrectSerial(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSerialNoList(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessGenerateScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessGenerateScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessGenerateScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimiProcessStepListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.SimiProcessGenerateScore.SimiProcessStep.serializeBinaryToWriter
    );
  }
  f = message.getEnableSingleScore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsCorrectSerial();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSerialNoListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessGenerateScore.SimiScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    examId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scorestandardId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessGenerateScore.SimiScoreReq;
  return proto.pbs.SimiProcessGenerateScore.SimiScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessGenerateScore.SimiScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScorestandardId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string exam_id = 1;
 * @return {string}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq} returns this
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scoreStandard_id = 2;
 * @return {string}
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.getScorestandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiScoreReq} returns this
 */
proto.pbs.SimiProcessGenerateScore.SimiScoreReq.prototype.setScorestandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessGenerateScore.SimiProcessStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icDevice: (f = msg.getIcDevice()) && ic_device_pb.ICDevice.toObject(includeInstance, f),
    scoreRequest: (f = msg.getScoreRequest()) && proto.pbs.SimiProcessGenerateScore.SimiScoreReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessGenerateScore.SimiProcessStep;
  return proto.pbs.SimiProcessGenerateScore.SimiProcessStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = new ic_device_pb.ICDevice;
      reader.readMessage(value,ic_device_pb.ICDevice.deserializeBinaryFromReader);
      msg.setIcDevice(value);
      break;
    case 3:
      var value = new proto.pbs.SimiProcessGenerateScore.SimiScoreReq;
      reader.readMessage(value,proto.pbs.SimiProcessGenerateScore.SimiScoreReq.deserializeBinaryFromReader);
      msg.setScoreRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessGenerateScore.SimiProcessStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIcDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ic_device_pb.ICDevice.serializeBinaryToWriter
    );
  }
  f = message.getScoreRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.SimiProcessGenerateScore.SimiScoreReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ICDevice ic_device = 2;
 * @return {?proto.pbs.ICDevice}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.getIcDevice = function() {
  return /** @type{?proto.pbs.ICDevice} */ (
    jspb.Message.getWrapperField(this, ic_device_pb.ICDevice, 2));
};


/**
 * @param {?proto.pbs.ICDevice|undefined} value
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} returns this
*/
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.setIcDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.clearIcDevice = function() {
  return this.setIcDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.hasIcDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SimiScoreReq score_request = 3;
 * @return {?proto.pbs.SimiProcessGenerateScore.SimiScoreReq}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.getScoreRequest = function() {
  return /** @type{?proto.pbs.SimiProcessGenerateScore.SimiScoreReq} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiProcessGenerateScore.SimiScoreReq, 3));
};


/**
 * @param {?proto.pbs.SimiProcessGenerateScore.SimiScoreReq|undefined} value
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} returns this
*/
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.setScoreRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.clearScoreRequest = function() {
  return this.setScoreRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessGenerateScore.SimiProcessStep.prototype.hasScoreRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated SimiProcessStep simi_process_step_list = 1;
 * @return {!Array<!proto.pbs.SimiProcessGenerateScore.SimiProcessStep>}
 */
proto.pbs.SimiProcessGenerateScore.prototype.getSimiProcessStepListList = function() {
  return /** @type{!Array<!proto.pbs.SimiProcessGenerateScore.SimiProcessStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiProcessGenerateScore.SimiProcessStep, 1));
};


/**
 * @param {!Array<!proto.pbs.SimiProcessGenerateScore.SimiProcessStep>} value
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
*/
proto.pbs.SimiProcessGenerateScore.prototype.setSimiProcessStepListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessGenerateScore.SimiProcessStep}
 */
proto.pbs.SimiProcessGenerateScore.prototype.addSimiProcessStepList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.SimiProcessGenerateScore.SimiProcessStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.clearSimiProcessStepListList = function() {
  return this.setSimiProcessStepListList([]);
};


/**
 * optional bool enable_single_score = 2;
 * @return {boolean}
 */
proto.pbs.SimiProcessGenerateScore.prototype.getEnableSingleScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.setEnableSingleScore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_correct_serial = 3;
 * @return {boolean}
 */
proto.pbs.SimiProcessGenerateScore.prototype.getIsCorrectSerial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.setIsCorrectSerial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated int32 serial_no_list = 4;
 * @return {!Array<number>}
 */
proto.pbs.SimiProcessGenerateScore.prototype.getSerialNoListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.setSerialNoListList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.addSerialNoList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessGenerateScore} returns this
 */
proto.pbs.SimiProcessGenerateScore.prototype.clearSerialNoListList = function() {
  return this.setSerialNoListList([]);
};


/**
 * @enum {number}
 */
proto.pbs.MA6ScoreField = {
  MA6SCOREFIELD_: 0,
  MA6SCOREFIELDMA6WAFERCARRIERSIZE: 1,
  MA6SCOREFIELDMA6BOARDSHELFSIZE: 2,
  MA6SCOREFIELDMA6LITHOGRAPHYBOARDSIZE: 3,
  MA6SCOREFIELDMA6LITHOGRAPHYBOARDOPTYPE: 4,
  MA6SCOREFIELDMA6WAFERALIGNPOSITIONX: 5,
  MA6SCOREFIELDMA6WAFERALIGNPOSITIONY: 6,
  MA6SCOREFIELDMA6WAFERALIGNANGLE: 7,
  MA6SCOREFIELDMA6EXPOSUREMODE: 8,
  MA6SCOREFIELDEXPOSUREDURATIONSET: 9,
  MA6SCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.LAB18ScoreField = {
  LAB18SCOREFIELD_: 0,
  LAB18SCOREFIELDCORRECTMATERIALS: 1,
  LAB18SCOREFIELDPROGRAMTYPE: 2,
  LAB18SCOREFIELDPROGRAMPARAMETERS: 3,
  LAB18SCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.SpinCoaterScoreField = {
  SPINCOATERSCOREFIELD_: 0,
  SPINCOATERSCOREFIELDCOATERDETAIL: 1,
  SPINCOATERSCOREFIELDPREBAKE: 2,
  SPINCOATERSCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.CleaningScoreField = {
  CLEANINGSCOREFIELD_: 0,
  CLEANINGSCOREFIELDCORRECTOPTIONS: 1,
  CLEANINGSCOREFIELDCLEANINGDETAIL: 2,
  CLEANINGSCOREFIELDCLEANINGPROCESS: 3,
  CLEANINGSCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.DevelopmentScoreField = {
  DEVELOPMENTSCOREFIELD_: 0,
  DEVELOPMENTSCOREFIELDCORRECTOPTIONS: 1,
  DEVELOPMENTSCOREFIELDDEVELOPMENTDETAIL: 2,
  DEVELOPMENTSCOREFIELDDEVELOPMENTPROCESS: 3,
  DEVELOPMENTSCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.PlasmaScoreField = {
  PLASMASCOREFIELD_: 0,
  PLASMASCOREFIELDETCHINGGASES: 1,
  PLASMASCOREFIELDGASPARAMETERS: 2,
  PLASMASCOREFIELDETCHPARAMETERS: 3,
  PLASMASCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.IonImplantationScoreField = {
  IONIMPLANTATIONSCOREFIELD_: 0,
  IONIMPLANTATIONSCOREFIELDPROCEDURE: 1,
  IONIMPLANTATIONSCOREFIELDIONBEAMDEBUGGING: 2,
  IONIMPLANTATIONSCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.ElectronBeamScoreField = {
  ELECTRONBEAMSCOREFIELD_: 0,
  ELECTRONBEAMSCOREFIELDCRUCIBLEPARAMETERS: 1,
  ELECTRONBEAMSCOREFIELDDEPOPROGRAM: 2,
  ELECTRONBEAMSCOREFIELDOPERATINGPARAMETER: 3,
  ELECTRONBEAMSCOREFIELDSIMIQUESTIONRET: 11
};

/**
 * @enum {number}
 */
proto.pbs.StepperScoreField = {
  STEPPERSCOREFIELD_: 0,
  STEPPERSCOREFIELDMASKPARAMS: 1,
  STEPPERSCOREFIELDMASKINSTALL: 2,
  STEPPERSCOREFIELDEXPOSUREAREA: 3,
  STEPPERSCOREFIELDEXPOSUREPARAMS: 4,
  STEPPERSCOREFIELDSIMIQUESTIONRET: 11
};

goog.object.extend(exports, proto.pbs);
