import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VenueService} from 'src/app/services/venue.service';
import {NzMessageService} from 'ng-zorro-antd';
import {ExamsService} from 'src/app/services/exams.service';
import {EventService} from 'src/app/event.service';
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.styl', '../registration.component.styl']
})
export class Step4Component implements OnInit {

    public listOfData: any = [
        {
            examid: "",
            examscore: 0,
            examvenue: {
                venueapplydue: 0,
                venuebatch: {
                    batchid: "",
                    batchname: "",
                    batchstart: 0,
                    batchdue: 0,
                    batchcreator: undefined
                },
                venuecreateat: 0,
                venuecreator: {
                    useravatar: "",
                    userbirthday: 0,
                    usercreateat: 0,
                    userdegree: 0,
                    userdeleteat: 0,
                    useremail: "",
                    userfname: "",
                    usergender: 1,
                    userid: "",
                    useridcard: "",
                    usermobile: "",
                    username: "",
                    usernation: "",
                    userpasswd: "",
                    userstatus: 0,
                    usertoken: "",
                    usertype: 2,
                    userupdateat: 0
                },
                venuedeleteat: 0,
                venueexamineeList: [],
                venueid: "",
                venuemajor: {
                    majorid: "",
                    majorname: ""
                },
                venuemaxcount: 0,
                venuepaper: {
                    paperid: "",
                    papersn: "",
                    papername: "",
                    paperdur: 0
                },
                venueplace: {
                    placeid: "",
                    placename: "",
                    placeaddr: ""
                },
                venuesn: "",
                venuestart: 0,
                venuestatus: 1,
                venueupdateat: 0
            },
            examexaminee: {
                userid: "",
                username: "",
                userfname: "",
                usergender: 1,
                useridcard: "",
                userbirthday:0
            },
            examsn: "",
            examanswersList: [],
            examstatus: 0,
            examteacher: {},
            examcreateat: 0,
            examdeleteat: 0,
            examreviewat: 0,
            examupdateat: 0

        }

    ];

    private venueId: string;
    public isApply: boolean;
    constructor(private message: NzMessageService,
                private activatedRoute: ActivatedRoute,
                private examsService: ExamsService,
                private userService: UserService,
                private router: Router,
                private event: EventService,
                private el: ElementRef) {
        activatedRoute.queryParams.subscribe(queryParams => {
            this.venueId = queryParams.venueId;
        })
    }


    print() {
        this.router.navigate(['/print-template'], {queryParams: {listOfData: JSON.stringify(this.listOfData)}});
    }

    ngOnInit() {
        this.listOfData = [];
        let exam = JSON.parse(localStorage.getItem("exam"));
        if (exam !== null) {
            if (exam.examvenue.majorsList.length > 0) {
                let majorsName = ''
                for (let j = 0; j < exam.examvenue.majorsList.length; j++) {
                    if (j == exam.examvenue.majorsList.length - 1) {
                        majorsName += exam.examvenue.majorsList[j].majorname
                    } else {
                        majorsName += exam.examvenue.majorsList[j].majorname + "、"
                    }
                }
                exam.examvenue.venuemajor.majorname = majorsName
            }
            this.isApply = true;
            exam.examexaminee = this.userService.getUser()
            exam.examvenue.venuestart = exam.examvenue.venuestart * 1000;
            exam.examvenue.venuepaper.paperdur = exam.examvenue.venuepaper.paperdur * 1000;
            exam.examexaminee.userbirthday = exam.examexaminee.userbirthday * 1000;
            this.listOfData.push(exam);
        }
    }

    toRedirectApplyStep(step) {
        let url = ""
        if (step == 1) {
            url = '/registration/step1'
        } else if (step == 2) {
            url = '/registration/step2'
        } else if (step == 3) {
            url = '/registration/step3'
        } else if (step == 4) {
            url = '/registration/step4'
        }
        this.router.navigate([url], {queryParams: {venueId: this.venueId}});
        // if (this.venueId != "") {
        // 	this.router.navigate([url],{ queryParams: { venueId: this.venueid } });
        // } else {
        // 	this.router.navigate([url]);
        // }
    }

}
