import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { ExamsService } from 'src/app/services/exams.service';
import { SchoolService } from 'src/app/services/school.service';
import { FacultyService } from 'src/app/services/faculty.service';
import { MajorService } from 'src/app/services/major.service';
import { ClassService } from 'src/app/services/class.service';
import { UploadService } from 'src/app/services/upload.service';
import { UploadReq, UploadResp } from 'src/app/pbs/upload_pb';
import { MyMajorListReq } from 'src/app/pbs/major_pb';
import { MyClassListReq } from 'src/app/pbs/class_pb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-personalsetting',
  templateUrl: './personalsetting.component.html',
  styleUrls: ['../registration/registration.component.styl','./personalsetting.component.styl']
})
export class PersonalsettingComponent implements OnInit {

  public user:any;

  public degreeData = [
    {
      value: 1,
      name: "小学"
    },
    {
      value: 2,
      name: "初中"
    },
    {
      value: 3,
      name: "高中/中专"
    },
    {
      value: 4,
      name: "本科"
    },
    {
      value: 5,
      name: "硕士"
    },
    {
      value: 6,
      name: "博士"
    }
  ];
  public nationData = [
    {
      value: "汉",
      name: "汉族"
    },
    {
      value: "壮族",
      name: "壮族"
    },
    {
      value: "藏族",
      name: "藏族"
    },
    {
      value: "裕固族",
      name: "裕固族"
    },
    {
      value: "彝族",
      name: "彝族"
    },
    {
      value: "瑶族",
      name: "瑶族"
    },
    {
      value: "锡伯族",
      name: "锡伯族"
    },
    {
      value: "乌孜别克族",
      name: "乌孜别克族"
    },
    {
      value: "维吾尔族",
      name: "维吾尔族"
    },
    {
      value: "佤族",
      name: "佤族"
    },
    {
      value: "土家族",
      name: "土家族"
    },
    {
      value: "土族",
      name: "土族"
    },
    {
      value: "塔塔尔族",
      name: "塔塔尔族"
    },
    {
      value: "塔吉克族",
      name: "塔吉克族"
    },
    {
      value: "水族",
      name: "水族"
    },
    {
      value: "畲族",
      name: "畲族"
    },
    {
      value: "撒拉族",
      name: "撒拉族"
    },
    {
      value: "羌族",
      name: "羌族"
    },
    {
      value: "普米族",
      name: "普米族"
    },
    {
      value: "怒族",
      name: "怒族"
    },
    {
      value: "纳西族",
      name: "纳西族"
    },
    {
      value: "仫佬族",
      name: "仫佬族"
    },
    {
      value: "苗族",
      name: "苗族"
    },
    {
      value: "蒙古族",
      name: "蒙古族"
    },
    {
      value: "门巴族",
      name: "门巴族"
    },
    {
      value: "毛南族",
      name: "毛南族"
    },
    {
      value: "满族",
      name: "满族"
    },
    {
      value: "珞巴族",
      name: "珞巴族"
    },
    {
      value: "僳僳族",
      name: "僳僳族"
    },
    {
      value: "黎族",
      name: "黎族"
    },
    {
      value: "拉祜族",
      name: "拉祜族"
    },
    {
      value: "柯尔克孜族",
      name: "柯尔克孜族"
    },
    {
      value: "景颇族",
      name: "景颇族"
    },
    {
      value: "基诺族",
      name: "基诺族"
    },
    {
      value: "回族",
      name: "回族"
    },
    {
      value: "赫哲族",
      name: "赫哲族"
    },
    {
      value: "哈萨克族",
      name: "哈萨克族"
    },
    {
      value: "哈尼族",
      name: "哈尼族"
    },
    {
      value: "仡佬族",
      name: "仡佬族"
    },
    {
      value: "高山族",
      name: "高山族"
    },
    {
      value: "鄂温克族",
      name: "鄂温克族"
    },
    {
      value: "俄罗斯族",
      name: "俄罗斯族"
    },
    {
      value: "鄂伦春族",
      name: "鄂伦春族"
    },
    {
      value: "独龙族",
      name: "独龙族"
    },
    {
      value: "东乡族",
      name: "东乡族"
    },
    {
      value: "侗族",
      name: "侗族"
    },
    {
      value: "德昂族",
      name: "德昂族"
    },
    {
      value: "傣族",
      name: "傣族"
    },
    {
      value: "达斡尔族",
      name: "达斡尔族"
    },
    {
      value: "朝鲜族",
      name: "朝鲜族"
    },
    {
      value: "布依族",
      name: "布依族"
    },
    {
      value: "保安族",
      name: "保安族"
    },
    {
      value: "布朗族",
      name: "布朗族"
    },
    {
      value: "白族",
      name: "白族"
    },
    {
      value: "阿昌族",
      name: "阿昌族"
    }
  ];

	public schoolList = [];
	public facultyList = [];
	public majorList = [];
	public classList = [];
	public classId:string;

  constructor(private userService: UserService,
    private message: NzMessageService,
    private examsService: ExamsService,
	private uploadService: UploadService,
	private schoolService: SchoolService,
	private facultyService: FacultyService,
	private majorService: MajorService,
	private classService: ClassService,
    private router: Router) { 

    }

    beforeUpload = (file: File) => {
      let isFileRight = false;
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'){
        isFileRight = true;
      }
      if (!isFileRight) {
        console.log(file.type);
        this.message.error('请上传格式为jpg/jepg/png的图片');
        return false;
      }
      const isLt500K = (file.size / 1024) < 500;
      if (!isLt500K) {
        this.message.error('图片不能大于500k!');
        return false;
      }
  
      if (isFileRight && isLt500K) {
        return true;
      }
    };
  
    private getBase64(img: File, callback: (img: string) => void): void {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result!.toString()));
      reader.readAsDataURL(img);
    }
	
	private getBytes(img: File, callback: (img: Uint8Array) => void): void {
	  const reader = new FileReader();
	  reader.addEventListener('load', () => callback(new Uint8Array(<ArrayBuffer>reader.result)));
	  reader.readAsArrayBuffer(img)
	}
  
    onChange(et:any) {
      let file = et.target.files[0];
      if (this.beforeUpload(file)) {
        // this.getBase64(file, (img: string) => {
        //   this.user.useravatar = img;
        // })
		this.getBytes(file, (img: Uint8Array) => {
		  //this.user.useravatar = img;
		  this.uploadFile(img)
		})
      }
    }


  ngOnInit() {
    this.user = this.userService.getUser();
	if (this.user.usertype == 0) {  //学生用户
		if (this.user.pb_class && this.user.pb_class.id) {
			this.classId = this.user.pb_class.id
		}
		this.getSchoolList()
		this.getFacultyList()
		this.getMajorList(this.user.facultyId)
		this.getClassList(this.user.majorId)
	}
	// if (this.user.useravatar != "") {
	// 	this.user.useravatar = environment.domainName + this.user.useravatar
	// }
    this.user.userbirthday = this.user.userbirthday * 1000;
    this.user.usergender = this.user.usergender.toString();
  }

  save() {
	if (this.user.usertype == 0) {
		if (this.user.majorId == "") {
			this.message.error("请选择专业")
			return
		}
		if (this.classId == "") {
			this.message.error("请选择班级")
			return
		}
		this.user.classId = this.classId;
	}
    this.userService.edit(JSON.stringify(this.user),
    (response) => {
      this.message.create("success","保存成功");
    },
    (fail) => {
      this.message.create("error",decodeURI(fail));
      if (decodeURI(fail) == "token无效") {
        common.returnLogin(this.router, this.examsService, this.message);
      }
    });
  }
  
  uploadFile(bytes) {
    let uploadReq = new UploadReq()
    uploadReq.setFile(bytes)
    this.uploadService.upload(uploadReq,(data:UploadResp)=>{
	  this.user.useravatar = environment.domainName + data.path;
	  this.user["headimg"] = data.path;
    },(err:string)=>{
      this.message.error('图片上传服务器失败')
    })
  }
  
  getSchoolList() {
	  let param = {
		page: 1,
		pageSize: 1000,
	  }
	  this.schoolService.getSchoolList(JSON.stringify(param),
	    (response) => {
	      this.schoolList = response.dataList;
	    },
	    (fail) => {
	      this.message.create("error", decodeURI(fail));
	      if (decodeURI(fail) == "token无效") {
	        common.returnLogin(this.router, this.examsService, this.message);
	      }
	    }
	  );
  }
  
  getFacultyList() {
	  this.facultyService.getFacultyList(
	    (response) => {
	      this.facultyList = response.dataList;
	    },
	    (fail) => {
	      this.message.create("error", decodeURI(fail));
	      if (decodeURI(fail) == "token无效") {
	        common.returnLogin(this.router, this.examsService, this.message);
	      }
	    }
	  );
  }
  
  getMajorList(facultyId:string) {
	  let listReq = new MyMajorListReq()
	  listReq.setFacultyId(facultyId)
	  this.majorService.getMyMajorList(listReq,
	    (response) => {
	      this.majorList = response.dataList;
	    },
	    (fail) => {
	      this.message.create("error", decodeURI(fail));
	      if (decodeURI(fail) == "token无效") {
	        common.returnLogin(this.router, this.examsService, this.message);
	      }
	    }
	  );
  }
  
  getClassList(majorId:string) {
	  let listReq = new MyClassListReq()
	  listReq.setMajorId(majorId)
	  this.classService.getClassList(listReq,
	    (response) => {
	      this.classList = response.dataList;
	    },
	    (fail) => {
	      this.message.create("error", decodeURI(fail));
	      if (decodeURI(fail) == "token无效") {
	        common.returnLogin(this.router, this.examsService, this.message);
	      }
	    }
	  );
  }
  
  changeFaculty(value) {
	  this.getMajorList(value)
	  this.user.majorId = "";
	  this.classId = "";
	  this.classList = [];
  }
  
  changeMajor(value) {
  	  this.getClassList(value)
  	  this.classId = "";
  }
}
