import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-class-header',
  templateUrl: './class-header.component.html',
  styleUrls: ['./class-header.component.styl']
})
export class ClassHeaderComponent implements OnInit {

  constructor(private router: Router) { }

  onClick() {
    this.router.navigate(["/entrance"]);
  }

  ngOnInit() {
  }

}
