import { Injectable } from '@angular/core';
import { Service } from './service';
import { SchoolServiceClient } from '../pbs/school_grpc_web_pb';
import { Schools,SchoolListReq } from '../pbs/school_pb';
import { Empty } from '../pbs/common_pb';
import { isNull } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SchoolService extends Service {

  schoolService: SchoolServiceClient;

  constructor() { 
    super()
    this.schoolService = new SchoolServiceClient(this.url, null, null);
  }

  /**
   * @description 获取课程列表
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof MajorService
   */
  getSchoolList(param: string,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let parm = JSON.parse(param);
    let schoolListReq = new SchoolListReq();
    schoolListReq.setPage(parm.page);
    schoolListReq.setPageSize(parm.pageSize);
    this.schoolService.list(schoolListReq,
      this.metadata, (err:Error, response: Schools) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

}
