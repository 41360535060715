// source: score_standard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var cutter_pb = require('./cutter_pb.js');
goog.object.extend(proto, cutter_pb);
var blank_pb = require('./blank_pb.js');
goog.object.extend(proto, blank_pb);
var major_pb = require('./major_pb.js');
goog.object.extend(proto, major_pb);
var ic_device_pb = require('./ic_device_pb.js');
goog.object.extend(proto, ic_device_pb);
goog.exportSymbol('proto.pbs.BlankParam', null, global);
goog.exportSymbol('proto.pbs.ClampParam', null, global);
goog.exportSymbol('proto.pbs.CleaningDeviceType', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard.CleaningDetail', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard.CleaningFluid', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard.CleaningProcess', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard.TemperatureSetting', null, global);
goog.exportSymbol('proto.pbs.CleaningScoreStandard.TimeSetting', null, global);
goog.exportSymbol('proto.pbs.CleaningStep', null, global);
goog.exportSymbol('proto.pbs.CleaningTemplateType', null, global);
goog.exportSymbol('proto.pbs.CleaningTmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.CleaningTmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.CutterParam', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard.DevelopmentDetail', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard.DevelopmentFluid', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard.DevelopmentProcess', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard.TemperatureSetting', null, global);
goog.exportSymbol('proto.pbs.DevelopmentScoreStandard.TimeSetting', null, global);
goog.exportSymbol('proto.pbs.Deviation', null, global);
goog.exportSymbol('proto.pbs.EbeamSwitchStatusType', null, global);
goog.exportSymbol('proto.pbs.EbeamTemplateType', null, global);
goog.exportSymbol('proto.pbs.EbeamTmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.EbeamTmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamOperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamOperationStageType', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamOperationStepType', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreStandard', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreStandard.CrucibleParameters', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreStandard.CrucibleSetting', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreStandard.DepoProgram', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamScoreStandard.OperatingParameter', null, global);
goog.exportSymbol('proto.pbs.IntRangeSetting', null, global);
goog.exportSymbol('proto.pbs.IonImplantationOperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.IonImplantationOperationStageType', null, global);
goog.exportSymbol('proto.pbs.IonImplantationOperationStepType', null, global);
goog.exportSymbol('proto.pbs.IonImplantationScoreStandard', null, global);
goog.exportSymbol('proto.pbs.IonImplantationScoreStandard.ImplantationElement', null, global);
goog.exportSymbol('proto.pbs.IonImplantationScoreStandard.IonBeamDebugging', null, global);
goog.exportSymbol('proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure', null, global);
goog.exportSymbol('proto.pbs.IonImplantationTemplateType', null, global);
goog.exportSymbol('proto.pbs.IonImplantationTmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.IonImplantationTmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.LAB18OperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.LAB18OperationStageType', null, global);
goog.exportSymbol('proto.pbs.LAB18OperationStepType', null, global);
goog.exportSymbol('proto.pbs.LAB18ScoreStandard', null, global);
goog.exportSymbol('proto.pbs.LAB18ScoreStandard.ProgramParameters', null, global);
goog.exportSymbol('proto.pbs.LAB18ScoreStandard.SputteringProcedure', null, global);
goog.exportSymbol('proto.pbs.LAB18ScoreStandard.TargetedMaterials', null, global);
goog.exportSymbol('proto.pbs.LAB18TemplateType', null, global);
goog.exportSymbol('proto.pbs.LAB18TmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.LAB18TmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.MA6BoardShelfSize', null, global);
goog.exportSymbol('proto.pbs.MA6ExposureMode', null, global);
goog.exportSymbol('proto.pbs.MA6LithographyBoardOpType', null, global);
goog.exportSymbol('proto.pbs.MA6LithographyBoardSize', null, global);
goog.exportSymbol('proto.pbs.MA6OperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.MA6OperationStageType', null, global);
goog.exportSymbol('proto.pbs.MA6OperationStepType', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.BoardShelfSizeScore', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.ExposureDurationScore', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.ExposureModeScore', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.LithographyBoardOpScore', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.LithographyBoardSize', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.MA6OperationStageStandard', null, global);
goog.exportSymbol('proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore', null, global);
goog.exportSymbol('proto.pbs.MA6TemplateType', null, global);
goog.exportSymbol('proto.pbs.MA6TmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.MA6TmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.MA6WaferCarrierSize', null, global);
goog.exportSymbol('proto.pbs.MA6WaferSize', null, global);
goog.exportSymbol('proto.pbs.MachineDeviation', null, global);
goog.exportSymbol('proto.pbs.MachineEffectiveParam', null, global);
goog.exportSymbol('proto.pbs.MachineParam', null, global);
goog.exportSymbol('proto.pbs.MachineProcessParam', null, global);
goog.exportSymbol('proto.pbs.OperateLogParam', null, global);
goog.exportSymbol('proto.pbs.OperateLogParamDetail', null, global);
goog.exportSymbol('proto.pbs.OperateProcessParam', null, global);
goog.exportSymbol('proto.pbs.PlasmaOperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.PlasmaOperationStageType', null, global);
goog.exportSymbol('proto.pbs.PlasmaOperationStepType', null, global);
goog.exportSymbol('proto.pbs.PlasmaScoreStandard', null, global);
goog.exportSymbol('proto.pbs.PlasmaScoreStandard.EtchParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaScoreStandard.Etchinggases', null, global);
goog.exportSymbol('proto.pbs.PlasmaScoreStandard.GasParameters', null, global);
goog.exportSymbol('proto.pbs.PlasmaTemplateType', null, global);
goog.exportSymbol('proto.pbs.PlasmaTmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.PlasmaTmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.ProfileDetail', null, global);
goog.exportSymbol('proto.pbs.ProfileParam', null, global);
goog.exportSymbol('proto.pbs.ProgramType', null, global);
goog.exportSymbol('proto.pbs.ScoreRangeSetting', null, global);
goog.exportSymbol('proto.pbs.ScoreStandard', null, global);
goog.exportSymbol('proto.pbs.ScoreStandardId', null, global);
goog.exportSymbol('proto.pbs.ScoreStandardType', null, global);
goog.exportSymbol('proto.pbs.ScoreStandards', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandard', null, global);
goog.exportSymbol('proto.pbs.SimiProcessStandard.SimiProcessStep', null, global);
goog.exportSymbol('proto.pbs.SimiQuestion', null, global);
goog.exportSymbol('proto.pbs.SimiResult', null, global);
goog.exportSymbol('proto.pbs.SimiResult.SimiResultItem', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterOperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterOperationStageType', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterOperationStepType', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterScoreStandard', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterScoreStandard.CoaterDetail', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterScoreStandard.PreBake', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterTemplateType', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterTmplTypeReq', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterTmplTypeResp', null, global);
goog.exportSymbol('proto.pbs.StandardListReq', null, global);
goog.exportSymbol('proto.pbs.StepperOperationDepInfo', null, global);
goog.exportSymbol('proto.pbs.StepperOperationStageType', null, global);
goog.exportSymbol('proto.pbs.StepperOperationStepType', null, global);
goog.exportSymbol('proto.pbs.StepperScoreStandard', null, global);
goog.exportSymbol('proto.pbs.StepperScoreStandard.ExposureArea', null, global);
goog.exportSymbol('proto.pbs.StepperScoreStandard.ExposureParams', null, global);
goog.exportSymbol('proto.pbs.StepperScoreStandard.Mask', null, global);
goog.exportSymbol('proto.pbs.StepperTemplateType', null, global);
goog.exportSymbol('proto.pbs.WorkpieceParam', null, global);
goog.exportSymbol('proto.pbs.WorkpieceParamMilling', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StandardListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StandardListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StandardListReq.displayName = 'proto.pbs.StandardListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreStandards.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreStandards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandards.displayName = 'proto.pbs.ScoreStandards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandardId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ScoreStandardId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandardId.displayName = 'proto.pbs.ScoreStandardId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ClampParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ClampParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ClampParam.displayName = 'proto.pbs.ClampParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CutterParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CutterParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.CutterParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CutterParam.displayName = 'proto.pbs.CutterParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineParam.displayName = 'proto.pbs.MachineParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineDeviation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineDeviation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineDeviation.displayName = 'proto.pbs.MachineDeviation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineProcessParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MachineProcessParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.MachineProcessParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineProcessParam.displayName = 'proto.pbs.MachineProcessParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceParam.displayName = 'proto.pbs.WorkpieceParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Deviation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Deviation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Deviation.displayName = 'proto.pbs.Deviation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.WorkpieceParamMilling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.WorkpieceParamMilling.repeatedFields_, null);
};
goog.inherits(proto.pbs.WorkpieceParamMilling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.WorkpieceParamMilling.displayName = 'proto.pbs.WorkpieceParamMilling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MachineEffectiveParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MachineEffectiveParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MachineEffectiveParam.displayName = 'proto.pbs.MachineEffectiveParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.BlankParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.BlankParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.BlankParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.BlankParam.displayName = 'proto.pbs.BlankParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateProcessParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateProcessParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateProcessParam.displayName = 'proto.pbs.OperateProcessParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLogParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.OperateLogParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.OperateLogParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLogParam.displayName = 'proto.pbs.OperateLogParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperateLogParamDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperateLogParamDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperateLogParamDetail.displayName = 'proto.pbs.OperateLogParamDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfileParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ProfileParam.repeatedFields_, null);
};
goog.inherits(proto.pbs.ProfileParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfileParam.displayName = 'proto.pbs.ProfileParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ProfileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ProfileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ProfileDetail.displayName = 'proto.pbs.ProfileDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6TmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6TmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6TmplTypeReq.displayName = 'proto.pbs.MA6TmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6TmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6TmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6TmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6TmplTypeResp.displayName = 'proto.pbs.MA6TmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6OperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6OperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6OperationDepInfo.displayName = 'proto.pbs.MA6OperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.MA6ScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.displayName = 'proto.pbs.MA6ScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.displayName = 'proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.BoardShelfSizeScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.displayName = 'proto.pbs.MA6ScoreStandard.BoardShelfSizeScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.LithographyBoardSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.LithographyBoardSize.displayName = 'proto.pbs.MA6ScoreStandard.LithographyBoardSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.LithographyBoardOpScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.displayName = 'proto.pbs.MA6ScoreStandard.LithographyBoardOpScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.ExposureModeScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.ExposureModeScore.displayName = 'proto.pbs.MA6ScoreStandard.ExposureModeScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.ExposureDurationScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.ExposureDurationScore.displayName = 'proto.pbs.MA6ScoreStandard.ExposureDurationScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6ScoreStandard.MA6OperationStageStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.displayName = 'proto.pbs.MA6ScoreStandard.MA6OperationStageStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18OperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18OperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18OperationDepInfo.displayName = 'proto.pbs.LAB18OperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18ScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18ScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18ScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18ScoreStandard.displayName = 'proto.pbs.LAB18ScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18ScoreStandard.TargetedMaterials.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18ScoreStandard.TargetedMaterials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18ScoreStandard.TargetedMaterials.displayName = 'proto.pbs.LAB18ScoreStandard.TargetedMaterials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18ScoreStandard.ProgramParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18ScoreStandard.ProgramParameters.displayName = 'proto.pbs.LAB18ScoreStandard.ProgramParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18ScoreStandard.SputteringProcedure.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18ScoreStandard.SputteringProcedure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18ScoreStandard.SputteringProcedure.displayName = 'proto.pbs.LAB18ScoreStandard.SputteringProcedure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18TmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.LAB18TmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18TmplTypeReq.displayName = 'proto.pbs.LAB18TmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18TmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18TmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18TmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18TmplTypeResp.displayName = 'proto.pbs.LAB18TmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterOperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterOperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterOperationDepInfo.displayName = 'proto.pbs.SpinCoaterOperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterScoreStandard.displayName = 'proto.pbs.SpinCoaterScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterScoreStandard.CoaterDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterScoreStandard.CoaterDetail.displayName = 'proto.pbs.SpinCoaterScoreStandard.CoaterDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterScoreStandard.PreBake = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterScoreStandard.PreBake, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterScoreStandard.PreBake.displayName = 'proto.pbs.SpinCoaterScoreStandard.PreBake';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterTmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterTmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterTmplTypeReq.displayName = 'proto.pbs.SpinCoaterTmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterTmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterTmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterTmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterTmplTypeResp.displayName = 'proto.pbs.SpinCoaterTmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.displayName = 'proto.pbs.CleaningScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard.CleaningFluid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningScoreStandard.CleaningFluid.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard.CleaningFluid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.CleaningFluid.displayName = 'proto.pbs.CleaningScoreStandard.CleaningFluid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard.TemperatureSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.TemperatureSetting.displayName = 'proto.pbs.CleaningScoreStandard.TemperatureSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard.TimeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard.TimeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.TimeSetting.displayName = 'proto.pbs.CleaningScoreStandard.TimeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard.CleaningDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard.CleaningDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.CleaningDetail.displayName = 'proto.pbs.CleaningScoreStandard.CleaningDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningScoreStandard.CleaningProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningScoreStandard.CleaningProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningScoreStandard.CleaningProcess.displayName = 'proto.pbs.CleaningScoreStandard.CleaningProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningTmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningTmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningTmplTypeReq.displayName = 'proto.pbs.CleaningTmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningTmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningTmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningTmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningTmplTypeResp.displayName = 'proto.pbs.CleaningTmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.displayName = 'proto.pbs.DevelopmentScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.repeatedFields_, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard.DevelopmentFluid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.displayName = 'proto.pbs.DevelopmentScoreStandard.DevelopmentFluid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard.TemperatureSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.TemperatureSetting.displayName = 'proto.pbs.DevelopmentScoreStandard.TemperatureSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard.TimeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.TimeSetting.displayName = 'proto.pbs.DevelopmentScoreStandard.TimeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard.DevelopmentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.displayName = 'proto.pbs.DevelopmentScoreStandard.DevelopmentDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentScoreStandard.DevelopmentProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.displayName = 'proto.pbs.DevelopmentScoreStandard.DevelopmentProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreRangeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ScoreRangeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreRangeSetting.displayName = 'proto.pbs.ScoreRangeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaOperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaOperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaOperationDepInfo.displayName = 'proto.pbs.PlasmaOperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaScoreStandard.displayName = 'proto.pbs.PlasmaScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaScoreStandard.Etchinggases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaScoreStandard.Etchinggases.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaScoreStandard.Etchinggases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaScoreStandard.Etchinggases.displayName = 'proto.pbs.PlasmaScoreStandard.Etchinggases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaScoreStandard.GasParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaScoreStandard.GasParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaScoreStandard.GasParameters.displayName = 'proto.pbs.PlasmaScoreStandard.GasParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaScoreStandard.EtchParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaScoreStandard.EtchParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaScoreStandard.EtchParameters.displayName = 'proto.pbs.PlasmaScoreStandard.EtchParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaTmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaTmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaTmplTypeReq.displayName = 'proto.pbs.PlasmaTmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaTmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaTmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaTmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaTmplTypeResp.displayName = 'proto.pbs.PlasmaTmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationOperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationOperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationOperationDepInfo.displayName = 'proto.pbs.IonImplantationOperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationScoreStandard.displayName = 'proto.pbs.IonImplantationScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationScoreStandard.ImplantationElement.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationScoreStandard.ImplantationElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationScoreStandard.ImplantationElement.displayName = 'proto.pbs.IonImplantationScoreStandard.ImplantationElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.displayName = 'proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationScoreStandard.IonBeamDebugging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.displayName = 'proto.pbs.IonImplantationScoreStandard.IonBeamDebugging';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationTmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IonImplantationTmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationTmplTypeReq.displayName = 'proto.pbs.IonImplantationTmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationTmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationTmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationTmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationTmplTypeResp.displayName = 'proto.pbs.IonImplantationTmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamOperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamOperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamOperationDepInfo.displayName = 'proto.pbs.ElectronBeamOperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamScoreStandard.displayName = 'proto.pbs.ElectronBeamScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamScoreStandard.CrucibleParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.displayName = 'proto.pbs.ElectronBeamScoreStandard.CrucibleParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamScoreStandard.CrucibleSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.displayName = 'proto.pbs.ElectronBeamScoreStandard.CrucibleSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamScoreStandard.DepoProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamScoreStandard.DepoProgram.displayName = 'proto.pbs.ElectronBeamScoreStandard.DepoProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamScoreStandard.OperatingParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamScoreStandard.OperatingParameter.displayName = 'proto.pbs.ElectronBeamScoreStandard.OperatingParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EbeamTmplTypeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.EbeamTmplTypeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EbeamTmplTypeReq.displayName = 'proto.pbs.EbeamTmplTypeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EbeamTmplTypeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.EbeamTmplTypeResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.EbeamTmplTypeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EbeamTmplTypeResp.displayName = 'proto.pbs.EbeamTmplTypeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IntRangeSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.IntRangeSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IntRangeSetting.displayName = 'proto.pbs.IntRangeSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperOperationDepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperOperationDepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperOperationDepInfo.displayName = 'proto.pbs.StepperOperationDepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.StepperScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.StepperScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperScoreStandard.displayName = 'proto.pbs.StepperScoreStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperScoreStandard.Mask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperScoreStandard.Mask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperScoreStandard.Mask.displayName = 'proto.pbs.StepperScoreStandard.Mask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperScoreStandard.ExposureArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperScoreStandard.ExposureArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperScoreStandard.ExposureArea.displayName = 'proto.pbs.StepperScoreStandard.ExposureArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperScoreStandard.ExposureParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperScoreStandard.ExposureParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperScoreStandard.ExposureParams.displayName = 'proto.pbs.StepperScoreStandard.ExposureParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiProcessStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiProcessStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandard.displayName = 'proto.pbs.SimiProcessStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiProcessStandard.SimiProcessStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SimiProcessStandard.SimiProcessStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiProcessStandard.SimiProcessStep.displayName = 'proto.pbs.SimiProcessStandard.SimiProcessStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiResult.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiResult.displayName = 'proto.pbs.SimiResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiResult.SimiResultItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiResult.SimiResultItem.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiResult.SimiResultItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiResult.SimiResultItem.displayName = 'proto.pbs.SimiResult.SimiResultItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SimiQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SimiQuestion.repeatedFields_, null);
};
goog.inherits(proto.pbs.SimiQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SimiQuestion.displayName = 'proto.pbs.SimiQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreStandard.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreStandard.displayName = 'proto.pbs.ScoreStandard';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StandardListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StandardListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StandardListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StandardListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    standardType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    modelType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    schoolId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isBelongSchool: jspb.Message.getFieldWithDefault(msg, 8, 0),
    icSerialNo: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StandardListReq}
 */
proto.pbs.StandardListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StandardListReq;
  return proto.pbs.StandardListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StandardListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StandardListReq}
 */
proto.pbs.StandardListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStandardType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsBelongSchool(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIcSerialNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StandardListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StandardListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StandardListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StandardListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStandardType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsBelongSchool();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIcSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 standard_type = 3;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getStandardType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setStandardType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 model_type = 4;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string school_id = 5;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string faculty_id = 6;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string major_id = 7;
 * @return {string}
 */
proto.pbs.StandardListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 is_belong_school = 8;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getIsBelongSchool = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setIsBelongSchool = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 ic_serial_no = 9;
 * @return {number}
 */
proto.pbs.StandardListReq.prototype.getIcSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StandardListReq} returns this
 */
proto.pbs.StandardListReq.prototype.setIcSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreStandards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandards.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandards.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.ScoreStandard.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandards}
 */
proto.pbs.ScoreStandards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandards;
  return proto.pbs.ScoreStandards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandards}
 */
proto.pbs.ScoreStandards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreStandard;
      reader.readMessage(value,proto.pbs.ScoreStandard.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ScoreStandard data = 1;
 * @return {!Array<!proto.pbs.ScoreStandard>}
 */
proto.pbs.ScoreStandards.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.ScoreStandard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ScoreStandard, 1));
};


/**
 * @param {!Array<!proto.pbs.ScoreStandard>} value
 * @return {!proto.pbs.ScoreStandards} returns this
*/
proto.pbs.ScoreStandards.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ScoreStandard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandards.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ScoreStandard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandards} returns this
 */
proto.pbs.ScoreStandards.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.ScoreStandards.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandards} returns this
 */
proto.pbs.ScoreStandards.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandardId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandardId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandardId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandardId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isSemiProcess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandardId}
 */
proto.pbs.ScoreStandardId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandardId;
  return proto.pbs.ScoreStandardId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandardId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandardId}
 */
proto.pbs.ScoreStandardId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSemiProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandardId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandardId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandardId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandardId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsSemiProcess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ScoreStandardId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandardId} returns this
 */
proto.pbs.ScoreStandardId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_semi_process = 2;
 * @return {boolean}
 */
proto.pbs.ScoreStandardId.prototype.getIsSemiProcess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.ScoreStandardId} returns this
 */
proto.pbs.ScoreStandardId.prototype.setIsSemiProcess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ClampParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ClampParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ClampParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    clampType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    positionMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    clampScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    positionMinOther: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    positionMaxOther: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ClampParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ClampParam;
  return proto.pbs.ClampParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ClampParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ClampParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClampType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMax(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMinOther(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionMaxOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ClampParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ClampParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ClampParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ClampParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClampType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPositionMin();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getPositionMax();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClampScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPositionMinOther();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPositionMaxOther();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 clamp_type = 1;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getClampType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setClampType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double position_min = 2;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double position_max = 3;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double clamp_score = 4;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getClampScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setClampScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double position_min_other = 5;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMinOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMinOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double position_max_other = 6;
 * @return {number}
 */
proto.pbs.ClampParam.prototype.getPositionMaxOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ClampParam} returns this
 */
proto.pbs.ClampParam.prototype.setPositionMaxOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CutterParam.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CutterParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CutterParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CutterParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxCutterNum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eachCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    beyondCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cuttersList: jspb.Message.toObjectList(msg.getCuttersList(),
    cutter_pb.Cutter.toObject, includeInstance),
    selectedCuttersList: jspb.Message.toObjectList(msg.getSelectedCuttersList(),
    cutter_pb.Cutter.toObject, includeInstance),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CutterParam}
 */
proto.pbs.CutterParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CutterParam;
  return proto.pbs.CutterParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CutterParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CutterParam}
 */
proto.pbs.CutterParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCutterNum(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEachCutterScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBeyondCutterDeductScore(value);
      break;
    case 4:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addCutters(value);
      break;
    case 5:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addSelectedCutters(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CutterParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CutterParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CutterParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxCutterNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEachCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBeyondCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCuttersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getSelectedCuttersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 max_cutter_num = 1;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getMaxCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setMaxCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double each_cutter_score = 2;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getEachCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setEachCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double beyond_cutter_deduct_score = 3;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getBeyondCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setBeyondCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Cutter cutters = 4;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterParam.prototype.getCuttersList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 4));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterParam} returns this
*/
proto.pbs.CutterParam.prototype.setCuttersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterParam.prototype.addCutters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.clearCuttersList = function() {
  return this.setCuttersList([]);
};


/**
 * repeated Cutter selected_cutters = 5;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterParam.prototype.getSelectedCuttersList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 5));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterParam} returns this
*/
proto.pbs.CutterParam.prototype.setSelectedCuttersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterParam.prototype.addSelectedCutters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.clearSelectedCuttersList = function() {
  return this.setSelectedCuttersList([]);
};


/**
 * optional double total_score = 6;
 * @return {number}
 */
proto.pbs.CutterParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CutterParam} returns this
 */
proto.pbs.CutterParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    craftType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spindleSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    feedSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    backCutterMete: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    spindleSpeedAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    feedSpeedAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    backCutterMeteAllowDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    spindleSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    feedSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    backCutterMeteScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    cutter: (f = msg.getCutter()) && cutter_pb.Cutter.toObject(includeInstance, f),
    cutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    machineDeviation: (f = msg.getMachineDeviation()) && proto.pbs.MachineDeviation.toObject(includeInstance, f),
    remark: jspb.Message.getFieldWithDefault(msg, 14, ""),
    machineParamId: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.MachineParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineParam;
  return proto.pbs.MachineParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.MachineParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCraftType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMete(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedAllowDeviation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedAllowDeviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteAllowDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteScore(value);
      break;
    case 11:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScore(value);
      break;
    case 13:
      var value = new proto.pbs.MachineDeviation;
      reader.readMessage(value,proto.pbs.MachineDeviation.deserializeBinaryFromReader);
      msg.setMachineDeviation(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineParamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCraftType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpindleSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFeedSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBackCutterMete();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSpindleSpeedAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getFeedSpeedAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBackCutterMeteAllowDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSpindleSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getFeedSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBackCutterMeteScore();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getMachineDeviation();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.MachineDeviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMachineParamId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 craft_type = 1;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getCraftType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setCraftType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double spindle_speed = 2;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double feed_speed = 3;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double back_cutter_mete = 4;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMete = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMete = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double spindle_speed_allow_deviation = 5;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeedAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeedAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double feed_speed_allow_deviation = 6;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeedAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeedAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double back_cutter_mete_allow_deviation = 7;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMeteAllowDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMeteAllowDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double spindle_speed_score = 8;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getSpindleSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setSpindleSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double feed_speed_score = 9;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getFeedSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setFeedSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double back_cutter_mete_score = 10;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getBackCutterMeteScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setBackCutterMeteScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional Cutter cutter = 11;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.MachineParam.prototype.getCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 11));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.MachineParam} returns this
*/
proto.pbs.MachineParam.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParam.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional double cutter_score = 12;
 * @return {number}
 */
proto.pbs.MachineParam.prototype.getCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional MachineDeviation machine_deviation = 13;
 * @return {?proto.pbs.MachineDeviation}
 */
proto.pbs.MachineParam.prototype.getMachineDeviation = function() {
  return /** @type{?proto.pbs.MachineDeviation} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineDeviation, 13));
};


/**
 * @param {?proto.pbs.MachineDeviation|undefined} value
 * @return {!proto.pbs.MachineParam} returns this
*/
proto.pbs.MachineParam.prototype.setMachineDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.clearMachineDeviation = function() {
  return this.setMachineDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineParam.prototype.hasMachineDeviation = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string remark = 14;
 * @return {string}
 */
proto.pbs.MachineParam.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string machine_param_id = 15;
 * @return {string}
 */
proto.pbs.MachineParam.prototype.getMachineParamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineParam} returns this
 */
proto.pbs.MachineParam.prototype.setMachineParamId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineDeviation.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineDeviation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineDeviation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineDeviation.toObject = function(includeInstance, msg) {
  var f, obj = {
    spindleSpeedDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    feedSpeedDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    backCutterMeteDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    spindleSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    feedSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    backCutterMeteScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    backupCutter: (f = msg.getBackupCutter()) && cutter_pb.Cutter.toObject(includeInstance, f),
    backupCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineDeviation}
 */
proto.pbs.MachineDeviation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineDeviation;
  return proto.pbs.MachineDeviation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineDeviation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineDeviation}
 */
proto.pbs.MachineDeviation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedDeviation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedDeviation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteDeviation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpindleSpeedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeedSpeedScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackCutterMeteScore(value);
      break;
    case 7:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.setBackupCutter(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackupCutterScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineDeviation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineDeviation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineDeviation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineDeviation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpindleSpeedDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFeedSpeedDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBackCutterMeteDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSpindleSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFeedSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBackCutterMeteScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBackupCutter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getBackupCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional double spindle_speed_deviation = 1;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getSpindleSpeedDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setSpindleSpeedDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double feed_speed_deviation = 2;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getFeedSpeedDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setFeedSpeedDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double back_cutter_mete_deviation = 3;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackCutterMeteDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackCutterMeteDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double spindle_speed_score = 4;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getSpindleSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setSpindleSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double feed_speed_score = 5;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getFeedSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setFeedSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double back_cutter_mete_score = 6;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackCutterMeteScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackCutterMeteScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional Cutter backup_cutter = 7;
 * @return {?proto.pbs.Cutter}
 */
proto.pbs.MachineDeviation.prototype.getBackupCutter = function() {
  return /** @type{?proto.pbs.Cutter} */ (
    jspb.Message.getWrapperField(this, cutter_pb.Cutter, 7));
};


/**
 * @param {?proto.pbs.Cutter|undefined} value
 * @return {!proto.pbs.MachineDeviation} returns this
*/
proto.pbs.MachineDeviation.prototype.setBackupCutter = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.clearBackupCutter = function() {
  return this.setBackupCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MachineDeviation.prototype.hasBackupCutter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double backup_cutter_score = 8;
 * @return {number}
 */
proto.pbs.MachineDeviation.prototype.getBackupCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineDeviation} returns this
 */
proto.pbs.MachineDeviation.prototype.setBackupCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MachineProcessParam.repeatedFields_ = [1,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineProcessParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineProcessParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineProcessParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependProcessList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    processScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    remark: jspb.Message.getFieldWithDefault(msg, 3, ""),
    craftType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    machineParamId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dependParamIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineProcessParam}
 */
proto.pbs.MachineProcessParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineProcessParam;
  return proto.pbs.MachineProcessParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineProcessParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineProcessParam}
 */
proto.pbs.MachineProcessParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDependProcess(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessScore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCraftType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineParamId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependParamIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineProcessParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineProcessParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineProcessParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineProcessParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependProcessList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getProcessScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCraftType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMachineParamId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDependParamIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * repeated int32 depend_process = 1;
 * @return {!Array<number>}
 */
proto.pbs.MachineProcessParam.prototype.getDependProcessList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setDependProcessList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.addDependProcess = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.clearDependProcessList = function() {
  return this.setDependProcessList([]);
};


/**
 * optional double process_score = 2;
 * @return {number}
 */
proto.pbs.MachineProcessParam.prototype.getProcessScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setProcessScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string remark = 3;
 * @return {string}
 */
proto.pbs.MachineProcessParam.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 craft_type = 4;
 * @return {number}
 */
proto.pbs.MachineProcessParam.prototype.getCraftType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setCraftType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string machine_param_id = 5;
 * @return {string}
 */
proto.pbs.MachineProcessParam.prototype.getMachineParamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setMachineParamId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string depend_param_ids = 6;
 * @return {!Array<string>}
 */
proto.pbs.MachineProcessParam.prototype.getDependParamIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.setDependParamIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.addDependParamIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MachineProcessParam} returns this
 */
proto.pbs.MachineProcessParam.prototype.clearDependParamIdsList = function() {
  return this.setDependParamIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceParam.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nominalSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    roughness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    midDiameter: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    upperDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    underDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    deviationList: jspb.Message.toObjectList(msg.getDeviationList(),
    proto.pbs.Deviation.toObject, includeInstance),
    remark: jspb.Message.getFieldWithDefault(msg, 10, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    tolerance: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    toleranceType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 18, 0),
    workpieceMarker: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.WorkpieceParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceParam;
  return proto.pbs.WorkpieceParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.WorkpieceParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNominalSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAngle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoughness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMidDiameter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnderDeviation(value);
      break;
    case 9:
      var value = new proto.pbs.Deviation;
      reader.readMessage(value,proto.pbs.Deviation.deserializeBinaryFromReader);
      msg.addDeviation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTolerance(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setToleranceType(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkpieceMarker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNominalSize();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRoughness();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMidDiameter();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getUpperDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getUnderDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.Deviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTolerance();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getToleranceType();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getWorkpieceMarker();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double nominal_size = 2;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getNominalSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setNominalSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double angle = 3;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double roughness = 4;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getRoughness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRoughness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double radius = 5;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double mid_diameter = 6;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getMidDiameter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setMidDiameter = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double upper_deviation = 7;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getUpperDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setUpperDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double under_deviation = 8;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getUnderDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setUnderDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated Deviation deviation = 9;
 * @return {!Array<!proto.pbs.Deviation>}
 */
proto.pbs.WorkpieceParam.prototype.getDeviationList = function() {
  return /** @type{!Array<!proto.pbs.Deviation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Deviation, 9));
};


/**
 * @param {!Array<!proto.pbs.Deviation>} value
 * @return {!proto.pbs.WorkpieceParam} returns this
*/
proto.pbs.WorkpieceParam.prototype.setDeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.Deviation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.WorkpieceParam.prototype.addDeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.Deviation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.clearDeviationList = function() {
  return this.setDeviationList([]);
};


/**
 * optional string remark = 10;
 * @return {string}
 */
proto.pbs.WorkpieceParam.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double score = 11;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double tolerance = 16;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setTolerance = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional int32 tolerance_type = 17;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getToleranceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setToleranceType = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 workpiece_index = 18;
 * @return {number}
 */
proto.pbs.WorkpieceParam.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string workpiece_marker = 19;
 * @return {string}
 */
proto.pbs.WorkpieceParam.prototype.getWorkpieceMarker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParam} returns this
 */
proto.pbs.WorkpieceParam.prototype.setWorkpieceMarker = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Deviation.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Deviation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Deviation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Deviation.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.Deviation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Deviation;
  return proto.pbs.Deviation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Deviation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.Deviation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Deviation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Deviation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Deviation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Deviation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double deviation = 1;
 * @return {number}
 */
proto.pbs.Deviation.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Deviation} returns this
 */
proto.pbs.Deviation.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.Deviation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Deviation} returns this
 */
proto.pbs.Deviation.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.WorkpieceParamMilling.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.WorkpieceParamMilling.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.WorkpieceParamMilling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.WorkpieceParamMilling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParamMilling.toObject = function(includeInstance, msg) {
  var f, obj = {
    pieceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    workpieceIndex: jspb.Message.getFieldWithDefault(msg, 13, 0),
    param: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    upperDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    underDeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    deviationList: jspb.Message.toObjectList(msg.getDeviationList(),
    proto.pbs.Deviation.toObject, includeInstance),
    remark: jspb.Message.getFieldWithDefault(msg, 10, ""),
    workpieceMarker: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.WorkpieceParamMilling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.WorkpieceParamMilling;
  return proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.WorkpieceParamMilling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPieceType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkpieceIndex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setParam(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperDeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnderDeviation(value);
      break;
    case 9:
      var value = new proto.pbs.Deviation;
      reader.readMessage(value,proto.pbs.Deviation.deserializeBinaryFromReader);
      msg.addDeviation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkpieceMarker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.WorkpieceParamMilling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.WorkpieceParamMilling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPieceType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getWorkpieceIndex();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getParam();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getUpperDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getUnderDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.Deviation.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWorkpieceMarker();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int32 piece_type = 1;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getPieceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setPieceType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double score = 12;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 workpiece_index = 13;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getWorkpieceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setWorkpieceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double param = 14;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getParam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setParam = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double upper_deviation = 7;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getUpperDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setUpperDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double under_deviation = 8;
 * @return {number}
 */
proto.pbs.WorkpieceParamMilling.prototype.getUnderDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setUnderDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated Deviation deviation = 9;
 * @return {!Array<!proto.pbs.Deviation>}
 */
proto.pbs.WorkpieceParamMilling.prototype.getDeviationList = function() {
  return /** @type{!Array<!proto.pbs.Deviation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Deviation, 9));
};


/**
 * @param {!Array<!proto.pbs.Deviation>} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
*/
proto.pbs.WorkpieceParamMilling.prototype.setDeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.Deviation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Deviation}
 */
proto.pbs.WorkpieceParamMilling.prototype.addDeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.Deviation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.clearDeviationList = function() {
  return this.setDeviationList([]);
};


/**
 * optional string remark = 10;
 * @return {string}
 */
proto.pbs.WorkpieceParamMilling.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string workpiece_marker = 19;
 * @return {string}
 */
proto.pbs.WorkpieceParamMilling.prototype.getWorkpieceMarker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.WorkpieceParamMilling} returns this
 */
proto.pbs.WorkpieceParamMilling.prototype.setWorkpieceMarker = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MachineEffectiveParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MachineEffectiveParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MachineEffectiveParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    ncRunTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ncScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    installCutterNum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    installCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changeBlankNum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    changeBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    changeBlankDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    hitCutterNum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hitCutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    hitCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    workpieceQualityProp: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    workpieceQualityScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    installCutterDeductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MachineEffectiveParam}
 */
proto.pbs.MachineEffectiveParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MachineEffectiveParam;
  return proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MachineEffectiveParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MachineEffectiveParam}
 */
proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNcRunTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCutterNum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInstallCutterScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangeBlankNum(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeBlankScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChangeBlankDeductScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitCutterNum(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHitCutterScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHitCutterDeductScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityProp(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityScore(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInstallCutterDeductScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MachineEffectiveParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MachineEffectiveParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MachineEffectiveParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MachineEffectiveParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcRunTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNcScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getInstallCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInstallCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getChangeBlankNum();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getChangeBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getChangeBlankDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHitCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getHitCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getHitCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getWorkpieceQualityProp();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getInstallCutterDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional int64 nc_run_time = 1;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getNcRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setNcRunTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double nc_score = 2;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getNcScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setNcScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 install_cutter_num = 3;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double install_cutter_score = 4;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 change_blank_num = 5;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankNum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double change_blank_score = 6;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double change_blank_deduct_score = 7;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getChangeBlankDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setChangeBlankDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 hit_cutter_num = 8;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double hit_cutter_score = 9;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double hit_cutter_deduct_score = 10;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getHitCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setHitCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double workpiece_quality_prop = 11;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getWorkpieceQualityProp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setWorkpieceQualityProp = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double workpiece_quality_score = 12;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getWorkpieceQualityScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double install_cutter_deduct_score = 13;
 * @return {number}
 */
proto.pbs.MachineEffectiveParam.prototype.getInstallCutterDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MachineEffectiveParam} returns this
 */
proto.pbs.MachineEffectiveParam.prototype.setInstallCutterDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.BlankParam.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.BlankParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.BlankParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.BlankParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctBlank: (f = msg.getCorrectBlank()) && blank_pb.Blank.toObject(includeInstance, f),
    halfCorrectBlank: (f = msg.getHalfCorrectBlank()) && blank_pb.Blank.toObject(includeInstance, f),
    correctBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    halfCorrectBlankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    selectedBlanksList: jspb.Message.toObjectList(msg.getSelectedBlanksList(),
    blank_pb.Blank.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.BlankParam}
 */
proto.pbs.BlankParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.BlankParam;
  return proto.pbs.BlankParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.BlankParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.BlankParam}
 */
proto.pbs.BlankParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.setCorrectBlank(value);
      break;
    case 2:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.setHalfCorrectBlank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCorrectBlankScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHalfCorrectBlankScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 6:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.addSelectedBlanks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.BlankParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.BlankParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.BlankParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.BlankParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectBlank();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getHalfCorrectBlank();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getCorrectBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHalfCorrectBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSelectedBlanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blank correct_blank = 1;
 * @return {?proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.getCorrectBlank = function() {
  return /** @type{?proto.pbs.Blank} */ (
    jspb.Message.getWrapperField(this, blank_pb.Blank, 1));
};


/**
 * @param {?proto.pbs.Blank|undefined} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setCorrectBlank = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearCorrectBlank = function() {
  return this.setCorrectBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankParam.prototype.hasCorrectBlank = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Blank half_correct_blank = 2;
 * @return {?proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.getHalfCorrectBlank = function() {
  return /** @type{?proto.pbs.Blank} */ (
    jspb.Message.getWrapperField(this, blank_pb.Blank, 2));
};


/**
 * @param {?proto.pbs.Blank|undefined} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setHalfCorrectBlank = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearHalfCorrectBlank = function() {
  return this.setHalfCorrectBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.BlankParam.prototype.hasHalfCorrectBlank = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double correct_blank_score = 3;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getCorrectBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setCorrectBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double half_correct_blank_score = 4;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getHalfCorrectBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setHalfCorrectBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_score = 5;
 * @return {number}
 */
proto.pbs.BlankParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated Blank selected_blanks = 6;
 * @return {!Array<!proto.pbs.Blank>}
 */
proto.pbs.BlankParam.prototype.getSelectedBlanksList = function() {
  return /** @type{!Array<!proto.pbs.Blank>} */ (
    jspb.Message.getRepeatedWrapperField(this, blank_pb.Blank, 6));
};


/**
 * @param {!Array<!proto.pbs.Blank>} value
 * @return {!proto.pbs.BlankParam} returns this
*/
proto.pbs.BlankParam.prototype.setSelectedBlanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.Blank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Blank}
 */
proto.pbs.BlankParam.prototype.addSelectedBlanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.Blank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.BlankParam} returns this
 */
proto.pbs.BlankParam.prototype.clearSelectedBlanksList = function() {
  return this.setSelectedBlanksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateProcessParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateProcessParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateProcessParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.OperateProcessParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateProcessParam;
  return proto.pbs.OperateProcessParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateProcessParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.OperateProcessParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateProcessParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateProcessParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateProcessParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateProcessParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateProcessParam.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessParam} returns this
 */
proto.pbs.OperateProcessParam.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.OperateProcessParam.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateProcessParam} returns this
 */
proto.pbs.OperateProcessParam.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.OperateLogParam.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLogParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLogParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLogParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    operateLogParamList: jspb.Message.toObjectList(msg.getOperateLogParamList(),
    proto.pbs.OperateLogParamDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLogParam}
 */
proto.pbs.OperateLogParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLogParam;
  return proto.pbs.OperateLogParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLogParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLogParam}
 */
proto.pbs.OperateLogParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 2:
      var value = new proto.pbs.OperateLogParamDetail;
      reader.readMessage(value,proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader);
      msg.addOperateLogParam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLogParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLogParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLogParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getOperateLogParamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.OperateLogParamDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional double total_score = 1;
 * @return {number}
 */
proto.pbs.OperateLogParam.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParam} returns this
 */
proto.pbs.OperateLogParam.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * repeated OperateLogParamDetail operate_log_param = 2;
 * @return {!Array<!proto.pbs.OperateLogParamDetail>}
 */
proto.pbs.OperateLogParam.prototype.getOperateLogParamList = function() {
  return /** @type{!Array<!proto.pbs.OperateLogParamDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateLogParamDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.OperateLogParamDetail>} value
 * @return {!proto.pbs.OperateLogParam} returns this
*/
proto.pbs.OperateLogParam.prototype.setOperateLogParamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.OperateLogParamDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParam.prototype.addOperateLogParam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.OperateLogParamDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.OperateLogParam} returns this
 */
proto.pbs.OperateLogParam.prototype.clearOperateLogParamList = function() {
  return this.setOperateLogParamList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperateLogParamDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperateLogParamDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperateLogParamDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParamDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    operateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deductScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    deductType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deductMaxNum: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParamDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperateLogParamDetail;
  return proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperateLogParamDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperateLogParamDetail}
 */
proto.pbs.OperateLogParamDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeductScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeductMaxNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperateLogParamDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperateLogParamDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperateLogParamDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperateLogParamDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeductScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDeductType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeductMaxNum();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 operate_type = 1;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double deduct_score = 2;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 deduct_type = 3;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deduct_max_num = 4;
 * @return {number}
 */
proto.pbs.OperateLogParamDetail.prototype.getDeductMaxNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperateLogParamDetail} returns this
 */
proto.pbs.OperateLogParamDetail.prototype.setDeductMaxNum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ProfileParam.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfileParam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfileParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfileParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileName: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileListList: jspb.Message.toObjectList(msg.getProfileListList(),
    proto.pbs.ProfileDetail.toObject, includeInstance),
    profileImg: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfileParam}
 */
proto.pbs.ProfileParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfileParam;
  return proto.pbs.ProfileParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfileParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfileParam}
 */
proto.pbs.ProfileParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileName(value);
      break;
    case 2:
      var value = new proto.pbs.ProfileDetail;
      reader.readMessage(value,proto.pbs.ProfileDetail.deserializeBinaryFromReader);
      msg.addProfileList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfileParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfileParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfileParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileName();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.ProfileDetail.serializeBinaryToWriter
    );
  }
  f = message.getProfileImg();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 profile_name = 1;
 * @return {number}
 */
proto.pbs.ProfileParam.prototype.getProfileName = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.setProfileName = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ProfileDetail profile_list = 2;
 * @return {!Array<!proto.pbs.ProfileDetail>}
 */
proto.pbs.ProfileParam.prototype.getProfileListList = function() {
  return /** @type{!Array<!proto.pbs.ProfileDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ProfileDetail, 2));
};


/**
 * @param {!Array<!proto.pbs.ProfileDetail>} value
 * @return {!proto.pbs.ProfileParam} returns this
*/
proto.pbs.ProfileParam.prototype.setProfileListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.ProfileDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileParam.prototype.addProfileList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.ProfileDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.clearProfileListList = function() {
  return this.setProfileListList([]);
};


/**
 * optional string profile_img = 3;
 * @return {string}
 */
proto.pbs.ProfileParam.prototype.getProfileImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ProfileParam} returns this
 */
proto.pbs.ProfileParam.prototype.setProfileImg = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ProfileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ProfileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ProfileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    startPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    endPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    endPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    arcPointX: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    arcPointZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ProfileDetail;
  return proto.pbs.ProfileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ProfileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ProfileDetail}
 */
proto.pbs.ProfileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPointZ(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndPointX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndPointZ(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArcPointX(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArcPointZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ProfileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ProfileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ProfileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ProfileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStartPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getEndPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getEndPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getArcPointX();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getArcPointZ();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 profile_type = 1;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getProfileType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setProfileType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double start_point_x = 2;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getStartPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setStartPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double start_point_z = 3;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getStartPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setStartPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double end_point_x = 4;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getEndPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setEndPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double end_point_z = 5;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getEndPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setEndPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double radius = 6;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double arc_point_x = 7;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getArcPointX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setArcPointX = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double arc_point_z = 8;
 * @return {number}
 */
proto.pbs.ProfileDetail.prototype.getArcPointZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ProfileDetail} returns this
 */
proto.pbs.ProfileDetail.prototype.setArcPointZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6TmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6TmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6TmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6TmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6TmplTypeReq}
 */
proto.pbs.MA6TmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6TmplTypeReq;
  return proto.pbs.MA6TmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6TmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6TmplTypeReq}
 */
proto.pbs.MA6TmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.MA6TemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6TmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6TmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6TmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6TmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional MA6TemplateType tmplType = 1;
 * @return {!proto.pbs.MA6TemplateType}
 */
proto.pbs.MA6TmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.MA6TemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.MA6TemplateType} value
 * @return {!proto.pbs.MA6TmplTypeReq} returns this
 */
proto.pbs.MA6TmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6TmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6TmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6TmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6TmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6TmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.MA6OperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6TmplTypeResp}
 */
proto.pbs.MA6TmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6TmplTypeResp;
  return proto.pbs.MA6TmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6TmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6TmplTypeResp}
 */
proto.pbs.MA6TmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.MA6OperationDepInfo;
      reader.readMessage(value,proto.pbs.MA6OperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6TmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6TmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6TmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6TmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.MA6OperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.MA6TmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.MA6TmplTypeResp} returns this
*/
proto.pbs.MA6TmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.MA6TmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6TmplTypeResp} returns this
 */
proto.pbs.MA6TmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated MA6OperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.MA6OperationDepInfo>}
 */
proto.pbs.MA6TmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.MA6OperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6OperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.MA6OperationDepInfo>} value
 * @return {!proto.pbs.MA6TmplTypeResp} returns this
*/
proto.pbs.MA6TmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.MA6OperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6OperationDepInfo}
 */
proto.pbs.MA6TmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.MA6OperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6TmplTypeResp} returns this
 */
proto.pbs.MA6TmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6OperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6OperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6OperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6OperationDepInfo}
 */
proto.pbs.MA6OperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6OperationDepInfo;
  return proto.pbs.MA6OperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6OperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6OperationDepInfo}
 */
proto.pbs.MA6OperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.MA6OperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.MA6OperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.MA6OperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6OperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6OperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6OperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6OperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.MA6OperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional MA6OperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.MA6OperationStageType}
 */
proto.pbs.MA6OperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.MA6OperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.MA6OperationStageType} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.MA6OperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MA6OperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.MA6OperationStepType}
 */
proto.pbs.MA6OperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.MA6OperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.MA6OperationStepType} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.MA6OperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional MA6OperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.MA6OperationStepType}
 */
proto.pbs.MA6OperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.MA6OperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.MA6OperationStepType} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.MA6OperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6OperationDepInfo} returns this
 */
proto.pbs.MA6OperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.MA6ScoreStandard.repeatedFields_ = [3,4,5,6,7,8,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    waferSizeSelection: jspb.Message.getFieldWithDefault(msg, 2, 0),
    waferCarrierList: jspb.Message.toObjectList(msg.getWaferCarrierList(),
    proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.toObject, includeInstance),
    boardShelfList: jspb.Message.toObjectList(msg.getBoardShelfList(),
    proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.toObject, includeInstance),
    lithographyBoardSizeList: jspb.Message.toObjectList(msg.getLithographyBoardSizeList(),
    proto.pbs.MA6ScoreStandard.LithographyBoardSize.toObject, includeInstance),
    lithographyBoardOpList: jspb.Message.toObjectList(msg.getLithographyBoardOpList(),
    proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.toObject, includeInstance),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.toObject, includeInstance),
    exposureModeScoreList: jspb.Message.toObjectList(msg.getExposureModeScoreList(),
    proto.pbs.MA6ScoreStandard.ExposureModeScore.toObject, includeInstance),
    exposureDuration: (f = msg.getExposureDuration()) && proto.pbs.MA6ScoreStandard.ExposureDurationScore.toObject(includeInstance, f),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 10, ""),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard}
 */
proto.pbs.MA6ScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard;
  return proto.pbs.MA6ScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard}
 */
proto.pbs.MA6ScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.MA6WaferSize} */ (reader.readEnum());
      msg.setWaferSizeSelection(value);
      break;
    case 3:
      var value = new proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.deserializeBinaryFromReader);
      msg.addWaferCarrier(value);
      break;
    case 4:
      var value = new proto.pbs.MA6ScoreStandard.BoardShelfSizeScore;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.deserializeBinaryFromReader);
      msg.addBoardShelf(value);
      break;
    case 5:
      var value = new proto.pbs.MA6ScoreStandard.LithographyBoardSize;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.LithographyBoardSize.deserializeBinaryFromReader);
      msg.addLithographyBoardSize(value);
      break;
    case 6:
      var value = new proto.pbs.MA6ScoreStandard.LithographyBoardOpScore;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.deserializeBinaryFromReader);
      msg.addLithographyBoardOp(value);
      break;
    case 7:
      var value = new proto.pbs.MA6ScoreStandard.MA6OperationStageStandard;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 8:
      var value = new proto.pbs.MA6ScoreStandard.ExposureModeScore;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.ExposureModeScore.deserializeBinaryFromReader);
      msg.addExposureModeScore(value);
      break;
    case 9:
      var value = new proto.pbs.MA6ScoreStandard.ExposureDurationScore;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.ExposureDurationScore.deserializeBinaryFromReader);
      msg.setExposureDuration(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 11:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 12:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWaferSizeSelection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWaferCarrierList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.serializeBinaryToWriter
    );
  }
  f = message.getBoardShelfList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.serializeBinaryToWriter
    );
  }
  f = message.getLithographyBoardSizeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.MA6ScoreStandard.LithographyBoardSize.serializeBinaryToWriter
    );
  }
  f = message.getLithographyBoardOpList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.serializeBinaryToWriter
    );
  }
  f = message.getExposureModeScoreList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.MA6ScoreStandard.ExposureModeScore.serializeBinaryToWriter
    );
  }
  f = message.getExposureDuration();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.MA6ScoreStandard.ExposureDurationScore.serializeBinaryToWriter
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeType: (f = msg.getSizeType()) && common_pb.CommonType.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore;
  return proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.setSizeType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CommonType size_type = 1;
 * @return {?proto.pbs.CommonType}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.getSizeType = function() {
  return /** @type{?proto.pbs.CommonType} */ (
    jspb.Message.getWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {?proto.pbs.CommonType|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} returns this
*/
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.setSizeType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} returns this
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.clearSizeType = function() {
  return this.setSizeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.hasSizeType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore} returns this
 */
proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeType: (f = msg.getSizeType()) && common_pb.CommonType.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.BoardShelfSizeScore;
  return proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.setSizeType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CommonType size_type = 1;
 * @return {?proto.pbs.CommonType}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.getSizeType = function() {
  return /** @type{?proto.pbs.CommonType} */ (
    jspb.Message.getWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {?proto.pbs.CommonType|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} returns this
*/
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.setSizeType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} returns this
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.clearSizeType = function() {
  return this.setSizeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.hasSizeType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore} returns this
 */
proto.pbs.MA6ScoreStandard.BoardShelfSizeScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.LithographyBoardSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeType: (f = msg.getSizeType()) && common_pb.CommonType.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.LithographyBoardSize;
  return proto.pbs.MA6ScoreStandard.LithographyBoardSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.setSizeType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.LithographyBoardSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CommonType size_type = 1;
 * @return {?proto.pbs.CommonType}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.getSizeType = function() {
  return /** @type{?proto.pbs.CommonType} */ (
    jspb.Message.getWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {?proto.pbs.CommonType|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} returns this
*/
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.setSizeType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} returns this
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.clearSizeType = function() {
  return this.setSizeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.hasSizeType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize} returns this
 */
proto.pbs.MA6ScoreStandard.LithographyBoardSize.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: (f = msg.getOperationType()) && common_pb.CommonType.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.LithographyBoardOpScore;
  return proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CommonType operation_type = 1;
 * @return {?proto.pbs.CommonType}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.getOperationType = function() {
  return /** @type{?proto.pbs.CommonType} */ (
    jspb.Message.getWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {?proto.pbs.CommonType|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} returns this
*/
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.setOperationType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} returns this
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.clearOperationType = function() {
  return this.setOperationType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.hasOperationType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore} returns this
 */
proto.pbs.MA6ScoreStandard.LithographyBoardOpScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.ExposureModeScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.ExposureModeScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: (f = msg.getMode()) && common_pb.CommonType.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.ExposureModeScore;
  return proto.pbs.MA6ScoreStandard.ExposureModeScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.ExposureModeScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.setMode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.ExposureModeScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.ExposureModeScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CommonType mode = 1;
 * @return {?proto.pbs.CommonType}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.getMode = function() {
  return /** @type{?proto.pbs.CommonType} */ (
    jspb.Message.getWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {?proto.pbs.CommonType|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore} returns this
*/
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.setMode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore} returns this
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.clearMode = function() {
  return this.setMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.hasMode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore} returns this
 */
proto.pbs.MA6ScoreStandard.ExposureModeScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.ExposureDurationScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerBoundary: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperBoundary: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.ExposureDurationScore}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.ExposureDurationScore;
  return proto.pbs.MA6ScoreStandard.ExposureDurationScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.ExposureDurationScore}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerBoundary(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperBoundary(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.ExposureDurationScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerBoundary();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperBoundary();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_boundary = 1;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.getLowerBoundary = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} returns this
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.setLowerBoundary = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_boundary = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.getUpperBoundary = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} returns this
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.setUpperBoundary = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.ExposureDurationScore} returns this
 */
proto.pbs.MA6ScoreStandard.ExposureDurationScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    opdepinfo: (f = msg.getOpdepinfo()) && proto.pbs.MA6OperationDepInfo.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6ScoreStandard.MA6OperationStageStandard;
  return proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.MA6OperationDepInfo;
      reader.readMessage(value,proto.pbs.MA6OperationDepInfo.deserializeBinaryFromReader);
      msg.setOpdepinfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpdepinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.MA6OperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional MA6OperationDepInfo OpDepInfo = 1;
 * @return {?proto.pbs.MA6OperationDepInfo}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.getOpdepinfo = function() {
  return /** @type{?proto.pbs.MA6OperationDepInfo} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6OperationDepInfo, 1));
};


/**
 * @param {?proto.pbs.MA6OperationDepInfo|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} returns this
*/
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.setOpdepinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} returns this
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.clearOpdepinfo = function() {
  return this.setOpdepinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.hasOpdepinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard} returns this
 */
proto.pbs.MA6ScoreStandard.MA6OperationStageStandard.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.MA6ScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MA6WaferSize wafer_size_selection = 2;
 * @return {!proto.pbs.MA6WaferSize}
 */
proto.pbs.MA6ScoreStandard.prototype.getWaferSizeSelection = function() {
  return /** @type {!proto.pbs.MA6WaferSize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.MA6WaferSize} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.setWaferSizeSelection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated WaferCarrierSizeScore wafer_carrier = 3;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore>}
 */
proto.pbs.MA6ScoreStandard.prototype.getWaferCarrierList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore, 3));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setWaferCarrierList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore}
 */
proto.pbs.MA6ScoreStandard.prototype.addWaferCarrier = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.MA6ScoreStandard.WaferCarrierSizeScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearWaferCarrierList = function() {
  return this.setWaferCarrierList([]);
};


/**
 * repeated BoardShelfSizeScore board_shelf = 4;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore>}
 */
proto.pbs.MA6ScoreStandard.prototype.getBoardShelfList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.BoardShelfSizeScore, 4));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setBoardShelfList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.BoardShelfSizeScore}
 */
proto.pbs.MA6ScoreStandard.prototype.addBoardShelf = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.MA6ScoreStandard.BoardShelfSizeScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearBoardShelfList = function() {
  return this.setBoardShelfList([]);
};


/**
 * repeated LithographyBoardSize lithography_board_size = 5;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardSize>}
 */
proto.pbs.MA6ScoreStandard.prototype.getLithographyBoardSizeList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardSize>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.LithographyBoardSize, 5));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardSize>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setLithographyBoardSizeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardSize=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardSize}
 */
proto.pbs.MA6ScoreStandard.prototype.addLithographyBoardSize = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.MA6ScoreStandard.LithographyBoardSize, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearLithographyBoardSizeList = function() {
  return this.setLithographyBoardSizeList([]);
};


/**
 * repeated LithographyBoardOpScore lithography_board_op = 6;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore>}
 */
proto.pbs.MA6ScoreStandard.prototype.getLithographyBoardOpList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.LithographyBoardOpScore, 6));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setLithographyBoardOpList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.LithographyBoardOpScore}
 */
proto.pbs.MA6ScoreStandard.prototype.addLithographyBoardOp = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.MA6ScoreStandard.LithographyBoardOpScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearLithographyBoardOpList = function() {
  return this.setLithographyBoardOpList([]);
};


/**
 * repeated MA6OperationStageStandard operations = 7;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard>}
 */
proto.pbs.MA6ScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.MA6OperationStageStandard, 7));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.MA6OperationStageStandard}
 */
proto.pbs.MA6ScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.MA6ScoreStandard.MA6OperationStageStandard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated ExposureModeScore exposure_mode_score = 8;
 * @return {!Array<!proto.pbs.MA6ScoreStandard.ExposureModeScore>}
 */
proto.pbs.MA6ScoreStandard.prototype.getExposureModeScoreList = function() {
  return /** @type{!Array<!proto.pbs.MA6ScoreStandard.ExposureModeScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MA6ScoreStandard.ExposureModeScore, 8));
};


/**
 * @param {!Array<!proto.pbs.MA6ScoreStandard.ExposureModeScore>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setExposureModeScoreList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.MA6ScoreStandard.ExposureModeScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MA6ScoreStandard.ExposureModeScore}
 */
proto.pbs.MA6ScoreStandard.prototype.addExposureModeScore = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.MA6ScoreStandard.ExposureModeScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearExposureModeScoreList = function() {
  return this.setExposureModeScoreList([]);
};


/**
 * optional ExposureDurationScore exposure_duration = 9;
 * @return {?proto.pbs.MA6ScoreStandard.ExposureDurationScore}
 */
proto.pbs.MA6ScoreStandard.prototype.getExposureDuration = function() {
  return /** @type{?proto.pbs.MA6ScoreStandard.ExposureDurationScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6ScoreStandard.ExposureDurationScore, 9));
};


/**
 * @param {?proto.pbs.MA6ScoreStandard.ExposureDurationScore|undefined} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setExposureDuration = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearExposureDuration = function() {
  return this.setExposureDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.MA6ScoreStandard.prototype.hasExposureDuration = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string experiment_img = 10;
 * @return {string}
 */
proto.pbs.MA6ScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated SimiQuestion simi_questions = 11;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.MA6ScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 11));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.MA6ScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 12;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.MA6ScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 12));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.MA6ScoreStandard} returns this
*/
proto.pbs.MA6ScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.MA6ScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.MA6ScoreStandard} returns this
 */
proto.pbs.MA6ScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18OperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18OperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18OperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18OperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18OperationDepInfo}
 */
proto.pbs.LAB18OperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18OperationDepInfo;
  return proto.pbs.LAB18OperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18OperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18OperationDepInfo}
 */
proto.pbs.LAB18OperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.LAB18OperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.LAB18OperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.LAB18OperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18OperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18OperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18OperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18OperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LAB18OperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.LAB18OperationStageType}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.LAB18OperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.LAB18OperationStageType} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LAB18OperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.LAB18OperationStepType}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.LAB18OperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.LAB18OperationStepType} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional LAB18OperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.LAB18OperationStepType}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.LAB18OperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.LAB18OperationStepType} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.LAB18OperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18OperationDepInfo} returns this
 */
proto.pbs.LAB18OperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18ScoreStandard.repeatedFields_ = [5,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18ScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18ScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18ScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetMaterial: (f = msg.getTargetMaterial()) && proto.pbs.LAB18ScoreStandard.TargetedMaterials.toObject(includeInstance, f),
    sputteringProcedure: (f = msg.getSputteringProcedure()) && proto.pbs.LAB18ScoreStandard.SputteringProcedure.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.LAB18OperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18ScoreStandard}
 */
proto.pbs.LAB18ScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18ScoreStandard;
  return proto.pbs.LAB18ScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18ScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18ScoreStandard}
 */
proto.pbs.LAB18ScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.LAB18ScoreStandard.TargetedMaterials;
      reader.readMessage(value,proto.pbs.LAB18ScoreStandard.TargetedMaterials.deserializeBinaryFromReader);
      msg.setTargetMaterial(value);
      break;
    case 4:
      var value = new proto.pbs.LAB18ScoreStandard.SputteringProcedure;
      reader.readMessage(value,proto.pbs.LAB18ScoreStandard.SputteringProcedure.deserializeBinaryFromReader);
      msg.setSputteringProcedure(value);
      break;
    case 5:
      var value = new proto.pbs.LAB18OperationDepInfo;
      reader.readMessage(value,proto.pbs.LAB18OperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 11:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 12:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18ScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18ScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18ScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetMaterial();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.LAB18ScoreStandard.TargetedMaterials.serializeBinaryToWriter
    );
  }
  f = message.getSputteringProcedure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.LAB18ScoreStandard.SputteringProcedure.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.LAB18OperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18ScoreStandard.TargetedMaterials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    materialOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    correctMaterialsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18ScoreStandard.TargetedMaterials;
  return proto.pbs.LAB18ScoreStandard.TargetedMaterials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaterialAllOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaterialOptions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectMaterials(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18ScoreStandard.TargetedMaterials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getMaterialOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getCorrectMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * repeated string material_all_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.getMaterialAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.setMaterialAllOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.addMaterialAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.clearMaterialAllOptionsList = function() {
  return this.setMaterialAllOptionsList([]);
};


/**
 * repeated string material_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.getMaterialOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.setMaterialOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.addMaterialOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.clearMaterialOptionsList = function() {
  return this.setMaterialOptionsList([]);
};


/**
 * repeated string correct_materials = 3;
 * @return {!Array<string>}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.getCorrectMaterialsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.setCorrectMaterialsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.addCorrectMaterials = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.clearCorrectMaterialsList = function() {
  return this.setCorrectMaterialsList([]);
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.TargetedMaterials} returns this
 */
proto.pbs.LAB18ScoreStandard.TargetedMaterials.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18ScoreStandard.ProgramParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18ScoreStandard.ProgramParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lowerBoundary: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    upperBoundary: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18ScoreStandard.ProgramParameters;
  return proto.pbs.LAB18ScoreStandard.ProgramParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18ScoreStandard.ProgramParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerBoundary(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperBoundary(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18ScoreStandard.ProgramParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18ScoreStandard.ProgramParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLowerBoundary();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUpperBoundary();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters} returns this
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float lower_boundary = 2;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.getLowerBoundary = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters} returns this
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.setLowerBoundary = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float upper_boundary = 3;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.getUpperBoundary = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters} returns this
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.setUpperBoundary = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters} returns this
 */
proto.pbs.LAB18ScoreStandard.ProgramParameters.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18ScoreStandard.SputteringProcedure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.toObject = function(includeInstance, msg) {
  var f, obj = {
    programType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    programParametersList: jspb.Message.toObjectList(msg.getProgramParametersList(),
    proto.pbs.LAB18ScoreStandard.ProgramParameters.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18ScoreStandard.SputteringProcedure;
  return proto.pbs.LAB18ScoreStandard.SputteringProcedure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.ProgramType} */ (reader.readEnum());
      msg.setProgramType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 3:
      var value = new proto.pbs.LAB18ScoreStandard.ProgramParameters;
      reader.readMessage(value,proto.pbs.LAB18ScoreStandard.ProgramParameters.deserializeBinaryFromReader);
      msg.addProgramParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18ScoreStandard.SputteringProcedure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProgramParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.LAB18ScoreStandard.ProgramParameters.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProgramType program_type = 1;
 * @return {!proto.pbs.ProgramType}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.getProgramType = function() {
  return /** @type {!proto.pbs.ProgramType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.ProgramType} value
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} returns this
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.setProgramType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} returns this
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated ProgramParameters program_parameters = 3;
 * @return {!Array<!proto.pbs.LAB18ScoreStandard.ProgramParameters>}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.getProgramParametersList = function() {
  return /** @type{!Array<!proto.pbs.LAB18ScoreStandard.ProgramParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18ScoreStandard.ProgramParameters, 3));
};


/**
 * @param {!Array<!proto.pbs.LAB18ScoreStandard.ProgramParameters>} value
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} returns this
*/
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.setProgramParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.LAB18ScoreStandard.ProgramParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18ScoreStandard.ProgramParameters}
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.addProgramParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.LAB18ScoreStandard.ProgramParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard.SputteringProcedure} returns this
 */
proto.pbs.LAB18ScoreStandard.SputteringProcedure.prototype.clearProgramParametersList = function() {
  return this.setProgramParametersList([]);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.LAB18ScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.LAB18ScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TargetedMaterials target_material = 3;
 * @return {?proto.pbs.LAB18ScoreStandard.TargetedMaterials}
 */
proto.pbs.LAB18ScoreStandard.prototype.getTargetMaterial = function() {
  return /** @type{?proto.pbs.LAB18ScoreStandard.TargetedMaterials} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18ScoreStandard.TargetedMaterials, 3));
};


/**
 * @param {?proto.pbs.LAB18ScoreStandard.TargetedMaterials|undefined} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
*/
proto.pbs.LAB18ScoreStandard.prototype.setTargetMaterial = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.clearTargetMaterial = function() {
  return this.setTargetMaterial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18ScoreStandard.prototype.hasTargetMaterial = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SputteringProcedure sputtering_procedure = 4;
 * @return {?proto.pbs.LAB18ScoreStandard.SputteringProcedure}
 */
proto.pbs.LAB18ScoreStandard.prototype.getSputteringProcedure = function() {
  return /** @type{?proto.pbs.LAB18ScoreStandard.SputteringProcedure} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18ScoreStandard.SputteringProcedure, 4));
};


/**
 * @param {?proto.pbs.LAB18ScoreStandard.SputteringProcedure|undefined} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
*/
proto.pbs.LAB18ScoreStandard.prototype.setSputteringProcedure = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.clearSputteringProcedure = function() {
  return this.setSputteringProcedure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.LAB18ScoreStandard.prototype.hasSputteringProcedure = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated LAB18OperationDepInfo operations = 5;
 * @return {!Array<!proto.pbs.LAB18OperationDepInfo>}
 */
proto.pbs.LAB18ScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.LAB18OperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18OperationDepInfo, 5));
};


/**
 * @param {!Array<!proto.pbs.LAB18OperationDepInfo>} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
*/
proto.pbs.LAB18ScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.LAB18OperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18OperationDepInfo}
 */
proto.pbs.LAB18ScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.LAB18OperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 11;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.LAB18ScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 11));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
*/
proto.pbs.LAB18ScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.LAB18ScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 12;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.LAB18ScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 12));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
*/
proto.pbs.LAB18ScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.LAB18ScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18ScoreStandard} returns this
 */
proto.pbs.LAB18ScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18TmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18TmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18TmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18TmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18TmplTypeReq}
 */
proto.pbs.LAB18TmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18TmplTypeReq;
  return proto.pbs.LAB18TmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18TmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18TmplTypeReq}
 */
proto.pbs.LAB18TmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.LAB18TemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18TmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18TmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18TmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18TmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional LAB18TemplateType tmplType = 1;
 * @return {!proto.pbs.LAB18TemplateType}
 */
proto.pbs.LAB18TmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.LAB18TemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.LAB18TemplateType} value
 * @return {!proto.pbs.LAB18TmplTypeReq} returns this
 */
proto.pbs.LAB18TmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18TmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18TmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18TmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18TmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18TmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.LAB18OperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18TmplTypeResp}
 */
proto.pbs.LAB18TmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18TmplTypeResp;
  return proto.pbs.LAB18TmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18TmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18TmplTypeResp}
 */
proto.pbs.LAB18TmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.LAB18OperationDepInfo;
      reader.readMessage(value,proto.pbs.LAB18OperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18TmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18TmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18TmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18TmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.LAB18OperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.LAB18TmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.LAB18TmplTypeResp} returns this
*/
proto.pbs.LAB18TmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.LAB18TmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18TmplTypeResp} returns this
 */
proto.pbs.LAB18TmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated LAB18OperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.LAB18OperationDepInfo>}
 */
proto.pbs.LAB18TmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.LAB18OperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.LAB18OperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.LAB18OperationDepInfo>} value
 * @return {!proto.pbs.LAB18TmplTypeResp} returns this
*/
proto.pbs.LAB18TmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.LAB18OperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18OperationDepInfo}
 */
proto.pbs.LAB18TmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.LAB18OperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18TmplTypeResp} returns this
 */
proto.pbs.LAB18TmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterOperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterOperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterOperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterOperationDepInfo}
 */
proto.pbs.SpinCoaterOperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterOperationDepInfo;
  return proto.pbs.SpinCoaterOperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterOperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterOperationDepInfo}
 */
proto.pbs.SpinCoaterOperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.SpinCoaterOperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.SpinCoaterOperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.SpinCoaterOperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterOperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterOperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterOperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SpinCoaterOperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.SpinCoaterOperationStageType}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.SpinCoaterOperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.SpinCoaterOperationStageType} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SpinCoaterOperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.SpinCoaterOperationStepType}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.SpinCoaterOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.SpinCoaterOperationStepType} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SpinCoaterOperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.SpinCoaterOperationStepType}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.SpinCoaterOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.SpinCoaterOperationStepType} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterOperationDepInfo} returns this
 */
proto.pbs.SpinCoaterOperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterScoreStandard.repeatedFields_ = [3,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coaterDetailList: jspb.Message.toObjectList(msg.getCoaterDetailList(),
    proto.pbs.SpinCoaterScoreStandard.CoaterDetail.toObject, includeInstance),
    prebake: (f = msg.getPrebake()) && proto.pbs.SpinCoaterScoreStandard.PreBake.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.SpinCoaterOperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterScoreStandard}
 */
proto.pbs.SpinCoaterScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterScoreStandard;
  return proto.pbs.SpinCoaterScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterScoreStandard}
 */
proto.pbs.SpinCoaterScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.SpinCoaterScoreStandard.CoaterDetail;
      reader.readMessage(value,proto.pbs.SpinCoaterScoreStandard.CoaterDetail.deserializeBinaryFromReader);
      msg.addCoaterDetail(value);
      break;
    case 4:
      var value = new proto.pbs.SpinCoaterScoreStandard.PreBake;
      reader.readMessage(value,proto.pbs.SpinCoaterScoreStandard.PreBake.deserializeBinaryFromReader);
      msg.setPrebake(value);
      break;
    case 5:
      var value = new proto.pbs.SpinCoaterOperationDepInfo;
      reader.readMessage(value,proto.pbs.SpinCoaterOperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 6:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 7:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoaterDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.SpinCoaterScoreStandard.CoaterDetail.serializeBinaryToWriter
    );
  }
  f = message.getPrebake();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.SpinCoaterScoreStandard.PreBake.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.SpinCoaterOperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterScoreStandard.CoaterDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lowerRotationalSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    upperRotationalSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    rotationalSpeedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    upperLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lowerLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterScoreStandard.CoaterDetail;
  return proto.pbs.SpinCoaterScoreStandard.CoaterDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerRotationalSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperRotationalSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRotationalSpeedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimitTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimitTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimeScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterScoreStandard.CoaterDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLowerRotationalSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUpperRotationalSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRotationalSpeedScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getUpperLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLowerLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTimeScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double lower_rotational_speed = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getLowerRotationalSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setLowerRotationalSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double upper_rotational_speed = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getUpperRotationalSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setUpperRotationalSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double rotational_speed_score = 4;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getRotationalSpeedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setRotationalSpeedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float upper_limit_time = 5;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getUpperLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setUpperLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float lower_limit_time = 6;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getLowerLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setLowerLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double time_score = 7;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.getTimeScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail} returns this
 */
proto.pbs.SpinCoaterScoreStandard.CoaterDetail.prototype.setTimeScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterScoreStandard.PreBake.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterScoreStandard.PreBake} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    temperatureScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    upperLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lowerLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    timeScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterScoreStandard.PreBake;
  return proto.pbs.SpinCoaterScoreStandard.PreBake.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterScoreStandard.PreBake} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerTemperature(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperTemperature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTemperatureScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimitTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimitTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimeScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterScoreStandard.PreBake.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterScoreStandard.PreBake} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTemperatureScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getUpperLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLowerLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTimeScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional float lower_temperature = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getLowerTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setLowerTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_temperature = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getUpperTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setUpperTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double temperature_score = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getTemperatureScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setTemperatureScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float upper_limit_time = 4;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getUpperLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setUpperLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float lower_limit_time = 5;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getLowerLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setLowerLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double time_score = 6;
 * @return {number}
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.getTimeScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterScoreStandard.PreBake} returns this
 */
proto.pbs.SpinCoaterScoreStandard.PreBake.prototype.setTimeScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CoaterDetail coater_detail = 3;
 * @return {!Array<!proto.pbs.SpinCoaterScoreStandard.CoaterDetail>}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getCoaterDetailList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterScoreStandard.CoaterDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterScoreStandard.CoaterDetail, 3));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterScoreStandard.CoaterDetail>} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
*/
proto.pbs.SpinCoaterScoreStandard.prototype.setCoaterDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterScoreStandard.CoaterDetail}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.addCoaterDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.SpinCoaterScoreStandard.CoaterDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.clearCoaterDetailList = function() {
  return this.setCoaterDetailList([]);
};


/**
 * optional PreBake preBake = 4;
 * @return {?proto.pbs.SpinCoaterScoreStandard.PreBake}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getPrebake = function() {
  return /** @type{?proto.pbs.SpinCoaterScoreStandard.PreBake} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterScoreStandard.PreBake, 4));
};


/**
 * @param {?proto.pbs.SpinCoaterScoreStandard.PreBake|undefined} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
*/
proto.pbs.SpinCoaterScoreStandard.prototype.setPrebake = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.clearPrebake = function() {
  return this.setPrebake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.hasPrebake = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated SpinCoaterOperationDepInfo operations = 5;
 * @return {!Array<!proto.pbs.SpinCoaterOperationDepInfo>}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterOperationDepInfo, 5));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterOperationDepInfo>} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
*/
proto.pbs.SpinCoaterScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.SpinCoaterOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterOperationDepInfo}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.SpinCoaterOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 6;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 6));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
*/
proto.pbs.SpinCoaterScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 7;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
*/
proto.pbs.SpinCoaterScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.SpinCoaterScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterScoreStandard} returns this
 */
proto.pbs.SpinCoaterScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterTmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterTmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterTmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterTmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterTmplTypeReq}
 */
proto.pbs.SpinCoaterTmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterTmplTypeReq;
  return proto.pbs.SpinCoaterTmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterTmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterTmplTypeReq}
 */
proto.pbs.SpinCoaterTmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.SpinCoaterTemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterTmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterTmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterTmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterTmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional SpinCoaterTemplateType tmplType = 1;
 * @return {!proto.pbs.SpinCoaterTemplateType}
 */
proto.pbs.SpinCoaterTmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.SpinCoaterTemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.SpinCoaterTemplateType} value
 * @return {!proto.pbs.SpinCoaterTmplTypeReq} returns this
 */
proto.pbs.SpinCoaterTmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterTmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterTmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterTmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterTmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.SpinCoaterOperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterTmplTypeResp}
 */
proto.pbs.SpinCoaterTmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterTmplTypeResp;
  return proto.pbs.SpinCoaterTmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterTmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterTmplTypeResp}
 */
proto.pbs.SpinCoaterTmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.SpinCoaterOperationDepInfo;
      reader.readMessage(value,proto.pbs.SpinCoaterOperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterTmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterTmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterTmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.SpinCoaterOperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.SpinCoaterTmplTypeResp} returns this
*/
proto.pbs.SpinCoaterTmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterTmplTypeResp} returns this
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated SpinCoaterOperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.SpinCoaterOperationDepInfo>}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterOperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterOperationDepInfo>} value
 * @return {!proto.pbs.SpinCoaterTmplTypeResp} returns this
*/
proto.pbs.SpinCoaterTmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.SpinCoaterOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterOperationDepInfo}
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.SpinCoaterOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterTmplTypeResp} returns this
 */
proto.pbs.SpinCoaterTmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningScoreStandard.repeatedFields_ = [5,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cleaningFluid: (f = msg.getCleaningFluid()) && proto.pbs.CleaningScoreStandard.CleaningFluid.toObject(includeInstance, f),
    requireOrder: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    cleaningDetailList: jspb.Message.toObjectList(msg.getCleaningDetailList(),
    proto.pbs.CleaningScoreStandard.CleaningDetail.toObject, includeInstance),
    cleaningProcess: (f = msg.getCleaningProcess()) && proto.pbs.CleaningScoreStandard.CleaningProcess.toObject(includeInstance, f),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard}
 */
proto.pbs.CleaningScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard;
  return proto.pbs.CleaningScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard}
 */
proto.pbs.CleaningScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.CleaningScoreStandard.CleaningFluid;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.CleaningFluid.deserializeBinaryFromReader);
      msg.setCleaningFluid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireOrder(value);
      break;
    case 5:
      var value = new proto.pbs.CleaningScoreStandard.CleaningDetail;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.CleaningDetail.deserializeBinaryFromReader);
      msg.addCleaningDetail(value);
      break;
    case 6:
      var value = new proto.pbs.CleaningScoreStandard.CleaningProcess;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.CleaningProcess.deserializeBinaryFromReader);
      msg.setCleaningProcess(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 8:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCleaningFluid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.CleaningScoreStandard.CleaningFluid.serializeBinaryToWriter
    );
  }
  f = message.getRequireOrder();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCleaningDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.CleaningScoreStandard.CleaningDetail.serializeBinaryToWriter
    );
  }
  f = message.getCleaningProcess();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.CleaningScoreStandard.CleaningProcess.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.CleaningFluid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard.CleaningFluid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.toObject = function(includeInstance, msg) {
  var f, obj = {
    fluidOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fluidAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard.CleaningFluid;
  return proto.pbs.CleaningScoreStandard.CleaningFluid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningFluid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFluidOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFluidAllOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.CleaningFluid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningFluid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFluidOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFluidAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string fluid_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.getFluidOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.setFluidOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.addFluidOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.clearFluidOptionsList = function() {
  return this.setFluidOptionsList([]);
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated string fluid_all_options = 4;
 * @return {!Array<string>}
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.getFluidAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.setFluidAllOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.addFluidAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningFluid} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningFluid.prototype.clearFluidAllOptionsList = function() {
  return this.setFluidAllOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.TemperatureSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard.TemperatureSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard.TemperatureSetting}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard.TemperatureSetting;
  return proto.pbs.CleaningScoreStandard.TemperatureSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard.TemperatureSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard.TemperatureSetting}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerTemperature(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperTemperature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.TemperatureSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard.TemperatureSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_temperature = 1;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.getLowerTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.setLowerTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_temperature = 2;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.getUpperTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.setUpperTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TemperatureSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.TimeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard.TimeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.TimeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard.TimeSetting}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard.TimeSetting;
  return proto.pbs.CleaningScoreStandard.TimeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard.TimeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard.TimeSetting}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimitTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimitTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.TimeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard.TimeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.TimeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_limit_time = 1;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.getLowerLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TimeSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.setLowerLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_limit_time = 2;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.getUpperLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TimeSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.setUpperLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.TimeSetting} returns this
 */
proto.pbs.CleaningScoreStandard.TimeSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.CleaningDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard.CleaningDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    fluidOption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cleaningDevice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cleaningTimes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cleaningTimesScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeSetting: (f = msg.getTimeSetting()) && proto.pbs.CleaningScoreStandard.TimeSetting.toObject(includeInstance, f),
    temperatureSetting: (f = msg.getTemperatureSetting()) && proto.pbs.CleaningScoreStandard.TemperatureSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard.CleaningDetail;
  return proto.pbs.CleaningScoreStandard.CleaningDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setCleaningDevice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviceScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCleaningTimes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCleaningTimesScore(value);
      break;
    case 7:
      var value = new proto.pbs.CleaningScoreStandard.TimeSetting;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.TimeSetting.deserializeBinaryFromReader);
      msg.setTimeSetting(value);
      break;
    case 8:
      var value = new proto.pbs.CleaningScoreStandard.TemperatureSetting;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.TemperatureSetting.deserializeBinaryFromReader);
      msg.setTemperatureSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.CleaningDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCleaningDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCleaningTimes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCleaningTimesScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTimeSetting();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.CleaningScoreStandard.TimeSetting.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureSetting();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.CleaningScoreStandard.TemperatureSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional double order_score = 1;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getOrderScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setOrderScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string fluid_option = 2;
 * @return {string}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CleaningDeviceType cleaning_device = 3;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getCleaningDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setCleaningDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double device_score = 4;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getDeviceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setDeviceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 cleaning_times = 5;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getCleaningTimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setCleaningTimes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double cleaning_times_score = 6;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getCleaningTimesScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setCleaningTimesScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional TimeSetting time_setting = 7;
 * @return {?proto.pbs.CleaningScoreStandard.TimeSetting}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getTimeSetting = function() {
  return /** @type{?proto.pbs.CleaningScoreStandard.TimeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningScoreStandard.TimeSetting, 7));
};


/**
 * @param {?proto.pbs.CleaningScoreStandard.TimeSetting|undefined} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
*/
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setTimeSetting = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.clearTimeSetting = function() {
  return this.setTimeSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.hasTimeSetting = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TemperatureSetting temperature_setting = 8;
 * @return {?proto.pbs.CleaningScoreStandard.TemperatureSetting}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.getTemperatureSetting = function() {
  return /** @type{?proto.pbs.CleaningScoreStandard.TemperatureSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningScoreStandard.TemperatureSetting, 8));
};


/**
 * @param {?proto.pbs.CleaningScoreStandard.TemperatureSetting|undefined} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
*/
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.setTemperatureSetting = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.clearTemperatureSetting = function() {
  return this.setTemperatureSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningScoreStandard.CleaningDetail.prototype.hasTemperatureSetting = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningScoreStandard.CleaningProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningScoreStandard.CleaningProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    cleanStep: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningProcess}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningScoreStandard.CleaningProcess;
  return proto.pbs.CleaningScoreStandard.CleaningProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningScoreStandard.CleaningProcess}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.CleaningStep} */ (reader.readEnum());
      msg.setCleanStep(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningScoreStandard.CleaningProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningScoreStandard.CleaningProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCleanStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CleaningStep clean_step = 1;
 * @return {!proto.pbs.CleaningStep}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.getCleanStep = function() {
  return /** @type {!proto.pbs.CleaningStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.CleaningStep} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningProcess} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.setCleanStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningScoreStandard.CleaningProcess} returns this
 */
proto.pbs.CleaningScoreStandard.CleaningProcess.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.CleaningScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.CleaningScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CleaningFluid cleaning_fluid = 3;
 * @return {?proto.pbs.CleaningScoreStandard.CleaningFluid}
 */
proto.pbs.CleaningScoreStandard.prototype.getCleaningFluid = function() {
  return /** @type{?proto.pbs.CleaningScoreStandard.CleaningFluid} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningScoreStandard.CleaningFluid, 3));
};


/**
 * @param {?proto.pbs.CleaningScoreStandard.CleaningFluid|undefined} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
*/
proto.pbs.CleaningScoreStandard.prototype.setCleaningFluid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.clearCleaningFluid = function() {
  return this.setCleaningFluid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningScoreStandard.prototype.hasCleaningFluid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool require_order = 4;
 * @return {boolean}
 */
proto.pbs.CleaningScoreStandard.prototype.getRequireOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.setRequireOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated CleaningDetail cleaning_detail = 5;
 * @return {!Array<!proto.pbs.CleaningScoreStandard.CleaningDetail>}
 */
proto.pbs.CleaningScoreStandard.prototype.getCleaningDetailList = function() {
  return /** @type{!Array<!proto.pbs.CleaningScoreStandard.CleaningDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.CleaningScoreStandard.CleaningDetail, 5));
};


/**
 * @param {!Array<!proto.pbs.CleaningScoreStandard.CleaningDetail>} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
*/
proto.pbs.CleaningScoreStandard.prototype.setCleaningDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.CleaningScoreStandard.CleaningDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningScoreStandard.CleaningDetail}
 */
proto.pbs.CleaningScoreStandard.prototype.addCleaningDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.CleaningScoreStandard.CleaningDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.clearCleaningDetailList = function() {
  return this.setCleaningDetailList([]);
};


/**
 * optional CleaningProcess cleaning_process = 6;
 * @return {?proto.pbs.CleaningScoreStandard.CleaningProcess}
 */
proto.pbs.CleaningScoreStandard.prototype.getCleaningProcess = function() {
  return /** @type{?proto.pbs.CleaningScoreStandard.CleaningProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningScoreStandard.CleaningProcess, 6));
};


/**
 * @param {?proto.pbs.CleaningScoreStandard.CleaningProcess|undefined} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
*/
proto.pbs.CleaningScoreStandard.prototype.setCleaningProcess = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.clearCleaningProcess = function() {
  return this.setCleaningProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CleaningScoreStandard.prototype.hasCleaningProcess = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated SimiQuestion simi_questions = 7;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.CleaningScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
*/
proto.pbs.CleaningScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.CleaningScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 8;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.CleaningScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 8));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.CleaningScoreStandard} returns this
*/
proto.pbs.CleaningScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.CleaningScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningScoreStandard} returns this
 */
proto.pbs.CleaningScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningTmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningTmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningTmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningTmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningTmplTypeReq}
 */
proto.pbs.CleaningTmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningTmplTypeReq;
  return proto.pbs.CleaningTmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningTmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningTmplTypeReq}
 */
proto.pbs.CleaningTmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.CleaningTemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningTmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningTmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningTmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningTmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CleaningTemplateType tmplType = 1;
 * @return {!proto.pbs.CleaningTemplateType}
 */
proto.pbs.CleaningTmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.CleaningTemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.CleaningTemplateType} value
 * @return {!proto.pbs.CleaningTmplTypeReq} returns this
 */
proto.pbs.CleaningTmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningTmplTypeResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningTmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningTmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningTmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningTmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningTmplTypeResp}
 */
proto.pbs.CleaningTmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningTmplTypeResp;
  return proto.pbs.CleaningTmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningTmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningTmplTypeResp}
 */
proto.pbs.CleaningTmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningTmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningTmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningTmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningTmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.CleaningTmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.CleaningTmplTypeResp} returns this
*/
proto.pbs.CleaningTmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.CleaningTmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningTmplTypeResp} returns this
 */
proto.pbs.CleaningTmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentScoreStandard.repeatedFields_ = [5,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    developmentFluid: (f = msg.getDevelopmentFluid()) && proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.toObject(includeInstance, f),
    requireOrder: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    developmentDetailList: jspb.Message.toObjectList(msg.getDevelopmentDetailList(),
    proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.toObject, includeInstance),
    developmentProcess: (f = msg.getDevelopmentProcess()) && proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.toObject(includeInstance, f),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard}
 */
proto.pbs.DevelopmentScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard;
  return proto.pbs.DevelopmentScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard}
 */
proto.pbs.DevelopmentScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.DevelopmentScoreStandard.DevelopmentFluid;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.deserializeBinaryFromReader);
      msg.setDevelopmentFluid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireOrder(value);
      break;
    case 5:
      var value = new proto.pbs.DevelopmentScoreStandard.DevelopmentDetail;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.deserializeBinaryFromReader);
      msg.addDevelopmentDetail(value);
      break;
    case 6:
      var value = new proto.pbs.DevelopmentScoreStandard.DevelopmentProcess;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.deserializeBinaryFromReader);
      msg.setDevelopmentProcess(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 8:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevelopmentFluid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.serializeBinaryToWriter
    );
  }
  f = message.getRequireOrder();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDevelopmentDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentProcess();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.toObject = function(includeInstance, msg) {
  var f, obj = {
    fluidOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fluidAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard.DevelopmentFluid;
  return proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFluidOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFluidAllOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFluidOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFluidAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string fluid_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.getFluidOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.setFluidOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.addFluidOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.clearFluidOptionsList = function() {
  return this.setFluidOptionsList([]);
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated string fluid_all_options = 4;
 * @return {!Array<string>}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.getFluidAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.setFluidAllOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.addFluidAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentFluid.prototype.clearFluidAllOptionsList = function() {
  return this.setFluidAllOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.TemperatureSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard.TemperatureSetting;
  return proto.pbs.DevelopmentScoreStandard.TemperatureSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerTemperature(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperTemperature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.TemperatureSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_temperature = 1;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.getLowerTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.setLowerTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_temperature = 2;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.getUpperTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.setUpperTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TemperatureSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TemperatureSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.TimeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard.TimeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperLimitTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard.TimeSetting}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard.TimeSetting;
  return proto.pbs.DevelopmentScoreStandard.TimeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard.TimeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard.TimeSetting}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimitTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimitTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.TimeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard.TimeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperLimitTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_limit_time = 1;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.getLowerLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TimeSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.setLowerLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_limit_time = 2;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.getUpperLimitTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TimeSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.setUpperLimitTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.TimeSetting} returns this
 */
proto.pbs.DevelopmentScoreStandard.TimeSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    fluidOption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    developmentDevice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    developmentTimes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    developmentTimesScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeSetting: (f = msg.getTimeSetting()) && proto.pbs.DevelopmentScoreStandard.TimeSetting.toObject(includeInstance, f),
    temperatureSetting: (f = msg.getTemperatureSetting()) && proto.pbs.DevelopmentScoreStandard.TemperatureSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard.DevelopmentDetail;
  return proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setDevelopmentDevice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviceScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDevelopmentTimes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDevelopmentTimesScore(value);
      break;
    case 7:
      var value = new proto.pbs.DevelopmentScoreStandard.TimeSetting;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.TimeSetting.deserializeBinaryFromReader);
      msg.setTimeSetting(value);
      break;
    case 8:
      var value = new proto.pbs.DevelopmentScoreStandard.TemperatureSetting;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.TemperatureSetting.deserializeBinaryFromReader);
      msg.setTemperatureSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevelopmentDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDevelopmentTimes();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDevelopmentTimesScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTimeSetting();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.DevelopmentScoreStandard.TimeSetting.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureSetting();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.DevelopmentScoreStandard.TemperatureSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional double order_score = 1;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getOrderScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setOrderScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string fluid_option = 2;
 * @return {string}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CleaningDeviceType development_device = 3;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getDevelopmentDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setDevelopmentDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double device_score = 4;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getDeviceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setDeviceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 development_times = 5;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getDevelopmentTimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setDevelopmentTimes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double development_times_score = 6;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getDevelopmentTimesScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setDevelopmentTimesScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional TimeSetting time_setting = 7;
 * @return {?proto.pbs.DevelopmentScoreStandard.TimeSetting}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getTimeSetting = function() {
  return /** @type{?proto.pbs.DevelopmentScoreStandard.TimeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentScoreStandard.TimeSetting, 7));
};


/**
 * @param {?proto.pbs.DevelopmentScoreStandard.TimeSetting|undefined} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
*/
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setTimeSetting = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.clearTimeSetting = function() {
  return this.setTimeSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.hasTimeSetting = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TemperatureSetting temperature_setting = 8;
 * @return {?proto.pbs.DevelopmentScoreStandard.TemperatureSetting}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.getTemperatureSetting = function() {
  return /** @type{?proto.pbs.DevelopmentScoreStandard.TemperatureSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentScoreStandard.TemperatureSetting, 8));
};


/**
 * @param {?proto.pbs.DevelopmentScoreStandard.TemperatureSetting|undefined} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
*/
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.setTemperatureSetting = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.clearTemperatureSetting = function() {
  return this.setTemperatureSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentDetail.prototype.hasTemperatureSetting = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    developmentStep: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentScoreStandard.DevelopmentProcess;
  return proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.CleaningStep} */ (reader.readEnum());
      msg.setDevelopmentStep(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevelopmentStep();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional CleaningStep development_step = 1;
 * @return {!proto.pbs.CleaningStep}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.getDevelopmentStep = function() {
  return /** @type {!proto.pbs.CleaningStep} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.CleaningStep} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.setDevelopmentStep = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} returns this
 */
proto.pbs.DevelopmentScoreStandard.DevelopmentProcess.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DevelopmentFluid development_fluid = 3;
 * @return {?proto.pbs.DevelopmentScoreStandard.DevelopmentFluid}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getDevelopmentFluid = function() {
  return /** @type{?proto.pbs.DevelopmentScoreStandard.DevelopmentFluid} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentScoreStandard.DevelopmentFluid, 3));
};


/**
 * @param {?proto.pbs.DevelopmentScoreStandard.DevelopmentFluid|undefined} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
*/
proto.pbs.DevelopmentScoreStandard.prototype.setDevelopmentFluid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.clearDevelopmentFluid = function() {
  return this.setDevelopmentFluid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentScoreStandard.prototype.hasDevelopmentFluid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool require_order = 4;
 * @return {boolean}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getRequireOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.setRequireOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated DevelopmentDetail development_detail = 5;
 * @return {!Array<!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail>}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getDevelopmentDetailList = function() {
  return /** @type{!Array<!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.DevelopmentScoreStandard.DevelopmentDetail, 5));
};


/**
 * @param {!Array<!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail>} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
*/
proto.pbs.DevelopmentScoreStandard.prototype.setDevelopmentDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.DevelopmentScoreStandard.DevelopmentDetail}
 */
proto.pbs.DevelopmentScoreStandard.prototype.addDevelopmentDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.DevelopmentScoreStandard.DevelopmentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.clearDevelopmentDetailList = function() {
  return this.setDevelopmentDetailList([]);
};


/**
 * optional DevelopmentProcess development_process = 6;
 * @return {?proto.pbs.DevelopmentScoreStandard.DevelopmentProcess}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getDevelopmentProcess = function() {
  return /** @type{?proto.pbs.DevelopmentScoreStandard.DevelopmentProcess} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentScoreStandard.DevelopmentProcess, 6));
};


/**
 * @param {?proto.pbs.DevelopmentScoreStandard.DevelopmentProcess|undefined} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
*/
proto.pbs.DevelopmentScoreStandard.prototype.setDevelopmentProcess = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.clearDevelopmentProcess = function() {
  return this.setDevelopmentProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.DevelopmentScoreStandard.prototype.hasDevelopmentProcess = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated SimiQuestion simi_questions = 7;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
*/
proto.pbs.DevelopmentScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.DevelopmentScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 8;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.DevelopmentScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 8));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
*/
proto.pbs.DevelopmentScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.DevelopmentScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.DevelopmentScoreStandard} returns this
 */
proto.pbs.DevelopmentScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreRangeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreRangeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreRangeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreRangeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ScoreRangeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreRangeSetting;
  return proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreRangeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLowerLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUpperLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreRangeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreRangeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreRangeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreRangeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getUpperLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional float lower_limit = 1;
 * @return {number}
 */
proto.pbs.ScoreRangeSetting.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreRangeSetting} returns this
 */
proto.pbs.ScoreRangeSetting.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float upper_limit = 2;
 * @return {number}
 */
proto.pbs.ScoreRangeSetting.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreRangeSetting} returns this
 */
proto.pbs.ScoreRangeSetting.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.ScoreRangeSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreRangeSetting} returns this
 */
proto.pbs.ScoreRangeSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaOperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaOperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaOperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaOperationDepInfo}
 */
proto.pbs.PlasmaOperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaOperationDepInfo;
  return proto.pbs.PlasmaOperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaOperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaOperationDepInfo}
 */
proto.pbs.PlasmaOperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.PlasmaOperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.PlasmaOperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.PlasmaOperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaOperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaOperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaOperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlasmaOperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.PlasmaOperationStageType}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.PlasmaOperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.PlasmaOperationStageType} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PlasmaOperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.PlasmaOperationStepType}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.PlasmaOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.PlasmaOperationStepType} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PlasmaOperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.PlasmaOperationStepType}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.PlasmaOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.PlasmaOperationStepType} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.PlasmaOperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaOperationDepInfo} returns this
 */
proto.pbs.PlasmaOperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaScoreStandard.repeatedFields_ = [4,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    etchinggases: (f = msg.getEtchinggases()) && proto.pbs.PlasmaScoreStandard.Etchinggases.toObject(includeInstance, f),
    gasParametersList: jspb.Message.toObjectList(msg.getGasParametersList(),
    proto.pbs.PlasmaScoreStandard.GasParameters.toObject, includeInstance),
    etchParameters: (f = msg.getEtchParameters()) && proto.pbs.PlasmaScoreStandard.EtchParameters.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.PlasmaOperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaScoreStandard}
 */
proto.pbs.PlasmaScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaScoreStandard;
  return proto.pbs.PlasmaScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaScoreStandard}
 */
proto.pbs.PlasmaScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.PlasmaScoreStandard.Etchinggases;
      reader.readMessage(value,proto.pbs.PlasmaScoreStandard.Etchinggases.deserializeBinaryFromReader);
      msg.setEtchinggases(value);
      break;
    case 4:
      var value = new proto.pbs.PlasmaScoreStandard.GasParameters;
      reader.readMessage(value,proto.pbs.PlasmaScoreStandard.GasParameters.deserializeBinaryFromReader);
      msg.addGasParameters(value);
      break;
    case 5:
      var value = new proto.pbs.PlasmaScoreStandard.EtchParameters;
      reader.readMessage(value,proto.pbs.PlasmaScoreStandard.EtchParameters.deserializeBinaryFromReader);
      msg.setEtchParameters(value);
      break;
    case 6:
      var value = new proto.pbs.PlasmaOperationDepInfo;
      reader.readMessage(value,proto.pbs.PlasmaOperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 8:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEtchinggases();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.PlasmaScoreStandard.Etchinggases.serializeBinaryToWriter
    );
  }
  f = message.getGasParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.PlasmaScoreStandard.GasParameters.serializeBinaryToWriter
    );
  }
  f = message.getEtchParameters();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.PlasmaScoreStandard.EtchParameters.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.PlasmaOperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaScoreStandard.Etchinggases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaScoreStandard.Etchinggases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.toObject = function(includeInstance, msg) {
  var f, obj = {
    gasOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    gasAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaScoreStandard.Etchinggases;
  return proto.pbs.PlasmaScoreStandard.Etchinggases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaScoreStandard.Etchinggases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addGasOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addGasAllOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaScoreStandard.Etchinggases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaScoreStandard.Etchinggases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGasOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getGasAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string gas_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.getGasOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.setGasOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.addGasOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.clearGasOptionsList = function() {
  return this.setGasOptionsList([]);
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated string gas_all_options = 4;
 * @return {!Array<string>}
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.getGasAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.setGasAllOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.addGasAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard.Etchinggases} returns this
 */
proto.pbs.PlasmaScoreStandard.Etchinggases.prototype.clearGasAllOptionsList = function() {
  return this.setGasAllOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaScoreStandard.GasParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaScoreStandard.GasParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.GasParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    pressureParameter: (f = msg.getPressureParameter()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    flowParameter: (f = msg.getFlowParameter()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    gasOption: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaScoreStandard.GasParameters;
  return proto.pbs.PlasmaScoreStandard.GasParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaScoreStandard.GasParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setPressureParameter(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setFlowParameter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaScoreStandard.GasParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaScoreStandard.GasParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.GasParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPressureParameter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getFlowParameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getGasOption();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ScoreRangeSetting pressure_parameter = 1;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.getPressureParameter = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 1));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters} returns this
*/
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.setPressureParameter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.clearPressureParameter = function() {
  return this.setPressureParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.hasPressureParameter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting flow_parameter = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.getFlowParameter = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters} returns this
*/
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.setFlowParameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.clearFlowParameter = function() {
  return this.setFlowParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.hasFlowParameter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string gas_option = 3;
 * @return {string}
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.getGasOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.GasParameters.prototype.setGasOption = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaScoreStandard.EtchParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaScoreStandard.EtchParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeParameter: (f = msg.getTimeParameter()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    icpParameter: (f = msg.getIcpParameter()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    rfParameter: (f = msg.getRfParameter()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaScoreStandard.EtchParameters;
  return proto.pbs.PlasmaScoreStandard.EtchParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaScoreStandard.EtchParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setTimeParameter(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setIcpParameter(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setRfParameter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaScoreStandard.EtchParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaScoreStandard.EtchParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeParameter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getIcpParameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getRfParameter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScoreRangeSetting time_parameter = 1;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.getTimeParameter = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 1));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
*/
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.setTimeParameter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.clearTimeParameter = function() {
  return this.setTimeParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.hasTimeParameter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting ICP_parameter = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.getIcpParameter = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
*/
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.setIcpParameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.clearIcpParameter = function() {
  return this.setIcpParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.hasIcpParameter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScoreRangeSetting RF_parameter = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.getRfParameter = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
*/
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.setRfParameter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard.EtchParameters} returns this
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.clearRfParameter = function() {
  return this.setRfParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.EtchParameters.prototype.hasRfParameter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.PlasmaScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.PlasmaScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Etchinggases etchinggases = 3;
 * @return {?proto.pbs.PlasmaScoreStandard.Etchinggases}
 */
proto.pbs.PlasmaScoreStandard.prototype.getEtchinggases = function() {
  return /** @type{?proto.pbs.PlasmaScoreStandard.Etchinggases} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaScoreStandard.Etchinggases, 3));
};


/**
 * @param {?proto.pbs.PlasmaScoreStandard.Etchinggases|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setEtchinggases = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearEtchinggases = function() {
  return this.setEtchinggases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.prototype.hasEtchinggases = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated GasParameters gas_parameters = 4;
 * @return {!Array<!proto.pbs.PlasmaScoreStandard.GasParameters>}
 */
proto.pbs.PlasmaScoreStandard.prototype.getGasParametersList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaScoreStandard.GasParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaScoreStandard.GasParameters, 4));
};


/**
 * @param {!Array<!proto.pbs.PlasmaScoreStandard.GasParameters>} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setGasParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.PlasmaScoreStandard.GasParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaScoreStandard.GasParameters}
 */
proto.pbs.PlasmaScoreStandard.prototype.addGasParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.PlasmaScoreStandard.GasParameters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearGasParametersList = function() {
  return this.setGasParametersList([]);
};


/**
 * optional EtchParameters etch_parameters = 5;
 * @return {?proto.pbs.PlasmaScoreStandard.EtchParameters}
 */
proto.pbs.PlasmaScoreStandard.prototype.getEtchParameters = function() {
  return /** @type{?proto.pbs.PlasmaScoreStandard.EtchParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaScoreStandard.EtchParameters, 5));
};


/**
 * @param {?proto.pbs.PlasmaScoreStandard.EtchParameters|undefined} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setEtchParameters = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearEtchParameters = function() {
  return this.setEtchParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PlasmaScoreStandard.prototype.hasEtchParameters = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated PlasmaOperationDepInfo operations = 6;
 * @return {!Array<!proto.pbs.PlasmaOperationDepInfo>}
 */
proto.pbs.PlasmaScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaOperationDepInfo, 6));
};


/**
 * @param {!Array<!proto.pbs.PlasmaOperationDepInfo>} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.PlasmaOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaOperationDepInfo}
 */
proto.pbs.PlasmaScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.PlasmaOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 7;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.PlasmaScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.PlasmaScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 8;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.PlasmaScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 8));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
*/
proto.pbs.PlasmaScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.PlasmaScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaScoreStandard} returns this
 */
proto.pbs.PlasmaScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaTmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaTmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaTmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaTmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaTmplTypeReq}
 */
proto.pbs.PlasmaTmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaTmplTypeReq;
  return proto.pbs.PlasmaTmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaTmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaTmplTypeReq}
 */
proto.pbs.PlasmaTmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.PlasmaTemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaTmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaTmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaTmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaTmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional PlasmaTemplateType tmplType = 1;
 * @return {!proto.pbs.PlasmaTemplateType}
 */
proto.pbs.PlasmaTmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.PlasmaTemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.PlasmaTemplateType} value
 * @return {!proto.pbs.PlasmaTmplTypeReq} returns this
 */
proto.pbs.PlasmaTmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaTmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaTmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaTmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaTmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.PlasmaOperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaTmplTypeResp}
 */
proto.pbs.PlasmaTmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaTmplTypeResp;
  return proto.pbs.PlasmaTmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaTmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaTmplTypeResp}
 */
proto.pbs.PlasmaTmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.PlasmaOperationDepInfo;
      reader.readMessage(value,proto.pbs.PlasmaOperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaTmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaTmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaTmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.PlasmaOperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.PlasmaTmplTypeResp} returns this
*/
proto.pbs.PlasmaTmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaTmplTypeResp} returns this
 */
proto.pbs.PlasmaTmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated PlasmaOperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.PlasmaOperationDepInfo>}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaOperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.PlasmaOperationDepInfo>} value
 * @return {!proto.pbs.PlasmaTmplTypeResp} returns this
*/
proto.pbs.PlasmaTmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.PlasmaOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaOperationDepInfo}
 */
proto.pbs.PlasmaTmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.PlasmaOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaTmplTypeResp} returns this
 */
proto.pbs.PlasmaTmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationOperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationOperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationOperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationOperationDepInfo}
 */
proto.pbs.IonImplantationOperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationOperationDepInfo;
  return proto.pbs.IonImplantationOperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationOperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationOperationDepInfo}
 */
proto.pbs.IonImplantationOperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.IonImplantationOperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.IonImplantationOperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.IonImplantationOperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationOperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationOperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationOperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional IonImplantationOperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.IonImplantationOperationStageType}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.IonImplantationOperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.IonImplantationOperationStageType} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional IonImplantationOperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.IonImplantationOperationStepType}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.IonImplantationOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.IonImplantationOperationStepType} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional IonImplantationOperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.IonImplantationOperationStepType}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.IonImplantationOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.IonImplantationOperationStepType} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationOperationDepInfo} returns this
 */
proto.pbs.IonImplantationOperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationScoreStandard.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ionImplantationProcedure: (f = msg.getIonImplantationProcedure()) && proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.toObject(includeInstance, f),
    ionBeamDebugging: (f = msg.getIonBeamDebugging()) && proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.IonImplantationOperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationScoreStandard}
 */
proto.pbs.IonImplantationScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationScoreStandard;
  return proto.pbs.IonImplantationScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationScoreStandard}
 */
proto.pbs.IonImplantationScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure;
      reader.readMessage(value,proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.deserializeBinaryFromReader);
      msg.setIonImplantationProcedure(value);
      break;
    case 4:
      var value = new proto.pbs.IonImplantationScoreStandard.IonBeamDebugging;
      reader.readMessage(value,proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.deserializeBinaryFromReader);
      msg.setIonBeamDebugging(value);
      break;
    case 5:
      var value = new proto.pbs.IonImplantationOperationDepInfo;
      reader.readMessage(value,proto.pbs.IonImplantationOperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 6:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 7:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIonImplantationProcedure();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.serializeBinaryToWriter
    );
  }
  f = message.getIonBeamDebugging();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pbs.IonImplantationOperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationScoreStandard.ImplantationElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    elementAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationScoreStandard.ImplantationElement;
  return proto.pbs.IonImplantationScoreStandard.ImplantationElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addElementOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addElementAllOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationScoreStandard.ImplantationElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getElementAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string element_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.getElementOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.setElementOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.addElementOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.clearElementOptionsList = function() {
  return this.setElementOptionsList([]);
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated string element_all_options = 4;
 * @return {!Array<string>}
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.getElementAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.setElementAllOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.addElementAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard.ImplantationElement} returns this
 */
proto.pbs.IonImplantationScoreStandard.ImplantationElement.prototype.clearElementAllOptionsList = function() {
  return this.setElementAllOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.toObject = function(includeInstance, msg) {
  var f, obj = {
    element: (f = msg.getElement()) && proto.pbs.IonImplantationScoreStandard.ImplantationElement.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    energy: (f = msg.getEnergy()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    dosage: (f = msg.getDosage()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    gasPressure: (f = msg.getGasPressure()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    gasFlow: (f = msg.getGasFlow()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    beam: (f = msg.getBeam()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    alphaAxisAngle: (f = msg.getAlphaAxisAngle()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    betaAxisAngle: (f = msg.getBetaAxisAngle()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure;
  return proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IonImplantationScoreStandard.ImplantationElement;
      reader.readMessage(value,proto.pbs.IonImplantationScoreStandard.ImplantationElement.deserializeBinaryFromReader);
      msg.setElement(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setEnergy(value);
      break;
    case 4:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setDosage(value);
      break;
    case 5:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setGasPressure(value);
      break;
    case 6:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setGasFlow(value);
      break;
    case 7:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setBeam(value);
      break;
    case 8:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setAlphaAxisAngle(value);
      break;
    case 9:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setBetaAxisAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IonImplantationScoreStandard.ImplantationElement.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getEnergy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getDosage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getGasPressure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getGasFlow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getBeam();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getAlphaAxisAngle();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getBetaAxisAngle();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImplantationElement element = 1;
 * @return {?proto.pbs.IonImplantationScoreStandard.ImplantationElement}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getElement = function() {
  return /** @type{?proto.pbs.IonImplantationScoreStandard.ImplantationElement} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationScoreStandard.ImplantationElement, 1));
};


/**
 * @param {?proto.pbs.IonImplantationScoreStandard.ImplantationElement|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setElement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearElement = function() {
  return this.setElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasElement = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting time = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getTime = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScoreRangeSetting energy = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getEnergy = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setEnergy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearEnergy = function() {
  return this.setEnergy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasEnergy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScoreRangeSetting dosage = 4;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getDosage = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 4));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setDosage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearDosage = function() {
  return this.setDosage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasDosage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScoreRangeSetting gas_pressure = 5;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getGasPressure = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 5));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setGasPressure = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearGasPressure = function() {
  return this.setGasPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasGasPressure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ScoreRangeSetting gas_flow = 6;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getGasFlow = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 6));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setGasFlow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearGasFlow = function() {
  return this.setGasFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasGasFlow = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ScoreRangeSetting beam = 7;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getBeam = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 7));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setBeam = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearBeam = function() {
  return this.setBeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasBeam = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ScoreRangeSetting alpha_axis_angle = 8;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getAlphaAxisAngle = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 8));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setAlphaAxisAngle = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearAlphaAxisAngle = function() {
  return this.setAlphaAxisAngle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasAlphaAxisAngle = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ScoreRangeSetting beta_axis_angle = 9;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.getBetaAxisAngle = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 9));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.setBetaAxisAngle = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.clearBetaAxisAngle = function() {
  return this.setBetaAxisAngle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure.prototype.hasBetaAxisAngle = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.toObject = function(includeInstance, msg) {
  var f, obj = {
    cathodePower: (f = msg.getCathodePower()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    electricArcPower: (f = msg.getElectricArcPower()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    extractionVoltage: (f = msg.getExtractionVoltage()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    amu: (f = msg.getAmu()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    accelerationVoltage: (f = msg.getAccelerationVoltage()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    scanVoltage: (f = msg.getScanVoltage()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationScoreStandard.IonBeamDebugging;
  return proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setCathodePower(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setElectricArcPower(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setExtractionVoltage(value);
      break;
    case 4:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setAmu(value);
      break;
    case 5:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setAccelerationVoltage(value);
      break;
    case 6:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setScanVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCathodePower();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getElectricArcPower();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getExtractionVoltage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getAmu();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationVoltage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getScanVoltage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScoreRangeSetting cathode_power = 1;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getCathodePower = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 1));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setCathodePower = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearCathodePower = function() {
  return this.setCathodePower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasCathodePower = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting electric_arc_power = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getElectricArcPower = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setElectricArcPower = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearElectricArcPower = function() {
  return this.setElectricArcPower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasElectricArcPower = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScoreRangeSetting extraction_voltage = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getExtractionVoltage = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setExtractionVoltage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearExtractionVoltage = function() {
  return this.setExtractionVoltage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasExtractionVoltage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScoreRangeSetting amu = 4;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getAmu = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 4));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setAmu = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearAmu = function() {
  return this.setAmu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasAmu = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScoreRangeSetting acceleration_voltage = 5;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getAccelerationVoltage = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 5));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setAccelerationVoltage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearAccelerationVoltage = function() {
  return this.setAccelerationVoltage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasAccelerationVoltage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ScoreRangeSetting scan_voltage = 6;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.getScanVoltage = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 6));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
*/
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.setScanVoltage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} returns this
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.clearScanVoltage = function() {
  return this.setScanVoltage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.IonBeamDebugging.prototype.hasScanVoltage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IonImplantationProcedure ion_implantation_procedure = 3;
 * @return {?proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getIonImplantationProcedure = function() {
  return /** @type{?proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure, 3));
};


/**
 * @param {?proto.pbs.IonImplantationScoreStandard.IonImplantationProcedure|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
*/
proto.pbs.IonImplantationScoreStandard.prototype.setIonImplantationProcedure = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.clearIonImplantationProcedure = function() {
  return this.setIonImplantationProcedure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.prototype.hasIonImplantationProcedure = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IonBeamDebugging ion_beam_debugging = 4;
 * @return {?proto.pbs.IonImplantationScoreStandard.IonBeamDebugging}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getIonBeamDebugging = function() {
  return /** @type{?proto.pbs.IonImplantationScoreStandard.IonBeamDebugging} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationScoreStandard.IonBeamDebugging, 4));
};


/**
 * @param {?proto.pbs.IonImplantationScoreStandard.IonBeamDebugging|undefined} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
*/
proto.pbs.IonImplantationScoreStandard.prototype.setIonBeamDebugging = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.clearIonBeamDebugging = function() {
  return this.setIonBeamDebugging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.IonImplantationScoreStandard.prototype.hasIonBeamDebugging = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated IonImplantationOperationDepInfo operations = 5;
 * @return {!Array<!proto.pbs.IonImplantationOperationDepInfo>}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.IonImplantationOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.IonImplantationOperationDepInfo, 5));
};


/**
 * @param {!Array<!proto.pbs.IonImplantationOperationDepInfo>} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
*/
proto.pbs.IonImplantationScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pbs.IonImplantationOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationOperationDepInfo}
 */
proto.pbs.IonImplantationScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pbs.IonImplantationOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 6;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 6));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
*/
proto.pbs.IonImplantationScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.IonImplantationScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 7;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.IonImplantationScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
*/
proto.pbs.IonImplantationScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.IonImplantationScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationScoreStandard} returns this
 */
proto.pbs.IonImplantationScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationTmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationTmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationTmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationTmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationTmplTypeReq}
 */
proto.pbs.IonImplantationTmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationTmplTypeReq;
  return proto.pbs.IonImplantationTmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationTmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationTmplTypeReq}
 */
proto.pbs.IonImplantationTmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.IonImplantationTemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationTmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationTmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationTmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationTmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional IonImplantationTemplateType tmplType = 1;
 * @return {!proto.pbs.IonImplantationTemplateType}
 */
proto.pbs.IonImplantationTmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.IonImplantationTemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.IonImplantationTemplateType} value
 * @return {!proto.pbs.IonImplantationTmplTypeReq} returns this
 */
proto.pbs.IonImplantationTmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationTmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationTmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationTmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationTmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.IonImplantationOperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationTmplTypeResp}
 */
proto.pbs.IonImplantationTmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationTmplTypeResp;
  return proto.pbs.IonImplantationTmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationTmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationTmplTypeResp}
 */
proto.pbs.IonImplantationTmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.IonImplantationOperationDepInfo;
      reader.readMessage(value,proto.pbs.IonImplantationOperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationTmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationTmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationTmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.IonImplantationOperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.IonImplantationTmplTypeResp} returns this
*/
proto.pbs.IonImplantationTmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationTmplTypeResp} returns this
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated IonImplantationOperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.IonImplantationOperationDepInfo>}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.IonImplantationOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.IonImplantationOperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.IonImplantationOperationDepInfo>} value
 * @return {!proto.pbs.IonImplantationTmplTypeResp} returns this
*/
proto.pbs.IonImplantationTmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.IonImplantationOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationOperationDepInfo}
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.IonImplantationOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationTmplTypeResp} returns this
 */
proto.pbs.IonImplantationTmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamOperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamOperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamOperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamOperationDepInfo}
 */
proto.pbs.ElectronBeamOperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamOperationDepInfo;
  return proto.pbs.ElectronBeamOperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamOperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamOperationDepInfo}
 */
proto.pbs.ElectronBeamOperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.ElectronBeamOperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.ElectronBeamOperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.ElectronBeamOperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamOperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamOperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamOperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ElectronBeamOperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.ElectronBeamOperationStageType}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.ElectronBeamOperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.ElectronBeamOperationStageType} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ElectronBeamOperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.ElectronBeamOperationStepType}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.ElectronBeamOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.ElectronBeamOperationStepType} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ElectronBeamOperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.ElectronBeamOperationStepType}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.ElectronBeamOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.ElectronBeamOperationStepType} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamOperationDepInfo} returns this
 */
proto.pbs.ElectronBeamOperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamScoreStandard.repeatedFields_ = [4,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    crucibleParameters: (f = msg.getCrucibleParameters()) && proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.toObject(includeInstance, f),
    depoProgramList: jspb.Message.toObjectList(msg.getDepoProgramList(),
    proto.pbs.ElectronBeamScoreStandard.DepoProgram.toObject, includeInstance),
    programScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    operatingParameter: (f = msg.getOperatingParameter()) && proto.pbs.ElectronBeamScoreStandard.OperatingParameter.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.ElectronBeamOperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamScoreStandard}
 */
proto.pbs.ElectronBeamScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamScoreStandard;
  return proto.pbs.ElectronBeamScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamScoreStandard}
 */
proto.pbs.ElectronBeamScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.ElectronBeamScoreStandard.CrucibleParameters;
      reader.readMessage(value,proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.deserializeBinaryFromReader);
      msg.setCrucibleParameters(value);
      break;
    case 4:
      var value = new proto.pbs.ElectronBeamScoreStandard.DepoProgram;
      reader.readMessage(value,proto.pbs.ElectronBeamScoreStandard.DepoProgram.deserializeBinaryFromReader);
      msg.addDepoProgram(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgramScore(value);
      break;
    case 7:
      var value = new proto.pbs.ElectronBeamScoreStandard.OperatingParameter;
      reader.readMessage(value,proto.pbs.ElectronBeamScoreStandard.OperatingParameter.deserializeBinaryFromReader);
      msg.setOperatingParameter(value);
      break;
    case 8:
      var value = new proto.pbs.ElectronBeamOperationDepInfo;
      reader.readMessage(value,proto.pbs.ElectronBeamOperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 9:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 10:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCrucibleParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.serializeBinaryToWriter
    );
  }
  f = message.getDepoProgramList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.ElectronBeamScoreStandard.DepoProgram.serializeBinaryToWriter
    );
  }
  f = message.getProgramScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOperatingParameter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.ElectronBeamScoreStandard.OperatingParameter.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.ElectronBeamOperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.repeatedFields_ = [1,2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correctOptionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    materialCrucibleMap: (f = msg.getMaterialCrucibleMap()) ? f.toObject(includeInstance, undefined) : [],
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    materialAllOptionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    optionCrucibleMap: (f = msg.getOptionCrucibleMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamScoreStandard.CrucibleParameters;
  return proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaterialOptions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCorrectOptions(value);
      break;
    case 3:
      var value = msg.getMaterialCrucibleMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaterialAllOptions(value);
      break;
    case 6:
      var value = msg.getOptionCrucibleMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrectOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMaterialCrucibleMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaterialAllOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOptionCrucibleMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * repeated string material_options = 1;
 * @return {!Array<string>}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getMaterialOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.setMaterialOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.addMaterialOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.clearMaterialOptionsList = function() {
  return this.setMaterialOptionsList([]);
};


/**
 * repeated string correct_options = 2;
 * @return {!Array<string>}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getCorrectOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.setCorrectOptionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.addCorrectOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.clearCorrectOptionsList = function() {
  return this.setCorrectOptionsList([]);
};


/**
 * map<string, int32> material_crucible = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getMaterialCrucibleMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.clearMaterialCrucibleMap = function() {
  this.getMaterialCrucibleMap().clear();
  return this;
};


/**
 * optional double score = 4;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated string material_all_options = 5;
 * @return {!Array<string>}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getMaterialAllOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.setMaterialAllOptionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.addMaterialAllOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.clearMaterialAllOptionsList = function() {
  return this.setMaterialAllOptionsList([]);
};


/**
 * map<string, int32> option_crucible = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.getOptionCrucibleMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleParameters.prototype.clearOptionCrucibleMap = function() {
  this.getOptionCrucibleMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    crucibleNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    material: jspb.Message.getFieldWithDefault(msg, 2, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    orderNo: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamScoreStandard.CrucibleSetting;
  return proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCrucibleNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCrucibleNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getOrderNo();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 crucible_no = 1;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.getCrucibleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.setCrucibleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string material = 2;
 * @return {string}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double score = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 order_no = 4;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.getOrderNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} returns this
 */
proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.prototype.setOrderNo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamScoreStandard.DepoProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    preEvaporationRate: (f = msg.getPreEvaporationRate()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    evaporationRate: (f = msg.getEvaporationRate()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    thickness: (f = msg.getThickness()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    revisedValue: (f = msg.getRevisedValue()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    crucibleSetting: (f = msg.getCrucibleSetting()) && proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamScoreStandard.DepoProgram;
  return proto.pbs.ElectronBeamScoreStandard.DepoProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setPreEvaporationRate(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setEvaporationRate(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setThickness(value);
      break;
    case 4:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setRevisedValue(value);
      break;
    case 5:
      var value = new proto.pbs.ElectronBeamScoreStandard.CrucibleSetting;
      reader.readMessage(value,proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.deserializeBinaryFromReader);
      msg.setCrucibleSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamScoreStandard.DepoProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreEvaporationRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getEvaporationRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getThickness();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getRevisedValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getCrucibleSetting();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.ElectronBeamScoreStandard.CrucibleSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScoreRangeSetting pre_evaporation_rate = 1;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.getPreEvaporationRate = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 1));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
*/
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.setPreEvaporationRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.clearPreEvaporationRate = function() {
  return this.setPreEvaporationRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.hasPreEvaporationRate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting evaporation_rate = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.getEvaporationRate = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
*/
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.setEvaporationRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.clearEvaporationRate = function() {
  return this.setEvaporationRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.hasEvaporationRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScoreRangeSetting thickness = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.getThickness = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
*/
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.setThickness = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.clearThickness = function() {
  return this.setThickness(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.hasThickness = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScoreRangeSetting revised_value = 4;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.getRevisedValue = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 4));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
*/
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.setRevisedValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.clearRevisedValue = function() {
  return this.setRevisedValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.hasRevisedValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CrucibleSetting crucible_setting = 5;
 * @return {?proto.pbs.ElectronBeamScoreStandard.CrucibleSetting}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.getCrucibleSetting = function() {
  return /** @type{?proto.pbs.ElectronBeamScoreStandard.CrucibleSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamScoreStandard.CrucibleSetting, 5));
};


/**
 * @param {?proto.pbs.ElectronBeamScoreStandard.CrucibleSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
*/
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.setCrucibleSetting = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram} returns this
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.clearCrucibleSetting = function() {
  return this.setCrucibleSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.DepoProgram.prototype.hasCrucibleSetting = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamScoreStandard.OperatingParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ebeamSwitch: jspb.Message.getFieldWithDefault(msg, 1, 0),
    switchScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    holderSpeed: (f = msg.getHolderSpeed()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamScoreStandard.OperatingParameter;
  return proto.pbs.ElectronBeamScoreStandard.OperatingParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.EbeamSwitchStatusType} */ (reader.readEnum());
      msg.setEbeamSwitch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwitchScore(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setHolderSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamScoreStandard.OperatingParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEbeamSwitch();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSwitchScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getHolderSpeed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional EbeamSwitchStatusType ebeam_switch = 1;
 * @return {!proto.pbs.EbeamSwitchStatusType}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.getEbeamSwitch = function() {
  return /** @type {!proto.pbs.EbeamSwitchStatusType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.EbeamSwitchStatusType} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.setEbeamSwitch = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double switch_score = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.getSwitchScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.setSwitchScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional ScoreRangeSetting holder_speed = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.getHolderSpeed = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} returns this
*/
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.setHolderSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard.OperatingParameter} returns this
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.clearHolderSpeed = function() {
  return this.setHolderSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.OperatingParameter.prototype.hasHolderSpeed = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CrucibleParameters crucible_parameters = 3;
 * @return {?proto.pbs.ElectronBeamScoreStandard.CrucibleParameters}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getCrucibleParameters = function() {
  return /** @type{?proto.pbs.ElectronBeamScoreStandard.CrucibleParameters} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamScoreStandard.CrucibleParameters, 3));
};


/**
 * @param {?proto.pbs.ElectronBeamScoreStandard.CrucibleParameters|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setCrucibleParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearCrucibleParameters = function() {
  return this.setCrucibleParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.hasCrucibleParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DepoProgram depo_program = 4;
 * @return {!Array<!proto.pbs.ElectronBeamScoreStandard.DepoProgram>}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getDepoProgramList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamScoreStandard.DepoProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamScoreStandard.DepoProgram, 4));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamScoreStandard.DepoProgram>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setDepoProgramList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.ElectronBeamScoreStandard.DepoProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamScoreStandard.DepoProgram}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.addDepoProgram = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.ElectronBeamScoreStandard.DepoProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearDepoProgramList = function() {
  return this.setDepoProgramList([]);
};


/**
 * optional double program_score = 6;
 * @return {number}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getProgramScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.setProgramScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional OperatingParameter operating_parameter = 7;
 * @return {?proto.pbs.ElectronBeamScoreStandard.OperatingParameter}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getOperatingParameter = function() {
  return /** @type{?proto.pbs.ElectronBeamScoreStandard.OperatingParameter} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamScoreStandard.OperatingParameter, 7));
};


/**
 * @param {?proto.pbs.ElectronBeamScoreStandard.OperatingParameter|undefined} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setOperatingParameter = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearOperatingParameter = function() {
  return this.setOperatingParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.hasOperatingParameter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ElectronBeamOperationDepInfo operations = 8;
 * @return {!Array<!proto.pbs.ElectronBeamOperationDepInfo>}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamOperationDepInfo, 8));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamOperationDepInfo>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.ElectronBeamOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamOperationDepInfo}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.ElectronBeamOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 9;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 9));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 10;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 10));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
*/
proto.pbs.ElectronBeamScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.ElectronBeamScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamScoreStandard} returns this
 */
proto.pbs.ElectronBeamScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EbeamTmplTypeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EbeamTmplTypeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EbeamTmplTypeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EbeamTmplTypeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tmpltype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EbeamTmplTypeReq}
 */
proto.pbs.EbeamTmplTypeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EbeamTmplTypeReq;
  return proto.pbs.EbeamTmplTypeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EbeamTmplTypeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EbeamTmplTypeReq}
 */
proto.pbs.EbeamTmplTypeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.EbeamTemplateType} */ (reader.readEnum());
      msg.setTmpltype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EbeamTmplTypeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EbeamTmplTypeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EbeamTmplTypeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EbeamTmplTypeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTmpltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional EbeamTemplateType tmplType = 1;
 * @return {!proto.pbs.EbeamTemplateType}
 */
proto.pbs.EbeamTmplTypeReq.prototype.getTmpltype = function() {
  return /** @type {!proto.pbs.EbeamTemplateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.EbeamTemplateType} value
 * @return {!proto.pbs.EbeamTmplTypeReq} returns this
 */
proto.pbs.EbeamTmplTypeReq.prototype.setTmpltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.EbeamTmplTypeResp.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EbeamTmplTypeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EbeamTmplTypeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EbeamTmplTypeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EbeamTmplTypeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    kvtypesList: jspb.Message.toObjectList(msg.getKvtypesList(),
    common_pb.CommonType.toObject, includeInstance),
    opdeptypesList: jspb.Message.toObjectList(msg.getOpdeptypesList(),
    proto.pbs.ElectronBeamOperationDepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EbeamTmplTypeResp}
 */
proto.pbs.EbeamTmplTypeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EbeamTmplTypeResp;
  return proto.pbs.EbeamTmplTypeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EbeamTmplTypeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EbeamTmplTypeResp}
 */
proto.pbs.EbeamTmplTypeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.CommonType;
      reader.readMessage(value,common_pb.CommonType.deserializeBinaryFromReader);
      msg.addKvtypes(value);
      break;
    case 2:
      var value = new proto.pbs.ElectronBeamOperationDepInfo;
      reader.readMessage(value,proto.pbs.ElectronBeamOperationDepInfo.deserializeBinaryFromReader);
      msg.addOpdeptypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EbeamTmplTypeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EbeamTmplTypeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EbeamTmplTypeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EbeamTmplTypeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKvtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.CommonType.serializeBinaryToWriter
    );
  }
  f = message.getOpdeptypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.ElectronBeamOperationDepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommonType kvTypes = 1;
 * @return {!Array<!proto.pbs.CommonType>}
 */
proto.pbs.EbeamTmplTypeResp.prototype.getKvtypesList = function() {
  return /** @type{!Array<!proto.pbs.CommonType>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.CommonType, 1));
};


/**
 * @param {!Array<!proto.pbs.CommonType>} value
 * @return {!proto.pbs.EbeamTmplTypeResp} returns this
*/
proto.pbs.EbeamTmplTypeResp.prototype.setKvtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.CommonType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.CommonType}
 */
proto.pbs.EbeamTmplTypeResp.prototype.addKvtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.CommonType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.EbeamTmplTypeResp} returns this
 */
proto.pbs.EbeamTmplTypeResp.prototype.clearKvtypesList = function() {
  return this.setKvtypesList([]);
};


/**
 * repeated ElectronBeamOperationDepInfo opDepTypes = 2;
 * @return {!Array<!proto.pbs.ElectronBeamOperationDepInfo>}
 */
proto.pbs.EbeamTmplTypeResp.prototype.getOpdeptypesList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamOperationDepInfo, 2));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamOperationDepInfo>} value
 * @return {!proto.pbs.EbeamTmplTypeResp} returns this
*/
proto.pbs.EbeamTmplTypeResp.prototype.setOpdeptypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.ElectronBeamOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamOperationDepInfo}
 */
proto.pbs.EbeamTmplTypeResp.prototype.addOpdeptypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.ElectronBeamOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.EbeamTmplTypeResp} returns this
 */
proto.pbs.EbeamTmplTypeResp.prototype.clearOpdeptypesList = function() {
  return this.setOpdeptypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IntRangeSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IntRangeSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IntRangeSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IntRangeSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerLimit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    upperLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IntRangeSetting}
 */
proto.pbs.IntRangeSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IntRangeSetting;
  return proto.pbs.IntRangeSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IntRangeSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IntRangeSetting}
 */
proto.pbs.IntRangeSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowerLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpperLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IntRangeSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IntRangeSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IntRangeSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IntRangeSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUpperLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 lower_limit = 1;
 * @return {number}
 */
proto.pbs.IntRangeSetting.prototype.getLowerLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IntRangeSetting} returns this
 */
proto.pbs.IntRangeSetting.prototype.setLowerLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 upper_limit = 2;
 * @return {number}
 */
proto.pbs.IntRangeSetting.prototype.getUpperLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IntRangeSetting} returns this
 */
proto.pbs.IntRangeSetting.prototype.setUpperLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperOperationDepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperOperationDepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperOperationDepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperOperationDepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationStageType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operationStage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preOrderActDepType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preOrderActDep: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postOrderActDepType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    postOrderActDep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperOperationDepInfo}
 */
proto.pbs.StepperOperationDepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperOperationDepInfo;
  return proto.pbs.StepperOperationDepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperOperationDepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperOperationDepInfo}
 */
proto.pbs.StepperOperationDepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.StepperOperationStageType} */ (reader.readEnum());
      msg.setOperationStageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationStage(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.StepperOperationStepType} */ (reader.readEnum());
      msg.setPreOrderActDepType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreOrderActDep(value);
      break;
    case 6:
      var value = /** @type {!proto.pbs.StepperOperationStepType} */ (reader.readEnum());
      msg.setPostOrderActDepType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostOrderActDep(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperOperationDepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperOperationDepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperOperationDepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperOperationDepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperationStageType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperationStage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPreOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostOrderActDepType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPostOrderActDep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.StepperOperationDepInfo.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StepperOperationStageType operation_stage_type = 2;
 * @return {!proto.pbs.StepperOperationStageType}
 */
proto.pbs.StepperOperationDepInfo.prototype.getOperationStageType = function() {
  return /** @type {!proto.pbs.StepperOperationStageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.StepperOperationStageType} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setOperationStageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation_stage = 3;
 * @return {string}
 */
proto.pbs.StepperOperationDepInfo.prototype.getOperationStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setOperationStage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StepperOperationStepType pre_order_act_dep_type = 4;
 * @return {!proto.pbs.StepperOperationStepType}
 */
proto.pbs.StepperOperationDepInfo.prototype.getPreOrderActDepType = function() {
  return /** @type {!proto.pbs.StepperOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.StepperOperationStepType} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setPreOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pre_order_act_dep = 5;
 * @return {string}
 */
proto.pbs.StepperOperationDepInfo.prototype.getPreOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setPreOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional StepperOperationStepType post_order_act_dep_type = 6;
 * @return {!proto.pbs.StepperOperationStepType}
 */
proto.pbs.StepperOperationDepInfo.prototype.getPostOrderActDepType = function() {
  return /** @type {!proto.pbs.StepperOperationStepType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pbs.StepperOperationStepType} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setPostOrderActDepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string post_order_act_dep = 7;
 * @return {string}
 */
proto.pbs.StepperOperationDepInfo.prototype.getPostOrderActDep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setPostOrderActDep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double score = 8;
 * @return {number}
 */
proto.pbs.StepperOperationDepInfo.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperOperationDepInfo} returns this
 */
proto.pbs.StepperOperationDepInfo.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.StepperScoreStandard.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mask: (f = msg.getMask()) && proto.pbs.StepperScoreStandard.Mask.toObject(includeInstance, f),
    exposureArea: (f = msg.getExposureArea()) && proto.pbs.StepperScoreStandard.ExposureArea.toObject(includeInstance, f),
    exposureParams: (f = msg.getExposureParams()) && proto.pbs.StepperScoreStandard.ExposureParams.toObject(includeInstance, f),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.pbs.StepperOperationDepInfo.toObject, includeInstance),
    simiQuestionsList: jspb.Message.toObjectList(msg.getSimiQuestionsList(),
    proto.pbs.SimiQuestion.toObject, includeInstance),
    simiResultsList: jspb.Message.toObjectList(msg.getSimiResultsList(),
    proto.pbs.SimiResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperScoreStandard}
 */
proto.pbs.StepperScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperScoreStandard;
  return proto.pbs.StepperScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperScoreStandard}
 */
proto.pbs.StepperScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentImg(value);
      break;
    case 3:
      var value = new proto.pbs.StepperScoreStandard.Mask;
      reader.readMessage(value,proto.pbs.StepperScoreStandard.Mask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 4:
      var value = new proto.pbs.StepperScoreStandard.ExposureArea;
      reader.readMessage(value,proto.pbs.StepperScoreStandard.ExposureArea.deserializeBinaryFromReader);
      msg.setExposureArea(value);
      break;
    case 5:
      var value = new proto.pbs.StepperScoreStandard.ExposureParams;
      reader.readMessage(value,proto.pbs.StepperScoreStandard.ExposureParams.deserializeBinaryFromReader);
      msg.setExposureParams(value);
      break;
    case 6:
      var value = new proto.pbs.StepperOperationDepInfo;
      reader.readMessage(value,proto.pbs.StepperOperationDepInfo.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 7:
      var value = new proto.pbs.SimiQuestion;
      reader.readMessage(value,proto.pbs.SimiQuestion.deserializeBinaryFromReader);
      msg.addSimiQuestions(value);
      break;
    case 8:
      var value = new proto.pbs.SimiResult;
      reader.readMessage(value,proto.pbs.SimiResult.deserializeBinaryFromReader);
      msg.addSimiResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.StepperScoreStandard.Mask.serializeBinaryToWriter
    );
  }
  f = message.getExposureArea();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.StepperScoreStandard.ExposureArea.serializeBinaryToWriter
    );
  }
  f = message.getExposureParams();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.StepperScoreStandard.ExposureParams.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.StepperOperationDepInfo.serializeBinaryToWriter
    );
  }
  f = message.getSimiQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.SimiQuestion.serializeBinaryToWriter
    );
  }
  f = message.getSimiResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.pbs.SimiResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperScoreStandard.Mask.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperScoreStandard.Mask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperScoreStandard.Mask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.Mask.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperScoreStandard.Mask}
 */
proto.pbs.StepperScoreStandard.Mask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperScoreStandard.Mask;
  return proto.pbs.StepperScoreStandard.Mask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperScoreStandard.Mask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperScoreStandard.Mask}
 */
proto.pbs.StepperScoreStandard.Mask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperScoreStandard.Mask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperScoreStandard.Mask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperScoreStandard.Mask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.Mask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.pbs.StepperScoreStandard.Mask.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperScoreStandard.Mask} returns this
 */
proto.pbs.StepperScoreStandard.Mask.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double score = 2;
 * @return {number}
 */
proto.pbs.StepperScoreStandard.Mask.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperScoreStandard.Mask} returns this
 */
proto.pbs.StepperScoreStandard.Mask.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperScoreStandard.ExposureArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperScoreStandard.ExposureArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.ExposureArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    rangeAx: (f = msg.getRangeAx()) && proto.pbs.IntRangeSetting.toObject(includeInstance, f),
    rangeAy: (f = msg.getRangeAy()) && proto.pbs.IntRangeSetting.toObject(includeInstance, f),
    rangeBx: (f = msg.getRangeBx()) && proto.pbs.IntRangeSetting.toObject(includeInstance, f),
    rangeBy: (f = msg.getRangeBy()) && proto.pbs.IntRangeSetting.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea}
 */
proto.pbs.StepperScoreStandard.ExposureArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperScoreStandard.ExposureArea;
  return proto.pbs.StepperScoreStandard.ExposureArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperScoreStandard.ExposureArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea}
 */
proto.pbs.StepperScoreStandard.ExposureArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.IntRangeSetting;
      reader.readMessage(value,proto.pbs.IntRangeSetting.deserializeBinaryFromReader);
      msg.setRangeAx(value);
      break;
    case 2:
      var value = new proto.pbs.IntRangeSetting;
      reader.readMessage(value,proto.pbs.IntRangeSetting.deserializeBinaryFromReader);
      msg.setRangeAy(value);
      break;
    case 3:
      var value = new proto.pbs.IntRangeSetting;
      reader.readMessage(value,proto.pbs.IntRangeSetting.deserializeBinaryFromReader);
      msg.setRangeBx(value);
      break;
    case 4:
      var value = new proto.pbs.IntRangeSetting;
      reader.readMessage(value,proto.pbs.IntRangeSetting.deserializeBinaryFromReader);
      msg.setRangeBy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperScoreStandard.ExposureArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperScoreStandard.ExposureArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.ExposureArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRangeAx();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.IntRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getRangeAy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.IntRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getRangeBx();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.IntRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getRangeBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.pbs.IntRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional IntRangeSetting range_AX = 1;
 * @return {?proto.pbs.IntRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.getRangeAx = function() {
  return /** @type{?proto.pbs.IntRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IntRangeSetting, 1));
};


/**
 * @param {?proto.pbs.IntRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
*/
proto.pbs.StepperScoreStandard.ExposureArea.prototype.setRangeAx = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.clearRangeAx = function() {
  return this.setRangeAx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.hasRangeAx = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IntRangeSetting range_AY = 2;
 * @return {?proto.pbs.IntRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.getRangeAy = function() {
  return /** @type{?proto.pbs.IntRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IntRangeSetting, 2));
};


/**
 * @param {?proto.pbs.IntRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
*/
proto.pbs.StepperScoreStandard.ExposureArea.prototype.setRangeAy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.clearRangeAy = function() {
  return this.setRangeAy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.hasRangeAy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntRangeSetting range_BX = 3;
 * @return {?proto.pbs.IntRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.getRangeBx = function() {
  return /** @type{?proto.pbs.IntRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IntRangeSetting, 3));
};


/**
 * @param {?proto.pbs.IntRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
*/
proto.pbs.StepperScoreStandard.ExposureArea.prototype.setRangeBx = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.clearRangeBx = function() {
  return this.setRangeBx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.hasRangeBx = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntRangeSetting range_BY = 4;
 * @return {?proto.pbs.IntRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.getRangeBy = function() {
  return /** @type{?proto.pbs.IntRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IntRangeSetting, 4));
};


/**
 * @param {?proto.pbs.IntRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
*/
proto.pbs.StepperScoreStandard.ExposureArea.prototype.setRangeBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.clearRangeBy = function() {
  return this.setRangeBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.hasRangeBy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double score = 5;
 * @return {number}
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureArea} returns this
 */
proto.pbs.StepperScoreStandard.ExposureArea.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperScoreStandard.ExposureParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperScoreStandard.ExposureParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.ExposureParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    focalPlane: (f = msg.getFocalPlane()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f),
    stepping: (f = msg.getStepping()) && proto.pbs.ScoreRangeSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams}
 */
proto.pbs.StepperScoreStandard.ExposureParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperScoreStandard.ExposureParams;
  return proto.pbs.StepperScoreStandard.ExposureParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperScoreStandard.ExposureParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams}
 */
proto.pbs.StepperScoreStandard.ExposureParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setFocalPlane(value);
      break;
    case 3:
      var value = new proto.pbs.ScoreRangeSetting;
      reader.readMessage(value,proto.pbs.ScoreRangeSetting.deserializeBinaryFromReader);
      msg.setStepping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperScoreStandard.ExposureParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperScoreStandard.ExposureParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperScoreStandard.ExposureParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getFocalPlane();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
  f = message.getStepping();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.ScoreRangeSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScoreRangeSetting time = 1;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.getTime = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 1));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
*/
proto.pbs.StepperScoreStandard.ExposureParams.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScoreRangeSetting focal_plane = 2;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.getFocalPlane = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 2));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
*/
proto.pbs.StepperScoreStandard.ExposureParams.prototype.setFocalPlane = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.clearFocalPlane = function() {
  return this.setFocalPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.hasFocalPlane = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScoreRangeSetting stepping = 3;
 * @return {?proto.pbs.ScoreRangeSetting}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.getStepping = function() {
  return /** @type{?proto.pbs.ScoreRangeSetting} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ScoreRangeSetting, 3));
};


/**
 * @param {?proto.pbs.ScoreRangeSetting|undefined} value
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
*/
proto.pbs.StepperScoreStandard.ExposureParams.prototype.setStepping = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard.ExposureParams} returns this
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.clearStepping = function() {
  return this.setStepping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.ExposureParams.prototype.hasStepping = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.StepperScoreStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string experiment_img = 2;
 * @return {string}
 */
proto.pbs.StepperScoreStandard.prototype.getExperimentImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.setExperimentImg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Mask mask = 3;
 * @return {?proto.pbs.StepperScoreStandard.Mask}
 */
proto.pbs.StepperScoreStandard.prototype.getMask = function() {
  return /** @type{?proto.pbs.StepperScoreStandard.Mask} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperScoreStandard.Mask, 3));
};


/**
 * @param {?proto.pbs.StepperScoreStandard.Mask|undefined} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.prototype.hasMask = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExposureArea exposure_area = 4;
 * @return {?proto.pbs.StepperScoreStandard.ExposureArea}
 */
proto.pbs.StepperScoreStandard.prototype.getExposureArea = function() {
  return /** @type{?proto.pbs.StepperScoreStandard.ExposureArea} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperScoreStandard.ExposureArea, 4));
};


/**
 * @param {?proto.pbs.StepperScoreStandard.ExposureArea|undefined} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setExposureArea = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearExposureArea = function() {
  return this.setExposureArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.prototype.hasExposureArea = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ExposureParams exposure_params = 5;
 * @return {?proto.pbs.StepperScoreStandard.ExposureParams}
 */
proto.pbs.StepperScoreStandard.prototype.getExposureParams = function() {
  return /** @type{?proto.pbs.StepperScoreStandard.ExposureParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperScoreStandard.ExposureParams, 5));
};


/**
 * @param {?proto.pbs.StepperScoreStandard.ExposureParams|undefined} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setExposureParams = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearExposureParams = function() {
  return this.setExposureParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.StepperScoreStandard.prototype.hasExposureParams = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated StepperOperationDepInfo operations = 6;
 * @return {!Array<!proto.pbs.StepperOperationDepInfo>}
 */
proto.pbs.StepperScoreStandard.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.pbs.StepperOperationDepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.StepperOperationDepInfo, 6));
};


/**
 * @param {!Array<!proto.pbs.StepperOperationDepInfo>} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.StepperOperationDepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.StepperOperationDepInfo}
 */
proto.pbs.StepperScoreStandard.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.StepperOperationDepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * repeated SimiQuestion simi_questions = 7;
 * @return {!Array<!proto.pbs.SimiQuestion>}
 */
proto.pbs.StepperScoreStandard.prototype.getSimiQuestionsList = function() {
  return /** @type{!Array<!proto.pbs.SimiQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiQuestion, 7));
};


/**
 * @param {!Array<!proto.pbs.SimiQuestion>} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setSimiQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.SimiQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.StepperScoreStandard.prototype.addSimiQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.SimiQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearSimiQuestionsList = function() {
  return this.setSimiQuestionsList([]);
};


/**
 * repeated SimiResult simi_results = 8;
 * @return {!Array<!proto.pbs.SimiResult>}
 */
proto.pbs.StepperScoreStandard.prototype.getSimiResultsList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult, 8));
};


/**
 * @param {!Array<!proto.pbs.SimiResult>} value
 * @return {!proto.pbs.StepperScoreStandard} returns this
*/
proto.pbs.StepperScoreStandard.prototype.setSimiResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.SimiResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.StepperScoreStandard.prototype.addSimiResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.SimiResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.StepperScoreStandard} returns this
 */
proto.pbs.StepperScoreStandard.prototype.clearSimiResultsList = function() {
  return this.setSimiResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiProcessStandard.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentRequirements: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentImgListList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    icDeviceListList: jspb.Message.toObjectList(msg.getIcDeviceListList(),
    ic_device_pb.ICDevice.toObject, includeInstance),
    processStepListList: jspb.Message.toObjectList(msg.getProcessStepListList(),
    proto.pbs.SimiProcessStandard.SimiProcessStep.toObject, includeInstance),
    stepScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    stepTotalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandard}
 */
proto.pbs.SimiProcessStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandard;
  return proto.pbs.SimiProcessStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandard}
 */
proto.pbs.SimiProcessStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperimentRequirements(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExperimentImgList(value);
      break;
    case 3:
      var value = new ic_device_pb.ICDevice;
      reader.readMessage(value,ic_device_pb.ICDevice.deserializeBinaryFromReader);
      msg.addIcDeviceList(value);
      break;
    case 4:
      var value = new proto.pbs.SimiProcessStandard.SimiProcessStep;
      reader.readMessage(value,proto.pbs.SimiProcessStandard.SimiProcessStep.deserializeBinaryFromReader);
      msg.addProcessStepList(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStepScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStepTotalScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperimentRequirements();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentImgListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getIcDeviceListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      ic_device_pb.ICDevice.serializeBinaryToWriter
    );
  }
  f = message.getProcessStepListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.SimiProcessStandard.SimiProcessStep.serializeBinaryToWriter
    );
  }
  f = message.getStepScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStepTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiProcessStandard.SimiProcessStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiProcessStandard.SimiProcessStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icDevice: (f = msg.getIcDevice()) && ic_device_pb.ICDevice.toObject(includeInstance, f),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scoreStandardName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scoreStandardScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiProcessStandard.SimiProcessStep;
  return proto.pbs.SimiProcessStandard.SimiProcessStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiProcessStandard.SimiProcessStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = new ic_device_pb.ICDevice;
      reader.readMessage(value,ic_device_pb.ICDevice.deserializeBinaryFromReader);
      msg.setIcDevice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScoreStandardScoreRefer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiProcessStandard.SimiProcessStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiProcessStandard.SimiProcessStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIcDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ic_device_pb.ICDevice.serializeBinaryToWriter
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScoreStandardName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScoreStandardScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ICDevice ic_device = 2;
 * @return {?proto.pbs.ICDevice}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.getIcDevice = function() {
  return /** @type{?proto.pbs.ICDevice} */ (
    jspb.Message.getWrapperField(this, ic_device_pb.ICDevice, 2));
};


/**
 * @param {?proto.pbs.ICDevice|undefined} value
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
*/
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.setIcDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.clearIcDevice = function() {
  return this.setIcDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.hasIcDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string score_standard_id = 3;
 * @return {string}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string score_standard_name = 4;
 * @return {string}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.getScoreStandardName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.setScoreStandardName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double score_standard_score_refer = 5;
 * @return {number}
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.getScoreStandardScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep} returns this
 */
proto.pbs.SimiProcessStandard.SimiProcessStep.prototype.setScoreStandardScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string experiment_requirements = 1;
 * @return {string}
 */
proto.pbs.SimiProcessStandard.prototype.getExperimentRequirements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.setExperimentRequirements = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string experiment_img_list = 2;
 * @return {!Array<string>}
 */
proto.pbs.SimiProcessStandard.prototype.getExperimentImgListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.setExperimentImgListList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.addExperimentImgList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.clearExperimentImgListList = function() {
  return this.setExperimentImgListList([]);
};


/**
 * repeated ICDevice ic_device_list = 3;
 * @return {!Array<!proto.pbs.ICDevice>}
 */
proto.pbs.SimiProcessStandard.prototype.getIcDeviceListList = function() {
  return /** @type{!Array<!proto.pbs.ICDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, ic_device_pb.ICDevice, 3));
};


/**
 * @param {!Array<!proto.pbs.ICDevice>} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
*/
proto.pbs.SimiProcessStandard.prototype.setIcDeviceListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.ICDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ICDevice}
 */
proto.pbs.SimiProcessStandard.prototype.addIcDeviceList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.ICDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.clearIcDeviceListList = function() {
  return this.setIcDeviceListList([]);
};


/**
 * repeated SimiProcessStep process_step_list = 4;
 * @return {!Array<!proto.pbs.SimiProcessStandard.SimiProcessStep>}
 */
proto.pbs.SimiProcessStandard.prototype.getProcessStepListList = function() {
  return /** @type{!Array<!proto.pbs.SimiProcessStandard.SimiProcessStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiProcessStandard.SimiProcessStep, 4));
};


/**
 * @param {!Array<!proto.pbs.SimiProcessStandard.SimiProcessStep>} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
*/
proto.pbs.SimiProcessStandard.prototype.setProcessStepListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.SimiProcessStandard.SimiProcessStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiProcessStandard.SimiProcessStep}
 */
proto.pbs.SimiProcessStandard.prototype.addProcessStepList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.SimiProcessStandard.SimiProcessStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.clearProcessStepListList = function() {
  return this.setProcessStepListList([]);
};


/**
 * optional double step_score = 5;
 * @return {number}
 */
proto.pbs.SimiProcessStandard.prototype.getStepScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.setStepScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double step_total_score = 6;
 * @return {number}
 */
proto.pbs.SimiProcessStandard.prototype.getStepTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiProcessStandard} returns this
 */
proto.pbs.SimiProcessStandard.prototype.setStepTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiResult.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiResult.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionResultList: jspb.Message.toObjectList(msg.getQuestionResultList(),
    proto.pbs.SimiResult.SimiResultItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.SimiResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiResult;
  return proto.pbs.SimiResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiResult}
 */
proto.pbs.SimiResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionNo(value);
      break;
    case 2:
      var value = new proto.pbs.SimiResult.SimiResultItem;
      reader.readMessage(value,proto.pbs.SimiResult.SimiResultItem.deserializeBinaryFromReader);
      msg.addQuestionResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuestionResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.pbs.SimiResult.SimiResultItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiResult.SimiResultItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiResult.SimiResultItem.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiResult.SimiResultItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiResult.SimiResultItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiResult.SimiResultItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionoption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questioncontent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionpiclistList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiResult.SimiResultItem}
 */
proto.pbs.SimiResult.SimiResultItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiResult.SimiResultItem;
  return proto.pbs.SimiResult.SimiResultItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiResult.SimiResultItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiResult.SimiResultItem}
 */
proto.pbs.SimiResult.SimiResultItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionoption(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestioncontent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionpiclist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiResult.SimiResultItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiResult.SimiResultItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiResult.SimiResultItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiResult.SimiResultItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionoption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestioncontent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionpiclistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string questionOption = 1;
 * @return {string}
 */
proto.pbs.SimiResult.SimiResultItem.prototype.getQuestionoption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiResult.SimiResultItem} returns this
 */
proto.pbs.SimiResult.SimiResultItem.prototype.setQuestionoption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string questionContent = 2;
 * @return {string}
 */
proto.pbs.SimiResult.SimiResultItem.prototype.getQuestioncontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiResult.SimiResultItem} returns this
 */
proto.pbs.SimiResult.SimiResultItem.prototype.setQuestioncontent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string questionPicList = 3;
 * @return {!Array<string>}
 */
proto.pbs.SimiResult.SimiResultItem.prototype.getQuestionpiclistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiResult.SimiResultItem} returns this
 */
proto.pbs.SimiResult.SimiResultItem.prototype.setQuestionpiclistList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult.SimiResultItem} returns this
 */
proto.pbs.SimiResult.SimiResultItem.prototype.addQuestionpiclist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiResult.SimiResultItem} returns this
 */
proto.pbs.SimiResult.SimiResultItem.prototype.clearQuestionpiclistList = function() {
  return this.setQuestionpiclistList([]);
};


/**
 * optional int32 question_no = 1;
 * @return {number}
 */
proto.pbs.SimiResult.prototype.getQuestionNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiResult} returns this
 */
proto.pbs.SimiResult.prototype.setQuestionNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SimiResultItem question_result = 2;
 * @return {!Array<!proto.pbs.SimiResult.SimiResultItem>}
 */
proto.pbs.SimiResult.prototype.getQuestionResultList = function() {
  return /** @type{!Array<!proto.pbs.SimiResult.SimiResultItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SimiResult.SimiResultItem, 2));
};


/**
 * @param {!Array<!proto.pbs.SimiResult.SimiResultItem>} value
 * @return {!proto.pbs.SimiResult} returns this
*/
proto.pbs.SimiResult.prototype.setQuestionResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.SimiResult.SimiResultItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiResult.SimiResultItem}
 */
proto.pbs.SimiResult.prototype.addQuestionResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.SimiResult.SimiResultItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiResult} returns this
 */
proto.pbs.SimiResult.prototype.clearQuestionResultList = function() {
  return this.setQuestionResultList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SimiQuestion.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SimiQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SimiQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SimiQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questiontype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionmedialistList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    questionoptionMap: (f = msg.getQuestionoptionMap()) ? f.toObject(includeInstance, undefined) : [],
    questionanswerList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    questionscores: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    questionanswerdesc: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.SimiQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SimiQuestion;
  return proto.pbs.SimiQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SimiQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SimiQuestion}
 */
proto.pbs.SimiQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuestiontype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionmedialist(value);
      break;
    case 5:
      var value = msg.getQuestionoptionMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestionanswer(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuestionscores(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionanswerdesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SimiQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SimiQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SimiQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SimiQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuestiontype();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getQuestionname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionmedialistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getQuestionoptionMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getQuestionanswerList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getQuestionscores();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getQuestionanswerdesc();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 question_no = 1;
 * @return {number}
 */
proto.pbs.SimiQuestion.prototype.getQuestionNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 questionType = 2;
 * @return {number}
 */
proto.pbs.SimiQuestion.prototype.getQuestiontype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestiontype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string questionName = 3;
 * @return {string}
 */
proto.pbs.SimiQuestion.prototype.getQuestionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string questionMediaList = 4;
 * @return {!Array<string>}
 */
proto.pbs.SimiQuestion.prototype.getQuestionmedialistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionmedialistList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.addQuestionmedialist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.clearQuestionmedialistList = function() {
  return this.setQuestionmedialistList([]);
};


/**
 * map<string, string> questionOption = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.SimiQuestion.prototype.getQuestionoptionMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.clearQuestionoptionMap = function() {
  this.getQuestionoptionMap().clear();
  return this;
};


/**
 * repeated string questionAnswer = 6;
 * @return {!Array<string>}
 */
proto.pbs.SimiQuestion.prototype.getQuestionanswerList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionanswerList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.addQuestionanswer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.clearQuestionanswerList = function() {
  return this.setQuestionanswerList([]);
};


/**
 * optional double questionScores = 7;
 * @return {number}
 */
proto.pbs.SimiQuestion.prototype.getQuestionscores = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionscores = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string questionAnswerDesc = 8;
 * @return {string}
 */
proto.pbs.SimiQuestion.prototype.getQuestionanswerdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SimiQuestion} returns this
 */
proto.pbs.SimiQuestion.prototype.setQuestionanswerdesc = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreStandard.repeatedFields_ = [4,6,7,9,14,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blank: (f = msg.getBlank()) && proto.pbs.BlankParam.toObject(includeInstance, f),
    clampParamList: jspb.Message.toObjectList(msg.getClampParamList(),
    proto.pbs.ClampParam.toObject, includeInstance),
    cutter: (f = msg.getCutter()) && proto.pbs.CutterParam.toObject(includeInstance, f),
    operateProcessList: jspb.Message.toObjectList(msg.getOperateProcessList(),
    proto.pbs.OperateProcessParam.toObject, includeInstance),
    machineList: jspb.Message.toObjectList(msg.getMachineList(),
    proto.pbs.MachineParam.toObject, includeInstance),
    machineProcessMap: (f = msg.getMachineProcessMap()) ? f.toObject(includeInstance, proto.pbs.MachineProcessParam.toObject) : [],
    workpieceList: jspb.Message.toObjectList(msg.getWorkpieceList(),
    proto.pbs.WorkpieceParam.toObject, includeInstance),
    machineEffective: (f = msg.getMachineEffective()) && proto.pbs.MachineEffectiveParam.toObject(includeInstance, f),
    operateLogParam: (f = msg.getOperateLogParam()) && proto.pbs.OperateLogParam.toObject(includeInstance, f),
    profileParam: (f = msg.getProfileParam()) && proto.pbs.ProfileParam.toObject(includeInstance, f),
    standardType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    workpieceMillingList: jspb.Message.toObjectList(msg.getWorkpieceMillingList(),
    proto.pbs.WorkpieceParamMilling.toObject, includeInstance),
    modelType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    workpieceImg: jspb.Message.getFieldWithDefault(msg, 17, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    major: (f = msg.getMajor()) && major_pb.Major.toObject(includeInstance, f),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 21, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    scorestandardmodifier: jspb.Message.getFieldWithDefault(msg, 24, ""),
    ma6ScoreStandard: (f = msg.getMa6ScoreStandard()) && proto.pbs.MA6ScoreStandard.toObject(includeInstance, f),
    machineRepeatProcessList: jspb.Message.toObjectList(msg.getMachineRepeatProcessList(),
    proto.pbs.MachineProcessParam.toObject, includeInstance),
    scorestandardcreator: jspb.Message.getFieldWithDefault(msg, 26, ""),
    lab18ScoreStandard: (f = msg.getLab18ScoreStandard()) && proto.pbs.LAB18ScoreStandard.toObject(includeInstance, f),
    spinCoaterScoreStandard: (f = msg.getSpinCoaterScoreStandard()) && proto.pbs.SpinCoaterScoreStandard.toObject(includeInstance, f),
    cleaningScoreStandard: (f = msg.getCleaningScoreStandard()) && proto.pbs.CleaningScoreStandard.toObject(includeInstance, f),
    developmentScoreStandard: (f = msg.getDevelopmentScoreStandard()) && proto.pbs.DevelopmentScoreStandard.toObject(includeInstance, f),
    plasmaScoreStandard: (f = msg.getPlasmaScoreStandard()) && proto.pbs.PlasmaScoreStandard.toObject(includeInstance, f),
    ionImplantationScoreStandard: (f = msg.getIonImplantationScoreStandard()) && proto.pbs.IonImplantationScoreStandard.toObject(includeInstance, f),
    electronBeamScoreStandard: (f = msg.getElectronBeamScoreStandard()) && proto.pbs.ElectronBeamScoreStandard.toObject(includeInstance, f),
    stepperScoreStandard: (f = msg.getStepperScoreStandard()) && proto.pbs.StepperScoreStandard.toObject(includeInstance, f),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    simiProcessStandard: (f = msg.getSimiProcessStandard()) && proto.pbs.SimiProcessStandard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreStandard;
  return proto.pbs.ScoreStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreStandard}
 */
proto.pbs.ScoreStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.pbs.BlankParam;
      reader.readMessage(value,proto.pbs.BlankParam.deserializeBinaryFromReader);
      msg.setBlank(value);
      break;
    case 4:
      var value = new proto.pbs.ClampParam;
      reader.readMessage(value,proto.pbs.ClampParam.deserializeBinaryFromReader);
      msg.addClampParam(value);
      break;
    case 5:
      var value = new proto.pbs.CutterParam;
      reader.readMessage(value,proto.pbs.CutterParam.deserializeBinaryFromReader);
      msg.setCutter(value);
      break;
    case 6:
      var value = new proto.pbs.OperateProcessParam;
      reader.readMessage(value,proto.pbs.OperateProcessParam.deserializeBinaryFromReader);
      msg.addOperateProcess(value);
      break;
    case 7:
      var value = new proto.pbs.MachineParam;
      reader.readMessage(value,proto.pbs.MachineParam.deserializeBinaryFromReader);
      msg.addMachine(value);
      break;
    case 8:
      var value = msg.getMachineProcessMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.pbs.MachineProcessParam.deserializeBinaryFromReader, "", new proto.pbs.MachineProcessParam());
         });
      break;
    case 9:
      var value = new proto.pbs.WorkpieceParam;
      reader.readMessage(value,proto.pbs.WorkpieceParam.deserializeBinaryFromReader);
      msg.addWorkpiece(value);
      break;
    case 10:
      var value = new proto.pbs.MachineEffectiveParam;
      reader.readMessage(value,proto.pbs.MachineEffectiveParam.deserializeBinaryFromReader);
      msg.setMachineEffective(value);
      break;
    case 11:
      var value = new proto.pbs.OperateLogParam;
      reader.readMessage(value,proto.pbs.OperateLogParam.deserializeBinaryFromReader);
      msg.setOperateLogParam(value);
      break;
    case 12:
      var value = new proto.pbs.ProfileParam;
      reader.readMessage(value,proto.pbs.ProfileParam.deserializeBinaryFromReader);
      msg.setProfileParam(value);
      break;
    case 13:
      var value = /** @type {!proto.pbs.ScoreStandardType} */ (reader.readEnum());
      msg.setStandardType(value);
      break;
    case 14:
      var value = new proto.pbs.WorkpieceParamMilling;
      reader.readMessage(value,proto.pbs.WorkpieceParamMilling.deserializeBinaryFromReader);
      msg.addWorkpieceMilling(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkpieceImg(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 20:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.setMajor(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardmodifier(value);
      break;
    case 22:
      var value = new proto.pbs.MA6ScoreStandard;
      reader.readMessage(value,proto.pbs.MA6ScoreStandard.deserializeBinaryFromReader);
      msg.setMa6ScoreStandard(value);
      break;
    case 25:
      var value = new proto.pbs.MachineProcessParam;
      reader.readMessage(value,proto.pbs.MachineProcessParam.deserializeBinaryFromReader);
      msg.addMachineRepeatProcess(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorestandardcreator(value);
      break;
    case 27:
      var value = new proto.pbs.LAB18ScoreStandard;
      reader.readMessage(value,proto.pbs.LAB18ScoreStandard.deserializeBinaryFromReader);
      msg.setLab18ScoreStandard(value);
      break;
    case 28:
      var value = new proto.pbs.SpinCoaterScoreStandard;
      reader.readMessage(value,proto.pbs.SpinCoaterScoreStandard.deserializeBinaryFromReader);
      msg.setSpinCoaterScoreStandard(value);
      break;
    case 29:
      var value = new proto.pbs.CleaningScoreStandard;
      reader.readMessage(value,proto.pbs.CleaningScoreStandard.deserializeBinaryFromReader);
      msg.setCleaningScoreStandard(value);
      break;
    case 30:
      var value = new proto.pbs.DevelopmentScoreStandard;
      reader.readMessage(value,proto.pbs.DevelopmentScoreStandard.deserializeBinaryFromReader);
      msg.setDevelopmentScoreStandard(value);
      break;
    case 31:
      var value = new proto.pbs.PlasmaScoreStandard;
      reader.readMessage(value,proto.pbs.PlasmaScoreStandard.deserializeBinaryFromReader);
      msg.setPlasmaScoreStandard(value);
      break;
    case 32:
      var value = new proto.pbs.IonImplantationScoreStandard;
      reader.readMessage(value,proto.pbs.IonImplantationScoreStandard.deserializeBinaryFromReader);
      msg.setIonImplantationScoreStandard(value);
      break;
    case 33:
      var value = new proto.pbs.ElectronBeamScoreStandard;
      reader.readMessage(value,proto.pbs.ElectronBeamScoreStandard.deserializeBinaryFromReader);
      msg.setElectronBeamScoreStandard(value);
      break;
    case 34:
      var value = new proto.pbs.StepperScoreStandard;
      reader.readMessage(value,proto.pbs.StepperScoreStandard.deserializeBinaryFromReader);
      msg.setStepperScoreStandard(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 36:
      var value = new proto.pbs.SimiProcessStandard;
      reader.readMessage(value,proto.pbs.SimiProcessStandard.deserializeBinaryFromReader);
      msg.setSimiProcessStandard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlank();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.pbs.BlankParam.serializeBinaryToWriter
    );
  }
  f = message.getClampParamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.pbs.ClampParam.serializeBinaryToWriter
    );
  }
  f = message.getCutter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.pbs.CutterParam.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.pbs.OperateProcessParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.pbs.MachineParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.pbs.MachineProcessParam.serializeBinaryToWriter);
  }
  f = message.getWorkpieceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.pbs.WorkpieceParam.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffective();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.MachineEffectiveParam.serializeBinaryToWriter
    );
  }
  f = message.getOperateLogParam();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.pbs.OperateLogParam.serializeBinaryToWriter
    );
  }
  f = message.getProfileParam();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.pbs.ProfileParam.serializeBinaryToWriter
    );
  }
  f = message.getStandardType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getWorkpieceMillingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.pbs.WorkpieceParamMilling.serializeBinaryToWriter
    );
  }
  f = message.getModelType();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getWorkpieceImg();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getMajor();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getScorestandardmodifier();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getMa6ScoreStandard();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.pbs.MA6ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getMachineRepeatProcessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.pbs.MachineProcessParam.serializeBinaryToWriter
    );
  }
  f = message.getScorestandardcreator();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getLab18ScoreStandard();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.pbs.LAB18ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getSpinCoaterScoreStandard();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.pbs.SpinCoaterScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getCleaningScoreStandard();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.pbs.CleaningScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentScoreStandard();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.pbs.DevelopmentScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaScoreStandard();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.pbs.PlasmaScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getIonImplantationScoreStandard();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.pbs.IonImplantationScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getElectronBeamScoreStandard();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.pbs.ElectronBeamScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getStepperScoreStandard();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.pbs.StepperScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getSimiProcessStandard();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.pbs.SimiProcessStandard.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BlankParam blank = 3;
 * @return {?proto.pbs.BlankParam}
 */
proto.pbs.ScoreStandard.prototype.getBlank = function() {
  return /** @type{?proto.pbs.BlankParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.BlankParam, 3));
};


/**
 * @param {?proto.pbs.BlankParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setBlank = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearBlank = function() {
  return this.setBlank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasBlank = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ClampParam clamp_param = 4;
 * @return {!Array<!proto.pbs.ClampParam>}
 */
proto.pbs.ScoreStandard.prototype.getClampParamList = function() {
  return /** @type{!Array<!proto.pbs.ClampParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ClampParam, 4));
};


/**
 * @param {!Array<!proto.pbs.ClampParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setClampParamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.ClampParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ClampParam}
 */
proto.pbs.ScoreStandard.prototype.addClampParam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.ClampParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearClampParamList = function() {
  return this.setClampParamList([]);
};


/**
 * optional CutterParam cutter = 5;
 * @return {?proto.pbs.CutterParam}
 */
proto.pbs.ScoreStandard.prototype.getCutter = function() {
  return /** @type{?proto.pbs.CutterParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CutterParam, 5));
};


/**
 * @param {?proto.pbs.CutterParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setCutter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearCutter = function() {
  return this.setCutter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasCutter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated OperateProcessParam operate_process = 6;
 * @return {!Array<!proto.pbs.OperateProcessParam>}
 */
proto.pbs.ScoreStandard.prototype.getOperateProcessList = function() {
  return /** @type{!Array<!proto.pbs.OperateProcessParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.OperateProcessParam, 6));
};


/**
 * @param {!Array<!proto.pbs.OperateProcessParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setOperateProcessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.pbs.OperateProcessParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.OperateProcessParam}
 */
proto.pbs.ScoreStandard.prototype.addOperateProcess = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pbs.OperateProcessParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearOperateProcessList = function() {
  return this.setOperateProcessList([]);
};


/**
 * repeated MachineParam machine = 7;
 * @return {!Array<!proto.pbs.MachineParam>}
 */
proto.pbs.ScoreStandard.prototype.getMachineList = function() {
  return /** @type{!Array<!proto.pbs.MachineParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MachineParam, 7));
};


/**
 * @param {!Array<!proto.pbs.MachineParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMachineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.pbs.MachineParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineParam}
 */
proto.pbs.ScoreStandard.prototype.addMachine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pbs.MachineParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineList = function() {
  return this.setMachineList([]);
};


/**
 * map<string, MachineProcessParam> machine_process = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.pbs.MachineProcessParam>}
 */
proto.pbs.ScoreStandard.prototype.getMachineProcessMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.pbs.MachineProcessParam>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.pbs.MachineProcessParam));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineProcessMap = function() {
  this.getMachineProcessMap().clear();
  return this;
};


/**
 * repeated WorkpieceParam workpiece = 9;
 * @return {!Array<!proto.pbs.WorkpieceParam>}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceParam, 9));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setWorkpieceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.pbs.WorkpieceParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParam}
 */
proto.pbs.ScoreStandard.prototype.addWorkpiece = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pbs.WorkpieceParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearWorkpieceList = function() {
  return this.setWorkpieceList([]);
};


/**
 * optional MachineEffectiveParam machine_effective = 10;
 * @return {?proto.pbs.MachineEffectiveParam}
 */
proto.pbs.ScoreStandard.prototype.getMachineEffective = function() {
  return /** @type{?proto.pbs.MachineEffectiveParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MachineEffectiveParam, 10));
};


/**
 * @param {?proto.pbs.MachineEffectiveParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMachineEffective = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineEffective = function() {
  return this.setMachineEffective(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasMachineEffective = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OperateLogParam operate_log_param = 11;
 * @return {?proto.pbs.OperateLogParam}
 */
proto.pbs.ScoreStandard.prototype.getOperateLogParam = function() {
  return /** @type{?proto.pbs.OperateLogParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.OperateLogParam, 11));
};


/**
 * @param {?proto.pbs.OperateLogParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setOperateLogParam = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearOperateLogParam = function() {
  return this.setOperateLogParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasOperateLogParam = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProfileParam profile_param = 12;
 * @return {?proto.pbs.ProfileParam}
 */
proto.pbs.ScoreStandard.prototype.getProfileParam = function() {
  return /** @type{?proto.pbs.ProfileParam} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ProfileParam, 12));
};


/**
 * @param {?proto.pbs.ProfileParam|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setProfileParam = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearProfileParam = function() {
  return this.setProfileParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasProfileParam = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ScoreStandardType standard_type = 13;
 * @return {!proto.pbs.ScoreStandardType}
 */
proto.pbs.ScoreStandard.prototype.getStandardType = function() {
  return /** @type {!proto.pbs.ScoreStandardType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.pbs.ScoreStandardType} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setStandardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated WorkpieceParamMilling workpiece_milling = 14;
 * @return {!Array<!proto.pbs.WorkpieceParamMilling>}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceMillingList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParamMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.WorkpieceParamMilling, 14));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParamMilling>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setWorkpieceMillingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.WorkpieceParamMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.ScoreStandard.prototype.addWorkpieceMilling = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.WorkpieceParamMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearWorkpieceMillingList = function() {
  return this.setWorkpieceMillingList([]);
};


/**
 * optional int32 model_type = 16;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getModelType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setModelType = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 created_at = 15;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string workpiece_img = 17;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getWorkpieceImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setWorkpieceImg = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string school_id = 18;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string faculty_id = 19;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional Major major = 20;
 * @return {?proto.pbs.Major}
 */
proto.pbs.ScoreStandard.prototype.getMajor = function() {
  return /** @type{?proto.pbs.Major} */ (
    jspb.Message.getWrapperField(this, major_pb.Major, 20));
};


/**
 * @param {?proto.pbs.Major|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMajor = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMajor = function() {
  return this.setMajor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasMajor = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 deleted_at = 21;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 updated_at = 23;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string scoreStandardModifier = 24;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getScorestandardmodifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setScorestandardmodifier = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional MA6ScoreStandard ma6_score_standard = 22;
 * @return {?proto.pbs.MA6ScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getMa6ScoreStandard = function() {
  return /** @type{?proto.pbs.MA6ScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6ScoreStandard, 22));
};


/**
 * @param {?proto.pbs.MA6ScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMa6ScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMa6ScoreStandard = function() {
  return this.setMa6ScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasMa6ScoreStandard = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated MachineProcessParam machine_repeat_process = 25;
 * @return {!Array<!proto.pbs.MachineProcessParam>}
 */
proto.pbs.ScoreStandard.prototype.getMachineRepeatProcessList = function() {
  return /** @type{!Array<!proto.pbs.MachineProcessParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.MachineProcessParam, 25));
};


/**
 * @param {!Array<!proto.pbs.MachineProcessParam>} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setMachineRepeatProcessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.pbs.MachineProcessParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineProcessParam}
 */
proto.pbs.ScoreStandard.prototype.addMachineRepeatProcess = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.pbs.MachineProcessParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearMachineRepeatProcessList = function() {
  return this.setMachineRepeatProcessList([]);
};


/**
 * optional string scoreStandardCreator = 26;
 * @return {string}
 */
proto.pbs.ScoreStandard.prototype.getScorestandardcreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setScorestandardcreator = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional LAB18ScoreStandard lab18_score_standard = 27;
 * @return {?proto.pbs.LAB18ScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getLab18ScoreStandard = function() {
  return /** @type{?proto.pbs.LAB18ScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18ScoreStandard, 27));
};


/**
 * @param {?proto.pbs.LAB18ScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setLab18ScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearLab18ScoreStandard = function() {
  return this.setLab18ScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasLab18ScoreStandard = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional SpinCoaterScoreStandard spin_coater_score_standard = 28;
 * @return {?proto.pbs.SpinCoaterScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getSpinCoaterScoreStandard = function() {
  return /** @type{?proto.pbs.SpinCoaterScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterScoreStandard, 28));
};


/**
 * @param {?proto.pbs.SpinCoaterScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setSpinCoaterScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearSpinCoaterScoreStandard = function() {
  return this.setSpinCoaterScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasSpinCoaterScoreStandard = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional CleaningScoreStandard cleaning_score_standard = 29;
 * @return {?proto.pbs.CleaningScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getCleaningScoreStandard = function() {
  return /** @type{?proto.pbs.CleaningScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningScoreStandard, 29));
};


/**
 * @param {?proto.pbs.CleaningScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setCleaningScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearCleaningScoreStandard = function() {
  return this.setCleaningScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasCleaningScoreStandard = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional DevelopmentScoreStandard development_score_standard = 30;
 * @return {?proto.pbs.DevelopmentScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getDevelopmentScoreStandard = function() {
  return /** @type{?proto.pbs.DevelopmentScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentScoreStandard, 30));
};


/**
 * @param {?proto.pbs.DevelopmentScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setDevelopmentScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearDevelopmentScoreStandard = function() {
  return this.setDevelopmentScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasDevelopmentScoreStandard = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional PlasmaScoreStandard plasma_score_standard = 31;
 * @return {?proto.pbs.PlasmaScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getPlasmaScoreStandard = function() {
  return /** @type{?proto.pbs.PlasmaScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaScoreStandard, 31));
};


/**
 * @param {?proto.pbs.PlasmaScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setPlasmaScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearPlasmaScoreStandard = function() {
  return this.setPlasmaScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasPlasmaScoreStandard = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional IonImplantationScoreStandard ion_implantation_score_standard = 32;
 * @return {?proto.pbs.IonImplantationScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getIonImplantationScoreStandard = function() {
  return /** @type{?proto.pbs.IonImplantationScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationScoreStandard, 32));
};


/**
 * @param {?proto.pbs.IonImplantationScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setIonImplantationScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearIonImplantationScoreStandard = function() {
  return this.setIonImplantationScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasIonImplantationScoreStandard = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional ElectronBeamScoreStandard electron_beam_score_standard = 33;
 * @return {?proto.pbs.ElectronBeamScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getElectronBeamScoreStandard = function() {
  return /** @type{?proto.pbs.ElectronBeamScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamScoreStandard, 33));
};


/**
 * @param {?proto.pbs.ElectronBeamScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setElectronBeamScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearElectronBeamScoreStandard = function() {
  return this.setElectronBeamScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasElectronBeamScoreStandard = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional StepperScoreStandard stepper_score_standard = 34;
 * @return {?proto.pbs.StepperScoreStandard}
 */
proto.pbs.ScoreStandard.prototype.getStepperScoreStandard = function() {
  return /** @type{?proto.pbs.StepperScoreStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperScoreStandard, 34));
};


/**
 * @param {?proto.pbs.StepperScoreStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setStepperScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearStepperScoreStandard = function() {
  return this.setStepperScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasStepperScoreStandard = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional double total_score = 35;
 * @return {number}
 */
proto.pbs.ScoreStandard.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional SimiProcessStandard simi_process_standard = 36;
 * @return {?proto.pbs.SimiProcessStandard}
 */
proto.pbs.ScoreStandard.prototype.getSimiProcessStandard = function() {
  return /** @type{?proto.pbs.SimiProcessStandard} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SimiProcessStandard, 36));
};


/**
 * @param {?proto.pbs.SimiProcessStandard|undefined} value
 * @return {!proto.pbs.ScoreStandard} returns this
*/
proto.pbs.ScoreStandard.prototype.setSimiProcessStandard = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ScoreStandard} returns this
 */
proto.pbs.ScoreStandard.prototype.clearSimiProcessStandard = function() {
  return this.setSimiProcessStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ScoreStandard.prototype.hasSimiProcessStandard = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * @enum {number}
 */
proto.pbs.MA6TemplateType = {
  MA6WAFERSIZETMPLTYPE: 0,
  MA6WAFERCARRIERSIZETMPLTYPE: 1,
  MA6BOARDSHELFSIZETMPLTYPE: 2,
  MA6LITHOGRAPHYBOARDSIZETMPLTYPE: 3,
  MA6LITHOGRAPHYBOARDOPTYPETMPLTYPE: 4,
  MA6EXPOSUREMODETMPLTYPE: 5,
  MA6OPERATIONTMPLTYPE: 6
};

/**
 * @enum {number}
 */
proto.pbs.MA6WaferSize = {
  WAFER_: 0,
  WAFERTWOINCHES: 1,
  WAFERFOURINCHES: 2,
  WAFERSIXINCHES: 3
};

/**
 * @enum {number}
 */
proto.pbs.MA6WaferCarrierSize = {
  WAFERCARRIER_: 0,
  WAFERCARRIERTWOINCHES: 1,
  WAFERCARRIERFOURINCHES: 2,
  WAFERCARRIERSIXINCHES: 3
};

/**
 * @enum {number}
 */
proto.pbs.MA6BoardShelfSize = {
  BOARDSHELF_: 0,
  BOARDSHELFTWOINCHES: 1,
  BOARDSHELFFOURINCHES: 2,
  BOARDSHELFSIXINCHES: 3
};

/**
 * @enum {number}
 */
proto.pbs.MA6LithographyBoardSize = {
  LITHOGRAPHYBOARD_: 0,
  LITHOGRAPHYBOARDTHREEINCHES: 1,
  LITHOGRAPHYBOARDFIVEINCHES: 2,
  LITHOGRAPHYBOARDSEVENINCHES: 3
};

/**
 * @enum {number}
 */
proto.pbs.MA6LithographyBoardOpType = {
  MA6LITHOGRAPHYBOARDOP_: 0,
  CRSIDEUPWARD: 1,
  CRSIDEADOWN: 2
};

/**
 * @enum {number}
 */
proto.pbs.MA6ExposureMode = {
  EXPOSUREMODE_: 0,
  HARD: 1,
  LOWVAC: 2,
  FLOOD_E: 3,
  SOFT: 4,
  VAC: 5,
  PROX: 6
};

/**
 * @enum {number}
 */
proto.pbs.MA6OperationStageType = {
  OPSTAGEMOUNTLITHOGRAPHYBOARD: 0,
  OPSTAGEMOUNTWAFER: 1,
  OPSTAGEWAFERALIGN: 2,
  OPSTAGEEXPOSURE: 3,
  OPSTAGEEXTRACTWAFER: 4,
  OPSTAGEEXTRACTBOARDSHELF: 5,
  OPSTAGEEXTRACTLITHOGRAPHYBOARD: 6
};

/**
 * @enum {number}
 */
proto.pbs.MA6OperationStepType = {
  OPSTEP_: 0,
  OPSTEP_LITHOGRAPHYBOARDSELECTION: 1,
  OPSTEP_PUSHBUTTONCHANGEMASKANDENTER: 2,
  OPSTEP_MOVEWAFER: 3,
  OPSTEP_THREEPOINTALIGN: 4,
  OPSTEP_PLACEWAFER: 5,
  OPSTEP_PUSHBUTTONLOADANDENTER: 6,
  OPSTEP_PUSHSAMPLEHOLDER: 7,
  OPSTEP_PUSHBUTTONENTER: 8,
  OPSTEP_MOVEMICROSCOPE: 9,
  OPSTEP_PUSHBUTTONSETPERFERENCE: 10,
  OPSTEP_PUSHBUTTONSCANAFTERALIGN: 11,
  OPSTEP_FINISHALIGN: 12,
  OPSTEP_PUSHBUTTONSELECTPROGRAM: 13,
  OPSTEP_PUSHBUTTONSELECTPROGRAMAFTERALIGN: 14,
  OPSTEP_PUSHBUTTONEDITPARAMETER: 15,
  OPSTEP_PUSHBUTTONEDITPARAMETERAFERALIGN: 16,
  OPSTEP_PULLOUTSAMPLEHOLDER: 17,
  OPSTEP_EXTRACTWAFER: 18,
  OPSTEP_PUSHBUTTONCHANGEMASK: 19,
  OPSTEP_EXTRACTLITHOGRAPHYBOARDSHELF: 20,
  OPSTEP_EXTRACTLITHOGRAPHYBOARD: 21,
  OPSTEP_STARTEXAM: 22,
  OPSTEP_ALIGN: 23,
  OPSTEP_PUSHBUTTONEXPOSURE: 24
};

/**
 * @enum {number}
 */
proto.pbs.LAB18TemplateType = {
  LAB18SPUTTERINGPROCEDURETYPE: 0,
  LAB18OPERATIONSTAGESTYPE: 1
};

/**
 * @enum {number}
 */
proto.pbs.ProgramType = {
  PROGRAM_: 0,
  DC_SPUTTERING: 1,
  RF_SPUTTERING: 2
};

/**
 * @enum {number}
 */
proto.pbs.LAB18OperationStageType = {
  LAB18_VACUUMDESTRUCTION: 0,
  LAB18_OPENTARGETBEZEL: 1,
  LAB18_TIGHTENSCREWS: 2,
  LAB18_CLOSETARGETBEZEL: 3,
  LAB18_VACUUMING: 4,
  LAB18_SAVEPROGRAM: 5,
  LAB18_RUNPROGRAM: 6,
  LAB18_REMOVETARGET: 7,
  LAB18_PLACEWAFER: 8
};

/**
 * @enum {number}
 */
proto.pbs.LAB18OperationStepType = {
  LAB18STEP_: 0,
  LAB18STEP_OPENHATCH: 1,
  LAB18STEP_REMOVESLEEVE: 2,
  LAB18STEP_INSERTTRACHEA: 3,
  LAB18STEP_CLOSEHATCH: 4,
  LAB18STEP_ADJUSTPARAMETERS: 5,
  LAB18STEP_CLICKUPDATEVBBUTTON: 6,
  LAB18STEP_RUNPROGRAMS: 7,
  LAB18STEP_REMOVRING: 8,
  LAB18STEP_CLICKSTARTPCVENTBUTTON: 9,
  LAB18STEP_CLICKTARGETSOURCEBEZEL: 10,
  LAB18STEP_TIGHTENSCREWSINDIAGONALORDER: 11,
  LAB18STEP_CLICKSTARTPCPUMPBUTTON: 12,
  LAB18STEP_CLICKRUNRECIPEBUTTON: 13,
  LAB18STEP_REMOVETARGET: 14,
  LAB18STEP_STARTEXAM: 15,
  LAB18STEP_CLOSETARGETSOURCEBEZEL: 16,
  LAB18STEP_OPENPLACEWAFERBEZEL: 17,
  LAB18STEP_CLICKWAFER: 18
};

/**
 * @enum {number}
 */
proto.pbs.SpinCoaterTemplateType = {
  SPINCOATEROPERATIONSTAGESTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.SpinCoaterOperationStageType = {
  OPSTAGEVACUUMING: 0,
  OPSTAGERUNPROGRAM: 1,
  OPSTAGEVACUUMDESTRUCTION: 2,
  OPSTAGEPREBAKE: 3
};

/**
 * @enum {number}
 */
proto.pbs.SpinCoaterOperationStepType = {
  SPINCOATER_: 0,
  SPINCOATER_PLACEWAFER: 1,
  SPINCOATER_DISPENSING: 2,
  SPINCOATER_RUNPROGRAM: 3,
  SPINCOATER_SETTEMPERATUREANDTIME: 4,
  SPINCOATER_CLICKVACCUNMBUTTON: 5,
  SPINCOATER_CLICKRUNBUTTON: 6,
  SPINCOATER_CLICKRESETBUTTON: 7,
  SPINCOATER_CLICKSTARTBUTTON: 8,
  SPINCOATER_STARTEXAM: 9
};

/**
 * @enum {number}
 */
proto.pbs.CleaningTemplateType = {
  CLEANINGDEVICETPLTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.CleaningStep = {
  NOTBLOWDRY: 0,
  BLOWDRY: 1
};

/**
 * @enum {number}
 */
proto.pbs.CleaningDeviceType = {
  DEVICETYPE_: 0,
  ULTRASONICCLEANINGMACHINE: 1,
  TIMER: 2
};

/**
 * @enum {number}
 */
proto.pbs.PlasmaTemplateType = {
  PLASMAOPERATIONSTAGESTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.PlasmaOperationStageType = {
  PLASMASTAGEVACUUMDESTRUCTION: 0,
  PLASMASTAGEPLACEWAFER: 1,
  PLASMASTAGEVACUUMING: 2,
  PLASMASTAGESTOPVACUUMING: 3
};

/**
 * @enum {number}
 */
proto.pbs.PlasmaOperationStepType = {
  PLASMA_: 0,
  PLASMA_OPENHATCH: 1,
  PLASMA_CLOSEHATCH: 2,
  PLASMA_RUNPROGRAM: 3,
  PLASMA_CLICKSTOPBUTTON: 4,
  PLASMA_CLICKDESTRUCTIONBUTTON: 5,
  PLASMA_CLICKPLACEWAFER: 6,
  PLASMA_CLICKVACUUMINGBUTTON: 7,
  PLASMA_STARTEXAM: 8
};

/**
 * @enum {number}
 */
proto.pbs.IonImplantationTemplateType = {
  IONIMPLANTATIONOPERATIONSTAGESTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.IonImplantationOperationStageType = {
  TURNONTHEPOWER: 0,
  STARTIONIMPLANTATION: 1,
  TURNOFFTHEPOWER: 2
};

/**
 * @enum {number}
 */
proto.pbs.IonImplantationOperationStepType = {
  IONIMPLANTATION_: 0,
  IONIMPLANTATION_SETTINGPARAMETERS: 1,
  IONIMPLANTATION_TURNONTHEPOWER: 2,
  IONIMPLANTATION_STARTIONIMPLANTATION: 3,
  IONIMPLANTATION_STOPIONIMPLANTATION: 4,
  IONIMPLANTATION_CLICKUNLOADBUTTON: 5
};

/**
 * @enum {number}
 */
proto.pbs.EbeamTemplateType = {
  EBEAMOPERATIONSTAGESTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.EbeamSwitchStatusType = {
  EBEAMSWITCH_CLOSE: 0,
  EBEAMSWITCH_OPEN: 1
};

/**
 * @enum {number}
 */
proto.pbs.ElectronBeamOperationStageType = {
  EBSTAGEVACUUMDESTRUCTION: 0,
  EBSTAGEPLACEWAFER: 1,
  EBSTAGEVACUUMING: 2,
  EBSTAGERUNPROGRAM: 3,
  EBSTAGEREMOVEWAFER: 4
};

/**
 * @enum {number}
 */
proto.pbs.ElectronBeamOperationStepType = {
  ELECTRONBEAM_: 0,
  EB_OPENHATCH: 1,
  EB_CLOSEHATCH: 2,
  EB_CHOOSEPROGRAM: 3,
  EB_CLICKRUNBUTTON: 4,
  EB_CLICKSTARTPCVENTBUTTON: 5,
  EB_PLACEWAFER: 6,
  EB_CLICKSTARTPCPUMPBUTTON: 7,
  EB_REMOVEWAFER: 8
};

/**
 * @enum {number}
 */
proto.pbs.StepperTemplateType = {
  STEPPEROPERATIONSTAGESTYPE: 0
};

/**
 * @enum {number}
 */
proto.pbs.StepperOperationStageType = {
  STEPPERSTAGEPLACELITHOGRAPHY: 0,
  STEPPERSTAGEPLACEWAFER: 1,
  SETPPERSTAGECORRECTLITHOGRAPHY: 2,
  SETPPERSTAGEREMOVELITHOGRAPHY: 3,
  SETPPERSTAGEREMOVEWAFER: 4
};

/**
 * @enum {number}
 */
proto.pbs.StepperOperationStepType = {
  STEPPER_: 0,
  STEPPER_OPENCABINETDOOR: 1,
  STEPPER_ENTERLOCATION: 2,
  STEPPER_CLICKLITHOGRAPHY: 3,
  STEPPER_CLICKWAFER: 4,
  STEPPER_CLICKCORRECTBUTTON: 5,
  STEPPER_CLICKWAFERDOOR: 6,
  STEPPER_CLICKUNLOADCABINET: 7,
  STEPPER_CLICKUNLOADWAFER: 8
};

/**
 * @enum {number}
 */
proto.pbs.ScoreStandardType = {
  LATHEMACHINE: 0,
  MILLINGMACHINE: 1,
  MA6: 2,
  LAB18: 3,
  SPINCOATER: 4,
  CLEANING: 5,
  DEVELOPMENT: 6,
  PLASMA: 7,
  IONIMPLANTATION: 8,
  ELECTRONBEAM: 9,
  STEPPER: 10,
  SIMIPROCESS: 11
};

goog.object.extend(exports, proto.pbs);
