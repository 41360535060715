// source: operation_log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
goog.exportSymbol('proto.pbs.CleaningParams', null, global);
goog.exportSymbol('proto.pbs.DevelopmentParams', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamParams', null, global);
goog.exportSymbol('proto.pbs.ElectronBeamParams.DepoProgram', null, global);
goog.exportSymbol('proto.pbs.IonImplantationParams', null, global);
goog.exportSymbol('proto.pbs.LAB18Params', null, global);
goog.exportSymbol('proto.pbs.MA6Params', null, global);
goog.exportSymbol('proto.pbs.OperationLog', null, global);
goog.exportSymbol('proto.pbs.PlasmaParams', null, global);
goog.exportSymbol('proto.pbs.PlasmaParams.Params', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterParams', null, global);
goog.exportSymbol('proto.pbs.SpinCoaterParams.Coater', null, global);
goog.exportSymbol('proto.pbs.StepperParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.MA6Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.MA6Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.MA6Params.displayName = 'proto.pbs.MA6Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.LAB18Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.LAB18Params.repeatedFields_, null);
};
goog.inherits(proto.pbs.LAB18Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.LAB18Params.displayName = 'proto.pbs.LAB18Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.SpinCoaterParams.repeatedFields_, null);
};
goog.inherits(proto.pbs.SpinCoaterParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterParams.displayName = 'proto.pbs.SpinCoaterParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SpinCoaterParams.Coater = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SpinCoaterParams.Coater, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SpinCoaterParams.Coater.displayName = 'proto.pbs.SpinCoaterParams.Coater';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CleaningParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningParams.displayName = 'proto.pbs.CleaningParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.DevelopmentParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.DevelopmentParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.DevelopmentParams.displayName = 'proto.pbs.DevelopmentParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaParams.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaParams.displayName = 'proto.pbs.PlasmaParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaParams.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlasmaParams.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaParams.Params.displayName = 'proto.pbs.PlasmaParams.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.IonImplantationParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.IonImplantationParams.repeatedFields_, null);
};
goog.inherits(proto.pbs.IonImplantationParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.IonImplantationParams.displayName = 'proto.pbs.IonImplantationParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ElectronBeamParams.repeatedFields_, null);
};
goog.inherits(proto.pbs.ElectronBeamParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamParams.displayName = 'proto.pbs.ElectronBeamParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ElectronBeamParams.DepoProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ElectronBeamParams.DepoProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ElectronBeamParams.DepoProgram.displayName = 'proto.pbs.ElectronBeamParams.DepoProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.StepperParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.StepperParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.StepperParams.displayName = 'proto.pbs.StepperParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.OperationLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.OperationLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.OperationLog.displayName = 'proto.pbs.OperationLog';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.MA6Params.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.MA6Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.MA6Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    ma6WaferCarrierSizeSel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ma6BoardShelfSizeSel: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ma6LithographyBoardSizeSel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ma6LithographyBoardOpTypeSel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ma6ExposureModeSel: jspb.Message.getFieldWithDefault(msg, 5, 0),
    exposureDurationSet: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    ma6WaferAlignPositionX: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    ma6WaferAlignPositionY: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    ma6WaferAlignAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.MA6Params}
 */
proto.pbs.MA6Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.MA6Params;
  return proto.pbs.MA6Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.MA6Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.MA6Params}
 */
proto.pbs.MA6Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.MA6WaferCarrierSize} */ (reader.readEnum());
      msg.setMa6WaferCarrierSizeSel(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.MA6BoardShelfSize} */ (reader.readEnum());
      msg.setMa6BoardShelfSizeSel(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.MA6LithographyBoardSize} */ (reader.readEnum());
      msg.setMa6LithographyBoardSizeSel(value);
      break;
    case 4:
      var value = /** @type {!proto.pbs.MA6LithographyBoardOpType} */ (reader.readEnum());
      msg.setMa6LithographyBoardOpTypeSel(value);
      break;
    case 5:
      var value = /** @type {!proto.pbs.MA6ExposureMode} */ (reader.readEnum());
      msg.setMa6ExposureModeSel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExposureDurationSet(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignPositionX(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignPositionY(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMa6WaferAlignAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.MA6Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.MA6Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.MA6Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.MA6Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMa6WaferCarrierSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMa6BoardShelfSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMa6LithographyBoardSizeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMa6LithographyBoardOpTypeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMa6ExposureModeSel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getExposureDurationSet();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMa6WaferAlignPositionX();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMa6WaferAlignPositionY();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getMa6WaferAlignAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional MA6WaferCarrierSize ma6_wafer_carrier_size_sel = 1;
 * @return {!proto.pbs.MA6WaferCarrierSize}
 */
proto.pbs.MA6Params.prototype.getMa6WaferCarrierSizeSel = function() {
  return /** @type {!proto.pbs.MA6WaferCarrierSize} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.MA6WaferCarrierSize} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6WaferCarrierSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MA6BoardShelfSize ma6_board_shelf_size_sel = 2;
 * @return {!proto.pbs.MA6BoardShelfSize}
 */
proto.pbs.MA6Params.prototype.getMa6BoardShelfSizeSel = function() {
  return /** @type {!proto.pbs.MA6BoardShelfSize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.MA6BoardShelfSize} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6BoardShelfSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MA6LithographyBoardSize ma6_lithography_board_size_sel = 3;
 * @return {!proto.pbs.MA6LithographyBoardSize}
 */
proto.pbs.MA6Params.prototype.getMa6LithographyBoardSizeSel = function() {
  return /** @type {!proto.pbs.MA6LithographyBoardSize} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.MA6LithographyBoardSize} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6LithographyBoardSizeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional MA6LithographyBoardOpType ma6_lithography_board_op_type_sel = 4;
 * @return {!proto.pbs.MA6LithographyBoardOpType}
 */
proto.pbs.MA6Params.prototype.getMa6LithographyBoardOpTypeSel = function() {
  return /** @type {!proto.pbs.MA6LithographyBoardOpType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.pbs.MA6LithographyBoardOpType} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6LithographyBoardOpTypeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional MA6ExposureMode ma6_exposure_mode_sel = 5;
 * @return {!proto.pbs.MA6ExposureMode}
 */
proto.pbs.MA6Params.prototype.getMa6ExposureModeSel = function() {
  return /** @type {!proto.pbs.MA6ExposureMode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.pbs.MA6ExposureMode} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6ExposureModeSel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float exposure_duration_set = 6;
 * @return {number}
 */
proto.pbs.MA6Params.prototype.getExposureDurationSet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setExposureDurationSet = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float ma6_wafer_align_position_x = 7;
 * @return {number}
 */
proto.pbs.MA6Params.prototype.getMa6WaferAlignPositionX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6WaferAlignPositionX = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float ma6_wafer_align_position_y = 8;
 * @return {number}
 */
proto.pbs.MA6Params.prototype.getMa6WaferAlignPositionY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6WaferAlignPositionY = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float ma6_wafer_align_angle = 9;
 * @return {number}
 */
proto.pbs.MA6Params.prototype.getMa6WaferAlignAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.MA6Params} returns this
 */
proto.pbs.MA6Params.prototype.setMa6WaferAlignAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.LAB18Params.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.LAB18Params.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.LAB18Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.LAB18Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    materialsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    lab18TemplateType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.LAB18Params}
 */
proto.pbs.LAB18Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.LAB18Params;
  return proto.pbs.LAB18Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.LAB18Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.LAB18Params}
 */
proto.pbs.LAB18Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMaterials(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.ProgramType} */ (reader.readEnum());
      msg.setLab18TemplateType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.LAB18Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.LAB18Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.LAB18Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.LAB18Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterialsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLab18TemplateType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string materials = 1;
 * @return {!Array<string>}
 */
proto.pbs.LAB18Params.prototype.getMaterialsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.LAB18Params} returns this
 */
proto.pbs.LAB18Params.prototype.setMaterialsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.LAB18Params} returns this
 */
proto.pbs.LAB18Params.prototype.addMaterials = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.LAB18Params} returns this
 */
proto.pbs.LAB18Params.prototype.clearMaterialsList = function() {
  return this.setMaterialsList([]);
};


/**
 * optional ProgramType lab18_template_type = 2;
 * @return {!proto.pbs.ProgramType}
 */
proto.pbs.LAB18Params.prototype.getLab18TemplateType = function() {
  return /** @type {!proto.pbs.ProgramType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.ProgramType} value
 * @return {!proto.pbs.LAB18Params} returns this
 */
proto.pbs.LAB18Params.prototype.setLab18TemplateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.SpinCoaterParams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    coaterList: jspb.Message.toObjectList(msg.getCoaterList(),
    proto.pbs.SpinCoaterParams.Coater.toObject, includeInstance),
    prebakeTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    prebakeTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterParams}
 */
proto.pbs.SpinCoaterParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterParams;
  return proto.pbs.SpinCoaterParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterParams}
 */
proto.pbs.SpinCoaterParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.SpinCoaterParams.Coater;
      reader.readMessage(value,proto.pbs.SpinCoaterParams.Coater.deserializeBinaryFromReader);
      msg.addCoater(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrebakeTemperature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrebakeTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoaterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.SpinCoaterParams.Coater.serializeBinaryToWriter
    );
  }
  f = message.getPrebakeTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPrebakeTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SpinCoaterParams.Coater.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SpinCoaterParams.Coater.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SpinCoaterParams.Coater} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterParams.Coater.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spinCoaterSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    spinCoaterTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SpinCoaterParams.Coater}
 */
proto.pbs.SpinCoaterParams.Coater.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SpinCoaterParams.Coater;
  return proto.pbs.SpinCoaterParams.Coater.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SpinCoaterParams.Coater} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SpinCoaterParams.Coater}
 */
proto.pbs.SpinCoaterParams.Coater.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpinCoaterSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpinCoaterTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SpinCoaterParams.Coater.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SpinCoaterParams.Coater.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SpinCoaterParams.Coater} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SpinCoaterParams.Coater.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSpinCoaterSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSpinCoaterTime();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int32 serial_no = 1;
 * @return {number}
 */
proto.pbs.SpinCoaterParams.Coater.prototype.getSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterParams.Coater} returns this
 */
proto.pbs.SpinCoaterParams.Coater.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double spin_coater_speed = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterParams.Coater.prototype.getSpinCoaterSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterParams.Coater} returns this
 */
proto.pbs.SpinCoaterParams.Coater.prototype.setSpinCoaterSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float spin_coater_time = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterParams.Coater.prototype.getSpinCoaterTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterParams.Coater} returns this
 */
proto.pbs.SpinCoaterParams.Coater.prototype.setSpinCoaterTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Coater coater = 1;
 * @return {!Array<!proto.pbs.SpinCoaterParams.Coater>}
 */
proto.pbs.SpinCoaterParams.prototype.getCoaterList = function() {
  return /** @type{!Array<!proto.pbs.SpinCoaterParams.Coater>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.SpinCoaterParams.Coater, 1));
};


/**
 * @param {!Array<!proto.pbs.SpinCoaterParams.Coater>} value
 * @return {!proto.pbs.SpinCoaterParams} returns this
*/
proto.pbs.SpinCoaterParams.prototype.setCoaterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.SpinCoaterParams.Coater=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.SpinCoaterParams.Coater}
 */
proto.pbs.SpinCoaterParams.prototype.addCoater = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.SpinCoaterParams.Coater, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.SpinCoaterParams} returns this
 */
proto.pbs.SpinCoaterParams.prototype.clearCoaterList = function() {
  return this.setCoaterList([]);
};


/**
 * optional float prebake_temperature = 2;
 * @return {number}
 */
proto.pbs.SpinCoaterParams.prototype.getPrebakeTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterParams} returns this
 */
proto.pbs.SpinCoaterParams.prototype.setPrebakeTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float prebake_time = 3;
 * @return {number}
 */
proto.pbs.SpinCoaterParams.prototype.getPrebakeTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SpinCoaterParams} returns this
 */
proto.pbs.SpinCoaterParams.prototype.setPrebakeTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    fluidOption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cleaningDevice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cleaningRepetitions: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cleaningTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cleaningTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningParams}
 */
proto.pbs.CleaningParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningParams;
  return proto.pbs.CleaningParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningParams}
 */
proto.pbs.CleaningParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setCleaningDevice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCleaningRepetitions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCleaningTemperature(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCleaningTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCleaningDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCleaningRepetitions();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCleaningTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCleaningTime();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string fluid_option = 1;
 * @return {string}
 */
proto.pbs.CleaningParams.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CleaningParams} returns this
 */
proto.pbs.CleaningParams.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CleaningDeviceType cleaning_device = 2;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.CleaningParams.prototype.getCleaningDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.CleaningParams} returns this
 */
proto.pbs.CleaningParams.prototype.setCleaningDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 cleaning_repetitions = 3;
 * @return {number}
 */
proto.pbs.CleaningParams.prototype.getCleaningRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningParams} returns this
 */
proto.pbs.CleaningParams.prototype.setCleaningRepetitions = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float cleaning_temperature = 4;
 * @return {number}
 */
proto.pbs.CleaningParams.prototype.getCleaningTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningParams} returns this
 */
proto.pbs.CleaningParams.prototype.setCleaningTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float cleaning_time = 5;
 * @return {number}
 */
proto.pbs.CleaningParams.prototype.getCleaningTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.CleaningParams} returns this
 */
proto.pbs.CleaningParams.prototype.setCleaningTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.DevelopmentParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.DevelopmentParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.DevelopmentParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    fluidOption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    developmentDevice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    developmentRepetitions: jspb.Message.getFieldWithDefault(msg, 3, 0),
    developmentTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    developmentTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.DevelopmentParams}
 */
proto.pbs.DevelopmentParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.DevelopmentParams;
  return proto.pbs.DevelopmentParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.DevelopmentParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.DevelopmentParams}
 */
proto.pbs.DevelopmentParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFluidOption(value);
      break;
    case 2:
      var value = /** @type {!proto.pbs.CleaningDeviceType} */ (reader.readEnum());
      msg.setDevelopmentDevice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDevelopmentRepetitions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDevelopmentTemperature(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDevelopmentTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.DevelopmentParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.DevelopmentParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.DevelopmentParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.DevelopmentParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFluidOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevelopmentDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDevelopmentRepetitions();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDevelopmentTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDevelopmentTime();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string fluid_option = 1;
 * @return {string}
 */
proto.pbs.DevelopmentParams.prototype.getFluidOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.DevelopmentParams} returns this
 */
proto.pbs.DevelopmentParams.prototype.setFluidOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CleaningDeviceType development_device = 2;
 * @return {!proto.pbs.CleaningDeviceType}
 */
proto.pbs.DevelopmentParams.prototype.getDevelopmentDevice = function() {
  return /** @type {!proto.pbs.CleaningDeviceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pbs.CleaningDeviceType} value
 * @return {!proto.pbs.DevelopmentParams} returns this
 */
proto.pbs.DevelopmentParams.prototype.setDevelopmentDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 development_repetitions = 3;
 * @return {number}
 */
proto.pbs.DevelopmentParams.prototype.getDevelopmentRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentParams} returns this
 */
proto.pbs.DevelopmentParams.prototype.setDevelopmentRepetitions = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float development_temperature = 4;
 * @return {number}
 */
proto.pbs.DevelopmentParams.prototype.getDevelopmentTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentParams} returns this
 */
proto.pbs.DevelopmentParams.prototype.setDevelopmentTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float development_time = 5;
 * @return {number}
 */
proto.pbs.DevelopmentParams.prototype.getDevelopmentTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.DevelopmentParams} returns this
 */
proto.pbs.DevelopmentParams.prototype.setDevelopmentTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaParams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsList: jspb.Message.toObjectList(msg.getParamsList(),
    proto.pbs.PlasmaParams.Params.toObject, includeInstance),
    etchTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    etchIcp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    etchRf: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaParams}
 */
proto.pbs.PlasmaParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaParams;
  return proto.pbs.PlasmaParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaParams}
 */
proto.pbs.PlasmaParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PlasmaParams.Params;
      reader.readMessage(value,proto.pbs.PlasmaParams.Params.deserializeBinaryFromReader);
      msg.addParams(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEtchTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEtchIcp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEtchRf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.PlasmaParams.Params.serializeBinaryToWriter
    );
  }
  f = message.getEtchTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEtchIcp();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getEtchRf();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaParams.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaParams.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaParams.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaParams.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    gasOption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gasPressure: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    gasFlow: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaParams.Params}
 */
proto.pbs.PlasmaParams.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaParams.Params;
  return proto.pbs.PlasmaParams.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaParams.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaParams.Params}
 */
proto.pbs.PlasmaParams.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGasOption(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasPressure(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasFlow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaParams.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaParams.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaParams.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaParams.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGasOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGasPressure();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getGasFlow();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string gas_option = 1;
 * @return {string}
 */
proto.pbs.PlasmaParams.Params.prototype.getGasOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlasmaParams.Params} returns this
 */
proto.pbs.PlasmaParams.Params.prototype.setGasOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float gas_pressure = 2;
 * @return {number}
 */
proto.pbs.PlasmaParams.Params.prototype.getGasPressure = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaParams.Params} returns this
 */
proto.pbs.PlasmaParams.Params.prototype.setGasPressure = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float gas_flow = 3;
 * @return {number}
 */
proto.pbs.PlasmaParams.Params.prototype.getGasFlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaParams.Params} returns this
 */
proto.pbs.PlasmaParams.Params.prototype.setGasFlow = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Params params = 1;
 * @return {!Array<!proto.pbs.PlasmaParams.Params>}
 */
proto.pbs.PlasmaParams.prototype.getParamsList = function() {
  return /** @type{!Array<!proto.pbs.PlasmaParams.Params>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PlasmaParams.Params, 1));
};


/**
 * @param {!Array<!proto.pbs.PlasmaParams.Params>} value
 * @return {!proto.pbs.PlasmaParams} returns this
*/
proto.pbs.PlasmaParams.prototype.setParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.PlasmaParams.Params=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaParams.Params}
 */
proto.pbs.PlasmaParams.prototype.addParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.PlasmaParams.Params, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaParams} returns this
 */
proto.pbs.PlasmaParams.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};


/**
 * optional float etch_time = 2;
 * @return {number}
 */
proto.pbs.PlasmaParams.prototype.getEtchTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaParams} returns this
 */
proto.pbs.PlasmaParams.prototype.setEtchTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float etch_ICP = 3;
 * @return {number}
 */
proto.pbs.PlasmaParams.prototype.getEtchIcp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaParams} returns this
 */
proto.pbs.PlasmaParams.prototype.setEtchIcp = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float etch_RF = 4;
 * @return {number}
 */
proto.pbs.PlasmaParams.prototype.getEtchRf = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlasmaParams} returns this
 */
proto.pbs.PlasmaParams.prototype.setEtchRf = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.IonImplantationParams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.IonImplantationParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.IonImplantationParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.IonImplantationParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    energy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    dosage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    gasPressure: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    gasFlow: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    beam: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    alphaAxisAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    betaAxisAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    cathodePower: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    electricArcPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    extractionVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    amu: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    accelerationVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    scanVoltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.IonImplantationParams}
 */
proto.pbs.IonImplantationParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.IonImplantationParams;
  return proto.pbs.IonImplantationParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.IonImplantationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.IonImplantationParams}
 */
proto.pbs.IonImplantationParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addElement(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEnergy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDosage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasPressure(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGasFlow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBeam(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAlphaAxisAngle(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBetaAxisAngle(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCathodePower(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setElectricArcPower(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExtractionVoltage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmu(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccelerationVoltage(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScanVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.IonImplantationParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.IonImplantationParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.IonImplantationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.IonImplantationParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEnergy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDosage();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getGasPressure();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getGasFlow();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getBeam();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAlphaAxisAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getBetaAxisAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getCathodePower();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getElectricArcPower();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getExtractionVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getAmu();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getAccelerationVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getScanVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
};


/**
 * repeated string element = 1;
 * @return {!Array<string>}
 */
proto.pbs.IonImplantationParams.prototype.getElementList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setElementList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.addElement = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.clearElementList = function() {
  return this.setElementList([]);
};


/**
 * optional float time = 2;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float energy = 3;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float dosage = 4;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getDosage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setDosage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float gas_pressure = 5;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getGasPressure = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setGasPressure = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float gas_flow = 6;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getGasFlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setGasFlow = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float beam = 7;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getBeam = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setBeam = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float alpha_axis_angle = 8;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getAlphaAxisAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setAlphaAxisAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float beta_axis_angle = 9;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getBetaAxisAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setBetaAxisAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float cathode_power = 10;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getCathodePower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setCathodePower = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float electric_arc_power = 11;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getElectricArcPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setElectricArcPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float extraction_voltage = 12;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getExtractionVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setExtractionVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float amu = 13;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getAmu = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setAmu = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float acceleration_voltage = 14;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getAccelerationVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setAccelerationVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float scan_voltage = 15;
 * @return {number}
 */
proto.pbs.IonImplantationParams.prototype.getScanVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.IonImplantationParams} returns this
 */
proto.pbs.IonImplantationParams.prototype.setScanVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ElectronBeamParams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    depoProgramList: jspb.Message.toObjectList(msg.getDepoProgramList(),
    proto.pbs.ElectronBeamParams.DepoProgram.toObject, includeInstance),
    holderSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamParams}
 */
proto.pbs.ElectronBeamParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamParams;
  return proto.pbs.ElectronBeamParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamParams}
 */
proto.pbs.ElectronBeamParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.ElectronBeamParams.DepoProgram;
      reader.readMessage(value,proto.pbs.ElectronBeamParams.DepoProgram.deserializeBinaryFromReader);
      msg.addDepoProgram(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHolderSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepoProgramList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.ElectronBeamParams.DepoProgram.serializeBinaryToWriter
    );
  }
  f = message.getHolderSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ElectronBeamParams.DepoProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ElectronBeamParams.DepoProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamParams.DepoProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    material: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preEvaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    evaporationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    thickness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    revisedValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram}
 */
proto.pbs.ElectronBeamParams.DepoProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ElectronBeamParams.DepoProgram;
  return proto.pbs.ElectronBeamParams.DepoProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ElectronBeamParams.DepoProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram}
 */
proto.pbs.ElectronBeamParams.DepoProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterial(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPreEvaporationRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEvaporationRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThickness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRevisedValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ElectronBeamParams.DepoProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ElectronBeamParams.DepoProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ElectronBeamParams.DepoProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaterial();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getEvaporationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getThickness();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getRevisedValue();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string material = 1;
 * @return {string}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.getMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram} returns this
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.setMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float pre_evaporation_rate = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.getPreEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram} returns this
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.setPreEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float evaporation_rate = 3;
 * @return {number}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.getEvaporationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram} returns this
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.setEvaporationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float thickness = 4;
 * @return {number}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.getThickness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram} returns this
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.setThickness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float revised_value = 5;
 * @return {number}
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.getRevisedValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram} returns this
 */
proto.pbs.ElectronBeamParams.DepoProgram.prototype.setRevisedValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated DepoProgram depo_program = 1;
 * @return {!Array<!proto.pbs.ElectronBeamParams.DepoProgram>}
 */
proto.pbs.ElectronBeamParams.prototype.getDepoProgramList = function() {
  return /** @type{!Array<!proto.pbs.ElectronBeamParams.DepoProgram>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.ElectronBeamParams.DepoProgram, 1));
};


/**
 * @param {!Array<!proto.pbs.ElectronBeamParams.DepoProgram>} value
 * @return {!proto.pbs.ElectronBeamParams} returns this
*/
proto.pbs.ElectronBeamParams.prototype.setDepoProgramList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.ElectronBeamParams.DepoProgram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ElectronBeamParams.DepoProgram}
 */
proto.pbs.ElectronBeamParams.prototype.addDepoProgram = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.ElectronBeamParams.DepoProgram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ElectronBeamParams} returns this
 */
proto.pbs.ElectronBeamParams.prototype.clearDepoProgramList = function() {
  return this.setDepoProgramList([]);
};


/**
 * optional float holder_speed = 2;
 * @return {number}
 */
proto.pbs.ElectronBeamParams.prototype.getHolderSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ElectronBeamParams} returns this
 */
proto.pbs.ElectronBeamParams.prototype.setHolderSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.StepperParams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.StepperParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.StepperParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    maskParams: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maskInstall: jspb.Message.getFieldWithDefault(msg, 2, 0),
    areaAx: jspb.Message.getFieldWithDefault(msg, 3, 0),
    areaAy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    areaBx: jspb.Message.getFieldWithDefault(msg, 5, 0),
    areaBy: jspb.Message.getFieldWithDefault(msg, 6, 0),
    time: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    focalPlane: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    stepping: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.StepperParams}
 */
proto.pbs.StepperParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.StepperParams;
  return proto.pbs.StepperParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.StepperParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.StepperParams}
 */
proto.pbs.StepperParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaskParams(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaskInstall(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaAx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaAy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaBx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaBy(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFocalPlane(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStepping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.StepperParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.StepperParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.StepperParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.StepperParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaskParams();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaskInstall();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAreaAx();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAreaAy();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAreaBx();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAreaBy();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getFocalPlane();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getStepping();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional int32 mask_params = 1;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getMaskParams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setMaskParams = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 mask_install = 2;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getMaskInstall = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setMaskInstall = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 area_AX = 3;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getAreaAx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setAreaAx = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 area_AY = 4;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getAreaAy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setAreaAy = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 area_BX = 5;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getAreaBx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setAreaBx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 area_BY = 6;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getAreaBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setAreaBy = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float time = 7;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float focal_plane = 8;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getFocalPlane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setFocalPlane = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float stepping = 9;
 * @return {number}
 */
proto.pbs.StepperParams.prototype.getStepping = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.StepperParams} returns this
 */
proto.pbs.StepperParams.prototype.setStepping = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.OperationLog.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.OperationLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.OperationLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperationLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operateType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operateDesc: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ma6params: (f = msg.getMa6params()) && proto.pbs.MA6Params.toObject(includeInstance, f),
    lab18params: (f = msg.getLab18params()) && proto.pbs.LAB18Params.toObject(includeInstance, f),
    spincoaterparams: (f = msg.getSpincoaterparams()) && proto.pbs.SpinCoaterParams.toObject(includeInstance, f),
    cleaningparams: (f = msg.getCleaningparams()) && proto.pbs.CleaningParams.toObject(includeInstance, f),
    developmentparams: (f = msg.getDevelopmentparams()) && proto.pbs.DevelopmentParams.toObject(includeInstance, f),
    plasmaparams: (f = msg.getPlasmaparams()) && proto.pbs.PlasmaParams.toObject(includeInstance, f),
    ionimplantationparams: (f = msg.getIonimplantationparams()) && proto.pbs.IonImplantationParams.toObject(includeInstance, f),
    electronbeamparams: (f = msg.getElectronbeamparams()) && proto.pbs.ElectronBeamParams.toObject(includeInstance, f),
    stepperparams: (f = msg.getStepperparams()) && proto.pbs.StepperParams.toObject(includeInstance, f),
    scoreStandardId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.OperationLog}
 */
proto.pbs.OperationLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.OperationLog;
  return proto.pbs.OperationLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.OperationLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.OperationLog}
 */
proto.pbs.OperationLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperateType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperateDesc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 7:
      var value = new proto.pbs.MA6Params;
      reader.readMessage(value,proto.pbs.MA6Params.deserializeBinaryFromReader);
      msg.setMa6params(value);
      break;
    case 8:
      var value = new proto.pbs.LAB18Params;
      reader.readMessage(value,proto.pbs.LAB18Params.deserializeBinaryFromReader);
      msg.setLab18params(value);
      break;
    case 9:
      var value = new proto.pbs.SpinCoaterParams;
      reader.readMessage(value,proto.pbs.SpinCoaterParams.deserializeBinaryFromReader);
      msg.setSpincoaterparams(value);
      break;
    case 10:
      var value = new proto.pbs.CleaningParams;
      reader.readMessage(value,proto.pbs.CleaningParams.deserializeBinaryFromReader);
      msg.setCleaningparams(value);
      break;
    case 11:
      var value = new proto.pbs.DevelopmentParams;
      reader.readMessage(value,proto.pbs.DevelopmentParams.deserializeBinaryFromReader);
      msg.setDevelopmentparams(value);
      break;
    case 12:
      var value = new proto.pbs.PlasmaParams;
      reader.readMessage(value,proto.pbs.PlasmaParams.deserializeBinaryFromReader);
      msg.setPlasmaparams(value);
      break;
    case 13:
      var value = new proto.pbs.IonImplantationParams;
      reader.readMessage(value,proto.pbs.IonImplantationParams.deserializeBinaryFromReader);
      msg.setIonimplantationparams(value);
      break;
    case 14:
      var value = new proto.pbs.ElectronBeamParams;
      reader.readMessage(value,proto.pbs.ElectronBeamParams.deserializeBinaryFromReader);
      msg.setElectronbeamparams(value);
      break;
    case 15:
      var value = new proto.pbs.StepperParams;
      reader.readMessage(value,proto.pbs.StepperParams.deserializeBinaryFromReader);
      msg.setStepperparams(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.OperationLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.OperationLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.OperationLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.OperationLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperateType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOperateDesc();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMa6params();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pbs.MA6Params.serializeBinaryToWriter
    );
  }
  f = message.getLab18params();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pbs.LAB18Params.serializeBinaryToWriter
    );
  }
  f = message.getSpincoaterparams();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.pbs.SpinCoaterParams.serializeBinaryToWriter
    );
  }
  f = message.getCleaningparams();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.pbs.CleaningParams.serializeBinaryToWriter
    );
  }
  f = message.getDevelopmentparams();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.pbs.DevelopmentParams.serializeBinaryToWriter
    );
  }
  f = message.getPlasmaparams();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.pbs.PlasmaParams.serializeBinaryToWriter
    );
  }
  f = message.getIonimplantationparams();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.pbs.IonImplantationParams.serializeBinaryToWriter
    );
  }
  f = message.getElectronbeamparams();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.pbs.ElectronBeamParams.serializeBinaryToWriter
    );
  }
  f = message.getStepperparams();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.pbs.StepperParams.serializeBinaryToWriter
    );
  }
  f = message.getScoreStandardId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.OperationLog.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exam_id = 2;
 * @return {string}
 */
proto.pbs.OperationLog.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 operate_type = 3;
 * @return {number}
 */
proto.pbs.OperationLog.prototype.getOperateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setOperateType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string operate_desc = 4;
 * @return {string}
 */
proto.pbs.OperationLog.prototype.getOperateDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setOperateDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.pbs.OperationLog.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 deleted_at = 6;
 * @return {number}
 */
proto.pbs.OperationLog.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional MA6Params MA6Params = 7;
 * @return {?proto.pbs.MA6Params}
 */
proto.pbs.OperationLog.prototype.getMa6params = function() {
  return /** @type{?proto.pbs.MA6Params} */ (
    jspb.Message.getWrapperField(this, proto.pbs.MA6Params, 7));
};


/**
 * @param {?proto.pbs.MA6Params|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setMa6params = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearMa6params = function() {
  return this.setMa6params(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasMa6params = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LAB18Params LAB18Params = 8;
 * @return {?proto.pbs.LAB18Params}
 */
proto.pbs.OperationLog.prototype.getLab18params = function() {
  return /** @type{?proto.pbs.LAB18Params} */ (
    jspb.Message.getWrapperField(this, proto.pbs.LAB18Params, 8));
};


/**
 * @param {?proto.pbs.LAB18Params|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setLab18params = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearLab18params = function() {
  return this.setLab18params(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasLab18params = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SpinCoaterParams SpinCoaterParams = 9;
 * @return {?proto.pbs.SpinCoaterParams}
 */
proto.pbs.OperationLog.prototype.getSpincoaterparams = function() {
  return /** @type{?proto.pbs.SpinCoaterParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.SpinCoaterParams, 9));
};


/**
 * @param {?proto.pbs.SpinCoaterParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setSpincoaterparams = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearSpincoaterparams = function() {
  return this.setSpincoaterparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasSpincoaterparams = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CleaningParams CleaningParams = 10;
 * @return {?proto.pbs.CleaningParams}
 */
proto.pbs.OperationLog.prototype.getCleaningparams = function() {
  return /** @type{?proto.pbs.CleaningParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.CleaningParams, 10));
};


/**
 * @param {?proto.pbs.CleaningParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setCleaningparams = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearCleaningparams = function() {
  return this.setCleaningparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasCleaningparams = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DevelopmentParams DevelopmentParams = 11;
 * @return {?proto.pbs.DevelopmentParams}
 */
proto.pbs.OperationLog.prototype.getDevelopmentparams = function() {
  return /** @type{?proto.pbs.DevelopmentParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.DevelopmentParams, 11));
};


/**
 * @param {?proto.pbs.DevelopmentParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setDevelopmentparams = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearDevelopmentparams = function() {
  return this.setDevelopmentparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasDevelopmentparams = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PlasmaParams PlasmaParams = 12;
 * @return {?proto.pbs.PlasmaParams}
 */
proto.pbs.OperationLog.prototype.getPlasmaparams = function() {
  return /** @type{?proto.pbs.PlasmaParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PlasmaParams, 12));
};


/**
 * @param {?proto.pbs.PlasmaParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setPlasmaparams = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearPlasmaparams = function() {
  return this.setPlasmaparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasPlasmaparams = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional IonImplantationParams IonImplantationParams = 13;
 * @return {?proto.pbs.IonImplantationParams}
 */
proto.pbs.OperationLog.prototype.getIonimplantationparams = function() {
  return /** @type{?proto.pbs.IonImplantationParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.IonImplantationParams, 13));
};


/**
 * @param {?proto.pbs.IonImplantationParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setIonimplantationparams = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearIonimplantationparams = function() {
  return this.setIonimplantationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasIonimplantationparams = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ElectronBeamParams ElectronBeamParams = 14;
 * @return {?proto.pbs.ElectronBeamParams}
 */
proto.pbs.OperationLog.prototype.getElectronbeamparams = function() {
  return /** @type{?proto.pbs.ElectronBeamParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ElectronBeamParams, 14));
};


/**
 * @param {?proto.pbs.ElectronBeamParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setElectronbeamparams = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearElectronbeamparams = function() {
  return this.setElectronbeamparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasElectronbeamparams = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional StepperParams StepperParams = 15;
 * @return {?proto.pbs.StepperParams}
 */
proto.pbs.OperationLog.prototype.getStepperparams = function() {
  return /** @type{?proto.pbs.StepperParams} */ (
    jspb.Message.getWrapperField(this, proto.pbs.StepperParams, 15));
};


/**
 * @param {?proto.pbs.StepperParams|undefined} value
 * @return {!proto.pbs.OperationLog} returns this
*/
proto.pbs.OperationLog.prototype.setStepperparams = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.clearStepperparams = function() {
  return this.setStepperparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.OperationLog.prototype.hasStepperparams = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string score_standard_id = 16;
 * @return {string}
 */
proto.pbs.OperationLog.prototype.getScoreStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.OperationLog} returns this
 */
proto.pbs.OperationLog.prototype.setScoreStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


goog.object.extend(exports, proto.pbs);
