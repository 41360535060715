import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'courseDifficulty'
})
export class CourseDifficultyPipe implements PipeTransform {

  private difficultyData = [
    {
      value: 0,
      name: "入门"
    },
    {
      value: 1,
      name: "初级"
    },
    {
      value: 2,
      name: "中级"
    },
    {
      value: 3,
      name: "高级"
    }
  ];
  transform(value: number): any {
    let name = "";
    this.difficultyData.forEach((item, index) => {
        if (item.value == value) {
          name = item.name;
        }
    });
    return name;
  }

}
