import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { ReviewpasswordComponent } from '../reviewpassword/reviewpassword.component';
import { NzModalService, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { UserService } from 'src/app/services/user.service';
import { ExamsService } from 'src/app/services/exams.service';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/pbs/user_pb';

@Component({
  selector: 'app-entrance',
  templateUrl: './entrance.component.html',
  styleUrls: ['./entrance.component.styl']
})
export class EntranceComponent implements OnInit {

  public list:any = [
    {
      id: "1",
      name: "报名考试",
      srcUrl: "/assets/img/sign-up.png",
      componentName: "/registration/step1"
    },
    {
      id: "2",
      name: "进入考试",
      srcUrl: "/assets/img/exam.png",
      componentName: "/main"
    },
    {
      id: "3",
      name: "查看成绩",
      srcUrl: "/assets/img/check-results.png",
      componentName: "/resultlist"
    },
    {
      id: "4",
      name: "实训记录",
      srcUrl: "/assets/img/log.png",
      componentName: "/practicelist"
    },
    {
      id: "5",
      name: "个人设置",
      srcUrl: "/assets/img/setting.png",
      componentName: "/personalsetting"
    },
    {
      id: "6",
      name: "关于我们",
      srcUrl: "/assets/img/about.png",
      componentName: "/about"
    },
    // {
    //   id: "7",
    //   name: "日志信息",
    //   srcUrl: "/assets/img/log.png",
    //   componentName: ""
    // },
    {
      id: "9",
      name: "课程学习",
      srcUrl: "/assets/img/classes.png",
      componentName: "/class/list"
    },
    {
      id: "8",
      name: "退出系统",
      srcUrl: "/assets/img/exit.png",
      componentName: ""
    }
  ];

  public user:any;
  private username:string = "Megan";
  private sex:string = "女";
  private registrationNumber:string = "20190813003";
  private identitiyNumber:string = "410894561234587143";

  constructor(private modalService: NzModalService,
    private router: Router,
    private userService: UserService,
    private examsService: ExamsService,
	private titleService: Title,
    private message: NzMessageService,
	private route: ActivatedRoute) { }

  revisePassword() {
    const modal = this.modalService.create({
      nzTitle: '修改密码',
      nzMaskClosable: false,
      nzContent: ReviewpasswordComponent,
      nzComponentParams: {
      },
      nzFooter: [
        {
          label: '保存',
          NzButtonShape: 'primary',
          onClick: (componentInstance) => {
            // modal
            this.userService.changePwd(componentInstance.newPwd,componentInstance.oldPwd,
              (response) => {
                this.message.create("success","修改成功");
                modal.destroy();
              },
              (fail) => {
                this.message.create("error",decodeURI(fail));
                if (decodeURI(fail) == "token无效") {
                  common.returnLogin(this.router, this.examsService, this.message);
                }
              });
          }
        },
        {
          label: '放弃',
          type: 'default',
          onClick: () =>  modal.destroy()
        }
      ]
    });
  }

  exit(){

    const modal: NzModalRef = this.modalService.create({
      nzTitle: '退出',
      nzMaskClosable: false,
      nzContent: '<div class="modal-container">\
                    <div class="icon"><img src="/assets/img/tip.png" alt=""></div>\
                    <p>确定要退出系统吗？</p>\
                  </div>',
      nzFooter: [
        {
          label: '确定',
          NzButtonShape: 'default',
          onClick: () => {
            // this.router.navigate(['/login']);
            common.returnLogin(this.router, this.examsService, this.message);
            modal.destroy();
          }
        },
        {
          label: '取消',
          type: 'primary',
          onClick: () =>  modal.destroy()
        }
      ]
    });
  }
  
  unOpened() {
	const modal: NzModalRef = this.modalService.create({
	  nzTitle: '友情提示',
	  nzMaskClosable: false,
	  nzContent: '<div class="modal-container">\
	                <div class="icon"><img src="/assets/img/tip.png" alt=""></div>\
	                <p>暂未开放，敬请期待！</p>\
	              </div>',
	  nzFooter: [
	    {
	      label: '确定',
	      NzButtonShape: 'default',
	      onClick: () => {
	        modal.destroy();
	      }
	    },
	    // {
	    //   label: '取消',
	    //   type: 'primary',
	    //   onClick: () =>  modal.destroy()
	    // }
	  ]
	});
  }

  onClick(url:any, ev:any){
    if (url == "") {
      this.exit();
    } else {
	  if (url == '/about') {
		// window.open('https://www.vsteach.com')
		return
	  }
	  if (url == '/class/list') {
		  // this.unOpened();
		  return
	  }
      this.router.navigate([url]);
    }
  }

  ngOnInit() {
	this.titleService.setTitle('智能云端数据中心');
	this.user = this.userService.getUser();
	if (!this.user) {
		var token = this.route.snapshot.queryParams.token??"";
		this.userService.getUserInfo((user:User)=>{
			this.user = user;
			this.userService.saveUser(user);
		},null, token)
	}
  }

}
