import { Injectable } from '@angular/core';
import { Service } from './service';
import { CourseCategoryServiceClient } from '../pbs/course_category_grpc_web_pb';
import { CourseCategorys ,CourseCategoryListReq} from '../pbs/course_category_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class CourseCategoryService extends Service {

  courseCategoryService: CourseCategoryServiceClient;

  constructor() { 
    super()
    this.courseCategoryService = new CourseCategoryServiceClient(this.url, null, null);
  }

  getCourseCategoryList(callback:Function=null,fail:Function=null) {
	this.initToken();
    let isOk = false;
    let parm = new CourseCategoryListReq();
    this.courseCategoryService.list(parm,
      this.metadata, (err:Error, response: CourseCategorys) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
}
