/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var user_pb = require('./user_pb.js')

var course_category_pb = require('./course_category_pb.js')
const proto = {};
proto.pbs = require('./course_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.CourseServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.CourseServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Course,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CourseService_Create = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.Course,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Course} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Course,
 *   !proto.pbs.Empty>}
 */
const methodInfo_CourseService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Course} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Course} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/Create',
      request,
      metadata || {},
      methodDescriptor_CourseService_Create,
      callback);
};


/**
 * @param {!proto.pbs.Course} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/Create',
      request,
      metadata || {},
      methodDescriptor_CourseService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Course,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CourseService_Update = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.Course,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Course} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Course,
 *   !proto.pbs.Empty>}
 */
const methodInfo_CourseService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Course} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Course} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/Update',
      request,
      metadata || {},
      methodDescriptor_CourseService_Update,
      callback);
};


/**
 * @param {!proto.pbs.Course} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/Update',
      request,
      metadata || {},
      methodDescriptor_CourseService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CourseService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.CourseId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Empty>}
 */
const methodInfo_CourseService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/Delete',
      request,
      metadata || {},
      methodDescriptor_CourseService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/Delete',
      request,
      metadata || {},
      methodDescriptor_CourseService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CourseListReq,
 *   !proto.pbs.Courses>}
 */
const methodDescriptor_CourseService_List = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/List',
  grpc.web.MethodType.UNARY,
  proto.pbs.CourseListReq,
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.CourseListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CourseListReq,
 *   !proto.pbs.Courses>}
 */
const methodInfo_CourseService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.CourseListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @param {!proto.pbs.CourseListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Courses)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Courses>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/List',
      request,
      metadata || {},
      methodDescriptor_CourseService_List,
      callback);
};


/**
 * @param {!proto.pbs.CourseListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Courses>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/List',
      request,
      metadata || {},
      methodDescriptor_CourseService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Course>}
 */
const methodDescriptor_CourseService_Detail = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/Detail',
  grpc.web.MethodType.UNARY,
  proto.pbs.CourseId,
  proto.pbs.Course,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Course.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Course>}
 */
const methodInfo_CourseService_Detail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Course,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Course.deserializeBinary
);


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Course)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Course>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.detail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/Detail',
      request,
      metadata || {},
      methodDescriptor_CourseService_Detail,
      callback);
};


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Course>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.detail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/Detail',
      request,
      metadata || {},
      methodDescriptor_CourseService_Detail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Courses>}
 */
const methodDescriptor_CourseService_GetUntransformedCourse = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/GetUntransformedCourse',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Courses>}
 */
const methodInfo_CourseService_GetUntransformedCourse = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Courses)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Courses>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.getUntransformedCourse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/GetUntransformedCourse',
      request,
      metadata || {},
      methodDescriptor_CourseService_GetUntransformedCourse,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Courses>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.getUntransformedCourse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/GetUntransformedCourse',
      request,
      metadata || {},
      methodDescriptor_CourseService_GetUntransformedCourse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_CourseService_ChangeCourseTransform = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/ChangeCourseTransform',
  grpc.web.MethodType.UNARY,
  proto.pbs.CourseId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CourseId,
 *   !proto.pbs.Empty>}
 */
const methodInfo_CourseService_ChangeCourseTransform = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.CourseId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.changeCourseTransform =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/ChangeCourseTransform',
      request,
      metadata || {},
      methodDescriptor_CourseService_ChangeCourseTransform,
      callback);
};


/**
 * @param {!proto.pbs.CourseId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.changeCourseTransform =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/ChangeCourseTransform',
      request,
      metadata || {},
      methodDescriptor_CourseService_ChangeCourseTransform);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CollectReq,
 *   !proto.pbs.CollectResp>}
 */
const methodDescriptor_CourseService_Collect = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/Collect',
  grpc.web.MethodType.UNARY,
  proto.pbs.CollectReq,
  proto.pbs.CollectResp,
  /**
   * @param {!proto.pbs.CollectReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.CollectResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CollectReq,
 *   !proto.pbs.CollectResp>}
 */
const methodInfo_CourseService_Collect = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.CollectResp,
  /**
   * @param {!proto.pbs.CollectReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.CollectResp.deserializeBinary
);


/**
 * @param {!proto.pbs.CollectReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.CollectResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.CollectResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.collect =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/Collect',
      request,
      metadata || {},
      methodDescriptor_CourseService_Collect,
      callback);
};


/**
 * @param {!proto.pbs.CollectReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.CollectResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.collect =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/Collect',
      request,
      metadata || {},
      methodDescriptor_CourseService_Collect);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.MyCollectReq,
 *   !proto.pbs.Courses>}
 */
const methodDescriptor_CourseService_MyCollect = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/MyCollect',
  grpc.web.MethodType.UNARY,
  proto.pbs.MyCollectReq,
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.MyCollectReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.MyCollectReq,
 *   !proto.pbs.Courses>}
 */
const methodInfo_CourseService_MyCollect = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.MyCollectReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @param {!proto.pbs.MyCollectReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Courses)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Courses>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.myCollect =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/MyCollect',
      request,
      metadata || {},
      methodDescriptor_CourseService_MyCollect,
      callback);
};


/**
 * @param {!proto.pbs.MyCollectReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Courses>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.myCollect =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/MyCollect',
      request,
      metadata || {},
      methodDescriptor_CourseService_MyCollect);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.CourseListReq,
 *   !proto.pbs.Courses>}
 */
const methodDescriptor_CourseService_CollectList = new grpc.web.MethodDescriptor(
  '/pbs.CourseService/CollectList',
  grpc.web.MethodType.UNARY,
  proto.pbs.CourseListReq,
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.CourseListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.CourseListReq,
 *   !proto.pbs.Courses>}
 */
const methodInfo_CourseService_CollectList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Courses,
  /**
   * @param {!proto.pbs.CourseListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Courses.deserializeBinary
);


/**
 * @param {!proto.pbs.CourseListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Courses)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Courses>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.CourseServiceClient.prototype.collectList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.CourseService/CollectList',
      request,
      metadata || {},
      methodDescriptor_CourseService_CollectList,
      callback);
};


/**
 * @param {!proto.pbs.CourseListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Courses>}
 *     A native promise that resolves to the response
 */
proto.pbs.CourseServicePromiseClient.prototype.collectList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.CourseService/CollectList',
      request,
      metadata || {},
      methodDescriptor_CourseService_CollectList);
};


module.exports = proto.pbs;

