import { Injectable } from '@angular/core';
import { Service } from './service';
import { MajorServiceClient } from '../pbs/major_grpc_web_pb';
import { Majors,MyMajorListReq } from '../pbs/major_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class MajorService extends Service {
  
  majorService: MajorServiceClient;

  constructor() {
    super()
    this.majorService = new MajorServiceClient(this.url,null,null);
  }


  /**
   * @description 获取专业列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof MajorService
   */
  getMajorList(callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.majorService.list(new Empty(),
      this.metadata, (err:Error, response: Majors) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response);
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
  
  getMyMajorList(req:MyMajorListReq,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.majorService.myMajorList(req,
      this.metadata, (err:Error, response: Majors) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
}
