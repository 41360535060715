/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var cutter_pb = require('./cutter_pb.js')

var blank_pb = require('./blank_pb.js')

var user_pb = require('./user_pb.js')

var exams_pb = require('./exams_pb.js')

var score_standard_pb = require('./score_standard_pb.js')

var operation_log_pb = require('./operation_log_pb.js')

var semi_exam_score_pb = require('./semi_exam_score_pb.js')
const proto = {};
proto.pbs = require('./exam_score_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExamScoreServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExamScoreServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.ScoreSummaryResp>}
 */
const methodDescriptor_ExamScoreService_GetScoreSummary = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetScoreSummary',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.ScoreSummaryResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ScoreSummaryResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.ScoreSummaryResp>}
 */
const methodInfo_ExamScoreService_GetScoreSummary = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.ScoreSummaryResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ScoreSummaryResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.ScoreSummaryResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.ScoreSummaryResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getScoreSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetScoreSummary',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetScoreSummary,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.ScoreSummaryResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getScoreSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetScoreSummary',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetScoreSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.ProfessionalScoreResp>}
 */
const methodDescriptor_ExamScoreService_GetProfessional = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetProfessional',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.ProfessionalScoreResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ProfessionalScoreResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.ProfessionalScoreResp>}
 */
const methodInfo_ExamScoreService_GetProfessional = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.ProfessionalScoreResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ProfessionalScoreResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.ProfessionalScoreResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.ProfessionalScoreResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetProfessional',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetProfessional,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.ProfessionalScoreResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetProfessional',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.MachineOperationResp>}
 */
const methodDescriptor_ExamScoreService_GetMachineOperation = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetMachineOperation',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.MachineOperationResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.MachineOperationResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.MachineOperationResp>}
 */
const methodInfo_ExamScoreService_GetMachineOperation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.MachineOperationResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.MachineOperationResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.MachineOperationResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.MachineOperationResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getMachineOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetMachineOperation',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetMachineOperation,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.MachineOperationResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getMachineOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetMachineOperation',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetMachineOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.MachineParamResp>}
 */
const methodDescriptor_ExamScoreService_GetMachineParam = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetMachineParam',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.MachineParamResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.MachineParamResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.MachineParamResp>}
 */
const methodInfo_ExamScoreService_GetMachineParam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.MachineParamResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.MachineParamResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.MachineParamResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.MachineParamResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getMachineParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetMachineParam',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetMachineParam,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.MachineParamResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getMachineParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetMachineParam',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetMachineParam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.OperateProcessResp>}
 */
const methodDescriptor_ExamScoreService_GetOperateProcess = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetOperateProcess',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.OperateProcessResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.OperateProcessResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.OperateProcessResp>}
 */
const methodInfo_ExamScoreService_GetOperateProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.OperateProcessResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.OperateProcessResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.OperateProcessResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.OperateProcessResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getOperateProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetOperateProcess',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetOperateProcess,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.OperateProcessResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getOperateProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetOperateProcess',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetOperateProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GenerateScoreReq,
 *   !proto.pbs.Result>}
 */
const methodDescriptor_ExamScoreService_GenerateScore = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GenerateScore',
  grpc.web.MethodType.UNARY,
  proto.pbs.GenerateScoreReq,
  common_pb.Result,
  /**
   * @param {!proto.pbs.GenerateScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GenerateScoreReq,
 *   !proto.pbs.Result>}
 */
const methodInfo_ExamScoreService_GenerateScore = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Result,
  /**
   * @param {!proto.pbs.GenerateScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @param {!proto.pbs.GenerateScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Result)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Result>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.generateScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateScore',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateScore,
      callback);
};


/**
 * @param {!proto.pbs.GenerateScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Result>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.generateScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateScore',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.AllTypeListResp>}
 */
const methodDescriptor_ExamScoreService_GetExamScoreAllTypeList = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetExamScoreAllTypeList',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.AllTypeListResp,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.AllTypeListResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.AllTypeListResp>}
 */
const methodInfo_ExamScoreService_GetExamScoreAllTypeList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.AllTypeListResp,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.AllTypeListResp.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.AllTypeListResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.AllTypeListResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getExamScoreAllTypeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetExamScoreAllTypeList',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetExamScoreAllTypeList,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.AllTypeListResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getExamScoreAllTypeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetExamScoreAllTypeList',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetExamScoreAllTypeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.BackupExamDataReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamScoreService_GenerateBackupExamData = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GenerateBackupExamData',
  grpc.web.MethodType.UNARY,
  proto.pbs.BackupExamDataReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BackupExamDataReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.BackupExamDataReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamScoreService_GenerateBackupExamData = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.BackupExamDataReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.BackupExamDataReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.generateBackupExamData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateBackupExamData',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateBackupExamData,
      callback);
};


/**
 * @param {!proto.pbs.BackupExamDataReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.generateBackupExamData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateBackupExamData',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateBackupExamData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.BackupExamDataResp>}
 */
const methodDescriptor_ExamScoreService_GetBackupExamData = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GetBackupExamData',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExamIdReq,
  proto.pbs.BackupExamDataResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.BackupExamDataResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExamIdReq,
 *   !proto.pbs.BackupExamDataResp>}
 */
const methodInfo_ExamScoreService_GetBackupExamData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.BackupExamDataResp,
  /**
   * @param {!proto.pbs.ExamIdReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.BackupExamDataResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.BackupExamDataResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.BackupExamDataResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.getBackupExamData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GetBackupExamData',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetBackupExamData,
      callback);
};


/**
 * @param {!proto.pbs.ExamIdReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.BackupExamDataResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.getBackupExamData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GetBackupExamData',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GetBackupExamData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GenerateModelReq,
 *   !proto.pbs.Result>}
 */
const methodDescriptor_ExamScoreService_GenerateModel = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/GenerateModel',
  grpc.web.MethodType.UNARY,
  proto.pbs.GenerateModelReq,
  common_pb.Result,
  /**
   * @param {!proto.pbs.GenerateModelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GenerateModelReq,
 *   !proto.pbs.Result>}
 */
const methodInfo_ExamScoreService_GenerateModel = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Result,
  /**
   * @param {!proto.pbs.GenerateModelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @param {!proto.pbs.GenerateModelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Result)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Result>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.generateModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateModel',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateModel,
      callback);
};


/**
 * @param {!proto.pbs.GenerateModelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Result>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.generateModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/GenerateModel',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_GenerateModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.UpdateExamScoreReq,
 *   !proto.pbs.UpdateExamScoreResp>}
 */
const methodDescriptor_ExamScoreService_UpdateExamScore = new grpc.web.MethodDescriptor(
  '/pbs.ExamScoreService/UpdateExamScore',
  grpc.web.MethodType.UNARY,
  proto.pbs.UpdateExamScoreReq,
  proto.pbs.UpdateExamScoreResp,
  /**
   * @param {!proto.pbs.UpdateExamScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.UpdateExamScoreResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.UpdateExamScoreReq,
 *   !proto.pbs.UpdateExamScoreResp>}
 */
const methodInfo_ExamScoreService_UpdateExamScore = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.UpdateExamScoreResp,
  /**
   * @param {!proto.pbs.UpdateExamScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.UpdateExamScoreResp.deserializeBinary
);


/**
 * @param {!proto.pbs.UpdateExamScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.UpdateExamScoreResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.UpdateExamScoreResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamScoreServiceClient.prototype.updateExamScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamScoreService/UpdateExamScore',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_UpdateExamScore,
      callback);
};


/**
 * @param {!proto.pbs.UpdateExamScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.UpdateExamScoreResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamScoreServicePromiseClient.prototype.updateExamScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamScoreService/UpdateExamScore',
      request,
      metadata || {},
      methodDescriptor_ExamScoreService_UpdateExamScore);
};


module.exports = proto.pbs;

