import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-main',
  templateUrl: './teacher-main.component.html',
  styleUrls: ['./teacher-main.component.styl']
})
export class TeacherMainComponent implements OnInit {

  public listData:any = [];
  public pageSize:number = 12;
  public page:number = 1;
  public totalSize: number = 0;
  public userId:string;
  public user:any = {
    useravatar: "",
    userfname: ""
  };

  public host:string = environment.domainName;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private message: NzMessageService) { 
    activatedRoute.queryParams.subscribe(queryParams => {
      this.userId = queryParams.userId;
    })
  }

  onClick(item:any) {
    if(item.courseType == 0) {
      this.router.navigate(['/class/detail'],{ queryParams: { courseId: item.id } });
    } else {
      this.router.navigate(['/class/detail2'],{ queryParams: { courseId: item.id } });
    } 
  }

  getCourseList() {
    let param = {
      categoryId: null,
      courseDifficulty: -1,
      creatorId: this.userId,
      page: this.page,
      pageSize: this.pageSize
    }
    this.courseService.getList(JSON.stringify(param),
      (response) => {
        this.listData = response.dataList;
        this.user = response.dataList[0].courseCreator;
        this.totalSize = response.count;
        console.log(response.dataList);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

  indexChange(index) {
    this.page = index;
    this.getCourseList();
  }

  ngOnInit() {
    this.getCourseList();
  }

}
