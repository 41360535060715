import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamScoreService } from 'src/app/services/exam-score.service';
import { NzMessageService } from 'ng-zorro-antd';
import * as screenfull from "screenfull";
import { Screenfull } from "screenfull";
import { environment } from 'src/environments/environment';
import { ExamsService } from 'src/app/services/exams.service';
import { StandardItemNotifyService } from 'src/app/services/standard_item_notify.service';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.styl']
})

export class ReportComponent implements OnInit {

  private examId:string;
  public paperType:any = 1; //0车床 1铣床
  public assetType:any = 0; //0图片 1三维模型
  public user:any;
  private pEle:any;
  public typeId:any;
  public op:any;
  public qrCodeUrl:string;

  public scoreSummary:any = {}; //成绩汇总
  public professionalScore:any = {}; //职业素养成绩

  private machineOperation:any; //机床操作成绩
  public blankScore:any = {}; //工件毛坯成绩
  public clampScore:any = {}; //装夹定位成绩
  public cutterScore:any = {}; //切削刀具成绩

  public machineScore:any = {}; //加工参数成绩
  public machineProcessScore:any = {}; //加工工序
  public workpieceQualityScore:any = {}; //工件质量
  public machineEffectiveScore:any = {}; //加工效率
  public ncProgramMap:any = []; //数控代码

  // private operationProcess:any; //操作流程
  public operateLogScore:any = {}; //操作日志
  public ncProgramScore:any = {}; //数控代码成绩
  public operateProcessScore:any = {}; //操作流程
  // private operationLogListList:any = {}; //操作日志明细
  

  public examInfo:any = {}; //考试
  public examVenue:any = {}; //考试场次
  private scoreList:any = []; //项目得分

  public professionalScoreList = []; //职业素养得分列表
  public blankScoreList = []; //工件毛坯成绩列表
  public clampScoreList = []; //装夹定位成绩列表
  public cutterScoreList = []; //切削刀具成绩列表

  public machineList:any = []; //加工参数成绩得分列表
  public machineProcessList:any = []; //加工工序得分列表
  public workpieceQualityList:any = []; //工件质量得分列表
  public machineEffectiveList:any = []; //加工效率得分列表
  // 判断题 A 和 B 映射为 "正确" 和 "错误"
  public optionMapping: { [key: string]: string } = {
    'A': '正确',
    'B': '错误'
  };

  public operateLogScoreList:any = []; //操作日志列表
  public operateProcessList:any = []; //操作流程列表
  public operationLogList:any = []; //操作日志明细列表
  
  private totalScore:any; //表格footer总得分

  public typeLists:any = []; //获取类型列表

  public uploadHost = environment.domainName;

  public tmpToken = ''; //后台查看报告时使用token
  
  public cutterAttrMaxLength = 0; //刀具动态参数最大长度
  
  public standardItemTypeList = []; //考核项智能推送内容
  //各考核项目推送文本
  public professionalNotifyText:string;
  public blankNotifyText:string;
  public clampNotifyText:string;
  public cutterNotifyText:string;
  public machineNotifyText:string;
  public machineProcessNotifyText:string;
  public workpieceQualityNotifyText:string;
  public machineEffectiveNotifyText:string;
  public irregularOperateNotifyText:string;
  public machineOperateNotifyText:string;
  
  @ViewChild('renderCanvas', { static: true })
  renderCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    // private screenfull: Screenfull,
    private message: NzMessageService,
    private router: Router,
    private examsService: ExamsService,
    private changeDetectorRef: ChangeDetectorRef,
	private standardItemNotifyService: StandardItemNotifyService,
    private examScoreService: ExamScoreService) {
    activatedRoute.queryParams.subscribe(queryParams => {
      this.examId = queryParams.examId;
	  this.tmpToken = queryParams.token;
      //this.paperType = queryParams.paperType;
    })
  }

  public leftMenu = [
    {
      id: "1",
      title: "成绩汇总",
      iconClass: "icon-settings",
      childMenu: [],
      active: true
    },
    {
      id: "2",
      title: "职业素养",
      iconClass: "icon-userOcc",
      childMenu: [],
      active: false
    },
    {
      id: "3",
      title: "机床操作",
      iconClass: "icon-machineTool",
      childMenu: [
        {
          id: "31",
          title: "工件毛胚",
		  hidden: false
        },
        {
          id: "32",
          title: "装夹定位",
		  hidden: true
        },
        {
          id: "33",
          title: "切削刀具",
		  hidden: false
        }
      ],
      active: false
    },
    {
      id: "4",
      title: "加工参数",
      iconClass: "icon-param",
      childMenu: [
        {
          id: "41",
          title: "加工工序",
		  hidden: true
        },
        {
          id: "42",
          title: "工件质量",
		  hidden: true
        },
        {
          id: "43",
          title: "加工效率",
		  hidden: false
        },
        {
          id: "44",
          title: "数控代码",
		  hidden: false
        }
      ],
      active: false
    },
    {
      id: "5",
      title: "操作日志",
      iconClass: "icon-log",
      childMenu: [
        {
          id: "51",
          title: "机床操作",
		  hidden: true
        },
        {
          id: "52",
          title: "操作日志明细",
		  hidden: true
        }
      ],
      active: false,
	  hidden: true
    }
  ];
  public assetPath = ""
  // 0透视图 1正视图 2侧视图 3顶视图
  public tabs = [
    // {
    //   active: false,
    //   imgPath: "../../../assets/img/v1.png",
    //   path: "",
    //   modelType: -1
    // },
    {
      active: true,
      imgPath: "../../../assets/img/v2.png",
      path: "",
      modelType: 0
    },
    {
      active: false,
      imgPath: "../../../assets/img/v3.png",
      path: "",
      modelType: 1
    },
    {
      active: false,
      imgPath: "../../../assets/img/v4.png",
      path: "",
      modelType: 2
    },
    {
      active: false,
      imgPath: "../../../assets/img/v5.png",
      path: "",
      modelType: 3
    }
  ];
  
  onClick(et:any,item:any) {
    for (let index = 0; index < this.leftMenu.length; index++) {
      if(this.leftMenu[index].id == item.id) {
        this.typeId = this.leftMenu[index].id;
        this.leftMenu[index].active = true;
      } else {
        this.leftMenu[index].active = false;
      }
    }
    if (item.id == "1") {
      this.ngAfterViewInit();
    }

    // this.elementRef.nativeElement.querySelector('.report_body').scrollTop= 0;
    let linkAnchor = this.elementRef.nativeElement.querySelectorAll('.linkAnchor'); 
    for (let index = 0; index < linkAnchor.length; index++) { 
      linkAnchor[index].style.color = "#000"; 
    } 
    if (this.elementRef.nativeElement.querySelector('#'+et.target.dataset.href)) {
      // et.target.style.color = "#46a5ff";
      let top = this.elementRef.nativeElement.querySelector('#'+ et.target.dataset.href).offsetTop - 90;
      let opts = {
        "top": top,
        "left": 0,
        "behavior": "smooth"
      }
      this.elementRef.nativeElement.querySelector('.report_body').scrollTo(opts);
      // this[et.target.dataset.href].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  picFullscreen() {
    setTimeout(()=>{
      let pic = this.elementRef.nativeElement.querySelector('.pic');
      (<Screenfull>screenfull).toggle(pic);
    },100)
  }

  fullscreenButton() {
    setTimeout(()=>{
      const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
      if (canvas.requestFullscreen) {
        canvas.requestFullscreen();
      }
    }, 100)
  }

  ngOnInit() {
    this.examScoreService.initToken(this.tmpToken);
    this.standardItemNotifyService.initToken(this.tmpToken);
    this.user = this.examScoreService.getUser();
    this.qrCodeUrl = window.location.href;
    this.typeId = this.leftMenu[0].id;
    this.pEle = this.elementRef.nativeElement.querySelector('.report_body');
    this.totalScore = 5;

    //获取类型列表
    this.examScoreService.getAllTypeList(
      (response) => {
        this.typeLists = response;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
      }
    );

    //成绩汇总
	
    this.examScoreService.getScoreSummary(this.examId,
      (response) => {
        console.log(response);
        this.scoreSummary = response;
        this.examInfo = response.examinfo;
        this.assetPath = response.assetpath
        if (response.modelImageList!=null && this.assetPath == null && this.paperType == 1) {
          this.assetType = 0 // 展示图片
        } else if (this.assetPath != "" && this.paperType == 0) {
          // 手动触发变更检测
          this.changeDetectorRef.detectChanges();
          this.assetType = 1 // 展示3D模型
        }
        // this.assetType = 1 // 测试固定展示模型
		if (this.tmpToken) {
			this.user = response.examinfo.examexaminee
			//this.examScoreService.saveUser(response.examinfo.examexaminee);
		}
    
		this.paperType = this.examInfo.examvenue.venuepaper.paperscorestandard.standardType;
        this.examVenue = response.examinfo.examvenue;
		if (!this.examVenue.venuemajor || this.examVenue.venuemajor.majorname == '' ) {
			this.examVenue.venuemajor = this.examVenue.majorsList[0];
		}
        this.scoreList = [
          {
            label: "职业素养",
            score: this.scoreSummary.professionalQualityScore.totalScore,
            scoreRefer: this.scoreSummary.professionalQualityScore.totalScoreRefer/2
          },
          {
            label: "工件毛坯",
            score: this.scoreSummary.blankScore.totalScore,
            scoreRefer: this.scoreSummary.blankScore.totalScoreRefer
          },
          {
            label: "装夹定位",
            score: this.scoreSummary.clampScore.totalScore,
            scoreRefer: this.scoreSummary.clampScore.totalScoreRefer
          },
          {
            label: "切削刀具",
            score: this.scoreSummary.cutterScore.totalScore,
            scoreRefer: this.scoreSummary.cutterScore.totalScoreRefer
          },
          {
            label: "机床操作",
            score: this.scoreSummary.operateScore.totalScore,
            scoreRefer: this.scoreSummary.operateScore.totalScoreRefer
          },
          {
            label: "加工参数",
            score: this.scoreSummary.machineParamScore.totalScore,
            scoreRefer: this.scoreSummary.machineParamScore.totalScoreRefer
          },
          {
            label: "加工工序",
            score: this.scoreSummary.machineProcessScore.totalScore,
            scoreRefer: this.scoreSummary.machineProcessScore.totalScoreRefer
          },
          {
            label: "工件质量",
            score: this.scoreSummary.workpieceQualityScore.totalScore,
            scoreRefer: this.scoreSummary.workpieceQualityScore.totalScoreRefer
          },
          {
            label: "加工效率",
            score: this.scoreSummary.machineEffectiveScore.totalScore,
            scoreRefer: this.scoreSummary.machineEffectiveScore.totalScoreRefer
          },
          {
            label: "数控代码",
            score: this.scoreSummary.ncprogramScore.totalScore,
            scoreRefer: this.scoreSummary.ncprogramScore.totalScoreRefer
          }
        ];
        this.op = {
          tooltip: {},
          radar: {
              // shape: 'circle',
              name: {
                textStyle: {
                    color: '#999',
                    padding: [3, 5]
                }
              },
              indicator: [
                  { name: '切削刀具', max: this.scoreSummary.cutterScore.totalScoreRefer},
                  { name: '机床操作', max: this.scoreSummary.operateScore.totalScoreRefer},
                  { name: '加工参数', max: this.scoreSummary.machineParamScore.totalScoreRefer},
                  { name: '加工工序', max: this.scoreSummary.machineProcessScore.totalScoreRefer},
                  { name: '工件质量', max: this.scoreSummary.workpieceQualityScore.totalScoreRefer},
                  { name: '加工效率', max: this.scoreSummary.machineEffectiveScore.totalScoreRefer},
                  { name: '数控代码', max: this.scoreSummary.ncprogramScore.totalScoreRefer}
              ],
              splitArea: {
                show: false
              },
              splitNumber: 3
          },
          series: [{
              name: '操作成绩',
              type: 'radar',
              symbolSize: 6,
              // areaStyle: {normal: {}},
              // radarIndex: 1,
              areaStyle: {
                color: "#65b8ef"
              },
              lineStyle: {
                color: "#999"
              },
              itemStyle: {
                color: '#999'
              },
              data: [
                  {
                      value: [this.scoreSummary.cutterScore.totalScore, this.scoreSummary.operateScore.totalScore, this.scoreSummary.machineParamScore.totalScore, this.scoreSummary.machineProcessScore.totalScore, this.scoreSummary.workpieceQualityScore.totalScore, this.scoreSummary.machineEffectiveScore.totalScore, this.scoreSummary.ncprogramScore.totalScore],
                      name: '操作成绩'
                  }
              ]
          }]
        };
        for (let m = 0; m < response.modelImageList.length; m++) {
          if (response.modelImageList[m].modelType == 0) {
            this.tabs[0].path = environment.domainName + response.modelImageList[m].path;
          }
          for (let n = 1; n < this.tabs.length; n++) {
            if (this.tabs[n].modelType == response.modelImageList[m].modelType) {
              this.tabs[n].path = environment.domainName + response.modelImageList[m].path;
            }
          }
        }
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );
	
    this.standardItemNotifyService.getStandardItemNotifyList(this.paperType, 
      (response) => {
        this.standardItemTypeList = response.dataList;
        let professionObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 0
        });
        this.professionalNotifyText = professionObj.length>0?professionObj[0].text:""
        let blankObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 1
        });
        this.blankNotifyText = blankObj.length>0?blankObj[0].text:""
        let clampObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 2
        });
        this.clampNotifyText = clampObj.length>0?clampObj[0].text:""
        let cutterObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 3
        });
        this.cutterNotifyText = cutterObj.length>0?cutterObj[0].text:""
        let machineObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 4
        });
        this.machineNotifyText = machineObj.length>0?machineObj[0].text:""
        let machineProcessObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 5
        });
        this.machineProcessNotifyText = machineProcessObj.length>0?machineProcessObj[0].text:""
        let workpieceQualityObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 6
        });
        this.workpieceQualityNotifyText = workpieceQualityObj.length>0?workpieceQualityObj[0].text:""
        let machineEffectiveObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 7
        });
        this.machineEffectiveNotifyText = machineEffectiveObj.length>0?machineEffectiveObj[0].text:""
        let irregularOperateObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 8
        });
        this.irregularOperateNotifyText = irregularOperateObj.length>0?irregularOperateObj[0].text:""
        let machineOperateObj = this.standardItemTypeList.filter(function(value, index){
          return value.itemType == 9
        });
        this.machineOperateNotifyText = machineOperateObj.length>0?machineOperateObj[0].text:""
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    )
	
    //职业素养成绩
    this.examScoreService.getProfessional(this.examId,
      (response) => {
        console.log(response);
        this.professionalScore = response;
        this.professionalScoreList = response.professionalScoreList;
        for (let i = 0; i < this.professionalScoreList.length; i++) {
          if (this.professionalScoreList[i].questionType == 2) {
            let ansArr = this.professionalScoreList[i].examineAnswer ? this.professionalScoreList[i].examineAnswer.split("、") : [];
            this.professionalScoreList[i]["questionArr"] = common.replaceHtml(this.professionalScoreList[i].questionDesc,ansArr);
          }
          if (this.professionalScoreList[i].questionType == 3) {
            this.professionalScoreList[i].examineAnswer = this.professionalScoreList[i].examineAnswer ? this.professionalScoreList[i].examineAnswer.split("、") : [];
            this.professionalScoreList[i].questionAnswer = this.professionalScoreList[i].questionAnswer ? this.professionalScoreList[i].questionAnswer.split("、") : [];
          }
        }
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //机床操作成绩
    this.examScoreService.getMachineOperation(this.examId,
      (response) => {
        console.log(response);
        this.machineOperation = response;
        this.blankScore = response.blankScore; //工件毛坯成绩
        this.blankScoreList = response.blankScore.blank?[response.blankScore.blank]:[];
        this.clampScore = response.clampScore; //装夹定位成绩
        this.clampScoreList = response.clampScore.clampsList;
        this.cutterScore = response.cutterScore; //切削刀具成绩
        this.cutterScoreList = response.cutterScore.cuttersList;
		let cutterAttrLengthArr = [];
		for(let i=0;i<this.cutterScoreList.length;i++){
			if (this.cutterScoreList[i].cutterImg != "") {
				this.cutterScoreList[i].cutterImg = environment.domainName + this.cutterScoreList[i].cutterImg
			}
			if (this.cutterScoreList[i].declinationImg != "") {
				this.cutterScoreList[i].declinationImg = environment.domainName + this.cutterScoreList[i].declinationImg
			}
			cutterAttrLengthArr.push(this.cutterScoreList[i].cutterAttrList.length)
            let tempCutterAttrList = [];
            tempCutterAttrList = this.cutterScoreList[i].cutterAttrList.sort(function (n1,n2){
                if (n1.attrId > n2.attrId) {
                    return 1;
                }else if (n1.attrId < n2.attrId){
                    return -1;
                }else{
                    return 0;
                }
            })
            this.cutterScoreList[i].cutterAttrList = tempCutterAttrList
		}

		cutterAttrLengthArr = cutterAttrLengthArr.sort(function(a, b){
			return b - a;
		})
		if (cutterAttrLengthArr.length > 0) {
			this.cutterAttrMaxLength = cutterAttrLengthArr[0]
		}	
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //加工参数成绩
    this.examScoreService.getMachineParam(this.examId,
      (response) => {
        console.log(response);
        this.machineScore = response.machineScore; //加工工艺参数
        this.machineList = response.machineScore.machineListList;
        // this.machineList.total = 0;
        // this.machineList.totalRefer = 0;
        for (let i = 0; i < this.machineList.length; i++) {
          this.machineList[i].total = (this.machineList[i].spindleSpeedScore + this.machineList[i].feedSpeedScore + this.machineList[i].backCutterMeteScore + this.machineList[i].cutterScore).toFixed(2);
          this.machineList[i].totalRefer = this.machineList[i].machineRefer.spindleSpeedScore + this.machineList[i].machineRefer.feedSpeedScore + this.machineList[i].machineRefer.backCutterMeteScore + this.machineList[i].machineRefer.cutterScore;
        }
        this.machineProcessScore = response.machineProcessScore; //加工工序
        this.machineProcessList = response.machineProcessScore.machineProcessListList;
        this.workpieceQualityScore = response.workpieceQualityScore; //工件质量
		if (this.paperType == 0) { //车床
			this.workpieceQualityList = response.workpieceQualityScore.workpieceQualityListList;
			for(let i=0;i<this.workpieceQualityList.length;i++) {
        var unit = ""
				this.workpieceQualityList[i].param = this.workpieceQualityList[i].param.toFixed(3)  //整数保留三位小数
				if (this.workpieceQualityList[i].pieceType == 1) { //直径
					unit = "Φ"
				} else if (this.workpieceQualityList[i].pieceType == 3) { //倒角
					unit = "C"
				} else if (this.workpieceQualityList[i].pieceType == 4) { //角度
					
				} else if (this.workpieceQualityList[i].pieceType == 5) { //粗糙度
					unit = "Ra"
				} else if (this.workpieceQualityList[i].pieceType == 6) { //圆角
					unit = "R"
				} else if (this.workpieceQualityList[i].pieceType == 9) { //圆弧
					unit = "R"
				}
				this.workpieceQualityList[i].unit = unit
			}
		} else if (this.paperType == 1) { //铣床
			this.workpieceQualityList = response.workpieceQualityScore.workpieceQualityMillingList
			for(let i=0;i<this.workpieceQualityList.length;i++) {
				// this.workpieceQualityList[i].length = this.workpieceQualityList[i].length.toFixed(3)  //整数保留三位小数
				// this.workpieceQualityList[i].width = this.workpieceQualityList[i].width.toFixed(3)  //整数保留三位小数
				// this.workpieceQualityList[i].height = this.workpieceQualityList[i].height.toFixed(3)  //整数保留三位小数
				this.workpieceQualityList[i].param = this.workpieceQualityList[i].param.toFixed(3)
			}
		}
        this.machineEffectiveScore = response.machineEffectiveScore; //加工效率
        this.machineEffectiveList = [
          {
            name: "NC运行时间",
            value: response.machineEffectiveScore.ncRunTime + "s"
          },
          {
            name: "装刀次数",
            value: response.machineEffectiveScore.installCutterNum
          },
          {
            name: "工件质量得分占比",
            value: response.machineEffectiveScore.workpieceQualityProp + "%"
          },
          {
            name: "更换毛坯次数",
            value: response.machineEffectiveScore.changeBlankNum
          },
          {
            name: "撞刀次数",
            value: response.machineEffectiveScore.hitCutterNum
          }
        ];
        this.ncProgramMap = response.ncProgramMap; //数控代码
        this.ncProgramScore = response.ncScore; //数控代码得分
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //操作流程
    this.examScoreService.getOperateProcess(this.examId,
      (response) => {
        console.log(response);
        // this.operationProcess = response;
        this.operateLogScore = response.operateLogScore; //操作日志
        this.operateLogScoreList = response.operateLogScore.operateLogListList; //操作日志
        this.operateLogScore.totalDeductScore = 0
        for (let i = 0; i < this.operateLogScoreList.length; i++) {
            this.operateLogScore.totalDeductScore -= this.operateLogScoreList[i].deductScore
        }
        this.operateProcessScore = response.operateProcessScore; //操作流程
        this.operateProcessList = response.operateProcessScore.operateProcessListList; //操作流程
        this.operationLogList = response.operationLogListList; //操作日志明细
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(()=>{
		let colors1 = ["#ffecb1","#fed0d5","#d2f3d5","#bfdfff","#c6f3e5","#ccd1fa","#ffe0d1","#ffd0e1","#f0e2f2","#BFE6C2"];
		let colors2 = ["#ffc52a","#fe7e8a","#83d885","#4ba6fe","#5fdcb9","#7a80f7","#ffa683","#ff7eaf","#d6ade0","#4FB6A1"];
		for (let i = 0; i < colors1.length; i++) {
			let selector = this.elementRef.nativeElement.querySelector('.score' + (i+1));
			if (selector)
				common.drawCanvas(selector,colors1[i],colors2[i],this.scoreList[i].score,this.scoreList[i].scoreRefer,this.scoreList[i].label);
		}// for end
     // 3D模型展示(延迟获取getScoreSummary的回调函数处理结果)
     console.log("this.paperType=", this.paperType)
     console.log("this.assetType=", this.assetType)
    if (this.assetType == 1 && this.paperType == 0) {
      // 展示3D模型
      // 加载3D模型，采用babylonjs引擎
      // 创建画布
      const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement;
      // 创建引擎
      const engine = new BABYLON.Engine(canvas, true);
      // 创建场景
      const scene = new BABYLON.Scene(engine);
      // 场景取成绩echart同色
      const hexColor = "#87bee3";
      const r = parseInt(hexColor.substr(1, 2), 16) / 255;
      const g = parseInt(hexColor.substr(3, 2), 16) / 255;
      const b = parseInt(hexColor.substr(5, 2), 16) / 255;
      const color = new BABYLON.Color4(r, g, b, 1.0);
      scene.clearColor = color;

      const scale = 10;
      // 测试地址，替换为 this.assetPath
      // const modelPath = "../../../assets/";
      // const modelName = "ZH0104.obj";
      const lastSlashIndex = this.assetPath.lastIndexOf('/');
      const modelName = this.assetPath.substring(lastSlashIndex + 1); // 获取模型名称
      const modelPath = environment.domainName+"/"+this.assetPath.substring(0, lastSlashIndex + 1); // 获取模型路径
      console.log("modelPath=", modelPath)
      console.log("modelName=", modelName)
      const progressBar = document.getElementById('progress');
      const pb = document.getElementById('progress-bar')
      const initAsset = async () => {
        // 异步加载初始模型并保存meshes
        const result = await BABYLON.SceneLoader.ImportMeshAsync("", modelPath, modelName, scene, (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            progressBar.style.width = `${progress}%`;
          }
        });
        progressBar.style.display = 'none';
        pb.style.display = 'none';
        canvas.style.display = 'block';

        const meshes = result.meshes as BABYLON.Mesh[];
        // 创建不锈钢材质
        const stainlessSteelMaterial = new BABYLON.StandardMaterial("stainlessSteelMaterial", scene);
        stainlessSteelMaterial.diffuseColor = new BABYLON.Color3(0.8, 0.8, 0.8); // 设置漫反射颜色
        stainlessSteelMaterial.specularColor = new BABYLON.Color3(0.9, 0.9, 0.9); // 设置高光反射颜色
        stainlessSteelMaterial.specularPower = 85; // 设置高光反射强度

        // 创建黄铜材质
        const brassMaterial = new BABYLON.StandardMaterial("brassMaterial", scene);
        brassMaterial.diffuseColor = new BABYLON.Color3(0.8, 0.6, 0.0); // 设置漫反射颜色
        brassMaterial.specularColor = new BABYLON.Color3(0.9, 0.7, 0.0); // 设置高光反射颜色
        brassMaterial.specularPower = 64; // 设置高光反射强度

        // 存放最终合并 mesh 后的模型
        const meshesToMerge = [];

        // 遍历每个 mesh
        meshes.forEach((mesh) => {
          mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
          mesh.isPickable = true;
          mesh.material = stainlessSteelMaterial;
          // 确保 mesh 具有统一的位置和旋转
          mesh.position.copyFromFloats(0, 0, 0);
          mesh.rotationQuaternion = BABYLON.Quaternion.Identity();

          // 放入mesh进行合并
          meshesToMerge.push(mesh);
        });

        // 合并模型
        const mergedMesh = BABYLON.Mesh.MergeMeshes(meshesToMerge, true, true);
        meshes.forEach((mesh) => {
          mesh.dispose();
        });

        // 通过模型边界框定位中点、模型大小及相机距离
        const boundingInfo = mergedMesh.getBoundingInfo();
        const modelCenter = boundingInfo.boundingBox.center;
        const modelSize = boundingInfo.boundingBox.extendSize;
        const maxDimension = Math.max(modelSize.x, modelSize.y, modelSize.z);
        const cameraDistance = maxDimension * scale;

        // 创建弧形旋转相机
        const arcRotateCamera = new BABYLON.ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, cameraDistance, modelCenter, scene);
        // 开启框架行为监控，防止相机穿透模型
        arcRotateCamera.useFramingBehavior = true;
        arcRotateCamera.setTarget(mergedMesh)
        // 使用半球光
        const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0.7, 0.9, 0.3), scene);
        light.intensity = 1.3;
        light.range = 50;

        // 监听鼠标按下和松开事件
        let isPointerDown = false;
        canvas.addEventListener("pointerdown", () => {
          isPointerDown = true;
        });
        canvas.addEventListener("pointerup", () => {
          isPointerDown = false;
        });

        // 监听鼠标移动事件
        canvas.addEventListener("pointermove", (event) => {
          if (isPointerDown) {
            // 根据鼠标移动的偏移量调整相机的旋转角度
            arcRotateCamera.alpha -= event.movementX / 100;
            arcRotateCamera.beta -= event.movementY / 100;

            // 碰撞检测
            const ray = arcRotateCamera.getForwardRay();
            const pickInfo = scene.pickWithRay(ray);
            if (pickInfo.hit && pickInfo.pickedMesh) {
              const distance = pickInfo.distance;
              const collisionDirection = ray.direction.scale(-distance);
              const cameraPositionBeforeCollision = modelCenter.add(collisionDirection);

              // 判断相机位置是否在模型内部(针对空心模型)
              const rayFromCamera = new BABYLON.Ray(cameraPositionBeforeCollision, collisionDirection);
              const collisionInfoFromCamera = scene.pickWithRay(rayFromCamera);
              if (!collisionInfoFromCamera) {
                arcRotateCamera.position = cameraPositionBeforeCollision;
              }
            }
          }
        });

        let initialWheelPosition = null;

        // 监听鼠标滚轮事件
        canvas.addEventListener("wheel", (event) => {
          event.preventDefault();
          if (initialWheelPosition === null) {
            initialWheelPosition = event.deltaY;
          }
        
          const delta = event.deltaY - initialWheelPosition;
          const distanceFactor = Math.max(maxDimension * scale / 10, 1); // 根据模型大小调整距离因子
          arcRotateCamera.radius -= delta * 0.01 * distanceFactor;

          // 动态计算最小相机距离
          const minCameraDistance = maxDimension * scale * 0.2; // 设置最小相机距离系数
          const dynamicMinCameraDistance = Math.max(minCameraDistance, maxDimension * scale * 0.1); // 根据模型大小调整最小相机距离
          if (arcRotateCamera.radius < dynamicMinCameraDistance) {
            arcRotateCamera.radius = dynamicMinCameraDistance;
          }
        });
        // 鼠标移开恢复位置
        canvas.addEventListener("pointerleave", () => {
          initialWheelPosition = null;
        }); 
      };
      // 渲染场景
      initAsset().then(() => {
        engine.runRenderLoop(() => {
          if (scene) {
            scene.render();
          }
        });
      });
      // 监听窗口大小改变(使场景始终填满整个窗口)
      window.addEventListener("resize", () => {
        engine.resize();
      });
    }
    },1200);
	  //setTimeout end
    
  }//func end
  
}