// source: settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.pbs.AuthorizeICDevice', null, global);
goog.exportSymbol('proto.pbs.AuthorizeICDeviceSerialNo', null, global);
goog.exportSymbol('proto.pbs.AuthorizeICProcess', null, global);
goog.exportSymbol('proto.pbs.AuthorizeICStep', null, global);
goog.exportSymbol('proto.pbs.CleaningFluidListResp', null, global);
goog.exportSymbol('proto.pbs.CoaterModule', null, global);
goog.exportSymbol('proto.pbs.EBElementListResp', null, global);
goog.exportSymbol('proto.pbs.EBElementListResp.EBElement', null, global);
goog.exportSymbol('proto.pbs.EBProgram', null, global);
goog.exportSymbol('proto.pbs.ICDeviceListResp', null, global);
goog.exportSymbol('proto.pbs.ImplanterElementListResp', null, global);
goog.exportSymbol('proto.pbs.Lab18MaterialListResp', null, global);
goog.exportSymbol('proto.pbs.Lab18Program', null, global);
goog.exportSymbol('proto.pbs.Lab18ProgramName', null, global);
goog.exportSymbol('proto.pbs.Lab18ProgramName.ProgramType', null, global);
goog.exportSymbol('proto.pbs.Lab18ProgramNames', null, global);
goog.exportSymbol('proto.pbs.PlasmaGasesListResp', null, global);
goog.exportSymbol('proto.pbs.Settings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EBProgram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.EBProgram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EBProgram.displayName = 'proto.pbs.EBProgram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EBElementListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.EBElementListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.EBElementListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EBElementListResp.displayName = 'proto.pbs.EBElementListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.EBElementListResp.EBElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.EBElementListResp.EBElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.EBElementListResp.EBElement.displayName = 'proto.pbs.EBElementListResp.EBElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ImplanterElementListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ImplanterElementListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.ImplanterElementListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ImplanterElementListResp.displayName = 'proto.pbs.ImplanterElementListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlasmaGasesListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PlasmaGasesListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.PlasmaGasesListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlasmaGasesListResp.displayName = 'proto.pbs.PlasmaGasesListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Lab18MaterialListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Lab18MaterialListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.Lab18MaterialListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Lab18MaterialListResp.displayName = 'proto.pbs.Lab18MaterialListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CleaningFluidListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CleaningFluidListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.CleaningFluidListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CleaningFluidListResp.displayName = 'proto.pbs.CleaningFluidListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ICDeviceListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ICDeviceListResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.ICDeviceListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ICDeviceListResp.displayName = 'proto.pbs.ICDeviceListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.AuthorizeICDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.AuthorizeICDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.AuthorizeICDevice.displayName = 'proto.pbs.AuthorizeICDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.AuthorizeICProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.AuthorizeICProcess.repeatedFields_, null);
};
goog.inherits(proto.pbs.AuthorizeICProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.AuthorizeICProcess.displayName = 'proto.pbs.AuthorizeICProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.AuthorizeICStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.AuthorizeICStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.AuthorizeICStep.displayName = 'proto.pbs.AuthorizeICStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Lab18ProgramName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Lab18ProgramName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Lab18ProgramName.displayName = 'proto.pbs.Lab18ProgramName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Lab18ProgramNames = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Lab18ProgramNames.repeatedFields_, null);
};
goog.inherits(proto.pbs.Lab18ProgramNames, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Lab18ProgramNames.displayName = 'proto.pbs.Lab18ProgramNames';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Lab18Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Lab18Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Lab18Program.displayName = 'proto.pbs.Lab18Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Settings.displayName = 'proto.pbs.Settings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EBProgram.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EBProgram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EBProgram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBProgram.toObject = function(includeInstance, msg) {
  var f, obj = {
    programContent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EBProgram}
 */
proto.pbs.EBProgram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EBProgram;
  return proto.pbs.EBProgram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EBProgram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EBProgram}
 */
proto.pbs.EBProgram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EBProgram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EBProgram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EBProgram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBProgram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string program_content = 1;
 * @return {string}
 */
proto.pbs.EBProgram.prototype.getProgramContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.EBProgram} returns this
 */
proto.pbs.EBProgram.prototype.setProgramContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.EBElementListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EBElementListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EBElementListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EBElementListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBElementListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    ebElementList: jspb.Message.toObjectList(msg.getEbElementList(),
    proto.pbs.EBElementListResp.EBElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EBElementListResp}
 */
proto.pbs.EBElementListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EBElementListResp;
  return proto.pbs.EBElementListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EBElementListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EBElementListResp}
 */
proto.pbs.EBElementListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.EBElementListResp.EBElement;
      reader.readMessage(value,proto.pbs.EBElementListResp.EBElement.deserializeBinaryFromReader);
      msg.addEbElement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EBElementListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EBElementListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EBElementListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBElementListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEbElementList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.EBElementListResp.EBElement.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.EBElementListResp.EBElement.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.EBElementListResp.EBElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.EBElementListResp.EBElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBElementListResp.EBElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    crucibleNum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.EBElementListResp.EBElement}
 */
proto.pbs.EBElementListResp.EBElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.EBElementListResp.EBElement;
  return proto.pbs.EBElementListResp.EBElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.EBElementListResp.EBElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.EBElementListResp.EBElement}
 */
proto.pbs.EBElementListResp.EBElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCrucibleNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.EBElementListResp.EBElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.EBElementListResp.EBElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.EBElementListResp.EBElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.EBElementListResp.EBElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCrucibleNum();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.EBElementListResp.EBElement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.EBElementListResp.EBElement} returns this
 */
proto.pbs.EBElementListResp.EBElement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 crucible_num = 2;
 * @return {number}
 */
proto.pbs.EBElementListResp.EBElement.prototype.getCrucibleNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.EBElementListResp.EBElement} returns this
 */
proto.pbs.EBElementListResp.EBElement.prototype.setCrucibleNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated EBElement EB_element = 1;
 * @return {!Array<!proto.pbs.EBElementListResp.EBElement>}
 */
proto.pbs.EBElementListResp.prototype.getEbElementList = function() {
  return /** @type{!Array<!proto.pbs.EBElementListResp.EBElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.EBElementListResp.EBElement, 1));
};


/**
 * @param {!Array<!proto.pbs.EBElementListResp.EBElement>} value
 * @return {!proto.pbs.EBElementListResp} returns this
*/
proto.pbs.EBElementListResp.prototype.setEbElementList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.EBElementListResp.EBElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.EBElementListResp.EBElement}
 */
proto.pbs.EBElementListResp.prototype.addEbElement = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.EBElementListResp.EBElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.EBElementListResp} returns this
 */
proto.pbs.EBElementListResp.prototype.clearEbElementList = function() {
  return this.setEbElementList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ImplanterElementListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ImplanterElementListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ImplanterElementListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ImplanterElementListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ImplanterElementListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ImplanterElementListResp}
 */
proto.pbs.ImplanterElementListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ImplanterElementListResp;
  return proto.pbs.ImplanterElementListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ImplanterElementListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ImplanterElementListResp}
 */
proto.pbs.ImplanterElementListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ImplanterElementListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ImplanterElementListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ImplanterElementListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ImplanterElementListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.pbs.ImplanterElementListResp.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ImplanterElementListResp} returns this
 */
proto.pbs.ImplanterElementListResp.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ImplanterElementListResp} returns this
 */
proto.pbs.ImplanterElementListResp.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ImplanterElementListResp} returns this
 */
proto.pbs.ImplanterElementListResp.prototype.clearNameList = function() {
  return this.setNameList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PlasmaGasesListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlasmaGasesListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlasmaGasesListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlasmaGasesListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGasesListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlasmaGasesListResp}
 */
proto.pbs.PlasmaGasesListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlasmaGasesListResp;
  return proto.pbs.PlasmaGasesListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlasmaGasesListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlasmaGasesListResp}
 */
proto.pbs.PlasmaGasesListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlasmaGasesListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlasmaGasesListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlasmaGasesListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlasmaGasesListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.pbs.PlasmaGasesListResp.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.PlasmaGasesListResp} returns this
 */
proto.pbs.PlasmaGasesListResp.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.PlasmaGasesListResp} returns this
 */
proto.pbs.PlasmaGasesListResp.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PlasmaGasesListResp} returns this
 */
proto.pbs.PlasmaGasesListResp.prototype.clearNameList = function() {
  return this.setNameList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Lab18MaterialListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Lab18MaterialListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Lab18MaterialListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Lab18MaterialListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18MaterialListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Lab18MaterialListResp}
 */
proto.pbs.Lab18MaterialListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Lab18MaterialListResp;
  return proto.pbs.Lab18MaterialListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Lab18MaterialListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Lab18MaterialListResp}
 */
proto.pbs.Lab18MaterialListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Lab18MaterialListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Lab18MaterialListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Lab18MaterialListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18MaterialListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.pbs.Lab18MaterialListResp.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Lab18MaterialListResp} returns this
 */
proto.pbs.Lab18MaterialListResp.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Lab18MaterialListResp} returns this
 */
proto.pbs.Lab18MaterialListResp.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Lab18MaterialListResp} returns this
 */
proto.pbs.Lab18MaterialListResp.prototype.clearNameList = function() {
  return this.setNameList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CleaningFluidListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CleaningFluidListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CleaningFluidListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CleaningFluidListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningFluidListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CleaningFluidListResp}
 */
proto.pbs.CleaningFluidListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CleaningFluidListResp;
  return proto.pbs.CleaningFluidListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CleaningFluidListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CleaningFluidListResp}
 */
proto.pbs.CleaningFluidListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CleaningFluidListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CleaningFluidListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CleaningFluidListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CleaningFluidListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.pbs.CleaningFluidListResp.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.CleaningFluidListResp} returns this
 */
proto.pbs.CleaningFluidListResp.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.CleaningFluidListResp} returns this
 */
proto.pbs.CleaningFluidListResp.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CleaningFluidListResp} returns this
 */
proto.pbs.CleaningFluidListResp.prototype.clearNameList = function() {
  return this.setNameList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ICDeviceListResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ICDeviceListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ICDeviceListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ICDeviceListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ICDeviceListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizeIcDevicesList: jspb.Message.toObjectList(msg.getAuthorizeIcDevicesList(),
    proto.pbs.AuthorizeICDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ICDeviceListResp}
 */
proto.pbs.ICDeviceListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ICDeviceListResp;
  return proto.pbs.ICDeviceListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ICDeviceListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ICDeviceListResp}
 */
proto.pbs.ICDeviceListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.AuthorizeICDevice;
      reader.readMessage(value,proto.pbs.AuthorizeICDevice.deserializeBinaryFromReader);
      msg.addAuthorizeIcDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ICDeviceListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ICDeviceListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ICDeviceListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ICDeviceListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizeIcDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.AuthorizeICDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuthorizeICDevice authorize_ic_devices = 1;
 * @return {!Array<!proto.pbs.AuthorizeICDevice>}
 */
proto.pbs.ICDeviceListResp.prototype.getAuthorizeIcDevicesList = function() {
  return /** @type{!Array<!proto.pbs.AuthorizeICDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.AuthorizeICDevice, 1));
};


/**
 * @param {!Array<!proto.pbs.AuthorizeICDevice>} value
 * @return {!proto.pbs.ICDeviceListResp} returns this
*/
proto.pbs.ICDeviceListResp.prototype.setAuthorizeIcDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.AuthorizeICDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.AuthorizeICDevice}
 */
proto.pbs.ICDeviceListResp.prototype.addAuthorizeIcDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.AuthorizeICDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ICDeviceListResp} returns this
 */
proto.pbs.ICDeviceListResp.prototype.clearAuthorizeIcDevicesList = function() {
  return this.setAuthorizeIcDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.AuthorizeICDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.AuthorizeICDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.AuthorizeICDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workProcess: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.AuthorizeICDevice}
 */
proto.pbs.AuthorizeICDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.AuthorizeICDevice;
  return proto.pbs.AuthorizeICDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.AuthorizeICDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.AuthorizeICDevice}
 */
proto.pbs.AuthorizeICDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.pbs.AuthorizeICDeviceSerialNo} */ (reader.readEnum());
      msg.setSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.AuthorizeICDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.AuthorizeICDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.AuthorizeICDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNo();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkProcess();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional AuthorizeICDeviceSerialNo serial_no = 1;
 * @return {!proto.pbs.AuthorizeICDeviceSerialNo}
 */
proto.pbs.AuthorizeICDevice.prototype.getSerialNo = function() {
  return /** @type {!proto.pbs.AuthorizeICDeviceSerialNo} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.pbs.AuthorizeICDeviceSerialNo} value
 * @return {!proto.pbs.AuthorizeICDevice} returns this
 */
proto.pbs.AuthorizeICDevice.prototype.setSerialNo = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string device_name = 2;
 * @return {string}
 */
proto.pbs.AuthorizeICDevice.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AuthorizeICDevice} returns this
 */
proto.pbs.AuthorizeICDevice.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string work_process = 3;
 * @return {string}
 */
proto.pbs.AuthorizeICDevice.prototype.getWorkProcess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AuthorizeICDevice} returns this
 */
proto.pbs.AuthorizeICDevice.prototype.setWorkProcess = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.AuthorizeICProcess.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.AuthorizeICProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.AuthorizeICProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.AuthorizeICProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    processSerialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorIcStepList: jspb.Message.toObjectList(msg.getAuthorIcStepList(),
    proto.pbs.AuthorizeICStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.AuthorizeICProcess}
 */
proto.pbs.AuthorizeICProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.AuthorizeICProcess;
  return proto.pbs.AuthorizeICProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.AuthorizeICProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.AuthorizeICProcess}
 */
proto.pbs.AuthorizeICProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcessSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessName(value);
      break;
    case 3:
      var value = new proto.pbs.AuthorizeICStep;
      reader.readMessage(value,proto.pbs.AuthorizeICStep.deserializeBinaryFromReader);
      msg.addAuthorIcStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.AuthorizeICProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.AuthorizeICProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.AuthorizeICProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProcessName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorIcStepList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.pbs.AuthorizeICStep.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 process_serial_no = 1;
 * @return {number}
 */
proto.pbs.AuthorizeICProcess.prototype.getProcessSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.AuthorizeICProcess} returns this
 */
proto.pbs.AuthorizeICProcess.prototype.setProcessSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_name = 2;
 * @return {string}
 */
proto.pbs.AuthorizeICProcess.prototype.getProcessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AuthorizeICProcess} returns this
 */
proto.pbs.AuthorizeICProcess.prototype.setProcessName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AuthorizeICStep author_ic_step = 3;
 * @return {!Array<!proto.pbs.AuthorizeICStep>}
 */
proto.pbs.AuthorizeICProcess.prototype.getAuthorIcStepList = function() {
  return /** @type{!Array<!proto.pbs.AuthorizeICStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.AuthorizeICStep, 3));
};


/**
 * @param {!Array<!proto.pbs.AuthorizeICStep>} value
 * @return {!proto.pbs.AuthorizeICProcess} returns this
*/
proto.pbs.AuthorizeICProcess.prototype.setAuthorIcStepList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.AuthorizeICStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.AuthorizeICStep}
 */
proto.pbs.AuthorizeICProcess.prototype.addAuthorIcStep = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.AuthorizeICStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AuthorizeICProcess} returns this
 */
proto.pbs.AuthorizeICProcess.prototype.clearAuthorIcStepList = function() {
  return this.setAuthorIcStepList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.AuthorizeICStep.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.AuthorizeICStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.AuthorizeICStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepSerialNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stepName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceSerialNo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deviceName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceSelectModule: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.AuthorizeICStep}
 */
proto.pbs.AuthorizeICStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.AuthorizeICStep;
  return proto.pbs.AuthorizeICStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.AuthorizeICStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.AuthorizeICStep}
 */
proto.pbs.AuthorizeICStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStepSerialNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepName(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.AuthorizeICDeviceSerialNo} */ (reader.readEnum());
      msg.setDeviceSerialNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceSelectModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.AuthorizeICStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.AuthorizeICStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.AuthorizeICStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AuthorizeICStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepSerialNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStepName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceSerialNo();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceSelectModule();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 step_serial_no = 1;
 * @return {number}
 */
proto.pbs.AuthorizeICStep.prototype.getStepSerialNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.AuthorizeICStep} returns this
 */
proto.pbs.AuthorizeICStep.prototype.setStepSerialNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string step_name = 2;
 * @return {string}
 */
proto.pbs.AuthorizeICStep.prototype.getStepName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AuthorizeICStep} returns this
 */
proto.pbs.AuthorizeICStep.prototype.setStepName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AuthorizeICDeviceSerialNo device_serial_no = 3;
 * @return {!proto.pbs.AuthorizeICDeviceSerialNo}
 */
proto.pbs.AuthorizeICStep.prototype.getDeviceSerialNo = function() {
  return /** @type {!proto.pbs.AuthorizeICDeviceSerialNo} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.AuthorizeICDeviceSerialNo} value
 * @return {!proto.pbs.AuthorizeICStep} returns this
 */
proto.pbs.AuthorizeICStep.prototype.setDeviceSerialNo = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string device_name = 4;
 * @return {string}
 */
proto.pbs.AuthorizeICStep.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AuthorizeICStep} returns this
 */
proto.pbs.AuthorizeICStep.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 device_select_module = 5;
 * @return {number}
 */
proto.pbs.AuthorizeICStep.prototype.getDeviceSelectModule = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.AuthorizeICStep} returns this
 */
proto.pbs.AuthorizeICStep.prototype.setDeviceSelectModule = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Lab18ProgramName.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Lab18ProgramName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Lab18ProgramName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18ProgramName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programtype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Lab18ProgramName}
 */
proto.pbs.Lab18ProgramName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Lab18ProgramName;
  return proto.pbs.Lab18ProgramName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Lab18ProgramName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Lab18ProgramName}
 */
proto.pbs.Lab18ProgramName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamname(value);
      break;
    case 3:
      var value = /** @type {!proto.pbs.Lab18ProgramName.ProgramType} */ (reader.readEnum());
      msg.setProgramtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Lab18ProgramName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Lab18ProgramName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Lab18ProgramName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18ProgramName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.pbs.Lab18ProgramName.ProgramType = {
  PROGRAM_: 0,
  DC_SPUTTERING: 1,
  RF_SPUTTERING: 2
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pbs.Lab18ProgramName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Lab18ProgramName} returns this
 */
proto.pbs.Lab18ProgramName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string examName = 2;
 * @return {string}
 */
proto.pbs.Lab18ProgramName.prototype.getExamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Lab18ProgramName} returns this
 */
proto.pbs.Lab18ProgramName.prototype.setExamname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProgramType programtype = 3;
 * @return {!proto.pbs.Lab18ProgramName.ProgramType}
 */
proto.pbs.Lab18ProgramName.prototype.getProgramtype = function() {
  return /** @type {!proto.pbs.Lab18ProgramName.ProgramType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.pbs.Lab18ProgramName.ProgramType} value
 * @return {!proto.pbs.Lab18ProgramName} returns this
 */
proto.pbs.Lab18ProgramName.prototype.setProgramtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Lab18ProgramNames.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Lab18ProgramNames.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Lab18ProgramNames.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Lab18ProgramNames} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18ProgramNames.toObject = function(includeInstance, msg) {
  var f, obj = {
    lab18ProgramNameList: jspb.Message.toObjectList(msg.getLab18ProgramNameList(),
    proto.pbs.Lab18ProgramName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Lab18ProgramNames}
 */
proto.pbs.Lab18ProgramNames.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Lab18ProgramNames;
  return proto.pbs.Lab18ProgramNames.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Lab18ProgramNames} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Lab18ProgramNames}
 */
proto.pbs.Lab18ProgramNames.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Lab18ProgramName;
      reader.readMessage(value,proto.pbs.Lab18ProgramName.deserializeBinaryFromReader);
      msg.addLab18ProgramName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Lab18ProgramNames.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Lab18ProgramNames.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Lab18ProgramNames} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18ProgramNames.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLab18ProgramNameList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Lab18ProgramName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Lab18ProgramName Lab18_program_name = 1;
 * @return {!Array<!proto.pbs.Lab18ProgramName>}
 */
proto.pbs.Lab18ProgramNames.prototype.getLab18ProgramNameList = function() {
  return /** @type{!Array<!proto.pbs.Lab18ProgramName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Lab18ProgramName, 1));
};


/**
 * @param {!Array<!proto.pbs.Lab18ProgramName>} value
 * @return {!proto.pbs.Lab18ProgramNames} returns this
*/
proto.pbs.Lab18ProgramNames.prototype.setLab18ProgramNameList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Lab18ProgramName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Lab18ProgramName}
 */
proto.pbs.Lab18ProgramNames.prototype.addLab18ProgramName = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Lab18ProgramName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Lab18ProgramNames} returns this
 */
proto.pbs.Lab18ProgramNames.prototype.clearLab18ProgramNameList = function() {
  return this.setLab18ProgramNameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Lab18Program.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Lab18Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Lab18Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    programName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    programContent: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Lab18Program}
 */
proto.pbs.Lab18Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Lab18Program;
  return proto.pbs.Lab18Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Lab18Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Lab18Program}
 */
proto.pbs.Lab18Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Lab18Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Lab18Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Lab18Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Lab18Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgramContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string program_name = 1;
 * @return {string}
 */
proto.pbs.Lab18Program.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Lab18Program} returns this
 */
proto.pbs.Lab18Program.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string program_content = 2;
 * @return {string}
 */
proto.pbs.Lab18Program.prototype.getProgramContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Lab18Program} returns this
 */
proto.pbs.Lab18Program.prototype.setProgramContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settingname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settingzone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    settinglang: jspb.Message.getFieldWithDefault(msg, 4, ""),
    settingadminemail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    settingadminfname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    settingcompany: jspb.Message.getFieldWithDefault(msg, 7, ""),
    settingdeleteat: jspb.Message.getFieldWithDefault(msg, 18, 0),
    settingupdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    settingcreateat: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Settings}
 */
proto.pbs.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Settings;
  return proto.pbs.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Settings}
 */
proto.pbs.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingzone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettinglang(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingadminemail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingadminfname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingcompany(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSettingdeleteat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSettingupdate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSettingcreateat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettingname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettingzone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSettinglang();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSettingadminemail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSettingadminfname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSettingcompany();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettingdeleteat();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getSettingupdate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSettingcreateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string SettingId = 1;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string SettingName = 2;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SettingZone = 3;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingzone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingzone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SettingLang = 4;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettinglang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettinglang = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string SettingAdminEmail = 5;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingadminemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingadminemail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string SettingAdminFname = 6;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingadminfname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingadminfname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string SettingCompany = 7;
 * @return {string}
 */
proto.pbs.Settings.prototype.getSettingcompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingcompany = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 SettingDeleteAt = 18;
 * @return {number}
 */
proto.pbs.Settings.prototype.getSettingdeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingdeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 SettingUpdate = 8;
 * @return {number}
 */
proto.pbs.Settings.prototype.getSettingupdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingupdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 SettingCreateAt = 9;
 * @return {number}
 */
proto.pbs.Settings.prototype.getSettingcreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Settings} returns this
 */
proto.pbs.Settings.prototype.setSettingcreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.pbs.AuthorizeICDeviceSerialNo = {
  AUTHORIZEICDEVICESERIALNO_: 0,
  AUTHORIZEICDEVICESERIALNO_MA6: 1,
  AUTHORIZEICDEVICESERIALNO_LAB18: 2,
  AUTHORIZEICDEVICESERIALNO_CLEANTABLE: 3,
  AUTHORIZEICDEVICESERIALNO_COATER: 4,
  AUTHORIZEICDEVICESERIALNO_DEVELOP: 5,
  AUTHORIZEICDEVICESERIALNO_IMPLANTER: 6,
  AUTHORIZEICDEVICESERIALNO_PLASMA: 7,
  AUTHORIZEICDEVICESERIALNO_EB: 8,
  AUTHORIZEICDEVICESERIALNO_NIKONNSR: 9,
  AUTHORIZEICDEVICESERIALNO_PLASMACVD: 10,
  AUTHORIZEICDEVICESERIALNO_HOTPLATE: 11
};

/**
 * @enum {number}
 */
proto.pbs.CoaterModule = {
  COATERMODULE_: 0,
  COATERMODULE_PREDRY: 1,
  COATERMODULE_SPINCOAT: 2
};

goog.object.extend(exports, proto.pbs);
