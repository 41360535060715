import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamScoreService } from 'src/app/services/exam-score.service';
import { NzMessageService } from 'ng-zorro-antd';
import * as screenfull from "screenfull";
import { Screenfull } from "screenfull";
import { environment } from 'src/environments/environment';
import { ExamsService } from 'src/app/services/exams.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-practice-report',
  templateUrl: './practice-report.component.html',
  styleUrls: ['./practice-report.component.styl']
})

export class PracticeReportComponent implements OnInit {

  private practiceId:string;
  public paperType:any = 0; //0车床 1铣床
  public user:any;
  private pEle:any;
  public typeId:any;
  public op:any;

  public scoreSummary:any = {}; //成绩汇总

  private machineOperation:any; //机床操作成绩
  public blankScore:any = {}; //工件毛坯成绩
  public clampScore:any = {}; //装夹定位成绩
  public cutterScore:any = {}; //切削刀具成绩

  public machineScore:any = {}; //加工参数成绩
  public machineProcessScore:any = {}; //加工工序
  public workpieceQualityScore:any = {}; //工件质量
  public machineEffectiveScore:any = {}; //加工效率
  public ncProgramMap:any = []; //数控代码

  // private operationProcess:any; //操作流程
  public operateLogScore:any = {}; //操作日志
  public operateProcessScore:any = {}; //操作流程
  // private operationLogListList:any = {}; //操作日志明细
  

  public practiceInfo:any = {}; //实训详情
  private scoreList:any = []; //项目得分

  public blankScoreList = []; //工件毛坯成绩列表
  public clampScoreList = []; //装夹定位成绩列表
  public cutterScoreList = []; //切削刀具成绩列表

  public machineList:any = []; //加工参数成绩得分列表
  public machineProcessList:any = []; //加工工序得分列表
  public workpieceQualityList:any = []; //工件质量得分列表
  public machineEffectiveList:any = []; //加工效率得分列表

  public operateLogScoreList:any = []; //操作日志列表
  public operateProcessList:any = []; //操作流程列表
  public operationLogList:any = []; //操作日志明细列表
  
  private totalScore:any; //表格footer总得分

  public typeLists:any = []; //获取类型列表

  public uploadHost = environment.domainName;

  public tmpToken = ''; //后台查看报告时使用token
  
  public cutterAttrMaxLength = 0; //刀具动态参数最大长度
  
  constructor(private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    // private screenfull: Screenfull,
    private message: NzMessageService,
    private router: Router,
    private examsService: ExamsService,
	private practiceService: PracticeService,
    private examScoreService: ExamScoreService) {
    activatedRoute.queryParams.subscribe(queryParams => {
      this.practiceId = queryParams.practiceId;
	  this.tmpToken = queryParams.token;
      //this.paperType = queryParams.paperType;
    })
  }

  public leftMenu = [
    {
      id: "1",
      title: "成绩汇总",
      iconClass: "icon-settings",
      childMenu: [],
      active: true
    },
    {
      id: "3",
      title: "机床操作",
      iconClass: "icon-machineTool",
      childMenu: [
        {
          id: "31",
          title: "工件毛胚",
		  hidden: false
        },
        {
          id: "32",
          title: "装夹定位",
		  hidden: true
        },
        {
          id: "33",
          title: "切削刀具",
		  hidden: false
        }
      ],
      active: false
    },
    {
      id: "4",
      title: "加工参数",
      iconClass: "icon-param",
      childMenu: [
        {
          id: "41",
          title: "加工工序",
		  hidden: true
        },
        {
          id: "42",
          title: "工件质量",
		  hidden: true
        },
        {
          id: "43",
          title: "加工效率",
		  hidden: false
        },
        {
          id: "44",
          title: "数控代码",
		  hidden: false
        }
      ],
      active: false
    },
    {
      id: "5",
      title: "操作日志",
      iconClass: "icon-log",
      childMenu: [
        {
          id: "51",
          title: "机床操作",
		  hidden: true
        },
        {
          id: "52",
          title: "操作日志明细",
		  hidden: true
        }
      ],
      active: false,
	  hidden: true
    }
  ];

  // 0透视图 1正视图 2侧视图 3顶视图
  public tabs = [
    // {
    //   active: false,
    //   imgPath: "../../../assets/img/v1.png",
    //   path: "",
    //   modelType: -1
    // },
    {
      active: true,
      imgPath: "../../../assets/img/v2.png",
      path: "",
      modelType: 0
    },
    {
      active: false,
      imgPath: "../../../assets/img/v3.png",
      path: "",
      modelType: 1
    },
    {
      active: false,
      imgPath: "../../../assets/img/v4.png",
      path: "",
      modelType: 2
    },
    {
      active: false,
      imgPath: "../../../assets/img/v5.png",
      path: "",
      modelType: 3
    }
  ];

  onClick(et:any,item:any) {
    for (let index = 0; index < this.leftMenu.length; index++) {
      if(this.leftMenu[index].id == item.id) {
        this.typeId = this.leftMenu[index].id;
        this.leftMenu[index].active = true;
      } else {
        this.leftMenu[index].active = false;
      }
    }
    if (item.id == "1") {
      this.ngAfterViewInit();
    }

    // this.elementRef.nativeElement.querySelector('.report_body').scrollTop= 0;
    let linkAnchor = this.elementRef.nativeElement.querySelectorAll('.linkAnchor'); 
    for (let index = 0; index < linkAnchor.length; index++) { 
      linkAnchor[index].style.color = "#000"; 
    } 
    if (this.elementRef.nativeElement.querySelector('#'+et.target.dataset.href)) {
      // et.target.style.color = "#46a5ff";
      let top = this.elementRef.nativeElement.querySelector('#'+ et.target.dataset.href).offsetTop - 90;
      let opts = {
        "top": top,
        "left": 0,
        "behavior": "smooth"
      }
      this.elementRef.nativeElement.querySelector('.report_body').scrollTo(opts);
      // this[et.target.dataset.href].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  picFullscreen() {
    setTimeout(()=>{
      let pic = this.elementRef.nativeElement.querySelector('.pic');
      (<Screenfull>screenfull).toggle(pic);
    },100)
  }

  ngOnInit() {
	console.log("token", this.tmpToken)
	this.practiceService.initToken(this.tmpToken);
    this.typeId = this.leftMenu[0].id;
    this.user = this.practiceService.getUser();
    this.pEle = this.elementRef.nativeElement.querySelector('.report_body');
    this.totalScore = 5;

    //获取类型列表
    this.examScoreService.getAllTypeList(
      (response) => {
        this.typeLists = response;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
      }
    );

    //成绩汇总
	
    this.practiceService.getTotalPracticeScore(this.practiceId,
      (response) => {
        console.log(response);
        this.scoreSummary = response;
        this.practiceInfo = response.practiceInfo;
		if (this.tmpToken) {
			this.user = response.practiceInfo.user
			//this.examScoreService.saveUser(response.examinfo.examexaminee);
		}
		this.paperType = this.practiceInfo.scoreStandard.standardType;
        this.scoreList = [
          // {
          //   label: "职业素养",
          //   score: this.scoreSummary.professionalQualityScore.totalScore
          // },
          {
            label: "工件毛坯",
            score: this.scoreSummary.blankScore.totalScore,
            scoreRefer: this.scoreSummary.blankScore.totalScoreRefer
          },
          {
            label: "装夹定位",
            score: this.scoreSummary.clampScore.totalScore,
            scoreRefer: this.scoreSummary.clampScore.totalScoreRefer
          },
          {
            label: "切削刀具",
            score: this.scoreSummary.cutterScore.totalScore,
            scoreRefer: this.scoreSummary.cutterScore.totalScoreRefer
          },
          {
            label: "机床操作",
            score: this.scoreSummary.operateScore.totalScore,
            scoreRefer: this.scoreSummary.operateScore.totalScoreRefer
          },
          {
            label: "加工参数",
            score: this.scoreSummary.machineParamScore.totalScore,
            scoreRefer: this.scoreSummary.machineParamScore.totalScoreRefer
          },
          {
            label: "加工工序",
            score: this.scoreSummary.machineProcessScore.totalScore,
            scoreRefer: this.scoreSummary.machineProcessScore.totalScoreRefer
          },
          {
            label: "工件质量",
            score: this.scoreSummary.workpieceQualityScore.totalScore,
            scoreRefer: this.scoreSummary.workpieceQualityScore.totalScoreRefer
          },
          {
            label: "加工效率",
            score: this.scoreSummary.machineEffectiveScore.totalScore,
            scoreRefer: this.scoreSummary.machineEffectiveScore.totalScoreRefer
          }
        ];
        this.op = {
          tooltip: {},
          radar: {
              // shape: 'circle',
              name: {
                textStyle: {
                    color: '#999',
                    padding: [3, 5]
                }
              },
              indicator: [
                  { name: '切削刀具', max: this.scoreSummary.cutterScore.totalScoreRefer},
                  { name: '机床操作', max: this.scoreSummary.operateScore.totalScoreRefer},
                  { name: '加工参数', max: this.scoreSummary.machineParamScore.totalScoreRefer},
                  { name: '加工工序', max: this.scoreSummary.machineProcessScore.totalScoreRefer},
                  { name: '工件质量', max: this.scoreSummary.workpieceQualityScore.totalScoreRefer},
                  { name: '加工效率', max: this.scoreSummary.machineEffectiveScore.totalScoreRefer}
              ],
              splitArea: {
                show: false
              },
              splitNumber: 3
          },
          series: [{
              name: '操作成绩',
              type: 'radar',
              symbolSize: 6,
              // areaStyle: {normal: {}},
              // radarIndex: 1,
              areaStyle: {
                color: "#65b8ef"
              },
              lineStyle: {
                color: "#999"
              },
              itemStyle: {
                color: '#999'
              },
              data: [
                  {
                      value: [this.scoreSummary.cutterScore.totalScore, this.scoreSummary.operateScore.totalScore, this.scoreSummary.machineParamScore.totalScore, this.scoreSummary.machineProcessScore.totalScore, this.scoreSummary.workpieceQualityScore.totalScore, this.scoreSummary.machineEffectiveScore.totalScore],
                      name: '操作成绩'
                  }
              ]
          }]
        };
        for (let m = 0; m < response.modelImageList.length; m++) {
          if (response.modelImageList[m].modelType == 0) {
            this.tabs[0].path = environment.domainName + response.modelImageList[m].path;
          }
          for (let n = 1; n < this.tabs.length; n++) {
            if (this.tabs[n].modelType == response.modelImageList[m].modelType) {
              this.tabs[n].path = environment.domainName + response.modelImageList[m].path;
            }
          }
        }
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //职业素养成绩
    // this.examScoreService.getProfessional(this.examId,
    //   (response) => {
    //     console.log(response);
    //     this.professionalScore = response;
    //     this.professionalScoreList = response.professionalScoreList;
    //     for (let i = 0; i < this.professionalScoreList.length; i++) {
    //       if (this.professionalScoreList[i].questionType == 2) {
    //         let ansArr = this.professionalScoreList[i].examineAnswer ? this.professionalScoreList[i].examineAnswer.split("、") : [];
    //         this.professionalScoreList[i]["questionArr"] = common.replaceHtml(this.professionalScoreList[i].questionDesc,ansArr);
    //       }
    //       if (this.professionalScoreList[i].questionType == 3) {
    //         this.professionalScoreList[i].examineAnswer = this.professionalScoreList[i].examineAnswer ? this.professionalScoreList[i].examineAnswer.split("、") : [];
    //         this.professionalScoreList[i].questionAnswer = this.professionalScoreList[i].questionAnswer ? this.professionalScoreList[i].questionAnswer.split("、") : [];
    //       }
    //     }
    //   },
    //   (fail) => {
    //     this.message.create("error", decodeURI(fail));
    //     if (decodeURI(fail) == "token无效") {
    //       common.returnLogin(this.router, this.examsService, this.message);
    //     }
    //   }
    // );

    //机床操作成绩
    this.practiceService.getPracticeMachineOperation(this.practiceId,
      (response) => {
        console.log(response);
        this.machineOperation = response;
        this.blankScore = response.blankScore; //工件毛坯成绩
        this.blankScoreList = response.blankScore.blank?[response.blankScore.blank]:[];
        this.clampScore = response.clampScore; //装夹定位成绩
        this.clampScoreList = response.clampScore.clampsList;
        this.cutterScore = response.cutterScore; //切削刀具成绩
        this.cutterScoreList = response.cutterScore.cuttersList;
		let cutterAttrLengthArr = [];
		for(let i=0;i<this.cutterScoreList.length;i++){
			if (this.cutterScoreList[i].cutterImg != "") {
				this.cutterScoreList[i].cutterImg = environment.domainName + this.cutterScoreList[i].cutterImg
			}
			if (this.cutterScoreList[i].declinationImg != "") {
				this.cutterScoreList[i].declinationImg = environment.domainName + this.cutterScoreList[i].declinationImg
			}
			cutterAttrLengthArr.push(this.cutterScoreList[i].cutterAttrList.length)
		}
		cutterAttrLengthArr = cutterAttrLengthArr.sort(function(a, b){
			return b - a;
		})
		if (cutterAttrLengthArr.length > 0) {
			this.cutterAttrMaxLength = cutterAttrLengthArr[0]
		}	
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //加工参数成绩
    this.practiceService.getPracticeMachineParam(this.practiceId,
      (response) => {
        console.log(response);
        this.machineScore = response.machineScore; //加工工艺参数
        this.machineList = response.machineScore.machineListList;
        // this.machineList.total = 0;
        // this.machineList.totalRefer = 0;
        for (let i = 0; i < this.machineList.length; i++) {
          this.machineList[i].total = this.machineList[i].spindleSpeedScore + this.machineList[i].feedSpeedScore + this.machineList[i].backCutterMeteScore + this.machineList[i].cutterScore;
          this.machineList[i].totalRefer = this.machineList[i].machineRefer.spindleSpeedScore + this.machineList[i].machineRefer.feedSpeedScore + this.machineList[i].machineRefer.backCutterMeteScore + this.machineList[i].machineRefer.cutterScore;
        }
        this.machineProcessScore = response.machineProcessScore; //加工工序
        this.machineProcessList = response.machineProcessScore.machineProcessListList;
        this.workpieceQualityScore = response.workpieceQualityScore; //工件质量
		if (this.paperType == 0) { //车床
			this.workpieceQualityList = response.workpieceQualityScore.workpieceQualityListList;
			for(let i=0;i<this.workpieceQualityList.length;i++) {
        var unit = ""
				this.workpieceQualityList[i].param = this.workpieceQualityList[i].param.toFixed(3)  //整数保留三位小数
				if (this.workpieceQualityList[i].pieceType == 1) { //直径
					unit = "Φ"
				} else if (this.workpieceQualityList[i].pieceType == 3) { //倒角
					unit = "C"
				} else if (this.workpieceQualityList[i].pieceType == 4) { //角度
					
				} else if (this.workpieceQualityList[i].pieceType == 5) { //粗糙度
					unit = "Ra"
				} else if (this.workpieceQualityList[i].pieceType == 6) { //圆角
					unit = "R"
				} else if (this.workpieceQualityList[i].pieceType == 9) { //圆弧
					unit = "R"
				}
				this.workpieceQualityList[i].unit = unit
			}
		} else if (this.paperType == 1) { //铣床
			this.workpieceQualityList = response.workpieceQualityScore.workpieceQualityMillingList
			for(let i=0;i<this.workpieceQualityList.length;i++) {
				// this.workpieceQualityList[i].length = this.workpieceQualityList[i].length.toFixed(3)  //整数保留三位小数
				// this.workpieceQualityList[i].width = this.workpieceQualityList[i].width.toFixed(3)  //整数保留三位小数
				// this.workpieceQualityList[i].height = this.workpieceQualityList[i].height.toFixed(3)  //整数保留三位小数
				this.workpieceQualityList[i].param = this.workpieceQualityList[i].param.toFixed(3)
			}
		}
        this.machineEffectiveScore = response.machineEffectiveScore; //加工效率
        this.machineEffectiveList = [
          {
            name: "NC运行时间",
            value: response.machineEffectiveScore.ncRunTime + "s"
          },
          {
            name: "装刀次数",
            value: response.machineEffectiveScore.installCutterNum
          },
          {
            name: "工件质量得分占比",
            value: response.machineEffectiveScore.workpieceQualityProp + "%"
          },
          {
            name: "更换毛坯次数",
            value: response.machineEffectiveScore.changeBlankNum
          },
          {
            name: "撞刀次数",
            value: response.machineEffectiveScore.hitCutterNum
          }
        ];
        this.ncProgramMap = response.ncProgramMap; //数控代码
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );

    //操作流程
    this.practiceService.getPracticeOperateProcess(this.practiceId,
      (response) => {
        console.log(response);
        // this.operationProcess = response;
        this.operateLogScore = response.operateLogScore; //操作日志
        this.operateLogScoreList = response.operateLogScore.operateLogListList; //操作日志
        this.operateProcessScore = response.operateProcessScore; //操作流程
        this.operateProcessList = response.operateProcessScore.operateProcessListList; //操作流程
        this.operationLogList = response.operationLogListList; //操作日志明细
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      }
    );
  }

  ngAfterViewInit() {

    setTimeout(()=>{
		// let colors1 = ["#ffecb1","#fed0d5","#d2f3d5","#bfdfff","#c6f3e5","#ccd1fa","#ffe0d1","#ffd0e1","#f0e2f2"];
		// let colors2 = ["#ffc52a","#fe7e8a","#83d885","#4ba6fe","#5fdcb9","#7a80f7","#ffa683","#ff7eaf","#d6ade0"];
		let colors1 = ["#fed0d5","#d2f3d5","#bfdfff","#c6f3e5","#ccd1fa","#ffe0d1","#ffd0e1","#f0e2f2"];
		let colors2 = ["#fe7e8a","#83d885","#4ba6fe","#5fdcb9","#7a80f7","#ffa683","#ff7eaf","#d6ade0"];
		for (let i = 0; i < colors1.length; i++) {
			let selector = this.elementRef.nativeElement.querySelector('.score' + (i+1));
			if (selector)
				common.drawCanvas(selector,colors1[i],colors2[i],this.scoreList[i].score,this.scoreList[i].scoreRefer,this.scoreList[i].label);
		}// for end
    },1800);
	//setTimeout end
  }//func end

}
