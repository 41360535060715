import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

  public userAccount: any;
  public userPassword: any;
  public authCode: string;
  public authCodePath: string;
  public userPhone: any;
  public verifyCode: string;
  public verifyMessage: string = "获取验证码";
  public code: boolean = false;
  public isSend: boolean = false;
  private timer:any;

  constructor(private router: Router,
    private userService:UserService,
    private examsService:ExamsService,
    private message: NzMessageService ) { }

  public get verifyMsg() {
      return this.verifyMessage;
  }
  public set verifyMsg(msg) {
      this.verifyMessage = msg;
  }
  
  login(et:any, lgoinType:number) {
    if (lgoinType == 1) {
      this.userAccount = this.userPhone;
      this.authCode = this.verifyCode;
    }
	let notLogin:string[] = ["lizhihua", "shenjunhao", "guozhicheng", "jieweikang", "aijingting", "xiongjiahao", "gongjunwei", "hanjun", "fanzhongxiang", "huangqinhui", "yanchao", "wuzhian", "yuankun", "huwenjie", "lengwenchao", "liansheng", "xiaozhikai", "lizhi", "yuhongtao", "caohaihua", "jiangpengfei", "hujiamin", "yangjiaqing", "caowei", "xuaoyifan", "huliangpeng", "zhoubinbin", "linjunhao", "chengchangbiao", "wanfucheng", "chengxihui", "fengjiajing", "sunminghui", "huangwenhao", "xushengyang", "zhongxiaochen", "lihao", "hezichao", "wangcheng", "xujinrong", "wangjin", "liaoping", "huzhihong", "zhanyuxuan", "wangzhenhua", "gongjunjie", "yanguomin", "huangwenyu", "huzhisong", "lizian", "zhouhaibing", "liguanqi", "chenliang", "wangchen", "wangdongxiao", "kangjunhang","GZNYXX", "YDZZ", "GZYZ", "GXZZ", "DNZZ", "YXZZ", "JAZZ", "XYZZ", "JJKJZZ", "RCZZ", "YYZZ", "SRGJJG", "SRGJZZ", "PXWGSZZ", "LPZZ-1", "LPZZ-2", "GAZZ", "DXJDZZ", "YTYYGC", "FCGJJG", "JXXDJS", "AFZZ", "RJZZ", "JXDZXX", "JXGCXX", "JXSWXX", "JXTYJSGC", "JXSGJJG"]
	if (notLogin.indexOf(this.userAccount) !== -1) {
		this.message.create("error", "请到赛项专属平台登陆");
		return
	}
    this.userService.login(this.userAccount,this.userPassword,this.authCode,lgoinType,
      (response) => {
        this.message.create("success", "登录成功");
        this.router.navigate(['/entrance']);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
		    this.changeVerify();
      }
    );
  }

  regist() {
    window.open("https://www.vsteach.com/register");
  }

  onClick() {
    this.userAccount = "";
    this.userPassword ="";
    this.authCode = "";
    this.userPhone = "";
    this.verifyCode = "";
  }

  changeVerify() {
    this.userService.getVerifyCode((response) => {
      this.code = true;
      this.authCodePath = response;
    },
    (fail) => {
      this.message.create("error", decodeURI(fail));
    })
  }

  getVerify() {
    if (!this.isSend) {
      this.isSend = true;
      this.userService.getPhoneVerifyCode(this.userPhone,(response) => {
        this.message.create("success", "已发送");
        let num = 60; //60s
        var timer = setInterval(() => {
          num--;
          this.verifyMsg = num + "s";
          if (num == 0) {
              clearInterval(timer);
              this.verifyMsg = "获取验证码";
              this.isSend = false;
          }
        }, 1000);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        this.isSend = false;
      })
    }
  }
  
  ngOnInit() {
    let user = this.userService.getUser();
    if (user) {
      this.router.navigate(['/entrance']);
    } else {
      this.changeVerify();
    }
  }
}
