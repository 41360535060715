import { Component, OnInit } from '@angular/core';
import { PracticeService } from 'src/app/services/practice.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-practice-list',
  templateUrl: './practice-list.component.html',
  styleUrls: ['./practice-list.component.styl']
})
export class PracticeListComponent implements OnInit {


  public user:any;
  public dataSet = [
    // {
    //   examanswersList: [],
    //   examcreateat: 0,
    //   examdeleteat: 0,
    //   examexaminee: {
    //     userid: "", 
    //     username: "", 
    //     userfname: "", 
    //     usergender: 1, 
    //     useridcard: ""
    //   },
    //   examid: "",
    //   examreviewat: 0,
    //   examscore: 0,
    //   examsn: "",
    //   examstatus: 1,
    //   examteacher: undefined,
    //   examupdateat: 0,
    //   examvenue: {
    //     venueid: "", 
    //     venuesn: "", 
    //     venuemajor: {
    //       majorid:"",
    //       majorname: ""
    //     }, 
    //     venuestart: 0, 
    //     venuemaxcount: 0
    //   }
    // }
  ];

  public size = 'default';

  public selectedIsPass: any = "0";
  public dateRange: Date[] = [];
  private startValue: Date | null = null;
  private endValue: Date | null = null;

  constructor(private practiceService: PracticeService,
    private message: NzMessageService,
    private router: Router) { }

  onDateChange(date: Date[]) {
    if (date.length != 0) {
      date[0] = new Date(common.dateFtt("yyyy-MM-dd",date[0]) + " 00:00:00");
      date[1] = new Date(common.dateFtt("yyyy-MM-dd",date[1]) + " 23:59:59");
    }
    this.startValue = date[0];
    this.endValue = date[1];
  }

  onSearch(){
    let startDate = this.startValue.valueOf() / 1000;
    let endDate = this.endValue.valueOf() / 1000;
    this.practiceService.getPracticeList(parseInt(startDate.toString()),parseInt(endDate.toString()),
      (response) => {
	    let searchData = {start: startDate, end: endDate}
	    sessionStorage.setItem("practice-list-search-time", JSON.stringify(searchData))
        console.log(response);
        this.dataSet = response.dataList;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.practiceService, this.message);
        }
      })
  }

  onViewReport(practiceId:string) {
    this.router.navigate(['/practicereport'],{ queryParams: { practiceId: practiceId } });
  }

  ngOnInit() {
	let startDate, endDate
	let searchDataStr = sessionStorage.getItem("practice-list-search-time")
	if (searchDataStr != null) {
		let searchData = JSON.parse(searchDataStr);
		startDate = searchData.start;
		endDate = searchData.end;
		this.startValue = new Date(startDate * 1000)
		this.endValue = new Date(endDate * 1000)
	} else {
		this.endValue = new Date(new Date().toLocaleDateString() + " 23:59:59");
		let nowTime = new Date(new Date().toLocaleDateString());
		nowTime.setMonth(nowTime.getMonth() -1);
		this.startValue = new Date(common.dateFtt("yyyy-MM-dd",nowTime) + " 23:59:59");
		startDate = this.startValue.valueOf() / 1000;
		endDate = this.endValue.valueOf() / 1000;
	}
    this.dateRange = [this.startValue,this.endValue];
    this.user = this.practiceService.getUser();
    
    this.practiceService.getPracticeList(parseInt(startDate.toString()),parseInt(endDate.toString()),
      (response) => {
        console.log("resss", response);
        this.dataSet = response.dataList;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.practiceService, this.message);
        }
      });
  }
  
}
