import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-collect',
  templateUrl: './my-collect.component.html',
  styleUrls: ['./my-collect.component.styl']
})
export class MyCollectComponent implements OnInit {

  public listData:any = [];
  public pageSize:number = 12;
  public page:number = 1;
  public totalSize: number = 0;
  public user:any = {
    useravatar: "",
    userfname: ""
  };

  public host:string = environment.domainName;

  constructor(private router: Router,
	private userService: UserService,
    private courseService: CourseService,
    private message: NzMessageService) { 
    
  }
  
  onClick(item:any) {
    if(item.courseType == 0) {
      this.router.navigate(['/class/detail'],{ queryParams: { courseId: item.id } });
    } else {
      this.router.navigate(['/class/detail2'],{ queryParams: { courseId: item.id } });
    } 
  }
  
  getMyCollectCourse() {
    let param = {
      page: this.page,
      pageSize: this.pageSize
    }
    this.courseService.getMyCollect(JSON.stringify(param),
      (response) => {
        this.listData = response.dataList;
        //this.user = response.dataList[0].courseCreator;
        this.totalSize = response.count;
        console.log(this.listData);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

  indexChange(index) {
    this.page = index;
    this.getMyCollectCourse();
  }

  ngOnInit() {
	this.user = this.userService.getUser();
    this.getMyCollectCourse();
  }

}
