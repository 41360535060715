import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/event.service';
import { CourseService } from 'src/app/services/course.service';
import { NzMessageService } from 'ng-zorro-antd';
import { CourseCategoryService } from 'src/app/services/course-category.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

declare var $:any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.styl']
})
export class ListComponent implements OnInit {

  public host:string = environment.domainName;

  public listData:any = [];

  public pageSize:number = 12;

  public totalSize: number = 0;

  public page:number = 1;

  public keyword:string = null;

  public difficultyData = [
    {
      value: 0,
      name: "入门",
      active: false
    },
    {
      value: 1,
      name: "初级",
      active: false
    },
    {
      value: 2,
      name: "中级",
      active: false
    },
    {
      value: 3,
      name: "高级",
      active: false
    }
  ];

  public typesdata = [
  ];

  // public hotList:any = ["机械制图","计算机基础","CAD/CAM应用","工程力学","夹具设计","工业企业管理","机械制造工艺学"];

  private categoryId:any = null;
  private courseDifficulty:any = -1;

  constructor(private router: Router,
    private event: EventService,
    private message: NzMessageService,
    private courseService: CourseService,
	private titleService: Title,
    private courseCategoryService: CourseCategoryService) { }

  
  onSearchType(event:any,item:any) {
    $(".part1 .tag").removeClass("active");
    if (!item) {
      $(".part1 .tag").first().addClass("active");
      this.categoryId = null;
    } else {
      this.typesdata.forEach(el => {
        el.active = false;
        if (el.id == item.id) {
          el.active = true;
          this.categoryId = item.id;
        }
      });
    }
    this.getCourseList();
  }

  onSearchLeval(event:any,item:any) {
    $(".part2 .tag").removeClass("active");
    if (!item) {
      $(".part2 .tag").first().addClass("active");
      this.courseDifficulty = -1;
    } else {
      this.difficultyData.forEach(el => {
        el.active = false;
        if (el.value == item.value) {
          el.active = true;
          this.courseDifficulty = item.value;
        }
      });
    }
    this.getCourseList();
  }

  onKeyDown(event:any,type:number) {
    switch (type) {
      case 0:
        if (event.keyCode == 13) {
          this.getCourseList();
        }
        break;
      case 1:
        this.getCourseList();
        break;
      default:
        break;
    }
  }

  onClick(item:any) {
    if(item.courseType == 0) {
      this.router.navigate(['/class/detail'],{ queryParams: { courseId: item.id } });
    } else {
      this.router.navigate(['/class/detail2'],{ queryParams: { courseId: item.id } });
    }
    
    // setTimeout(()=>{
    //   this.event.sendDetailData(item);
    // },100);
    
  }

  onFav(item:any) {
    let type = 1; //0取消收藏 1收藏
    if (item.hasCollect) {
      type = 0
    } else {
      type = 1;
    }
    event.stopPropagation(); 
    this.courseService.setCollect(item.id,type,
    (response) => {
      this.listData.forEach(itm => {
        if(itm.id == item.id) {
          if (type == 0){
            itm.hasCollect = false;
          } else {
            itm.hasCollect = true;
          }
		  itm.collectNums = response.collectNum
        }
      });
    },
    (fail) => {
      this.message.create("error", decodeURI(fail));
      if (decodeURI(fail) == "token无效") {
        common.returnLogin(this.router, this.courseService, this.message);
      }
    }
  );
  }

  getCourseList() {
    let param = {
      categoryId: this.categoryId,
      courseDifficulty: this.courseDifficulty,
      creatorId: null,
      keyword: this.keyword,
      page: this.page,
      pageSize: this.pageSize
    }
    this.courseService.getList(JSON.stringify(param),
      (response) => {
        this.listData = response.dataList;
        this.totalSize = response.count;
        console.log(response.dataList);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }


  indexChange(index) {
    this.page = index;
    this.getCourseList();
  }

  ngOnInit() {
    //课程难度 0入门 1初级 2中级 3高级
    // this.listData = [
      // {
      //   courseFile: "/assets/img/1.mp4",
      //   courseName: "AutoCAD2020视频教程",
      //   courseDifficulty: 0,
      //   courseIntro: "开启AutoCAD 2018软件，您可以更轻松地指导入门PDF图形包括SHX字体、光栅图像等等",
      //   courseCoverImg: "/assets/img/class/1.png",
      //   courseDesc: "",
      //   courseType: 0
      // }
    // ]
	this.titleService.setTitle('巅思课程在线学习平台');
    this.courseCategoryService.getCourseCategoryList(
      (response) => {
        this.typesdata = response.dataList;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseCategoryService, this.message);
        }
      }
    );
    this.getCourseList();
  }
  
  toMyCollect() {
	this.router.navigate(['/class/myCollect']);
  }
}
