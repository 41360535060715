/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var venue_pb = require('./venue_pb.js')

var user_pb = require('./user_pb.js')

var cutter_pb = require('./cutter_pb.js')

var blank_pb = require('./blank_pb.js')

var score_standard_pb = require('./score_standard_pb.js')
const proto = {};
proto.pbs = require('./exams_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExamServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.ExamServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Exams>}
 */
const methodDescriptor_ExamService_ListResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/ListResult',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Exams>}
 */
const methodInfo_ExamService_ListResult = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exams)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exams>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.listResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/ListResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ListResult,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exams>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.listResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/ListResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ListResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Exam>}
 */
const methodDescriptor_ExamService_GetResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/GetResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.Exam,
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Exam>}
 */
const methodInfo_ExamService_GetResult = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.getResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/GetResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetResult,
      callback);
};


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exam>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.getResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/GetResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamService_EditResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/EditResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.Exam,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamService_EditResult = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.editResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/EditResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_EditResult,
      callback);
};


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.editResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/EditResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_EditResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Exam>}
 */
const methodDescriptor_ExamService_ReviewResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/ReviewResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.Exam,
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Exam>}
 */
const methodInfo_ExamService_ReviewResult = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.reviewResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/ReviewResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ReviewResult,
      callback);
};


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exam>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.reviewResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/ReviewResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ReviewResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamService_RemoveResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/RemoveResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.Exam,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Exam,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamService_RemoveResult = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.removeResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/RemoveResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_RemoveResult,
      callback);
};


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.removeResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/RemoveResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_RemoveResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ApplyForm,
 *   !proto.pbs.Exam>}
 */
const methodDescriptor_ExamService_Apply = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/Apply',
  grpc.web.MethodType.UNARY,
  proto.pbs.ApplyForm,
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.ApplyForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ApplyForm,
 *   !proto.pbs.Exam>}
 */
const methodInfo_ExamService_Apply = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.ApplyForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @param {!proto.pbs.ApplyForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.apply =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/Apply',
      request,
      metadata || {},
      methodDescriptor_ExamService_Apply,
      callback);
};


/**
 * @param {!proto.pbs.ApplyForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exam>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.apply =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/Apply',
      request,
      metadata || {},
      methodDescriptor_ExamService_Apply);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.SearchExamForm,
 *   !proto.pbs.Exams>}
 */
const methodDescriptor_ExamService_SearchResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/SearchResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.SearchExamForm,
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.SearchExamForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.SearchExamForm,
 *   !proto.pbs.Exams>}
 */
const methodInfo_ExamService_SearchResult = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.SearchExamForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @param {!proto.pbs.SearchExamForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exams)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exams>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.searchResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/SearchResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_SearchResult,
      callback);
};


/**
 * @param {!proto.pbs.SearchExamForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exams>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.searchResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/SearchResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_SearchResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.AnsForm,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamService_Answering = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/Answering',
  grpc.web.MethodType.UNARY,
  proto.pbs.AnsForm,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.AnsForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.AnsForm,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamService_Answering = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.AnsForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.AnsForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.answering =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/Answering',
      request,
      metadata || {},
      methodDescriptor_ExamService_Answering,
      callback);
};


/**
 * @param {!proto.pbs.AnsForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.answering =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/Answering',
      request,
      metadata || {},
      methodDescriptor_ExamService_Answering);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Exam>}
 */
const methodDescriptor_ExamService_StartExam = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/StartExam',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Exam>}
 */
const methodInfo_ExamService_StartExam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exam,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exam.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.startExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/StartExam',
      request,
      metadata || {},
      methodDescriptor_ExamService_StartExam,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exam>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.startExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/StartExam',
      request,
      metadata || {},
      methodDescriptor_ExamService_StartExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.ExistExamResp>}
 */
const methodDescriptor_ExamService_CheckExistExam = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/CheckExistExam',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.ExistExamResp,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ExistExamResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.ExistExamResp>}
 */
const methodInfo_ExamService_CheckExistExam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.ExistExamResp,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ExistExamResp.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.ExistExamResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.ExistExamResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.checkExistExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/CheckExistExam',
      request,
      metadata || {},
      methodDescriptor_ExamService_CheckExistExam,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.ExistExamResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.checkExistExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/CheckExistExam',
      request,
      metadata || {},
      methodDescriptor_ExamService_CheckExistExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Exam,
 *   !proto.pbs.CutterAndBlankResp>}
 */
const methodDescriptor_ExamService_GetCutterAndBlank = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/GetCutterAndBlank',
  grpc.web.MethodType.UNARY,
  proto.pbs.Exam,
  proto.pbs.CutterAndBlankResp,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.CutterAndBlankResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Exam,
 *   !proto.pbs.CutterAndBlankResp>}
 */
const methodInfo_ExamService_GetCutterAndBlank = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.CutterAndBlankResp,
  /**
   * @param {!proto.pbs.Exam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.CutterAndBlankResp.deserializeBinary
);


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.CutterAndBlankResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.CutterAndBlankResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.getCutterAndBlank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/GetCutterAndBlank',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetCutterAndBlank,
      callback);
};


/**
 * @param {!proto.pbs.Exam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.CutterAndBlankResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.getCutterAndBlank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/GetCutterAndBlank',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetCutterAndBlank);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetExamListReq,
 *   !proto.pbs.Exams>}
 */
const methodDescriptor_ExamService_GetExamList = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/GetExamList',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetExamListReq,
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.GetExamListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GetExamListReq,
 *   !proto.pbs.Exams>}
 */
const methodInfo_ExamService_GetExamList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.GetExamListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @param {!proto.pbs.GetExamListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exams)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exams>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.getExamList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/GetExamList',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetExamList,
      callback);
};


/**
 * @param {!proto.pbs.GetExamListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exams>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.getExamList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/GetExamList',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetExamList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetExamsReq,
 *   !proto.pbs.Exams>}
 */
const methodDescriptor_ExamService_GetExams = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/GetExams',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetExamsReq,
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.GetExamsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GetExamsReq,
 *   !proto.pbs.Exams>}
 */
const methodInfo_ExamService_GetExams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Exams,
  /**
   * @param {!proto.pbs.GetExamsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Exams.deserializeBinary
);


/**
 * @param {!proto.pbs.GetExamsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Exams)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Exams>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.getExams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/GetExams',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetExams,
      callback);
};


/**
 * @param {!proto.pbs.GetExamsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Exams>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.getExams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/GetExams',
      request,
      metadata || {},
      methodDescriptor_ExamService_GetExams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.RegisterAndApplyReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamService_RegisterAndApply = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/RegisterAndApply',
  grpc.web.MethodType.UNARY,
  proto.pbs.RegisterAndApplyReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterAndApplyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.RegisterAndApplyReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamService_RegisterAndApply = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.RegisterAndApplyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.RegisterAndApplyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.registerAndApply =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/RegisterAndApply',
      request,
      metadata || {},
      methodDescriptor_ExamService_RegisterAndApply,
      callback);
};


/**
 * @param {!proto.pbs.RegisterAndApplyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.registerAndApply =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/RegisterAndApply',
      request,
      metadata || {},
      methodDescriptor_ExamService_RegisterAndApply);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExtendExamReq,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_ExamService_ExtendExamTime = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/ExtendExamTime',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExtendExamReq,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ExtendExamReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExtendExamReq,
 *   !proto.pbs.Empty>}
 */
const methodInfo_ExamService_ExtendExamTime = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.ExtendExamReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.ExtendExamReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.extendExamTime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/ExtendExamTime',
      request,
      metadata || {},
      methodDescriptor_ExamService_ExtendExamTime,
      callback);
};


/**
 * @param {!proto.pbs.ExtendExamReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.extendExamTime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/ExtendExamTime',
      request,
      metadata || {},
      methodDescriptor_ExamService_ExtendExamTime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ExportResultReq,
 *   !proto.pbs.ExportResultResp>}
 */
const methodDescriptor_ExamService_ExportResult = new grpc.web.MethodDescriptor(
  '/pbs.ExamService/ExportResult',
  grpc.web.MethodType.UNARY,
  proto.pbs.ExportResultReq,
  proto.pbs.ExportResultResp,
  /**
   * @param {!proto.pbs.ExportResultReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ExportResultResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ExportResultReq,
 *   !proto.pbs.ExportResultResp>}
 */
const methodInfo_ExamService_ExportResult = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.ExportResultResp,
  /**
   * @param {!proto.pbs.ExportResultReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.ExportResultResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ExportResultReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.ExportResultResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.ExportResultResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.ExamServiceClient.prototype.exportResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.ExamService/ExportResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ExportResult,
      callback);
};


/**
 * @param {!proto.pbs.ExportResultReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.ExportResultResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.ExamServicePromiseClient.prototype.exportResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.ExamService/ExportResult',
      request,
      metadata || {},
      methodDescriptor_ExamService_ExportResult);
};


module.exports = proto.pbs;

