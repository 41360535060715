import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { ExamsService } from 'src/app/services/exams.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['../registration.component.styl','./step1.component.styl']
})
export class Step1Component implements OnInit {
	
	private venueId:string;
	
	constructor(private router: Router, 
		private examService: ExamsService, 
		private message: NzMessageService,
		private activatedRoute: ActivatedRoute,) {
		activatedRoute.queryParams.subscribe(queryParams => {
		  this.venueId = queryParams.venueId;})
	}

  ngOnInit() {
	  //this.checkExistExam()
	  console.log(environment.envType)
  }

  agree(){
	this.router.navigate(['/registration/step2']);
  }

  disagree(){
    this.router.navigate(['/entrance']);
  }
  
  toRedirectApplyStep(step) {
	  let url = ""
	  if (step == 1) {
		  url = '/registration/step1'
	  } else if (step == 2) {
		  url = '/registration/step2'
	  } else if (step == 3) {
		  url = '/registration/step3'
	  } else if (step == 4) {
		  url = '/registration/step4'
	  }
	  this.router.navigate([url],{ queryParams: { venueId: this.venueId } });
  }
  
  checkExistExam() {
	  //this.examService.checkExistExam()
	  // this.examService.checkExistExam((data)=>{
	  //    console.log("rrrr", data)
	  // },(err:string)=>{
	  //   this.toastr.error(decodeURI(err),'获取数据失败')
	  // })
	  this.examService.checkExistExam(
	    (response) => {
			console.log("main试卷",response);
	    }, (fail) => {
	      this.message.create("error",decodeURI(fail));
	      if (decodeURI(fail) == "token无效") {
	        common.returnLogin(this.router, this.examService, this.message);
	      } else {
	        this.router.navigate(['/entrance']);
	      }
	    }
	  ) 
  }
}
