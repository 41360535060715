import { Injectable } from '@angular/core';
import { Service } from './service';
import { PracticeScoreServiceClient } from '../pbs/practice_score_grpc_web_pb';
import { GetPracticeListReq, PracticeScores, PracticeScoreId, TotalPracticeScoreResp, MachineOperationResp, MachineParamResp, OperateProcessResp } from '../pbs/practice_score_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root',
})
export class PracticeService extends Service {

  practiceService:PracticeScoreServiceClient
  constructor() { 
    super()
    this.practiceService = new PracticeScoreServiceClient(this.url,null,null);
  }

  
  /**
   * @description 获得实训列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof PracticeService
   */
  getPracticeList(start: number,end: number,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
	let req = new GetPracticeListReq()
	req.setPracticeStartTime(start)
	req.setPracticeEndTime(end)
    this.practiceService.getPracticeList(req,
      this.metadata, (err:Error, response: PracticeScores) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

  /**
   * @description 获取实训成绩汇总
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getTotalPracticeScore(practiceId:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let practiceIdReq = new PracticeScoreId();
    practiceIdReq.setId(practiceId);
    this.practiceService.getTotalPracticeScore(practiceIdReq,
      this.metadata, (err: Error, response: TotalPracticeScoreResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }
  
  /**
   * @description 机床操作成绩
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getPracticeMachineOperation(practiceId:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let practiceIdReq = new PracticeScoreId();
    practiceIdReq.setId(practiceId);
    this.practiceService.getPracticeMachineOperation(practiceIdReq,
      this.metadata, (err: Error, response: MachineOperationResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }
  
  /**
   * @description 加工参数成绩
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getPracticeMachineParam(practiceId:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let practiceIdReq = new PracticeScoreId();
    practiceIdReq.setId(practiceId);
    this.practiceService.getPracticeMachineParam(practiceIdReq,
      this.metadata, (err: Error, response: MachineParamResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }
  
  /**
   * @description 操作流程
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getPracticeOperateProcess(practiceId:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let practiceIdReq = new PracticeScoreId();
    practiceIdReq.setId(practiceId);
    this.practiceService.getPracticeOperateProcess(practiceIdReq,
      this.metadata, (err: Error, response: OperateProcessResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }
}
