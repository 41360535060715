import { Injectable } from '@angular/core';
import { Service } from './service';
import { StandardItemNotifyServiceClient } from '../pbs/standard_item_notify_grpc_web_pb';
import { StandardItemNotifyListReq, StandardItemNotifies } from '../pbs/standard_item_notify_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class StandardItemNotifyService extends Service {

  standardItemNotifyService: StandardItemNotifyServiceClient;
  constructor() { 
    super()
    this.standardItemNotifyService = new StandardItemNotifyServiceClient(this.url, null, null);
  }

  /**
   * @description 
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof MajorService
   */
  getStandardItemNotifyList(standardType:Number,callback:Function=null,fail:Function=null) {
    let isOk = false;
    let req = new StandardItemNotifyListReq();
    req.setItemType(-1)
    req.setStandardType(standardType)
	//console.log("token", this.metadata)
    this.standardItemNotifyService.list(req,
      this.metadata, (err:Error, response: StandardItemNotifies) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
}
