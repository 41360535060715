// source: practice_score.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var exam_score_pb = require('./exam_score_pb.js');
goog.object.extend(proto, exam_score_pb);
var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
goog.exportSymbol('proto.pbs.CreatePracticeReq', null, global);
goog.exportSymbol('proto.pbs.GetPracticeListReq', null, global);
goog.exportSymbol('proto.pbs.PracticeCutterAndBlankResp', null, global);
goog.exportSymbol('proto.pbs.PracticeListReq', null, global);
goog.exportSymbol('proto.pbs.PracticeScore', null, global);
goog.exportSymbol('proto.pbs.PracticeScoreId', null, global);
goog.exportSymbol('proto.pbs.PracticeScores', null, global);
goog.exportSymbol('proto.pbs.ScoreReq', null, global);
goog.exportSymbol('proto.pbs.TotalPracticeScoreResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PracticeCutterAndBlankResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PracticeCutterAndBlankResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.PracticeCutterAndBlankResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PracticeCutterAndBlankResp.displayName = 'proto.pbs.PracticeCutterAndBlankResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PracticeListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PracticeListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PracticeListReq.displayName = 'proto.pbs.PracticeListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetPracticeListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GetPracticeListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetPracticeListReq.displayName = 'proto.pbs.GetPracticeListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PracticeScores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PracticeScores.repeatedFields_, null);
};
goog.inherits(proto.pbs.PracticeScores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PracticeScores.displayName = 'proto.pbs.PracticeScores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.TotalPracticeScoreResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.TotalPracticeScoreResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.TotalPracticeScoreResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.TotalPracticeScoreResp.displayName = 'proto.pbs.TotalPracticeScoreResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ScoreReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.ScoreReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.ScoreReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ScoreReq.displayName = 'proto.pbs.ScoreReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CreatePracticeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.CreatePracticeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CreatePracticeReq.displayName = 'proto.pbs.CreatePracticeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PracticeScoreId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PracticeScoreId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PracticeScoreId.displayName = 'proto.pbs.PracticeScoreId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PracticeScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.PracticeScore.repeatedFields_, null);
};
goog.inherits(proto.pbs.PracticeScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PracticeScore.displayName = 'proto.pbs.PracticeScore';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PracticeCutterAndBlankResp.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PracticeCutterAndBlankResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PracticeCutterAndBlankResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeCutterAndBlankResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    cutterList: jspb.Message.toObjectList(msg.getCutterList(),
    cutter_pb.Cutter.toObject, includeInstance),
    blankList: jspb.Message.toObjectList(msg.getBlankList(),
    blank_pb.Blank.toObject, includeInstance),
    workpieceList: jspb.Message.toObjectList(msg.getWorkpieceList(),
    score_standard_pb.WorkpieceParamMilling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PracticeCutterAndBlankResp}
 */
proto.pbs.PracticeCutterAndBlankResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PracticeCutterAndBlankResp;
  return proto.pbs.PracticeCutterAndBlankResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PracticeCutterAndBlankResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PracticeCutterAndBlankResp}
 */
proto.pbs.PracticeCutterAndBlankResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addCutter(value);
      break;
    case 2:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.addBlank(value);
      break;
    case 3:
      var value = new score_standard_pb.WorkpieceParamMilling;
      reader.readMessage(value,score_standard_pb.WorkpieceParamMilling.deserializeBinaryFromReader);
      msg.addWorkpiece(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PracticeCutterAndBlankResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PracticeCutterAndBlankResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeCutterAndBlankResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCutterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getBlankList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      score_standard_pb.WorkpieceParamMilling.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cutter cutter = 1;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.getCutterList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 1));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
*/
proto.pbs.PracticeCutterAndBlankResp.prototype.setCutterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.addCutter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.clearCutterList = function() {
  return this.setCutterList([]);
};


/**
 * repeated Blank blank = 2;
 * @return {!Array<!proto.pbs.Blank>}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.getBlankList = function() {
  return /** @type{!Array<!proto.pbs.Blank>} */ (
    jspb.Message.getRepeatedWrapperField(this, blank_pb.Blank, 2));
};


/**
 * @param {!Array<!proto.pbs.Blank>} value
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
*/
proto.pbs.PracticeCutterAndBlankResp.prototype.setBlankList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.Blank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Blank}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.addBlank = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.Blank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.clearBlankList = function() {
  return this.setBlankList([]);
};


/**
 * repeated WorkpieceParamMilling workpiece = 3;
 * @return {!Array<!proto.pbs.WorkpieceParamMilling>}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.getWorkpieceList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParamMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, score_standard_pb.WorkpieceParamMilling, 3));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParamMilling>} value
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
*/
proto.pbs.PracticeCutterAndBlankResp.prototype.setWorkpieceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.pbs.WorkpieceParamMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.addWorkpiece = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pbs.WorkpieceParamMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PracticeCutterAndBlankResp} returns this
 */
proto.pbs.PracticeCutterAndBlankResp.prototype.clearWorkpieceList = function() {
  return this.setWorkpieceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PracticeListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PracticeListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PracticeListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 5, ""),
    page: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PracticeListReq}
 */
proto.pbs.PracticeListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PracticeListReq;
  return proto.pbs.PracticeListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PracticeListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PracticeListReq}
 */
proto.pbs.PracticeListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PracticeListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PracticeListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PracticeListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.PracticeListReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.PracticeListReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.PracticeListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string class_id = 4;
 * @return {string}
 */
proto.pbs.PracticeListReq.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string keyword = 5;
 * @return {string}
 */
proto.pbs.PracticeListReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 page = 6;
 * @return {number}
 */
proto.pbs.PracticeListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 page_size = 7;
 * @return {number}
 */
proto.pbs.PracticeListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeListReq} returns this
 */
proto.pbs.PracticeListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetPracticeListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetPracticeListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetPracticeListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetPracticeListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    practiceStartTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    practiceEndTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetPracticeListReq}
 */
proto.pbs.GetPracticeListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetPracticeListReq;
  return proto.pbs.GetPracticeListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetPracticeListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetPracticeListReq}
 */
proto.pbs.GetPracticeListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPracticeStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPracticeEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetPracticeListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetPracticeListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetPracticeListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetPracticeListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPracticeStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPracticeEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.pbs.GetPracticeListReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetPracticeListReq} returns this
 */
proto.pbs.GetPracticeListReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 practice_start_time = 2;
 * @return {number}
 */
proto.pbs.GetPracticeListReq.prototype.getPracticeStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetPracticeListReq} returns this
 */
proto.pbs.GetPracticeListReq.prototype.setPracticeStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 practice_end_time = 3;
 * @return {number}
 */
proto.pbs.GetPracticeListReq.prototype.getPracticeEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetPracticeListReq} returns this
 */
proto.pbs.GetPracticeListReq.prototype.setPracticeEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.pbs.GetPracticeListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetPracticeListReq} returns this
 */
proto.pbs.GetPracticeListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 page_size = 5;
 * @return {number}
 */
proto.pbs.GetPracticeListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetPracticeListReq} returns this
 */
proto.pbs.GetPracticeListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PracticeScores.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PracticeScores.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PracticeScores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PracticeScores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScores.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.PracticeScore.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PracticeScores}
 */
proto.pbs.PracticeScores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PracticeScores;
  return proto.pbs.PracticeScores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PracticeScores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PracticeScores}
 */
proto.pbs.PracticeScores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PracticeScore;
      reader.readMessage(value,proto.pbs.PracticeScore.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PracticeScores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PracticeScores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PracticeScores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.PracticeScore.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PracticeScore data = 1;
 * @return {!Array<!proto.pbs.PracticeScore>}
 */
proto.pbs.PracticeScores.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.PracticeScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.PracticeScore, 1));
};


/**
 * @param {!Array<!proto.pbs.PracticeScore>} value
 * @return {!proto.pbs.PracticeScores} returns this
*/
proto.pbs.PracticeScores.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.PracticeScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.PracticeScore}
 */
proto.pbs.PracticeScores.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.PracticeScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PracticeScores} returns this
 */
proto.pbs.PracticeScores.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.PracticeScores.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScores} returns this
 */
proto.pbs.PracticeScores.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.TotalPracticeScoreResp.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.TotalPracticeScoreResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.TotalPracticeScoreResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.TotalPracticeScoreResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.TotalPracticeScoreResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    practiceInfo: (f = msg.getPracticeInfo()) && proto.pbs.PracticeScore.toObject(includeInstance, f),
    blankScore: (f = msg.getBlankScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    clampScore: (f = msg.getClampScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    cutterScore: (f = msg.getCutterScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    operateScore: (f = msg.getOperateScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    machineParamScore: (f = msg.getMachineParamScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    machineProcessScore: (f = msg.getMachineProcessScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    workpieceQualityScore: (f = msg.getWorkpieceQualityScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    machineEffectiveScore: (f = msg.getMachineEffectiveScore()) && exam_score_pb.EachItemScore.toObject(includeInstance, f),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    exam_score_pb.ModelImage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.TotalPracticeScoreResp}
 */
proto.pbs.TotalPracticeScoreResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.TotalPracticeScoreResp;
  return proto.pbs.TotalPracticeScoreResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.TotalPracticeScoreResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.TotalPracticeScoreResp}
 */
proto.pbs.TotalPracticeScoreResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.PracticeScore;
      reader.readMessage(value,proto.pbs.PracticeScore.deserializeBinaryFromReader);
      msg.setPracticeInfo(value);
      break;
    case 3:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setBlankScore(value);
      break;
    case 4:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setClampScore(value);
      break;
    case 5:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setCutterScore(value);
      break;
    case 6:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setOperateScore(value);
      break;
    case 7:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineParamScore(value);
      break;
    case 8:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineProcessScore(value);
      break;
    case 9:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setWorkpieceQualityScore(value);
      break;
    case 10:
      var value = new exam_score_pb.EachItemScore;
      reader.readMessage(value,exam_score_pb.EachItemScore.deserializeBinaryFromReader);
      msg.setMachineEffectiveScore(value);
      break;
    case 11:
      var value = new exam_score_pb.ModelImage;
      reader.readMessage(value,exam_score_pb.ModelImage.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.TotalPracticeScoreResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.TotalPracticeScoreResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.TotalPracticeScoreResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.TotalPracticeScoreResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPracticeInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pbs.PracticeScore.serializeBinaryToWriter
    );
  }
  f = message.getBlankScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getClampScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getOperateScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineParamScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffectiveScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      exam_score_pb.EachItemScore.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      exam_score_pb.ModelImage.serializeBinaryToWriter
    );
  }
};


/**
 * optional PracticeScore practice_info = 1;
 * @return {?proto.pbs.PracticeScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getPracticeInfo = function() {
  return /** @type{?proto.pbs.PracticeScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.PracticeScore, 1));
};


/**
 * @param {?proto.pbs.PracticeScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setPracticeInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearPracticeInfo = function() {
  return this.setPracticeInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasPracticeInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EachItemScore blank_score = 3;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getBlankScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 3));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setBlankScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearBlankScore = function() {
  return this.setBlankScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasBlankScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EachItemScore clamp_score = 4;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getClampScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 4));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setClampScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearClampScore = function() {
  return this.setClampScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasClampScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EachItemScore cutter_score = 5;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getCutterScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 5));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setCutterScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearCutterScore = function() {
  return this.setCutterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasCutterScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EachItemScore operate_score = 6;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getOperateScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 6));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setOperateScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearOperateScore = function() {
  return this.setOperateScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasOperateScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EachItemScore machine_param_score = 7;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getMachineParamScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 7));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setMachineParamScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearMachineParamScore = function() {
  return this.setMachineParamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasMachineParamScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EachItemScore machine_process_score = 8;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getMachineProcessScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 8));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearMachineProcessScore = function() {
  return this.setMachineProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasMachineProcessScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EachItemScore workpiece_quality_score = 9;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getWorkpieceQualityScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 9));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearWorkpieceQualityScore = function() {
  return this.setWorkpieceQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasWorkpieceQualityScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EachItemScore machine_effective_score = 10;
 * @return {?proto.pbs.EachItemScore}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getMachineEffectiveScore = function() {
  return /** @type{?proto.pbs.EachItemScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.EachItemScore, 10));
};


/**
 * @param {?proto.pbs.EachItemScore|undefined} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearMachineEffectiveScore = function() {
  return this.setMachineEffectiveScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.TotalPracticeScoreResp.prototype.hasMachineEffectiveScore = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated ModelImage model_image = 11;
 * @return {!Array<!proto.pbs.ModelImage>}
 */
proto.pbs.TotalPracticeScoreResp.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.ModelImage, 11));
};


/**
 * @param {!Array<!proto.pbs.ModelImage>} value
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
*/
proto.pbs.TotalPracticeScoreResp.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.ModelImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.TotalPracticeScoreResp.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.ModelImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.TotalPracticeScoreResp} returns this
 */
proto.pbs.TotalPracticeScoreResp.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.ScoreReq.repeatedFields_ = [2,10,11,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ScoreReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ScoreReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ScoreReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    practiceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cutterIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    blankId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ncRunTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    installCutterNum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    changeBlankNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hitCutterNum: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ncProgramMap: (f = msg.getNcProgramMap()) ? f.toObject(includeInstance, undefined) : [],
    clampsList: jspb.Message.toObjectList(msg.getClampsList(),
    exam_score_pb.Clamp.toObject, includeInstance),
    craftsList: jspb.Message.toObjectList(msg.getCraftsList(),
    exam_score_pb.MachineCraft.toObject, includeInstance),
    workpiecesList: jspb.Message.toObjectList(msg.getWorkpiecesList(),
    exam_score_pb.Workpiece.toObject, includeInstance),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    exam_score_pb.ModelImageByte.toObject, includeInstance),
    workpieceMillingList: jspb.Message.toObjectList(msg.getWorkpieceMillingList(),
    exam_score_pb.WorkpieceMilling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ScoreReq}
 */
proto.pbs.ScoreReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ScoreReq;
  return proto.pbs.ScoreReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ScoreReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ScoreReq}
 */
proto.pbs.ScoreReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPracticeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCutterIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlankId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNcRunTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallCutterNum(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangeBlankNum(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHitCutterNum(value);
      break;
    case 9:
      var value = msg.getNcProgramMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = new exam_score_pb.Clamp;
      reader.readMessage(value,exam_score_pb.Clamp.deserializeBinaryFromReader);
      msg.addClamps(value);
      break;
    case 11:
      var value = new exam_score_pb.MachineCraft;
      reader.readMessage(value,exam_score_pb.MachineCraft.deserializeBinaryFromReader);
      msg.addCrafts(value);
      break;
    case 12:
      var value = new exam_score_pb.Workpiece;
      reader.readMessage(value,exam_score_pb.Workpiece.deserializeBinaryFromReader);
      msg.addWorkpieces(value);
      break;
    case 13:
      var value = new exam_score_pb.ModelImageByte;
      reader.readMessage(value,exam_score_pb.ModelImageByte.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    case 14:
      var value = new exam_score_pb.WorkpieceMilling;
      reader.readMessage(value,exam_score_pb.WorkpieceMilling.deserializeBinaryFromReader);
      msg.addWorkpieceMilling(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ScoreReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ScoreReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ScoreReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ScoreReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPracticeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCutterIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBlankId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNcRunTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getInstallCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getChangeBlankNum();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getHitCutterNum();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNcProgramMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getClampsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      exam_score_pb.Clamp.serializeBinaryToWriter
    );
  }
  f = message.getCraftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      exam_score_pb.MachineCraft.serializeBinaryToWriter
    );
  }
  f = message.getWorkpiecesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      exam_score_pb.Workpiece.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      exam_score_pb.ModelImageByte.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceMillingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      exam_score_pb.WorkpieceMilling.serializeBinaryToWriter
    );
  }
};


/**
 * optional string practice_id = 1;
 * @return {string}
 */
proto.pbs.ScoreReq.prototype.getPracticeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setPracticeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string cutter_ids = 2;
 * @return {!Array<string>}
 */
proto.pbs.ScoreReq.prototype.getCutterIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setCutterIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.addCutterIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearCutterIdsList = function() {
  return this.setCutterIdsList([]);
};


/**
 * optional string blank_id = 3;
 * @return {string}
 */
proto.pbs.ScoreReq.prototype.getBlankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setBlankId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double nc_run_time = 5;
 * @return {number}
 */
proto.pbs.ScoreReq.prototype.getNcRunTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setNcRunTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 install_cutter_num = 6;
 * @return {number}
 */
proto.pbs.ScoreReq.prototype.getInstallCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setInstallCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 change_blank_num = 7;
 * @return {number}
 */
proto.pbs.ScoreReq.prototype.getChangeBlankNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setChangeBlankNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 hit_cutter_num = 8;
 * @return {number}
 */
proto.pbs.ScoreReq.prototype.getHitCutterNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.setHitCutterNum = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * map<string, string> nc_program = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.ScoreReq.prototype.getNcProgramMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearNcProgramMap = function() {
  this.getNcProgramMap().clear();
  return this;
};


/**
 * repeated Clamp clamps = 10;
 * @return {!Array<!proto.pbs.Clamp>}
 */
proto.pbs.ScoreReq.prototype.getClampsList = function() {
  return /** @type{!Array<!proto.pbs.Clamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.Clamp, 10));
};


/**
 * @param {!Array<!proto.pbs.Clamp>} value
 * @return {!proto.pbs.ScoreReq} returns this
*/
proto.pbs.ScoreReq.prototype.setClampsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.pbs.Clamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Clamp}
 */
proto.pbs.ScoreReq.prototype.addClamps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.pbs.Clamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearClampsList = function() {
  return this.setClampsList([]);
};


/**
 * repeated MachineCraft crafts = 11;
 * @return {!Array<!proto.pbs.MachineCraft>}
 */
proto.pbs.ScoreReq.prototype.getCraftsList = function() {
  return /** @type{!Array<!proto.pbs.MachineCraft>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.MachineCraft, 11));
};


/**
 * @param {!Array<!proto.pbs.MachineCraft>} value
 * @return {!proto.pbs.ScoreReq} returns this
*/
proto.pbs.ScoreReq.prototype.setCraftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.pbs.MachineCraft=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.MachineCraft}
 */
proto.pbs.ScoreReq.prototype.addCrafts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.pbs.MachineCraft, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearCraftsList = function() {
  return this.setCraftsList([]);
};


/**
 * repeated Workpiece workpieces = 12;
 * @return {!Array<!proto.pbs.Workpiece>}
 */
proto.pbs.ScoreReq.prototype.getWorkpiecesList = function() {
  return /** @type{!Array<!proto.pbs.Workpiece>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.Workpiece, 12));
};


/**
 * @param {!Array<!proto.pbs.Workpiece>} value
 * @return {!proto.pbs.ScoreReq} returns this
*/
proto.pbs.ScoreReq.prototype.setWorkpiecesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.pbs.Workpiece=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Workpiece}
 */
proto.pbs.ScoreReq.prototype.addWorkpieces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.pbs.Workpiece, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearWorkpiecesList = function() {
  return this.setWorkpiecesList([]);
};


/**
 * repeated ModelImageByte model_image = 13;
 * @return {!Array<!proto.pbs.ModelImageByte>}
 */
proto.pbs.ScoreReq.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImageByte>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.ModelImageByte, 13));
};


/**
 * @param {!Array<!proto.pbs.ModelImageByte>} value
 * @return {!proto.pbs.ScoreReq} returns this
*/
proto.pbs.ScoreReq.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.pbs.ModelImageByte=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImageByte}
 */
proto.pbs.ScoreReq.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.pbs.ModelImageByte, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};


/**
 * repeated WorkpieceMilling workpiece_milling = 14;
 * @return {!Array<!proto.pbs.WorkpieceMilling>}
 */
proto.pbs.ScoreReq.prototype.getWorkpieceMillingList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.WorkpieceMilling, 14));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceMilling>} value
 * @return {!proto.pbs.ScoreReq} returns this
*/
proto.pbs.ScoreReq.prototype.setWorkpieceMillingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.WorkpieceMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceMilling}
 */
proto.pbs.ScoreReq.prototype.addWorkpieceMilling = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.WorkpieceMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.ScoreReq} returns this
 */
proto.pbs.ScoreReq.prototype.clearWorkpieceMillingList = function() {
  return this.setWorkpieceMillingList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CreatePracticeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CreatePracticeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CreatePracticeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CreatePracticeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    standardId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CreatePracticeReq}
 */
proto.pbs.CreatePracticeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CreatePracticeReq;
  return proto.pbs.CreatePracticeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CreatePracticeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CreatePracticeReq}
 */
proto.pbs.CreatePracticeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStandardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CreatePracticeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CreatePracticeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CreatePracticeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CreatePracticeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStandardId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string standard_id = 1;
 * @return {string}
 */
proto.pbs.CreatePracticeReq.prototype.getStandardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.CreatePracticeReq} returns this
 */
proto.pbs.CreatePracticeReq.prototype.setStandardId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PracticeScoreId.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PracticeScoreId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PracticeScoreId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScoreId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PracticeScoreId}
 */
proto.pbs.PracticeScoreId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PracticeScoreId;
  return proto.pbs.PracticeScoreId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PracticeScoreId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PracticeScoreId}
 */
proto.pbs.PracticeScoreId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PracticeScoreId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PracticeScoreId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PracticeScoreId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScoreId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.PracticeScoreId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeScoreId} returns this
 */
proto.pbs.PracticeScoreId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.PracticeScore.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PracticeScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PracticeScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PracticeScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && user_pb.User.toObject(includeInstance, f),
    blankScore: (f = msg.getBlankScore()) && exam_score_pb.BlankScore.toObject(includeInstance, f),
    clampScore: (f = msg.getClampScore()) && exam_score_pb.ClampScore.toObject(includeInstance, f),
    cutterScore: (f = msg.getCutterScore()) && exam_score_pb.CutterScore.toObject(includeInstance, f),
    machineScore: (f = msg.getMachineScore()) && exam_score_pb.MachineScore.toObject(includeInstance, f),
    machineProcessScore: (f = msg.getMachineProcessScore()) && exam_score_pb.MachineProcessScore.toObject(includeInstance, f),
    workpieceQualityScore: (f = msg.getWorkpieceQualityScore()) && exam_score_pb.WorkpieceQualityScore.toObject(includeInstance, f),
    machineEffectiveScore: (f = msg.getMachineEffectiveScore()) && exam_score_pb.MachineEffectiveScore.toObject(includeInstance, f),
    operateProcessScore: (f = msg.getOperateProcessScore()) && exam_score_pb.OperateProcessScore.toObject(includeInstance, f),
    ncProgramMap: (f = msg.getNcProgramMap()) ? f.toObject(includeInstance, undefined) : [],
    operateLogScore: (f = msg.getOperateLogScore()) && exam_score_pb.OperateLogScore.toObject(includeInstance, f),
    modelImageList: jspb.Message.toObjectList(msg.getModelImageList(),
    exam_score_pb.ModelImage.toObject, includeInstance),
    scoreStandard: (f = msg.getScoreStandard()) && score_standard_pb.ScoreStandard.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 16, 0),
    totalScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    totalScoreRefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PracticeScore}
 */
proto.pbs.PracticeScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PracticeScore;
  return proto.pbs.PracticeScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PracticeScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PracticeScore}
 */
proto.pbs.PracticeScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = new exam_score_pb.BlankScore;
      reader.readMessage(value,exam_score_pb.BlankScore.deserializeBinaryFromReader);
      msg.setBlankScore(value);
      break;
    case 5:
      var value = new exam_score_pb.ClampScore;
      reader.readMessage(value,exam_score_pb.ClampScore.deserializeBinaryFromReader);
      msg.setClampScore(value);
      break;
    case 6:
      var value = new exam_score_pb.CutterScore;
      reader.readMessage(value,exam_score_pb.CutterScore.deserializeBinaryFromReader);
      msg.setCutterScore(value);
      break;
    case 7:
      var value = new exam_score_pb.MachineScore;
      reader.readMessage(value,exam_score_pb.MachineScore.deserializeBinaryFromReader);
      msg.setMachineScore(value);
      break;
    case 8:
      var value = new exam_score_pb.MachineProcessScore;
      reader.readMessage(value,exam_score_pb.MachineProcessScore.deserializeBinaryFromReader);
      msg.setMachineProcessScore(value);
      break;
    case 9:
      var value = new exam_score_pb.WorkpieceQualityScore;
      reader.readMessage(value,exam_score_pb.WorkpieceQualityScore.deserializeBinaryFromReader);
      msg.setWorkpieceQualityScore(value);
      break;
    case 10:
      var value = new exam_score_pb.MachineEffectiveScore;
      reader.readMessage(value,exam_score_pb.MachineEffectiveScore.deserializeBinaryFromReader);
      msg.setMachineEffectiveScore(value);
      break;
    case 11:
      var value = new exam_score_pb.OperateProcessScore;
      reader.readMessage(value,exam_score_pb.OperateProcessScore.deserializeBinaryFromReader);
      msg.setOperateProcessScore(value);
      break;
    case 12:
      var value = msg.getNcProgramMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 13:
      var value = new exam_score_pb.OperateLogScore;
      reader.readMessage(value,exam_score_pb.OperateLogScore.deserializeBinaryFromReader);
      msg.setOperateLogScore(value);
      break;
    case 14:
      var value = new exam_score_pb.ModelImage;
      reader.readMessage(value,exam_score_pb.ModelImage.deserializeBinaryFromReader);
      msg.addModelImage(value);
      break;
    case 15:
      var value = new score_standard_pb.ScoreStandard;
      reader.readMessage(value,score_standard_pb.ScoreStandard.deserializeBinaryFromReader);
      msg.setScoreStandard(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScore(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalScoreRefer(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PracticeScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PracticeScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PracticeScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PracticeScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBlankScore();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      exam_score_pb.BlankScore.serializeBinaryToWriter
    );
  }
  f = message.getClampScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      exam_score_pb.ClampScore.serializeBinaryToWriter
    );
  }
  f = message.getCutterScore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      exam_score_pb.CutterScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineScore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      exam_score_pb.MachineScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineProcessScore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      exam_score_pb.MachineProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      exam_score_pb.WorkpieceQualityScore.serializeBinaryToWriter
    );
  }
  f = message.getMachineEffectiveScore();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      exam_score_pb.MachineEffectiveScore.serializeBinaryToWriter
    );
  }
  f = message.getOperateProcessScore();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      exam_score_pb.OperateProcessScore.serializeBinaryToWriter
    );
  }
  f = message.getNcProgramMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getOperateLogScore();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      exam_score_pb.OperateLogScore.serializeBinaryToWriter
    );
  }
  f = message.getModelImageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      exam_score_pb.ModelImage.serializeBinaryToWriter
    );
  }
  f = message.getScoreStandard();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      score_standard_pb.ScoreStandard.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getTotalScoreRefer();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.pbs.PracticeScore.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 3;
 * @return {?proto.pbs.User}
 */
proto.pbs.PracticeScore.prototype.getUser = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 3));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BlankScore blank_score = 4;
 * @return {?proto.pbs.BlankScore}
 */
proto.pbs.PracticeScore.prototype.getBlankScore = function() {
  return /** @type{?proto.pbs.BlankScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.BlankScore, 4));
};


/**
 * @param {?proto.pbs.BlankScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setBlankScore = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearBlankScore = function() {
  return this.setBlankScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasBlankScore = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClampScore clamp_score = 5;
 * @return {?proto.pbs.ClampScore}
 */
proto.pbs.PracticeScore.prototype.getClampScore = function() {
  return /** @type{?proto.pbs.ClampScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.ClampScore, 5));
};


/**
 * @param {?proto.pbs.ClampScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setClampScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearClampScore = function() {
  return this.setClampScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasClampScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CutterScore cutter_score = 6;
 * @return {?proto.pbs.CutterScore}
 */
proto.pbs.PracticeScore.prototype.getCutterScore = function() {
  return /** @type{?proto.pbs.CutterScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.CutterScore, 6));
};


/**
 * @param {?proto.pbs.CutterScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setCutterScore = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearCutterScore = function() {
  return this.setCutterScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasCutterScore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MachineScore machine_score = 7;
 * @return {?proto.pbs.MachineScore}
 */
proto.pbs.PracticeScore.prototype.getMachineScore = function() {
  return /** @type{?proto.pbs.MachineScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.MachineScore, 7));
};


/**
 * @param {?proto.pbs.MachineScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setMachineScore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearMachineScore = function() {
  return this.setMachineScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasMachineScore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MachineProcessScore machine_process_score = 8;
 * @return {?proto.pbs.MachineProcessScore}
 */
proto.pbs.PracticeScore.prototype.getMachineProcessScore = function() {
  return /** @type{?proto.pbs.MachineProcessScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.MachineProcessScore, 8));
};


/**
 * @param {?proto.pbs.MachineProcessScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearMachineProcessScore = function() {
  return this.setMachineProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasMachineProcessScore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WorkpieceQualityScore workpiece_quality_score = 9;
 * @return {?proto.pbs.WorkpieceQualityScore}
 */
proto.pbs.PracticeScore.prototype.getWorkpieceQualityScore = function() {
  return /** @type{?proto.pbs.WorkpieceQualityScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.WorkpieceQualityScore, 9));
};


/**
 * @param {?proto.pbs.WorkpieceQualityScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearWorkpieceQualityScore = function() {
  return this.setWorkpieceQualityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasWorkpieceQualityScore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MachineEffectiveScore machine_effective_score = 10;
 * @return {?proto.pbs.MachineEffectiveScore}
 */
proto.pbs.PracticeScore.prototype.getMachineEffectiveScore = function() {
  return /** @type{?proto.pbs.MachineEffectiveScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.MachineEffectiveScore, 10));
};


/**
 * @param {?proto.pbs.MachineEffectiveScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearMachineEffectiveScore = function() {
  return this.setMachineEffectiveScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasMachineEffectiveScore = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OperateProcessScore operate_process_score = 11;
 * @return {?proto.pbs.OperateProcessScore}
 */
proto.pbs.PracticeScore.prototype.getOperateProcessScore = function() {
  return /** @type{?proto.pbs.OperateProcessScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.OperateProcessScore, 11));
};


/**
 * @param {?proto.pbs.OperateProcessScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setOperateProcessScore = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearOperateProcessScore = function() {
  return this.setOperateProcessScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasOperateProcessScore = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * map<string, string> nc_program = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pbs.PracticeScore.prototype.getNcProgramMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearNcProgramMap = function() {
  this.getNcProgramMap().clear();
  return this;
};


/**
 * optional OperateLogScore operate_log_score = 13;
 * @return {?proto.pbs.OperateLogScore}
 */
proto.pbs.PracticeScore.prototype.getOperateLogScore = function() {
  return /** @type{?proto.pbs.OperateLogScore} */ (
    jspb.Message.getWrapperField(this, exam_score_pb.OperateLogScore, 13));
};


/**
 * @param {?proto.pbs.OperateLogScore|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setOperateLogScore = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearOperateLogScore = function() {
  return this.setOperateLogScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasOperateLogScore = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated ModelImage model_image = 14;
 * @return {!Array<!proto.pbs.ModelImage>}
 */
proto.pbs.PracticeScore.prototype.getModelImageList = function() {
  return /** @type{!Array<!proto.pbs.ModelImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, exam_score_pb.ModelImage, 14));
};


/**
 * @param {!Array<!proto.pbs.ModelImage>} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setModelImageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.pbs.ModelImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.ModelImage}
 */
proto.pbs.PracticeScore.prototype.addModelImage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.pbs.ModelImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearModelImageList = function() {
  return this.setModelImageList([]);
};


/**
 * optional ScoreStandard score_standard = 15;
 * @return {?proto.pbs.ScoreStandard}
 */
proto.pbs.PracticeScore.prototype.getScoreStandard = function() {
  return /** @type{?proto.pbs.ScoreStandard} */ (
    jspb.Message.getWrapperField(this, score_standard_pb.ScoreStandard, 15));
};


/**
 * @param {?proto.pbs.ScoreStandard|undefined} value
 * @return {!proto.pbs.PracticeScore} returns this
*/
proto.pbs.PracticeScore.prototype.setScoreStandard = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.clearScoreStandard = function() {
  return this.setScoreStandard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.PracticeScore.prototype.hasScoreStandard = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int32 status = 16;
 * @return {number}
 */
proto.pbs.PracticeScore.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double total_score = 19;
 * @return {number}
 */
proto.pbs.PracticeScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double total_score_refer = 20;
 * @return {number}
 */
proto.pbs.PracticeScore.prototype.getTotalScoreRefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setTotalScoreRefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional int64 created_at = 17;
 * @return {number}
 */
proto.pbs.PracticeScore.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 updated_at = 18;
 * @return {number}
 */
proto.pbs.PracticeScore.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PracticeScore} returns this
 */
proto.pbs.PracticeScore.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


goog.object.extend(exports, proto.pbs);
