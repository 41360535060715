// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envType: 'env',
  // domainName: 'http://58.246.129.34:8022'
  domainName: 'https://img.vsteach.com'
  // domainName: 'http://172.30.12.33:8009'
  // domainName: 'http://office.vsteach.com:8022'
  // domainName: 'http://10.0.0.55:8022'
  // domainName: 'http://172.18.0.132:8090'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
